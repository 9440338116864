export const apiEndPoints = {
  auth: {
    login: 'auth/signin',
    refreshToken: 'auth/refresh_token',
  },
  user: {
    userId: 'user/',
    username: 'user/userName?username=',
  },
  files: {
    uploadFile: 'file/upload',
    downloadFile: '/file/download?fileName=',
    deleteFile: 'file/delete?fileName=',
  },
  projectData: {
    submitProjectData: 'draft/project/submit',
    updateProjectData: 'draft/project/',
    getProjectData: 'draft/project/',
    getAllproject: 'draft/project',
    removeProject: 'draft/project/',
  },
  businessModel: {
    componentsModels: 'prepare-business-layout',
    getSelectedBusinessModel: 'draft/business-layout/project/',
    submitComponentsModels: 'draft/business-layout/',
  },
  energyLayout: {
    allModelsItems: 'component-model',
    addModelItem: 'draft/component-draw?draftProjectId=',
    removeModelItem: 'draft/component-draw/',
    getSelectedItemsModel: 'draft/component-draw/project/',
  },
  load: {
    getProfiles: 'draft/load-configuration/draw-component',
    removeProfile: 'draft/load-configuration/load',
    getAllChartsData: 'draft/load-configuration/draw-component',
    sample: {
      getTemplates: 'building-type',
      calculateSampleLoad: 'pre-defined-profiles/calculate-monthly-avg',
      saveSampleLoad: 'draft/load-configuration/draw-component',
      getProfileById: 'draft/load-configuration/load/sample-load/',
      updateSampleProfile: 'draft/load-configuration/sample-load',
    },
    upload: {
      calculateUploadProfile: 'draft/load-configuration/draw-component',
      calculateUploadPGrid: 'draft/grid-configuration/draw-component',
      saveUploadProfile: 'draft/load-configuration/draw-component',
      getProfileById: 'draft/load-configuration/load/upload-load/',
      updateUploadProfile: 'draft/load-configuration/upload-load',
      downloadUploadedFile: 'draft/load-configuration/load/upload-load',
    },
    generate: {
      calculateGenerateProfile: 'draft/load-configuration/draw-component',
      saveGenerateProfile: 'draft/load-configuration/draw-component',
      getProfileById: 'draft/load-configuration/load/generate-load/',
      updateGenerateProfile: 'draft/load-configuration/generate-load',
      downlaodGenerateExcelSheetProfile:
        'draft/load-configuration/draw-component/1/load/1/generate-load/downloadGenerateExcel',
    },
  },
  grid: {
    getGridData: 'draft/grid-configuration',
    saveGridConfigration: 'draft/grid-configuration/draw-component',
    reliabilities: {
      calculateOutages: 'draft/grid-configuration/mean-outage-frequency',
      calculateLoad: 'draft/grid-configuration/draw-component',
      electricityCountrySource:
        'draft/grid-configuration/electricity-country-source',
      emmisions: 'emission-data',
      powerOutageData: 'power-outage-data',
    },
    saveTechnical: 'draft/grid-configuration/draw-component',
    saveTariffs: 'draft/grid-configuration/draw-component',
    saveReliability: 'draft/grid-configuration/draw-component',
    saveExtensions: 'draft/grid-configuration/draw-component',
    saveEmissions: 'draft/grid-configuration/draw-component',
    downloadFile: 'draft/grid-configuration/upload-load',
    uploadTariff: 'draft/grid-configuration/draw-component',
  },
  pv: {
    base: 'pv-configuration/',
    getParams: 'pv-parameter',
    save: 'pv-configuration/draw-component/',
    hasLoad: 'draft/project/',
    uploadCalculate: 'pv-configuration/upload/calculate',
    generateCalculate: 'pv-configuration/draw-component/',
    downloadFile: 'pv-configuration/upload-load',

  },
  storage: {
    get: 'draft/storage-configuration',
    save: 'draft/storage-configuration/draw-component/',
    put: 'draft/storage-configuration/',
  },
  result: {
    getResults: 'result/project/',
  },
};
