import { createSlice } from '@reduxjs/toolkit';

type PayloadType = {
  componentName: string;
  id?: string;
  chartData?: any;
  reportData?: any;
  data?: any;
  image?: string | null;
};
interface PrintState {
  data: PayloadType[];
  isLoading: boolean;
  isShotLoading: boolean;
}

const initialState: PrintState = {
  data: [],
  isLoading: false,
  isShotLoading: false,
};

const printSlice = createSlice({
  name: 'print',
  initialState: initialState,
  reducers: {
    setPrintStart: (state: PrintState, payload) => {
      if (payload.payload === 'shot') {
        state.isShotLoading = true;
      } else {
        state.isLoading = true;
      }
    },
    setPrint: (state: PrintState, { payload }: { payload: PayloadType }) => {
      if (state.data.findIndex(({ id }) => id === payload.id) === -1) {
        state.data.push(payload);
      } else {
        state.data = state.data.filter(({ id }) => id !== payload.id);
      }
      state.isLoading = false;
      state.isShotLoading = false;
    },
    removePrint: (state: PrintState, { payload }: { payload: string }) => {
      state.data = state.data.filter(({ id }) => id !== payload);
    },
  },
});

export const { setPrint, setPrintStart, removePrint } = printSlice.actions;

export default printSlice.reducer;
