import { useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowIcon from '../SvgIcon/ArrowIcon.tsx';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsTypes } from '../../types/reduxTypes.ts';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import useToggleTimer from '../../hooks/useToggleTimer.tsx';
import { getProjetcDetails } from '../../redux/actions-reducers/project.ts';
import { useParams } from 'react-router';
import { ModelComponentType } from '../pages/EnergyLayout/index.tsx';

type CssTextFieldProps = {
  isopen?: string;
};
export const StyledGrid = styled(Grid)<CssTextFieldProps>(({ isopen }) => ({
  transition: 'ease-in-out 0.4s',
  display: isopen === 'true' ? 'inline' : 'none',
}));

const ProjectDetails = () => {
  const { t } = useTranslation();
  const { isDetailsVisible } = useToggleTimer();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { settings, project } = useSelector(
    ({ settings, project }: { settings: SettingsTypes; project: any }) => ({
      settings,
      project,
    })
  );
  const { isDarkMode, isProjectDetailsOpen } = settings;

  useEffect(() => {
    if (projectId) {
      setTimeout(() => {
        dispatch(getProjetcDetails(projectId as unknown as number) as any);
      }, 1000);
    }
  }, []);
  if (isDetailsVisible) {
    // Filter the PV models from the component draws
    const pvModels = project.componentDraws.filter(
      ({ componentModel }: { componentModel: ModelComponentType }) =>
        componentModel?.configurationType === 'PV'
    );
    const pvProfiles = pvModels
      .map(({ pv }: { pv: { profileName: string } }) => pv?.profileName)
      .join(', ');

    return (
      <StyledGrid item xs={3} isopen={isProjectDetailsOpen.toString()}>
        <Box className="project-details">
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={
                <ArrowIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="p"> {t('Project_Data')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectDetailsItemWithValue
                label={t('Project_Name')}
                value={project?.projectName}
              />
              <ProjectDetailsItemWithValue
                label={t('project_Designer')}
                value={project?.designerName}
              />
              <ProjectDetailsItemWithValue
                label={t('currency')}
                value={project?.currency}
              />
              <ProjectDetailsItemWithValue
                label={t('Project_Address')}
                value={project?.projectAddress}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={
                <ArrowIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography component="p">{t('System_Type')}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <ProjectDetailsItemWithValue
                label={t('Type_of_System')}
                value={t(' Grid + PV + Storage')}
              />
              <ProjectDetailsItemWithValue
                label={t('Location')}
                value={t('Vienna, Austria')}
              />
              <ProjectDetailsItemWithValue
                label={t('Resolution_of_Data')}
                value={t('15 Minutes')}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={
                <ArrowIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography component="p">{t('PV_System')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectDetailsItemWithValue
                label={t('pv_profiles')}
                value={pvProfiles}
              />
              <ProjectDetailsItemWithValue
                label={t('pv_size')}
                value={project?.designerName} // Power
              />
              <ProjectDetailsItemWithValue
                label={t('price_per_kw')}
                value={project?.currency} // totalCost / Power // pricePerKwp
              />
              <ProjectDetailsItemWithValue
                label={t('pv_opex')}
                value={project?.projectAddress} // pvOpexYearly
              />
              <ProjectDetailsItemWithValue
                label={t('pv_incentives')}
                value={project?.projectAddress} // subsidies
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={
                <ArrowIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography component="p">{t('Storage_system')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectDetailsItemWithValue
                label={t('Project_Name')}
                value={project?.projectName}
              />
              <ProjectDetailsItemWithValue
                label={t('project_Designer')}
                value={project?.designerName}
              />
              <ProjectDetailsItemWithValue
                label={t('currency')}
                value={project?.currency}
              />
              <ProjectDetailsItemWithValue
                label={t('Project_Address')}
                value={project?.projectAddress}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={
                <ArrowIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} />
              }
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography component="p">{t('Grid_connection')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectDetailsItemWithValue
                label={t('Project_Name')}
                value={project?.projectName}
              />
              <ProjectDetailsItemWithValue
                label={t('project_Designer')}
                value={project?.designerName}
              />
              <ProjectDetailsItemWithValue
                label={t('currency')}
                value={project?.currency}
              />
              <ProjectDetailsItemWithValue
                label={t('Project_Address')}
                value={project?.projectAddress}
              />
            </AccordionDetails>
          </Accordion>
          <Box className="project-details__lastUpdate">
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'start'}
            >
              <Typography>{t('Last_updated')}: </Typography>
              <Typography>{t('03.04.2023')}</Typography>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'start'}
            >
              <Typography>{t('By')}: </Typography>
              <Typography>{t('Hani')}</Typography>
            </Stack>
          </Box>
        </Box>
      </StyledGrid>
    );
  }
  return null;
};

const ProjectDetailsItemWithValue = ({
  label,
  value,
  justifyContent = 'start',
  gap = 0,
}: {
  label: string;
  value: string;
  justifyContent?: string;
  gap?: string | number;
}) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={justifyContent}
      gap={gap}
    >
      <Typography>{label}: </Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
};
export default ProjectDetails;
