import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SimulationResult from './SimulationResult.tsx';
import DigramEditor from './DigramEditor.tsx';
import EnergyForcastWithConsumption from './EnergyForcastWithConsumption.tsx';
import UseOfPvEnergy from './UseOfPvEnergy.tsx';
import ConverageOfConsumption from './ConverageOfConsumption.tsx';
import MonthlyPeacks from './MonthlyPeacks.tsx';
import EnergyFlowDiagram from './EnergyFlowDiagram.tsx';
import {
  getConverageOfConsumptionData,
  getDigramEditorData,
  getEnergyForcastWithConsmptionData,
  getMonthlyPeacksData,
  getSimulationData,
  getUseOfPvEnergyData,
  getEnergyFlowDigramData,
} from './helper/helper.tsx';

const Simulation = () => {
  const { type } = useParams<{ id: string; type: string }>();

  const components: Record<string, React.ComponentType<any>> = {
    SIMULATION_RESULT: SimulationResult,
    DIGRAM_EDITOR: DigramEditor,
    ENERGY_FORCAST_WITH_CONSUMPTION: EnergyForcastWithConsumption,
    USE_OF_PV_ENERGY: UseOfPvEnergy,
    COVERAGE_OF_CONSUMPTION: ConverageOfConsumption,
    MONTHLY_PEAKS: MonthlyPeacks,
    ENERGY_FLOW_GRAPH: EnergyFlowDiagram,
    // ELECTRICITY_DIAGRAM: 'test',
  };
  const { simulationData = {} } = useSelector(
    ({ projectResult }: any) => projectResult
  );
  const simulationData1 = getSimulationData(simulationData);
  const EnergyForcastWithConsumptionData =
    getEnergyForcastWithConsmptionData(simulationData);
  const UseOfPvEnergyData = getUseOfPvEnergyData(simulationData);
  const converageOfConsumptionData =
    getConverageOfConsumptionData(simulationData);
  const monthlyPeacksData = getMonthlyPeacksData(simulationData);
  const DigramEditorData = getDigramEditorData(simulationData);
  const energyFlowDigramData = getEnergyFlowDigramData(simulationData);

  const data: Record<string, any> = {
    SIMULATION_RESULT: simulationData1,
    DIGRAM_EDITOR: DigramEditorData,
    ENERGY_FORCAST_WITH_CONSUMPTION: EnergyForcastWithConsumptionData,
    USE_OF_PV_ENERGY: UseOfPvEnergyData,
    COVERAGE_OF_CONSUMPTION: converageOfConsumptionData,
    MONTHLY_PEAKS: monthlyPeacksData,
    ENERGY_FLOW_GRAPH: energyFlowDigramData,
    // ELECTRICITY_DIAGRAM: 'test',
  };

  const SimulationComponent = type ? components?.[type] : null;
  const SimulationDataComponent = type ? data?.[type] : null;

  return (
    <>
      {SimulationComponent && (
        <SimulationComponent data={SimulationDataComponent} />
      )}
    </>
  );
};

export default Simulation;
