export const ROUTES = {
  LOGIN: '/login',
  FORGET_PASSWORD: '/forget-password',

  START: '/',
  PROJECT_DATA: '/project-Data/:projectId?',
  BUSINESS_MODEL: '/business-model/:projectId',
  PROJECT_OVERVIEW: '/project-overview/:projectId',

  // Energy loayout routes
  ENERGY_LAYOUT: '/energy-layout/:projectId',
  GRID_FLOW: 'grid/:componentId/:configrationId?',
  MOBILITY_FLOW: 'mobility/:componentId/:configrationId?',
  GENERATE_FLOW: 'generate/:componentId/:configrationId?',
  STORAGE_FLOW: 'storage/:componentId/:configrationId?',
  WIND_FLOW: 'wind/:componentId/:configrationId?',

  PV_SYSTEM: 'pv-system/:componentId/:configrationId?',
  LOAD_FLOW: 'load/:componentId/:configrationId',
  LOAD_FLOW_UPLOAD_PROFILE:
    'load/:componentId/upload-new/:configrationId/:profileId?',
  LOAD_FLOW_GENERATE_PROFILE:
    'load/:componentId/generate/:configrationId/:profileId?',
  LOAD_FLOW_SAMPLE_PROFILE:
    'load/:componentId/sample/:configrationId/:profileId?',

  // RESULTS routes
  RESULTS: '/results/:projectId',
  RESULTS_PIE_INFO: '/results/:projectId/pie-info',
  RESULTS_FLOW_GRAPH: '/results/:projectId/flow-graph',
  RESULTS_LINE_CHARTS_RESULT: '/results/:projectId/line-info',
  RESULTS_BAR_CHARTS_RESULT: '/results/:projectId/bar-info',
  RESULTS_TABLE_RESULTS: '/results/:projectId/table-info',
  RESULTS_FINANCIAL_ANALYSIS: '/results/:projectId/financial-analysis/:type',
  RESULTS_SIMULATION: '/results/:projectId/simulation/:type',
  RESULTS_AI_POWERED_SUMMARY: '/results/:projectId/ai-powered-summary',
  RESULTS_OVERVIEW: '/results/:projectId/overview',
  ENERGY_PRICES_ANALYSIS: '/results/:projectId/energy-prices-analysis',
  RESULTS_PV_SYSTEM_ENERGY_BALANCE:
    '/results/:projectId/pv-system-energy-balance',

  // Other routes
  PROFILE: '/Profile',
  PRINT_PREVIEW: '/print-preview/:projectId',
};

export const MODULES_COLORS = {
  PV: '#ffa500',
  WIND: '#FF4500',
  GENERATE: '#FF6347',
  STORAGE: '#FFA07A',
  LOAD: '#808080',
  MOBILITY: '#FF4500',
  GRID: '#760808',
};
