import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SettingsTypes } from '../types/reduxTypes.ts';

const useToggleTimer = () => {
  const { isProjectDetailsOpen } = useSelector(
    ({ settings }: { settings: SettingsTypes }) => settings
  );

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const firstTime = useRef(true);

  useEffect(() => {
    if (firstTime.current && isProjectDetailsOpen) {
      setIsDetailsVisible(true);
      firstTime.current = false;
    } else {
      if (!isProjectDetailsOpen) {
        setIsDetailsVisible(false);
      } else {
        const timer = setTimeout(() => {
          setIsDetailsVisible(true);
        }, 400);
        return () => clearTimeout(timer);
      }
      firstTime.current = false;
    }
  }, [isProjectDetailsOpen]);

  return {
    isDetailsVisible,
  };
};

export default useToggleTimer;
