const GridIcon = () => {
  return (
    <svg
      width="37"
      height="55"
      viewBox="0 0 37 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_479_15240)">
        <path
          d="M11.6072 22.5446L18.2371 22.3013V22.8017L18.0271 22.9199L17.9361 22.8156L12.4333 23.0172L18.2371 26.3461V26.7909L18.0271 26.909L17.9291 26.6102L9.878 21.9956L9.66797 21.6689L9.878 21.5508L11.6072 22.5446Z"
          fill="#BCBEC0"
        />
        <path
          d="M11.3972 22.6628L18.0271 22.4195V22.9199L12.2233 23.1353L18.0271 26.4642V26.909L9.66797 22.1137V21.6689L11.3972 22.6628Z"
          fill="#A7A9AC"
        />
        <path
          d="M10.2493 21.7662L10.0393 21.8844L9.68924 22.3291L7.53295 23.5801L6.67183 23.8025L6.30078 23.587L9.87826 21.5508L10.2493 21.7662Z"
          fill="#BCBEC0"
        />
        <path
          d="M10.0393 21.8848L6.67188 23.8029V24.2477L10.0393 22.3295V21.8848Z"
          fill="#939598"
        />
        <path
          d="M8.24004 24.4626L14.8699 24.2193V24.7197L14.6599 24.8378L14.5689 24.7336L9.06615 24.9351L14.8699 28.264V28.7019L14.6599 28.827L14.5619 28.5281L6.51081 23.9135L6.30078 23.5869L6.51081 23.4688L8.24004 24.4626Z"
          fill="#BCBEC0"
        />
        <path
          d="M8.03001 24.5807L14.6599 24.3375V24.8379L8.85613 25.0533L14.6599 28.3822V28.827L6.30078 24.0317V23.5869L8.03001 24.5807Z"
          fill="#A7A9AC"
        />
        <path
          d="M6.41996 23.3578C6.58098 23.2675 6.84702 23.2675 7.01504 23.3578C7.18307 23.4551 7.18307 23.608 7.01504 23.6983C6.84702 23.7887 6.58098 23.7887 6.41996 23.6983C6.25194 23.601 6.25194 23.4481 6.41996 23.3578Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 23.5312V23.5729C7.17591 23.6424 7.13391 23.705 7.0429 23.7606C6.86087 23.8648 6.57383 23.8648 6.39181 23.7606C6.3008 23.7119 6.25879 23.6424 6.25879 23.5729V23.5312C6.25879 23.6007 7.17591 23.6007 7.17591 23.5312Z"
          fill="#303033"
        />
        <path
          d="M6.3914 23.3438C6.57343 23.2396 6.86046 23.2396 7.04249 23.3438C7.22451 23.4481 7.22451 23.6148 7.04249 23.7191C6.86046 23.8233 6.57343 23.8233 6.3914 23.7191C6.20938 23.6148 6.20938 23.4481 6.3914 23.3438Z"
          fill="#535359"
        />
        <path
          d="M6.41996 23.58C6.58098 23.6773 6.84702 23.6773 7.01504 23.58C7.09905 23.5313 7.14106 23.4688 7.14106 23.4062V23.5244C7.14106 23.5869 7.09905 23.6495 7.01504 23.6981C6.84702 23.7885 6.58098 23.7885 6.41996 23.6981C6.33595 23.6495 6.29395 23.5869 6.29395 23.5244V23.4062C6.29395 23.4688 6.33595 23.5313 6.41996 23.58Z"
          fill="#121214"
        />
        <path
          d="M6.41996 23.24C6.58098 23.1427 6.84702 23.1427 7.01504 23.24C7.18307 23.3373 7.18307 23.4902 7.01504 23.5805C6.84702 23.6778 6.58098 23.6778 6.41996 23.5805C6.25194 23.4832 6.25194 23.3373 6.41996 23.24Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 23.4062V23.4479C7.17591 23.5174 7.13391 23.58 7.0429 23.6356C6.86087 23.7398 6.57383 23.7398 6.39181 23.6356C6.3008 23.5869 6.25879 23.5174 6.25879 23.4479V23.4062C6.25879 23.4757 7.17591 23.4757 7.17591 23.4062Z"
          fill="#303033"
        />
        <path
          d="M6.3914 23.2188C6.57343 23.1146 6.86046 23.1146 7.04249 23.2188C7.22451 23.3231 7.22451 23.4898 7.04249 23.5941C6.86046 23.6983 6.57343 23.6983 6.3914 23.5941C6.20938 23.4898 6.20938 23.3231 6.3914 23.2188Z"
          fill="#535359"
        />
        <path
          d="M6.41996 23.455C6.58098 23.5523 6.84702 23.5523 7.01504 23.455C7.09905 23.4063 7.14106 23.3438 7.14106 23.2812V23.3994C7.14106 23.4619 7.09905 23.5245 7.01504 23.5662C6.84702 23.6635 6.58098 23.6635 6.41996 23.5662C6.33595 23.5175 6.29395 23.455 6.29395 23.3994V23.2812C6.29395 23.3438 6.33595 23.4063 6.41996 23.455Z"
          fill="#121214"
        />
        <path
          d="M6.41996 23.115C6.58098 23.0177 6.84702 23.0177 7.01504 23.115C7.18307 23.2123 7.18307 23.3652 7.01504 23.4555C6.84702 23.5528 6.58098 23.5528 6.41996 23.4555C6.25194 23.3582 6.25194 23.2053 6.41996 23.115Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 23.2881V23.3298C7.17591 23.3993 7.13391 23.4618 7.0429 23.5174C6.86087 23.6217 6.57383 23.6217 6.39181 23.5174C6.3008 23.4688 6.25879 23.3993 6.25879 23.3298V23.2881C6.25879 23.3576 7.17591 23.3576 7.17591 23.2881Z"
          fill="#303033"
        />
        <path
          d="M6.3914 23.1006C6.57343 22.9964 6.86046 22.9964 7.04249 23.1006C7.22451 23.2049 7.22451 23.3717 7.04249 23.4759C6.86046 23.5802 6.57343 23.5802 6.3914 23.4759C6.20938 23.3717 6.20938 23.2049 6.3914 23.1006Z"
          fill="#535359"
        />
        <path
          d="M6.41996 23.33C6.58098 23.4273 6.85402 23.4273 7.01504 23.33C7.09905 23.2813 7.14106 23.2188 7.14106 23.1562V23.2744C7.14106 23.3369 7.09905 23.3995 7.01504 23.4481C6.85402 23.5454 6.58098 23.5454 6.41996 23.4481C6.33595 23.3995 6.29395 23.3369 6.29395 23.2744V23.1562C6.29395 23.2188 6.33595 23.2813 6.41996 23.33Z"
          fill="#121214"
        />
        <path
          d="M6.41996 22.9831C6.58098 22.8858 6.85402 22.8858 7.01504 22.9831C7.18307 23.0804 7.18307 23.2333 7.01504 23.3237C6.85402 23.421 6.58098 23.421 6.41996 23.3237C6.25194 23.2264 6.25194 23.0735 6.41996 22.9831Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 23.1562V23.1979C7.17591 23.2674 7.13391 23.33 7.0429 23.3856C6.86087 23.4898 6.57383 23.4898 6.39181 23.3856C6.3008 23.3369 6.25879 23.2674 6.25879 23.1979V23.1562C6.25879 23.2257 7.17591 23.2257 7.17591 23.1562Z"
          fill="#303033"
        />
        <path
          d="M6.3914 22.9688C6.57343 22.8646 6.86046 22.8646 7.04249 22.9688C7.22451 23.0731 7.22451 23.2398 7.04249 23.3441C6.86046 23.4483 6.57343 23.4483 6.3914 23.3441C6.20938 23.2398 6.20938 23.0731 6.3914 22.9688Z"
          fill="#535359"
        />
        <path
          d="M6.41996 23.205C6.58098 23.3023 6.85402 23.3023 7.01504 23.205C7.09905 23.1563 7.14106 23.0938 7.14106 23.0312V23.1494C7.14106 23.2119 7.09905 23.2745 7.01504 23.3231C6.85402 23.4204 6.58098 23.4204 6.41996 23.3231C6.33595 23.2745 6.29395 23.2119 6.29395 23.1494V23.0312C6.29395 23.0938 6.33595 23.1563 6.41996 23.205Z"
          fill="#121214"
        />
        <path
          d="M6.41996 22.865C6.58098 22.7677 6.85402 22.7677 7.01504 22.865C7.18307 22.9623 7.18307 23.1152 7.01504 23.2055C6.85402 23.3028 6.58098 23.3028 6.41996 23.2055C6.25194 23.1152 6.25194 22.9623 6.41996 22.865Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 23.0381V23.0798C7.17591 23.1493 7.13391 23.2118 7.0429 23.2674C6.86087 23.3717 6.57383 23.3717 6.39181 23.2674C6.3008 23.2188 6.25879 23.1493 6.25879 23.0798V23.0381C6.25879 23.1076 7.17591 23.1076 7.17591 23.0381Z"
          fill="#303033"
        />
        <path
          d="M6.3914 22.8506C6.57343 22.7464 6.86046 22.7464 7.04249 22.8506C7.22451 22.9549 7.22451 23.1217 7.04249 23.2259C6.86046 23.3302 6.57343 23.3302 6.3914 23.2259C6.20938 23.1217 6.20938 22.9549 6.3914 22.8506Z"
          fill="#535359"
        />
        <path
          d="M6.41996 23.0867C6.58098 23.184 6.85402 23.184 7.01504 23.0867C7.09905 23.0381 7.14106 22.9755 7.14106 22.9199V23.0381C7.14106 23.1006 7.09905 23.1632 7.01504 23.2118C6.85402 23.3091 6.58098 23.3091 6.41996 23.2118C6.33595 23.1632 6.29395 23.1006 6.29395 23.0381V22.9199C6.29395 22.9825 6.33595 23.045 6.41996 23.0867Z"
          fill="#121214"
        />
        <path
          d="M6.41996 22.7468C6.58098 22.6495 6.85402 22.6495 7.01504 22.7468C7.18307 22.8441 7.18307 22.997 7.01504 23.0873C6.85402 23.1846 6.58098 23.1846 6.41996 23.0873C6.25194 22.997 6.25194 22.8441 6.41996 22.7468Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 22.9131V22.9548C7.17591 23.0243 7.13391 23.0868 7.0429 23.1424C6.86087 23.2467 6.57383 23.2467 6.39181 23.1424C6.3008 23.0938 6.25879 23.0243 6.25879 22.9548V22.9131C6.25879 22.9826 7.17591 22.9826 7.17591 22.9131Z"
          fill="#303033"
        />
        <path
          d="M6.3914 22.7256C6.57343 22.6214 6.86046 22.6214 7.04249 22.7256C7.22451 22.8299 7.22451 22.9967 7.04249 23.1009C6.86046 23.2052 6.57343 23.2052 6.3914 23.1009C6.20938 22.9967 6.20938 22.8299 6.3914 22.7256Z"
          fill="#535359"
        />
        <path
          d="M6.41996 22.9618C6.58098 23.0591 6.85402 23.0591 7.01504 22.9618C7.09905 22.9132 7.14106 22.8506 7.14106 22.7881V22.9062C7.14106 22.9688 7.09905 23.0313 7.01504 23.073C6.85402 23.1703 6.58098 23.1703 6.41996 23.073C6.33595 23.0244 6.29395 22.9618 6.29395 22.9062V22.7881C6.29395 22.8506 6.33595 22.9132 6.41996 22.9618Z"
          fill="#121214"
        />
        <path
          d="M6.41996 22.6208C6.58098 22.5235 6.85402 22.5235 7.01504 22.6208C7.18307 22.7181 7.18307 22.871 7.01504 22.9614C6.85402 23.0587 6.58098 23.0587 6.41996 22.9614C6.25194 22.8641 6.25194 22.7112 6.41996 22.6208Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 22.7949V22.8366C7.17591 22.9061 7.13391 22.9687 7.0429 23.0243C6.86087 23.1285 6.57383 23.1285 6.39181 23.0243C6.3008 22.9756 6.25879 22.9061 6.25879 22.8366V22.7949C6.25879 22.8644 7.17591 22.8644 7.17591 22.7949Z"
          fill="#303033"
        />
        <path
          d="M6.3914 22.6075C6.57343 22.5032 6.86046 22.5032 7.04249 22.6075C7.22451 22.7117 7.22451 22.8785 7.04249 22.9828C6.86046 23.087 6.57343 23.087 6.3914 22.9828C6.20938 22.8785 6.20938 22.7117 6.3914 22.6075Z"
          fill="#535359"
        />
        <path
          d="M6.55324 22.4546C6.63725 22.5033 6.78427 22.5033 6.86829 22.4546C6.91029 22.4268 6.93129 22.399 6.93129 22.3643V22.7812C6.93129 22.7812 6.91029 22.8438 6.86829 22.8716C6.78427 22.9202 6.63725 22.9202 6.55324 22.8716C6.51124 22.8507 6.49023 22.816 6.49023 22.7812V22.3643C6.49023 22.3643 6.51124 22.4268 6.55324 22.4546Z"
          fill="#A7A9AC"
        />
        <path
          d="M6.55324 22.2806C6.63725 22.232 6.78427 22.232 6.86829 22.2806C6.9523 22.3293 6.9523 22.4127 6.86829 22.4613C6.78427 22.51 6.63725 22.51 6.55324 22.4613C6.46923 22.4127 6.46923 22.3293 6.55324 22.2806Z"
          fill="#E6E7E8"
        />
        <path
          d="M6.76322 22.0449V22.3716C6.76322 22.3716 6.76322 22.3855 6.74922 22.3924C6.72821 22.4063 6.69321 22.4063 6.6722 22.3924C6.6652 22.3924 6.6582 22.3785 6.6582 22.3716V22.0449C6.6582 22.0449 6.76322 22.0519 6.76322 22.0449Z"
          fill="#303033"
        />
        <path
          d="M6.672 22.0238C6.672 22.0238 6.72801 22.0099 6.74901 22.0238C6.77002 22.0377 6.77002 22.0516 6.74901 22.0655C6.72801 22.0794 6.693 22.0794 6.672 22.0655C6.651 22.0516 6.651 22.0377 6.672 22.0238Z"
          fill="#535359"
        />
        <path
          d="M9.61918 21.5232C9.7802 21.4259 10.0532 21.4259 10.2143 21.5232C10.3823 21.6205 10.3823 21.7734 10.2143 21.8637C10.0532 21.961 9.7802 21.961 9.61918 21.8637C9.45116 21.7664 9.45116 21.6135 9.61918 21.5232Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 21.6973V21.739C10.3751 21.8085 10.3261 21.871 10.2421 21.9266C10.0601 22.0309 9.77305 22.0309 9.59103 21.9266C9.50001 21.878 9.45801 21.8085 9.45801 21.739V21.6973C9.45801 21.7668 10.3751 21.7668 10.3751 21.6973Z"
          fill="#303033"
        />
        <path
          d="M9.5916 21.5098C9.77362 21.4056 10.0607 21.4056 10.2427 21.5098C10.4247 21.6141 10.4247 21.7809 10.2427 21.8851C10.0607 21.9894 9.77362 21.9894 9.5916 21.8851C9.40957 21.7809 9.40957 21.6141 9.5916 21.5098Z"
          fill="#535359"
        />
        <path
          d="M9.61918 21.746C9.7802 21.8433 10.0532 21.8433 10.2143 21.746C10.2983 21.6974 10.3403 21.6348 10.3403 21.5723V21.6904C10.3403 21.753 10.2983 21.8155 10.2143 21.8642C10.0532 21.9614 9.7802 21.9614 9.61918 21.8642C9.53517 21.8155 9.49316 21.753 9.49316 21.6904V21.5723C9.49316 21.6348 9.53517 21.6974 9.61918 21.746Z"
          fill="#121214"
        />
        <path
          d="M9.61918 21.405C9.7802 21.3077 10.0532 21.3077 10.2143 21.405C10.3823 21.5023 10.3823 21.6552 10.2143 21.7455C10.0532 21.8428 9.7802 21.8428 9.61918 21.7455C9.45116 21.6482 9.45116 21.4953 9.61918 21.405Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 21.5791V21.6208C10.3751 21.6903 10.3261 21.7528 10.2421 21.8084C10.0601 21.9127 9.77305 21.9127 9.59103 21.8084C9.50001 21.7598 9.45801 21.6903 9.45801 21.6208V21.5791C9.45801 21.6486 10.3751 21.6486 10.3751 21.5791Z"
          fill="#303033"
        />
        <path
          d="M9.5916 21.3917C9.77362 21.2874 10.0607 21.2874 10.2427 21.3917C10.4247 21.4959 10.4247 21.6627 10.2427 21.7669C10.0607 21.8712 9.77362 21.8712 9.5916 21.7669C9.40957 21.6627 9.40957 21.4959 9.5916 21.3917Z"
          fill="#535359"
        />
        <path
          d="M9.61918 21.6278C9.7802 21.7251 10.0532 21.7251 10.2143 21.6278C10.2983 21.5792 10.3403 21.5166 10.3403 21.4541V21.5722C10.3403 21.6348 10.2983 21.6973 10.2143 21.739C10.0532 21.8363 9.7802 21.8363 9.61918 21.739C9.53517 21.6904 9.49316 21.6278 9.49316 21.5722V21.4541C9.49316 21.5166 9.53517 21.5792 9.61918 21.6278Z"
          fill="#121214"
        />
        <path
          d="M9.61918 21.2868C9.7802 21.1895 10.0532 21.1895 10.2143 21.2868C10.3823 21.3841 10.3823 21.537 10.2143 21.6274C10.0532 21.7247 9.7802 21.7247 9.61918 21.6274C9.45116 21.5301 9.45116 21.3772 9.61918 21.2868Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 21.4541V21.4958C10.3751 21.5653 10.3261 21.6278 10.2421 21.6834C10.0601 21.7877 9.77305 21.7877 9.59103 21.6834C9.50001 21.6348 9.45801 21.5653 9.45801 21.4958V21.4541C9.45801 21.5236 10.3751 21.5236 10.3751 21.4541Z"
          fill="#303033"
        />
        <path
          d="M9.5916 21.2657C9.77362 21.1614 10.0607 21.1614 10.2427 21.2657C10.4247 21.3699 10.4247 21.5367 10.2427 21.641C10.0607 21.7452 9.77362 21.7452 9.5916 21.641C9.40957 21.5367 9.40957 21.3699 9.5916 21.2657Z"
          fill="#535359"
        />
        <path
          d="M9.61918 21.495C9.7802 21.5923 10.0532 21.5923 10.2143 21.495C10.2983 21.4464 10.3403 21.3838 10.3403 21.3213V21.4394C10.3403 21.502 10.2983 21.5645 10.2143 21.6132C10.0532 21.7105 9.7802 21.7105 9.61918 21.6132C9.53517 21.5645 9.49316 21.502 9.49316 21.4394V21.3213C9.49316 21.3838 9.53517 21.4464 9.61918 21.495Z"
          fill="#121214"
        />
        <path
          d="M9.61918 21.155C9.7802 21.0577 10.0532 21.0577 10.2143 21.155C10.3823 21.2523 10.3823 21.4052 10.2143 21.4955C10.0532 21.5928 9.7802 21.5928 9.61918 21.4955C9.45116 21.3982 9.45116 21.2453 9.61918 21.155Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 21.3213V21.363C10.3751 21.4325 10.3261 21.495 10.2421 21.5506C10.0601 21.6549 9.77305 21.6549 9.59103 21.5506C9.50001 21.502 9.45801 21.4325 9.45801 21.363V21.3213C9.45801 21.3908 10.3751 21.3908 10.3751 21.3213Z"
          fill="#303033"
        />
        <path
          d="M9.5916 21.1407C9.77362 21.0364 10.0607 21.0364 10.2427 21.1407C10.4177 21.2449 10.4177 21.4117 10.2427 21.516C10.0607 21.6202 9.77362 21.6202 9.5916 21.516C9.40957 21.4117 9.40957 21.2449 9.5916 21.1407Z"
          fill="#535359"
        />
        <path
          d="M9.61918 21.37C9.7802 21.4673 10.0532 21.4673 10.2143 21.37C10.2983 21.3214 10.3403 21.2588 10.3403 21.1963V21.3144C10.3403 21.377 10.2983 21.4395 10.2143 21.4882C10.0532 21.5855 9.7802 21.5855 9.61918 21.4882C9.53517 21.4395 9.49316 21.377 9.49316 21.3144V21.1963C9.49316 21.2588 9.53517 21.3214 9.61918 21.37Z"
          fill="#121214"
        />
        <path
          d="M9.61918 21.03C9.7802 20.9327 10.0532 20.9327 10.2143 21.03C10.3823 21.1273 10.3823 21.2802 10.2143 21.3705C10.0532 21.4678 9.7802 21.4678 9.61918 21.3705C9.45116 21.2732 9.45116 21.1203 9.61918 21.03Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 21.2031V21.2448C10.3751 21.3143 10.3261 21.3769 10.2421 21.4325C10.0601 21.5367 9.77305 21.5367 9.59103 21.4325C9.50001 21.3838 9.45801 21.3143 9.45801 21.2448V21.2031C9.45801 21.2726 10.3751 21.2726 10.3751 21.2031Z"
          fill="#303033"
        />
        <path
          d="M9.5916 21.0157C9.77362 20.9114 10.0607 20.9114 10.2427 21.0157C10.4177 21.1199 10.4177 21.2867 10.2427 21.391C10.0607 21.4952 9.77362 21.4952 9.5916 21.391C9.40957 21.2867 9.40957 21.1199 9.5916 21.0157Z"
          fill="#535359"
        />
        <path
          d="M9.61918 21.2519C9.7802 21.3492 10.0532 21.3492 10.2143 21.2519C10.2983 21.2032 10.3403 21.1407 10.3403 21.0781V21.1963C10.3403 21.2588 10.2983 21.3214 10.2143 21.37C10.0532 21.4673 9.7802 21.4673 9.61918 21.37C9.53517 21.3214 9.49316 21.2588 9.49316 21.1963V21.0781C9.49316 21.1407 9.53517 21.2032 9.61918 21.2519Z"
          fill="#121214"
        />
        <path
          d="M9.61918 20.9118C9.7802 20.8145 10.0532 20.8145 10.2143 20.9118C10.3823 21.0091 10.3823 21.162 10.2143 21.2524C10.0532 21.3497 9.7802 21.3497 9.61918 21.2524C9.45116 21.1551 9.45116 21.0022 9.61918 20.9118Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 21.085V21.1267C10.3751 21.1962 10.3261 21.2587 10.2421 21.3143C10.0601 21.4185 9.77305 21.4185 9.59103 21.3143C9.50001 21.2657 9.45801 21.1962 9.45801 21.1267V21.085C9.45801 21.1545 10.3751 21.1545 10.3751 21.085Z"
          fill="#303033"
        />
        <path
          d="M9.5916 20.8975C9.77362 20.7933 10.0607 20.7933 10.2427 20.8975C10.4177 21.0018 10.4177 21.1686 10.2427 21.2728C10.0607 21.3771 9.77362 21.3771 9.5916 21.2728C9.40957 21.1686 9.40957 21.0018 9.5916 20.8975Z"
          fill="#535359"
        />
        <path
          d="M9.61918 21.1337C9.7802 21.231 10.0532 21.231 10.2143 21.1337C10.2983 21.0851 10.3403 21.0225 10.3403 20.96V21.0781C10.3403 21.1407 10.2983 21.2032 10.2143 21.2518C10.0532 21.3491 9.7802 21.3491 9.61918 21.2518C9.53517 21.2032 9.49316 21.1407 9.49316 21.0781V20.96C9.49316 21.0225 9.53517 21.0851 9.61918 21.1337Z"
          fill="#121214"
        />
        <path
          d="M9.61918 20.7937C9.7802 20.6964 10.0532 20.6964 10.2143 20.7937C10.3823 20.891 10.3823 21.0439 10.2143 21.1342C10.0532 21.2315 9.7802 21.2315 9.61918 21.1342C9.45116 21.0369 9.45116 20.884 9.61918 20.7937Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 20.96V21.0017C10.3751 21.0712 10.3261 21.1337 10.2421 21.1893C10.0601 21.2935 9.77305 21.2935 9.59103 21.1893C9.50001 21.1407 9.45801 21.0712 9.45801 21.0017V20.96C9.45801 21.0295 10.3751 21.0295 10.3751 20.96Z"
          fill="#303033"
        />
        <path
          d="M9.5916 20.7725C9.77362 20.6683 10.0607 20.6683 10.2427 20.7725C10.4177 20.8768 10.4177 21.0436 10.2427 21.1478C10.0607 21.2521 9.77362 21.2521 9.5916 21.1478C9.40957 21.0436 9.40957 20.8768 9.5916 20.7725Z"
          fill="#535359"
        />
        <path
          d="M9.75246 20.6265C9.83647 20.6751 9.98349 20.6751 10.0675 20.6265C10.1095 20.5987 10.1305 20.5709 10.1305 20.5361V20.9531C10.1305 20.9531 10.1095 21.0157 10.0675 21.0435C9.98349 21.0921 9.83647 21.0921 9.75246 21.0435C9.71046 21.0157 9.68945 20.9879 9.68945 20.9531V20.5361C9.68945 20.5361 9.71046 20.5987 9.75246 20.6265Z"
          fill="#A7A9AC"
        />
        <path
          d="M9.75246 20.4457C9.83647 20.397 9.98349 20.397 10.0675 20.4457C10.1515 20.4943 10.1515 20.5777 10.0675 20.6264C9.98349 20.675 9.83647 20.675 9.75246 20.6264C9.66845 20.5777 9.66845 20.4943 9.75246 20.4457Z"
          fill="#E6E7E8"
        />
        <path
          d="M9.96244 20.21V20.5366C9.96244 20.5366 9.96244 20.5505 9.94843 20.5574C9.92743 20.5713 9.89243 20.5713 9.87142 20.5574C9.85742 20.5574 9.85742 20.5435 9.85742 20.5366V20.21C9.85742 20.21 9.96244 20.2169 9.96244 20.21Z"
          fill="#303033"
        />
        <path
          d="M9.87122 20.1888C9.87122 20.1888 9.92723 20.1749 9.94823 20.1888C9.96923 20.2027 9.96923 20.2166 9.94823 20.2305C9.92723 20.2444 9.89222 20.2444 9.87122 20.2305C9.85022 20.2166 9.85022 20.2027 9.87122 20.1888Z"
          fill="#535359"
        />
        <path
          d="M9.90643 20.2581C9.90643 20.2581 9.85742 20.2372 9.85742 20.2094C9.85742 20.1816 9.87842 20.1608 9.90643 20.1608C9.94143 20.1608 13.0639 20.126 17.0334 17.4018C17.0544 17.3879 17.0894 17.3948 17.1034 17.4157C17.1174 17.4365 17.1174 17.4713 17.0894 17.4852C13.0919 20.2303 9.93443 20.2581 9.90643 20.2581Z"
          fill="#121214"
        />
        <path
          d="M0.0556194 27.243C0.0556194 27.243 0.0206146 27.2291 0.00661275 27.2152C-0.00738914 27.1874 0.00661275 27.1596 0.0276156 27.1457C0.0696213 27.1249 4.3402 24.9496 6.67151 22.0029C6.68552 21.9821 6.72052 21.9751 6.74152 21.996C6.76253 22.0168 6.76953 22.0446 6.74852 22.0655C4.41021 25.033 0.118628 27.2152 0.0766222 27.2361C0.0766222 27.2361 0.0626203 27.2361 0.0556194 27.2361V27.243Z"
          fill="#121214"
        />
        <path
          d="M11.6072 14.1979L18.2371 13.9547V14.455L18.0271 14.5732L17.9361 14.4689L12.4333 14.6705L18.2371 17.9925V18.4372L18.0271 18.5623L17.9291 18.2635L9.878 13.6489L9.66797 13.3222L9.878 13.2041L11.6072 14.1979Z"
          fill="#BCBEC0"
        />
        <path
          d="M11.3972 14.3161L18.0271 14.0728V14.5732L12.2233 14.7887L18.0271 18.1176V18.5623L9.66797 13.767V13.3223L11.3972 14.3161Z"
          fill="#A7A9AC"
        />
        <path
          d="M10.2493 13.4195L10.0393 13.5377L9.68924 13.9825L7.53295 15.2334L6.67183 15.4558L6.30078 15.2404L9.87826 13.2041L10.2493 13.4195Z"
          fill="#BCBEC0"
        />
        <path
          d="M10.0393 13.5381L6.67188 15.4562V15.901L10.0393 13.9829V13.5381Z"
          fill="#939598"
        />
        <path
          d="M8.24004 16.1099L14.8699 15.8667V16.374L14.6599 16.4921L14.5689 16.3809L9.06615 16.5894L14.8699 19.9114V20.3562L14.6599 20.4743L14.5619 20.1824L6.51081 15.5678L6.30078 15.2412L6.51081 15.123L8.24004 16.1099Z"
          fill="#BCBEC0"
        />
        <path
          d="M8.03001 16.234L14.6599 15.9908V16.4912L8.85613 16.7066L14.6599 20.0286V20.4734L6.30078 15.685V15.2402L8.03001 16.234Z"
          fill="#A7A9AC"
        />
        <path
          d="M6.41996 15.0111C6.58098 14.9208 6.84702 14.9208 7.01504 15.0111C7.18307 15.1084 7.18307 15.2613 7.01504 15.3517C6.84702 15.449 6.58098 15.449 6.41996 15.3517C6.25194 15.2544 6.25194 15.1015 6.41996 15.0111Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 15.1777V15.2194C7.17591 15.2889 7.13391 15.3515 7.0429 15.4071C6.86087 15.5113 6.57383 15.5113 6.39181 15.4071C6.3008 15.3584 6.25879 15.2889 6.25879 15.2194V15.1777C6.25879 15.2472 7.17591 15.2472 7.17591 15.1777Z"
          fill="#303033"
        />
        <path
          d="M6.3914 14.9971C6.57343 14.8929 6.86046 14.8929 7.04249 14.9971C7.22451 15.1014 7.22451 15.2682 7.04249 15.3724C6.86046 15.4767 6.57343 15.4767 6.3914 15.3724C6.20938 15.2682 6.20938 15.1014 6.3914 14.9971Z"
          fill="#535359"
        />
        <path
          d="M6.41996 15.2333C6.58098 15.3237 6.84702 15.3237 7.01504 15.2333C7.09905 15.1847 7.14106 15.1221 7.14106 15.0596V15.1777C7.14106 15.2403 7.09905 15.3028 7.01504 15.3515C6.84702 15.4488 6.58098 15.4488 6.41996 15.3515C6.33595 15.3028 6.29395 15.2403 6.29395 15.1777V15.0596C6.29395 15.1221 6.33595 15.1847 6.41996 15.2333Z"
          fill="#121214"
        />
        <path
          d="M6.41996 14.8864C6.58098 14.7892 6.84702 14.7892 7.01504 14.8864C7.18307 14.9837 7.18307 15.1366 7.01504 15.227C6.84702 15.3173 6.58098 15.3173 6.41996 15.227C6.25194 15.1297 6.25194 14.9768 6.41996 14.8864Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 15.0596V15.1013C7.17591 15.1708 7.13391 15.2333 7.0429 15.2889C6.86087 15.3932 6.57383 15.3932 6.39181 15.2889C6.3008 15.2403 6.25879 15.1708 6.25879 15.1013V15.0596C6.25879 15.1291 7.17591 15.1291 7.17591 15.0596Z"
          fill="#303033"
        />
        <path
          d="M6.3914 14.8721C6.57343 14.7679 6.86046 14.7679 7.04249 14.8721C7.22451 14.9764 7.22451 15.1432 7.04249 15.2474C6.86046 15.3517 6.57343 15.3517 6.3914 15.2474C6.20938 15.1432 6.20938 14.9764 6.3914 14.8721Z"
          fill="#535359"
        />
        <path
          d="M6.41996 15.1083C6.58098 15.2056 6.84702 15.2056 7.01504 15.1083C7.09905 15.0597 7.14106 14.9971 7.14106 14.9346V15.0527C7.14106 15.1153 7.09905 15.1778 7.01504 15.2265C6.84702 15.3168 6.58098 15.3168 6.41996 15.2265C6.33595 15.1778 6.29395 15.1153 6.29395 15.0527V14.9346C6.29395 14.9971 6.33595 15.0597 6.41996 15.1083Z"
          fill="#121214"
        />
        <path
          d="M6.41996 14.7683C6.58098 14.671 6.84702 14.671 7.01504 14.7683C7.18307 14.8586 7.18307 15.0115 7.01504 15.1088C6.84702 15.2061 6.58098 15.2061 6.41996 15.1088C6.25194 15.0115 6.25194 14.8586 6.41996 14.7683Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 14.9414V14.9831C7.17591 15.0526 7.13391 15.1221 7.0429 15.1707C6.86087 15.275 6.57383 15.275 6.39181 15.1707C6.3008 15.1221 6.25879 15.0526 6.25879 14.9831V14.9414C6.25879 15.0109 7.17591 15.0109 7.17591 14.9414Z"
          fill="#303033"
        />
        <path
          d="M6.3914 14.754C6.57343 14.6497 6.86046 14.6497 7.04249 14.754C7.22451 14.8582 7.22451 15.025 7.04249 15.1293C6.86046 15.2335 6.57343 15.2335 6.3914 15.1293C6.20938 15.025 6.20938 14.8582 6.3914 14.754Z"
          fill="#535359"
        />
        <path
          d="M6.41996 14.9765C6.58098 15.0738 6.85402 15.0738 7.01504 14.9765C7.09905 14.9278 7.14106 14.8653 7.14106 14.8027V14.9209C7.14106 14.9834 7.09905 15.046 7.01504 15.0946C6.85402 15.1919 6.58098 15.1919 6.41996 15.0946C6.33595 15.046 6.29395 14.9834 6.29395 14.9209V14.8027C6.29395 14.8653 6.33595 14.9278 6.41996 14.9765Z"
          fill="#121214"
        />
        <path
          d="M6.41996 14.6364C6.58098 14.5392 6.85402 14.5392 7.01504 14.6364C7.18307 14.7337 7.18307 14.8866 7.01504 14.977C6.85402 15.0743 6.58098 15.0743 6.41996 14.977C6.25194 14.8797 6.25194 14.7268 6.41996 14.6364Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 14.8096V14.8513C7.17591 14.9208 7.13391 14.9833 7.0429 15.0389C6.86087 15.1432 6.57383 15.1432 6.39181 15.0389C6.3008 14.9903 6.25879 14.9208 6.25879 14.8513V14.8096C6.25879 14.8791 7.17591 14.8791 7.17591 14.8096Z"
          fill="#303033"
        />
        <path
          d="M6.3914 14.6221C6.57343 14.5179 6.86046 14.5179 7.04249 14.6221C7.22451 14.7264 7.22451 14.8932 7.04249 14.9974C6.86046 15.1017 6.57343 15.1017 6.3914 14.9974C6.20938 14.8932 6.20938 14.7264 6.3914 14.6221Z"
          fill="#535359"
        />
        <path
          d="M6.41996 14.8583C6.58098 14.9556 6.85402 14.9556 7.01504 14.8583C7.09905 14.8097 7.14106 14.7471 7.14106 14.6846V14.8027C7.14106 14.8653 7.09905 14.9278 7.01504 14.9765C6.85402 15.0738 6.58098 15.0738 6.41996 14.9765C6.33595 14.9278 6.29395 14.8653 6.29395 14.8027V14.6846C6.29395 14.7471 6.33595 14.8097 6.41996 14.8583Z"
          fill="#121214"
        />
        <path
          d="M6.41996 14.5183C6.58098 14.421 6.85402 14.421 7.01504 14.5183C7.18307 14.6156 7.18307 14.7685 7.01504 14.8588C6.85402 14.9561 6.58098 14.9561 6.41996 14.8588C6.25194 14.7685 6.25194 14.6156 6.41996 14.5183Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 14.6846V14.7263C7.17591 14.7958 7.13391 14.8583 7.0429 14.9139C6.86087 15.0182 6.57383 15.0182 6.39181 14.9139C6.3008 14.8653 6.25879 14.7958 6.25879 14.7263V14.6846C6.25879 14.7541 7.17591 14.7541 7.17591 14.6846Z"
          fill="#303033"
        />
        <path
          d="M6.3914 14.4971C6.57343 14.3929 6.86046 14.3929 7.04249 14.4971C7.22451 14.6014 7.22451 14.7682 7.04249 14.8724C6.86046 14.9767 6.57343 14.9767 6.3914 14.8724C6.20938 14.7682 6.20938 14.6014 6.3914 14.4971Z"
          fill="#535359"
        />
        <path
          d="M6.41996 14.7401C6.58098 14.8374 6.85402 14.8374 7.01504 14.7401C7.09905 14.6915 7.14106 14.629 7.14106 14.5664V14.6846C7.14106 14.7471 7.09905 14.8096 7.01504 14.8583C6.85402 14.9556 6.58098 14.9556 6.41996 14.8583C6.33595 14.8096 6.29395 14.7471 6.29395 14.6846V14.5664C6.29395 14.629 6.33595 14.6915 6.41996 14.7401Z"
          fill="#121214"
        />
        <path
          d="M6.41996 14.3933C6.58098 14.296 6.85402 14.296 7.01504 14.3933C7.18307 14.4836 7.18307 14.6435 7.01504 14.7338C6.85402 14.8311 6.58098 14.8311 6.41996 14.7338C6.25194 14.6365 6.25194 14.4836 6.41996 14.3933Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 14.5664V14.6081C7.17591 14.6776 7.13391 14.7402 7.0429 14.7957C6.86087 14.9 6.57383 14.9 6.39181 14.7957C6.3008 14.7471 6.25879 14.6776 6.25879 14.6081V14.5664C6.25879 14.6359 7.17591 14.6359 7.17591 14.5664Z"
          fill="#303033"
        />
        <path
          d="M6.3914 14.379C6.57343 14.2747 6.86046 14.2747 7.04249 14.379C7.22451 14.4832 7.22451 14.65 7.04249 14.7542C6.86046 14.8585 6.57343 14.8585 6.3914 14.7542C6.20938 14.65 6.20938 14.4832 6.3914 14.379Z"
          fill="#535359"
        />
        <path
          d="M6.41996 14.6151C6.58098 14.7124 6.85402 14.7124 7.01504 14.6151C7.09905 14.5665 7.14106 14.504 7.14106 14.4414V14.5596C7.14106 14.6221 7.09905 14.6846 7.01504 14.7333C6.85402 14.8306 6.58098 14.8306 6.41996 14.7333C6.33595 14.6846 6.29395 14.6221 6.29395 14.5596V14.4414C6.29395 14.504 6.33595 14.5665 6.41996 14.6151Z"
          fill="#121214"
        />
        <path
          d="M6.41996 14.2751C6.58098 14.1778 6.85402 14.1778 7.01504 14.2751C7.18307 14.3655 7.18307 14.5184 7.01504 14.6157C6.85402 14.713 6.58098 14.713 6.41996 14.6157C6.25194 14.5184 6.25194 14.3655 6.41996 14.2751Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 14.4482V14.4899C7.17591 14.5594 7.13391 14.622 7.0429 14.6776C6.86087 14.7818 6.57383 14.7818 6.39181 14.6776C6.3008 14.6289 6.25879 14.5594 6.25879 14.4899V14.4482C6.25879 14.5177 7.17591 14.5177 7.17591 14.4482Z"
          fill="#303033"
        />
        <path
          d="M6.3914 14.2608C6.57343 14.1566 6.86046 14.1566 7.04249 14.2608C7.22451 14.365 7.22451 14.5318 7.04249 14.6361C6.86046 14.7403 6.57343 14.7403 6.3914 14.6361C6.20938 14.5318 6.20938 14.365 6.3914 14.2608Z"
          fill="#535359"
        />
        <path
          d="M6.55324 14.1079C6.63725 14.1566 6.78427 14.1566 6.86829 14.1079C6.91029 14.0801 6.93129 14.0523 6.93129 14.0176V14.4346C6.93129 14.4346 6.91029 14.5041 6.86829 14.5249C6.78427 14.5736 6.63725 14.5736 6.55324 14.5249C6.51124 14.5041 6.49023 14.4693 6.49023 14.4346V14.0176C6.49023 14.0176 6.51124 14.0801 6.55324 14.1079Z"
          fill="#A7A9AC"
        />
        <path
          d="M6.55324 13.9271C6.63725 13.8785 6.78427 13.8785 6.86829 13.9271C6.9523 13.9758 6.9523 14.0592 6.86829 14.1078C6.78427 14.1565 6.63725 14.1565 6.55324 14.1078C6.46923 14.0592 6.46923 13.9758 6.55324 13.9271Z"
          fill="#E6E7E8"
        />
        <path
          d="M6.76322 13.6914V14.0111C6.76322 14.0111 6.76322 14.025 6.74922 14.0319C6.72821 14.0458 6.69321 14.0458 6.6722 14.0319C6.6652 14.0319 6.6582 14.018 6.6582 14.0111V13.6914C6.6582 13.6914 6.76322 13.6984 6.76322 13.6914Z"
          fill="#303033"
        />
        <path
          d="M6.672 13.6702C6.672 13.6702 6.72801 13.6563 6.74901 13.6702C6.77002 13.6841 6.77002 13.698 6.74901 13.7119C6.72801 13.7258 6.693 13.7258 6.672 13.7119C6.651 13.698 6.651 13.6841 6.672 13.6702Z"
          fill="#535359"
        />
        <path
          d="M9.61918 13.1771C9.7802 13.0868 10.0532 13.0868 10.2143 13.1771C10.3823 13.2744 10.3823 13.4273 10.2143 13.5177C10.0532 13.615 9.7802 13.615 9.61918 13.5177C9.45116 13.4204 9.45116 13.2675 9.61918 13.1771Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 13.3506V13.3923C10.3751 13.4618 10.3261 13.5243 10.2421 13.5799C10.0601 13.6842 9.77305 13.6842 9.59103 13.5799C9.50001 13.5313 9.45801 13.4618 9.45801 13.3923V13.3506C9.45801 13.4201 10.3751 13.4201 10.3751 13.3506Z"
          fill="#303033"
        />
        <path
          d="M9.5916 13.1631C9.77362 13.0589 10.0607 13.0589 10.2427 13.1631C10.4247 13.2674 10.4247 13.4342 10.2427 13.5384C10.0607 13.6427 9.77362 13.6427 9.5916 13.5384C9.40957 13.4342 9.40957 13.2674 9.5916 13.1631Z"
          fill="#535359"
        />
        <path
          d="M9.61918 13.3993C9.7802 13.4966 10.0532 13.4966 10.2143 13.3993C10.2983 13.3507 10.3403 13.2881 10.3403 13.2256V13.3437C10.3403 13.4063 10.2983 13.4688 10.2143 13.5175C10.0532 13.6148 9.7802 13.6148 9.61918 13.5175C9.53517 13.4688 9.49316 13.4063 9.49316 13.3437V13.2256C9.49316 13.2881 9.53517 13.3507 9.61918 13.3993Z"
          fill="#121214"
        />
        <path
          d="M9.61918 13.0583C9.7802 12.961 10.0532 12.961 10.2143 13.0583C10.3823 13.1556 10.3823 13.3085 10.2143 13.3989C10.0532 13.4962 9.7802 13.4962 9.61918 13.3989C9.45116 13.3016 9.45116 13.1487 9.61918 13.0583Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 13.2256V13.2673C10.3751 13.3368 10.3261 13.3993 10.2421 13.4549C10.0601 13.5592 9.77305 13.5592 9.59103 13.4549C9.50001 13.4063 9.45801 13.3368 9.45801 13.2673V13.2256C9.45801 13.2951 10.3751 13.2951 10.3751 13.2256Z"
          fill="#303033"
        />
        <path
          d="M9.5916 13.045C9.77362 12.9407 10.0607 12.9407 10.2427 13.045C10.4247 13.1492 10.4247 13.316 10.2427 13.4203C10.0607 13.5245 9.77362 13.5245 9.5916 13.4203C9.40957 13.316 9.40957 13.1492 9.5916 13.045Z"
          fill="#535359"
        />
        <path
          d="M9.61918 13.2812C9.7802 13.3785 10.0532 13.3785 10.2143 13.2812C10.2983 13.2325 10.3403 13.17 10.3403 13.1074V13.2256C10.3403 13.2881 10.2983 13.3507 10.2143 13.3993C10.0532 13.4966 9.7802 13.4966 9.61918 13.3993C9.53517 13.3507 9.49316 13.2881 9.49316 13.2256V13.1074C9.49316 13.17 9.53517 13.2325 9.61918 13.2812Z"
          fill="#121214"
        />
        <path
          d="M9.61918 12.9402C9.7802 12.8429 10.0532 12.8429 10.2143 12.9402C10.3823 13.0305 10.3823 13.1834 10.2143 13.2807C10.0532 13.378 9.7802 13.378 9.61918 13.2807C9.45116 13.1834 9.45116 13.0305 9.61918 12.9402Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 13.1074V13.1491C10.3751 13.2186 10.3261 13.2812 10.2421 13.3368C10.0601 13.441 9.77305 13.441 9.59103 13.3368C9.50001 13.2881 9.45801 13.2186 9.45801 13.1491V13.1074C9.45801 13.1769 10.3751 13.1769 10.3751 13.1074Z"
          fill="#303033"
        />
        <path
          d="M9.5916 12.92C9.77362 12.8157 10.0607 12.8157 10.2427 12.92C10.4247 13.0242 10.4247 13.191 10.2427 13.2953C10.0607 13.3995 9.77362 13.3995 9.5916 13.2953C9.40957 13.191 9.40957 13.0242 9.5916 12.92Z"
          fill="#535359"
        />
        <path
          d="M9.61918 13.1493C9.7802 13.2466 10.0532 13.2466 10.2143 13.1493C10.2983 13.1007 10.3403 13.0381 10.3403 12.9756V13.0937C10.3403 13.1563 10.2983 13.2188 10.2143 13.2675C10.0532 13.3648 9.7802 13.3648 9.61918 13.2675C9.53517 13.2188 9.49316 13.1563 9.49316 13.0937V12.9756C9.49316 13.0381 9.53517 13.1007 9.61918 13.1493Z"
          fill="#121214"
        />
        <path
          d="M9.61918 12.8015C9.7802 12.7042 10.0532 12.7042 10.2143 12.8015C10.3823 12.8988 10.3823 13.0517 10.2143 13.142C10.0532 13.2393 9.7802 13.2393 9.61918 13.142C9.45116 13.0447 9.45116 12.8918 9.61918 12.8015Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 12.9756V13.0173C10.3751 13.0868 10.3261 13.1493 10.2421 13.2049C10.0601 13.3092 9.77305 13.3092 9.59103 13.2049C9.50001 13.1563 9.45801 13.0868 9.45801 13.0173V12.9756C9.45801 13.0451 10.3751 13.0451 10.3751 12.9756Z"
          fill="#303033"
        />
        <path
          d="M9.5916 12.7872C9.77362 12.6829 10.0607 12.6829 10.2427 12.7872C10.4177 12.8914 10.4177 13.0582 10.2427 13.1625C10.0607 13.2667 9.77362 13.2667 9.5916 13.1625C9.40957 13.0582 9.40957 12.8914 9.5916 12.7872Z"
          fill="#535359"
        />
        <path
          d="M9.61918 13.0234C9.7802 13.1206 10.0532 13.1206 10.2143 13.0234C10.2983 12.9747 10.3403 12.9122 10.3403 12.8496V12.9678C10.3403 13.0303 10.2983 13.0928 10.2143 13.1415C10.0532 13.2388 9.7802 13.2388 9.61918 13.1415C9.53517 13.0928 9.49316 13.0303 9.49316 12.9678V12.8496C9.49316 12.9122 9.53517 12.9747 9.61918 13.0234Z"
          fill="#121214"
        />
        <path
          d="M9.61918 12.6833C9.7802 12.586 10.0532 12.586 10.2143 12.6833C10.3823 12.7806 10.3823 12.9266 10.2143 13.0239C10.0532 13.1212 9.7802 13.1212 9.61918 13.0239C9.45116 12.9266 9.45116 12.7737 9.61918 12.6833Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 12.8574V12.8991C10.3751 12.9686 10.3261 13.0312 10.2421 13.0868C10.0601 13.191 9.77305 13.191 9.59103 13.0868C9.50001 13.0312 9.45801 12.9686 9.45801 12.8991V12.8574C9.45801 12.9269 10.3751 12.9269 10.3751 12.8574Z"
          fill="#303033"
        />
        <path
          d="M9.5916 12.669C9.77362 12.5648 10.0607 12.5648 10.2427 12.669C10.4177 12.7733 10.4177 12.94 10.2427 13.0443C10.0607 13.1485 9.77362 13.1485 9.5916 13.0443C9.40957 12.94 9.40957 12.7733 9.5916 12.669Z"
          fill="#535359"
        />
        <path
          d="M9.61918 12.9052C9.7802 13.0025 10.0532 13.0025 10.2143 12.9052C10.2983 12.8565 10.3403 12.794 10.3403 12.7314V12.8496C10.3403 12.9121 10.2983 12.9747 10.2143 13.0233C10.0532 13.1206 9.7802 13.1206 9.61918 13.0233C9.53517 12.9747 9.49316 12.9121 9.49316 12.8496V12.7314C9.49316 12.794 9.53517 12.8565 9.61918 12.9052Z"
          fill="#121214"
        />
        <path
          d="M9.61918 12.5652C9.7802 12.4679 10.0532 12.4679 10.2143 12.5652C10.3823 12.6625 10.3823 12.8153 10.2143 12.9057C10.0532 13.003 9.7802 13.003 9.61918 12.9057C9.45116 12.8084 9.45116 12.6555 9.61918 12.5652Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 12.7314V12.7731C10.3751 12.8426 10.3261 12.9052 10.2421 12.9608C10.0601 13.065 9.77305 13.065 9.59103 12.9608C9.50001 12.9121 9.45801 12.8426 9.45801 12.7731V12.7314C9.45801 12.8009 10.3751 12.8009 10.3751 12.7314Z"
          fill="#303033"
        />
        <path
          d="M9.5916 12.544C9.77362 12.4398 10.0607 12.4398 10.2427 12.544C10.4177 12.6483 10.4177 12.815 10.2427 12.9193C10.0607 13.0235 9.77362 13.0235 9.5916 12.9193C9.40957 12.815 9.40957 12.6483 9.5916 12.544Z"
          fill="#535359"
        />
        <path
          d="M9.61918 12.7879C9.7802 12.8852 10.0532 12.8852 10.2143 12.7879C10.2983 12.7392 10.3403 12.6767 10.3403 12.6211V12.7392C10.3403 12.8018 10.2983 12.8643 10.2143 12.913C10.0532 13.0103 9.7802 13.0103 9.61918 12.913C9.53517 12.8643 9.49316 12.8018 9.49316 12.7392V12.6211C9.49316 12.6836 9.53517 12.7462 9.61918 12.7879Z"
          fill="#121214"
        />
        <path
          d="M9.61918 12.4402C9.7802 12.3429 10.0532 12.3429 10.2143 12.4402C10.3823 12.5375 10.3823 12.6903 10.2143 12.7807C10.0532 12.878 9.7802 12.878 9.61918 12.7807C9.45116 12.6834 9.45116 12.5375 9.61918 12.4402Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 12.6133V12.655C10.3751 12.7245 10.3261 12.787 10.2421 12.8426C10.0601 12.9469 9.77305 12.9469 9.59103 12.8426C9.50001 12.794 9.45801 12.7245 9.45801 12.655V12.6133C9.45801 12.6828 10.3751 12.6828 10.3751 12.6133Z"
          fill="#303033"
        />
        <path
          d="M9.5916 12.4258C9.77362 12.3216 10.0607 12.3216 10.2427 12.4258C10.4177 12.5301 10.4177 12.6969 10.2427 12.8011C10.0607 12.9054 9.77362 12.9054 9.5916 12.8011C9.40957 12.6969 9.40957 12.5301 9.5916 12.4258Z"
          fill="#535359"
        />
        <path
          d="M9.75246 12.2798C9.83647 12.3284 9.98349 12.3284 10.0675 12.2798C10.1095 12.252 10.1305 12.2242 10.1305 12.1895V12.6064C10.1305 12.6064 10.1095 12.669 10.0675 12.6968C9.98349 12.7454 9.83647 12.7454 9.75246 12.6968C9.71046 12.6759 9.68945 12.6412 9.68945 12.6064V12.1895C9.68945 12.1895 9.71046 12.252 9.75246 12.2798Z"
          fill="#A7A9AC"
        />
        <path
          d="M9.75246 12.099C9.83647 12.0503 9.98349 12.0503 10.0675 12.099C10.1515 12.1476 10.1515 12.231 10.0675 12.2797C9.98349 12.3283 9.83647 12.3283 9.75246 12.2797C9.66845 12.231 9.66845 12.1476 9.75246 12.099Z"
          fill="#E6E7E8"
        />
        <path
          d="M9.96244 11.8633V12.183C9.96244 12.183 9.96244 12.1969 9.94843 12.2038C9.92743 12.2177 9.89243 12.2177 9.87142 12.2038C9.85742 12.2038 9.85742 12.1899 9.85742 12.183V11.8633C9.85742 11.8633 9.96244 11.8702 9.96244 11.8633Z"
          fill="#303033"
        />
        <path
          d="M9.87122 11.8421C9.87122 11.8421 9.92723 11.8282 9.94823 11.8421C9.96923 11.856 9.96923 11.8699 9.94823 11.8838C9.92723 11.8977 9.89222 11.8977 9.87122 11.8838C9.85022 11.8699 9.85022 11.856 9.87122 11.8421Z"
          fill="#535359"
        />
        <path
          d="M9.90643 11.9114C9.90643 11.9114 9.85742 11.8906 9.85742 11.8628C9.85742 11.835 9.87842 11.8141 9.90643 11.8141C9.94143 11.8141 13.0639 11.7794 17.0334 9.05508C17.0544 9.04118 17.0894 9.04813 17.1034 9.06898C17.1174 9.08983 17.1174 9.12458 17.0894 9.13848C13.0919 11.8836 9.93443 11.9114 9.90643 11.9114Z"
          fill="#121214"
        />
        <path
          d="M0.0556194 18.8963C0.0556194 18.8963 0.0206146 18.8824 0.00661275 18.8685C-0.00738914 18.8407 0.00661275 18.8129 0.0276156 18.799C0.0696213 18.7782 4.3402 16.6099 6.67151 13.6563C6.68552 13.6354 6.72052 13.6285 6.74152 13.6493C6.76253 13.6632 6.76953 13.698 6.74852 13.7188C4.41021 16.6863 0.118628 18.8685 0.0766222 18.8894C0.0766222 18.8894 0.0626203 18.8894 0.0556194 18.8894V18.8963Z"
          fill="#121214"
        />
        <path
          d="M11.6072 5.84488L18.2371 5.60164V6.10202L18.0271 6.22017L17.9361 6.11592L12.4333 6.31746L18.2371 9.64637V10.0842L18.0271 10.2093L17.9291 9.91046L9.878 5.29586L9.66797 4.96922L9.878 4.85107L11.6072 5.84488Z"
          fill="#BCBEC0"
        />
        <path
          d="M11.3972 5.96305L18.0271 5.71981V6.22019L12.2233 6.43563L18.0271 9.76454V10.2093L9.66797 5.41402V4.96924L11.3972 5.96305Z"
          fill="#A7A9AC"
        />
        <path
          d="M10.2493 5.06652L10.0393 5.18466L9.68924 5.62944L7.53295 6.88039L6.67183 7.10278L6.30078 6.88734L9.87826 4.85107L10.2493 5.06652Z"
          fill="#BCBEC0"
        />
        <path
          d="M10.0393 5.18457L6.67188 7.10269V7.54747L10.0393 5.62935V5.18457Z"
          fill="#939598"
        />
        <path
          d="M8.24004 7.76236L14.8699 7.51912V8.0195L14.6599 8.13765L14.5689 8.0334L9.06615 8.23494L14.8699 11.5569V12.0017L14.6599 12.1198L14.5619 11.8279L6.51081 7.21334L6.30078 6.8867L6.51081 6.76855L8.24004 7.76236Z"
          fill="#BCBEC0"
        />
        <path
          d="M8.03001 7.88053L14.6599 7.63729V8.13767L8.85613 8.35311L14.6599 11.682V12.1199L6.30078 7.3315V6.88672L8.03001 7.88053Z"
          fill="#A7A9AC"
        />
        <path
          d="M6.41996 6.6576C6.58098 6.56726 6.84702 6.56726 7.01504 6.6576C7.18307 6.7549 7.18307 6.90779 7.01504 6.99814C6.84702 7.09543 6.58098 7.09543 6.41996 6.99814C6.25194 6.90084 6.25194 6.74795 6.41996 6.6576Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 6.83203V6.87373C7.17591 6.94323 7.13391 7.00577 7.0429 7.06137C6.86087 7.16562 6.57383 7.16562 6.39181 7.06137C6.3008 7.01272 6.25879 6.94323 6.25879 6.87373V6.83203C6.25879 6.90153 7.17591 6.90153 7.17591 6.83203Z"
          fill="#303033"
        />
        <path
          d="M6.3914 6.64361C6.57343 6.53937 6.86046 6.53937 7.04249 6.64361C7.22451 6.74786 7.22451 6.91465 7.04249 7.0189C6.86046 7.12314 6.57343 7.12314 6.3914 7.0189C6.20938 6.91465 6.20938 6.74786 6.3914 6.64361Z"
          fill="#535359"
        />
        <path
          d="M6.41996 6.8798C6.58098 6.97709 6.84702 6.97709 7.01504 6.8798C7.09905 6.83115 7.14106 6.7686 7.14106 6.70605V6.8242C7.14106 6.88675 7.09905 6.94929 7.01504 6.99794C6.84702 7.09524 6.58098 7.09524 6.41996 6.99794C6.33595 6.94929 6.29395 6.88675 6.29395 6.8242V6.70605C6.29395 6.7686 6.33595 6.83115 6.41996 6.8798Z"
          fill="#121214"
        />
        <path
          d="M6.41996 6.53977C6.58098 6.44247 6.84702 6.44247 7.01504 6.53977C7.18307 6.63012 7.18307 6.78996 7.01504 6.8803C6.84702 6.9776 6.58098 6.9776 6.41996 6.8803C6.25194 6.78301 6.25194 6.63012 6.41996 6.53977Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 6.70605V6.74775C7.17591 6.81725 7.13391 6.8798 7.0429 6.9354C6.86087 7.03964 6.57383 7.03964 6.39181 6.9354C6.3008 6.88675 6.25879 6.81725 6.25879 6.74775V6.70605C6.25879 6.77555 7.17591 6.77555 7.17591 6.70605Z"
          fill="#303033"
        />
        <path
          d="M6.3914 6.51861C6.57343 6.41437 6.86046 6.41437 7.04249 6.51861C7.22451 6.62286 7.22451 6.78965 7.04249 6.8939C6.86046 6.99814 6.57343 6.99814 6.3914 6.8939C6.20938 6.78965 6.20938 6.62286 6.3914 6.51861Z"
          fill="#535359"
        />
        <path
          d="M6.41996 6.7548C6.58098 6.85209 6.84702 6.85209 7.01504 6.7548C7.09905 6.70615 7.14106 6.6436 7.14106 6.58105V6.6992C7.14106 6.76175 7.09905 6.82429 7.01504 6.87294C6.84702 6.97024 6.58098 6.97024 6.41996 6.87294C6.33595 6.82429 6.29395 6.76175 6.29395 6.6992V6.58105C6.29395 6.6436 6.33595 6.70615 6.41996 6.7548Z"
          fill="#121214"
        />
        <path
          d="M6.41996 6.41477C6.58098 6.31747 6.84702 6.31747 7.01504 6.41477C7.18307 6.50512 7.18307 6.66496 7.01504 6.75531C6.84702 6.8526 6.58098 6.8526 6.41996 6.75531C6.25194 6.65801 6.25194 6.50512 6.41996 6.41477Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 6.58789V6.62959C7.17591 6.69909 7.13391 6.76163 7.0429 6.81723C6.86087 6.92148 6.57383 6.92148 6.39181 6.81723C6.3008 6.76858 6.25879 6.69909 6.25879 6.62959V6.58789C6.25879 6.65739 7.17591 6.65739 7.17591 6.58789Z"
          fill="#303033"
        />
        <path
          d="M6.3914 6.40045C6.57343 6.2962 6.86046 6.2962 7.04249 6.40045C7.22451 6.5047 7.22451 6.67149 7.04249 6.77573C6.86046 6.87998 6.57343 6.87998 6.3914 6.77573C6.20938 6.67149 6.20938 6.5047 6.3914 6.40045Z"
          fill="#535359"
        />
        <path
          d="M6.41996 6.62296C6.58098 6.72026 6.85402 6.72026 7.01504 6.62296C7.09905 6.57431 7.14106 6.51177 7.14106 6.44922V6.56736C7.14106 6.62991 7.09905 6.69246 7.01504 6.74111C6.85402 6.8384 6.58098 6.8384 6.41996 6.74111C6.33595 6.69246 6.29395 6.62991 6.29395 6.56736V6.44922C6.29395 6.51177 6.33595 6.57431 6.41996 6.62296Z"
          fill="#121214"
        />
        <path
          d="M6.41996 6.28293C6.58098 6.18564 6.85402 6.18564 7.01504 6.28293C7.18307 6.38023 7.18307 6.53312 7.01504 6.62347C6.85402 6.72076 6.58098 6.72076 6.41996 6.62347C6.25194 6.52617 6.25194 6.38023 6.41996 6.28293Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 6.45605V6.49775C7.17591 6.56725 7.13391 6.6298 7.0429 6.6854C6.86087 6.78964 6.57383 6.78964 6.39181 6.6854C6.3008 6.63675 6.25879 6.56725 6.25879 6.49775V6.45605C6.25879 6.52555 7.17591 6.52555 7.17591 6.45605Z"
          fill="#303033"
        />
        <path
          d="M6.3914 6.26861C6.57343 6.16437 6.86046 6.16437 7.04249 6.26861C7.22451 6.37286 7.22451 6.53965 7.04249 6.6439C6.86046 6.74814 6.57343 6.74814 6.3914 6.6439C6.20938 6.53965 6.20938 6.37286 6.3914 6.26861Z"
          fill="#535359"
        />
        <path
          d="M6.41996 6.5048C6.58098 6.60209 6.85402 6.60209 7.01504 6.5048C7.09905 6.45615 7.14106 6.3936 7.14106 6.33105V6.4492C7.14106 6.51175 7.09905 6.57429 7.01504 6.62294C6.85402 6.72024 6.58098 6.72024 6.41996 6.62294C6.33595 6.57429 6.29395 6.51175 6.29395 6.4492V6.33105C6.29395 6.3936 6.33595 6.45615 6.41996 6.5048Z"
          fill="#121214"
        />
        <path
          d="M6.41996 6.16477C6.58098 6.06747 6.85402 6.06747 7.01504 6.16477C7.18307 6.26206 7.18307 6.41496 7.01504 6.5053C6.85402 6.6026 6.58098 6.6026 6.41996 6.5053C6.25194 6.40801 6.25194 6.25512 6.41996 6.16477Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 6.33105V6.37275C7.17591 6.44225 7.13391 6.5048 7.0429 6.5604C6.86087 6.66464 6.57383 6.66464 6.39181 6.5604C6.3008 6.5048 6.25879 6.44225 6.25879 6.37275V6.33105C6.25879 6.40055 7.17591 6.40055 7.17591 6.33105Z"
          fill="#303033"
        />
        <path
          d="M6.3914 6.15045C6.57343 6.0462 6.86046 6.0462 7.04249 6.15045C7.22451 6.2547 7.22451 6.42149 7.04249 6.52573C6.86046 6.62998 6.57343 6.62998 6.3914 6.52573C6.20938 6.42149 6.20938 6.2547 6.3914 6.15045Z"
          fill="#535359"
        />
        <path
          d="M6.41996 6.38652C6.58098 6.48382 6.85402 6.48382 7.01504 6.38652C7.09905 6.33787 7.14106 6.27532 7.14106 6.21973V6.33787C7.14106 6.40042 7.09905 6.46297 7.01504 6.51161C6.85402 6.60891 6.58098 6.60891 6.41996 6.51161C6.33595 6.46297 6.29395 6.40042 6.29395 6.33787V6.21973C6.29395 6.28227 6.33595 6.34482 6.41996 6.38652Z"
          fill="#121214"
        />
        <path
          d="M6.41996 6.0466C6.58098 5.94931 6.85402 5.94931 7.01504 6.0466C7.18307 6.1439 7.18307 6.29679 7.01504 6.38714C6.85402 6.48444 6.58098 6.48444 6.41996 6.38714C6.25194 6.29679 6.25194 6.1439 6.41996 6.0466Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 6.21289V6.25459C7.17591 6.32409 7.13391 6.38663 7.0429 6.44223C6.86087 6.54648 6.57383 6.54648 6.39181 6.44223C6.3008 6.39358 6.25879 6.32409 6.25879 6.25459V6.21289C6.25879 6.28239 7.17591 6.28239 7.17591 6.21289Z"
          fill="#303033"
        />
        <path
          d="M6.3914 6.02545C6.57343 5.9212 6.86046 5.9212 7.04249 6.02545C7.22451 6.1297 7.22451 6.29649 7.04249 6.40073C6.86046 6.50498 6.57343 6.50498 6.3914 6.40073C6.20938 6.29649 6.20938 6.1297 6.3914 6.02545Z"
          fill="#535359"
        />
        <path
          d="M6.41996 6.26163C6.58098 6.35893 6.85402 6.35893 7.01504 6.26163C7.09905 6.21299 7.14106 6.15044 7.14106 6.08789V6.20604C7.14106 6.26858 7.09905 6.33113 7.01504 6.37283C6.85402 6.47012 6.58098 6.47012 6.41996 6.37283C6.33595 6.32418 6.29395 6.26163 6.29395 6.20604V6.08789C6.29395 6.15044 6.33595 6.21299 6.41996 6.26163Z"
          fill="#121214"
        />
        <path
          d="M6.41996 5.9216C6.58098 5.82431 6.85402 5.82431 7.01504 5.9216C7.18307 6.0189 7.18307 6.17179 7.01504 6.26214C6.85402 6.35944 6.58098 6.35944 6.41996 6.26214C6.25194 6.16484 6.25194 6.01195 6.41996 5.9216Z"
          fill="#E6E7E8"
        />
        <path
          d="M7.17591 6.09473V6.13642C7.17591 6.20592 7.13391 6.26847 7.0429 6.32407C6.86087 6.42831 6.57383 6.42831 6.39181 6.32407C6.3008 6.27542 6.25879 6.20592 6.25879 6.13642V6.09473C6.25879 6.16422 7.17591 6.16422 7.17591 6.09473Z"
          fill="#303033"
        />
        <path
          d="M6.3914 5.90729C6.57343 5.80304 6.86046 5.80304 7.04249 5.90729C7.22451 6.01153 7.22451 6.17832 7.04249 6.28257C6.86046 6.38682 6.57343 6.38682 6.3914 6.28257C6.20938 6.17832 6.20938 6.01153 6.3914 5.90729Z"
          fill="#535359"
        />
        <path
          d="M6.55324 5.75441C6.63725 5.80306 6.78427 5.80306 6.86829 5.75441C6.91029 5.72661 6.93129 5.69881 6.93129 5.66406V6.08105C6.93129 6.08105 6.91029 6.14359 6.86829 6.17139C6.78427 6.22004 6.63725 6.22004 6.55324 6.17139C6.51124 6.15054 6.49023 6.11579 6.49023 6.08105V5.66406C6.49023 5.66406 6.51124 5.72661 6.55324 5.75441Z"
          fill="#A7A9AC"
        />
        <path
          d="M6.55324 5.5736C6.63725 5.52495 6.78427 5.52495 6.86829 5.5736C6.9523 5.62224 6.9523 5.70564 6.86829 5.75429C6.78427 5.80294 6.63725 5.80294 6.55324 5.75429C6.46923 5.70564 6.46923 5.62224 6.55324 5.5736Z"
          fill="#E6E7E8"
        />
        <path
          d="M6.76322 5.33691V5.6566C6.76322 5.6566 6.76322 5.6705 6.74922 5.67745C6.72821 5.69135 6.69321 5.69135 6.6722 5.67745C6.6652 5.67745 6.6582 5.66355 6.6582 5.6566V5.33691C6.6582 5.33691 6.76322 5.34386 6.76322 5.33691Z"
          fill="#303033"
        />
        <path
          d="M6.672 5.31672C6.672 5.31672 6.72801 5.30282 6.74901 5.31672C6.77002 5.33062 6.77002 5.34452 6.74901 5.35842C6.72801 5.37232 6.693 5.37232 6.672 5.35842C6.651 5.34452 6.651 5.33062 6.672 5.31672Z"
          fill="#535359"
        />
        <path
          d="M9.61918 4.82297C9.7802 4.72568 10.0532 4.72568 10.2143 4.82297C10.3823 4.92027 10.3823 5.07316 10.2143 5.16351C10.0532 5.25385 9.7802 5.25385 9.61918 5.16351C9.45116 5.06621 9.45116 4.91332 9.61918 4.82297Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 4.99707V5.03877C10.3751 5.10827 10.3261 5.17081 10.2421 5.22641C10.0601 5.33066 9.77305 5.33066 9.59103 5.22641C9.50001 5.17776 9.45801 5.10827 9.45801 5.03877V4.99707C9.45801 5.06657 10.3751 5.06657 10.3751 4.99707Z"
          fill="#303033"
        />
        <path
          d="M9.5916 4.80914C9.77362 4.7049 10.0607 4.7049 10.2427 4.80914C10.4247 4.91339 10.4247 5.08018 10.2427 5.18443C10.0607 5.28867 9.77362 5.28867 9.5916 5.18443C9.40957 5.08018 9.40957 4.91339 9.5916 4.80914Z"
          fill="#535359"
        />
        <path
          d="M9.61918 5.04532C9.7802 5.14262 10.0532 5.14262 10.2143 5.04532C10.2983 4.99668 10.3403 4.93413 10.3403 4.87158V4.98973C10.3403 5.05227 10.2983 5.11482 10.2143 5.16347C10.0532 5.25382 9.7802 5.25382 9.61918 5.16347C9.53517 5.11482 9.49316 5.05227 9.49316 4.98973V4.87158C9.49316 4.93413 9.53517 4.99668 9.61918 5.04532Z"
          fill="#121214"
        />
        <path
          d="M9.61918 4.70481C9.7802 4.60751 10.0532 4.60751 10.2143 4.70481C10.3823 4.8021 10.3823 4.94805 10.2143 5.04534C10.0532 5.14264 9.7802 5.14264 9.61918 5.04534C9.45116 4.94805 9.45116 4.79515 9.61918 4.70481Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 4.87891V4.9206C10.3751 4.9901 10.3261 5.05265 10.2421 5.10825C10.0601 5.21249 9.77305 5.21249 9.59103 5.10825C9.50001 5.0596 9.45801 4.9901 9.45801 4.9206V4.87891C9.45801 4.9484 10.3751 4.9484 10.3751 4.87891Z"
          fill="#303033"
        />
        <path
          d="M9.5916 4.69098C9.77362 4.58673 10.0607 4.58673 10.2427 4.69098C10.4247 4.79522 10.4247 4.96202 10.2427 5.06626C10.0607 5.17051 9.77362 5.17051 9.5916 5.06626C9.40957 4.96202 9.40957 4.79522 9.5916 4.69098Z"
          fill="#535359"
        />
        <path
          d="M9.61918 4.92716C9.7802 5.02446 10.0532 5.02446 10.2143 4.92716C10.2983 4.87851 10.3403 4.81597 10.3403 4.75342V4.87156C10.3403 4.93411 10.2983 4.99666 10.2143 5.04531C10.0532 5.1426 9.7802 5.1426 9.61918 5.04531C9.53517 4.99666 9.49316 4.93411 9.49316 4.87156V4.75342C9.49316 4.81597 9.53517 4.87851 9.61918 4.92716Z"
          fill="#121214"
        />
        <path
          d="M9.61918 4.58664C9.7802 4.48935 10.0532 4.48935 10.2143 4.58664C10.3823 4.68394 10.3823 4.83683 10.2143 4.92718C10.0532 5.02448 9.7802 5.02448 9.61918 4.92718C9.45116 4.82988 9.45116 4.67699 9.61918 4.58664Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 4.75342V4.79512C10.3751 4.86461 10.3261 4.92716 10.2421 4.98276C10.0601 5.087 9.77305 5.087 9.59103 4.98276C9.50001 4.93411 9.45801 4.86461 9.45801 4.79512V4.75342C9.45801 4.82292 10.3751 4.82292 10.3751 4.75342Z"
          fill="#303033"
        />
        <path
          d="M9.5916 4.56598C9.77362 4.46173 10.0607 4.46173 10.2427 4.56598C10.4247 4.67022 10.4247 4.83702 10.2427 4.94126C10.0607 5.04551 9.77362 5.04551 9.5916 4.94126C9.40957 4.83702 9.40957 4.67022 9.5916 4.56598Z"
          fill="#535359"
        />
        <path
          d="M9.61918 4.79532C9.7802 4.89262 10.0532 4.89262 10.2143 4.79532C10.2983 4.74668 10.3403 4.68413 10.3403 4.62158V4.73973C10.3403 4.80227 10.2983 4.86482 10.2143 4.91347C10.0532 5.01077 9.7802 5.01077 9.61918 4.91347C9.53517 4.86482 9.49316 4.80227 9.49316 4.73973V4.62158C9.49316 4.68413 9.53517 4.74668 9.61918 4.79532Z"
          fill="#121214"
        />
        <path
          d="M9.61918 4.45481C9.7802 4.35751 10.0532 4.35751 10.2143 4.45481C10.3823 4.5521 10.3823 4.705 10.2143 4.79534C10.0532 4.89264 9.7802 4.89264 9.61918 4.79534C9.45116 4.69805 9.45116 4.5521 9.61918 4.45481Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 4.62158V4.66328C10.3751 4.73278 10.3261 4.79532 10.2421 4.85092C10.0601 4.95517 9.77305 4.95517 9.59103 4.85092C9.50001 4.80227 9.45801 4.73278 9.45801 4.66328V4.62158C9.45801 4.69108 10.3751 4.69108 10.3751 4.62158Z"
          fill="#303033"
        />
        <path
          d="M9.5916 4.43414C9.77362 4.3299 10.0607 4.3299 10.2427 4.43414C10.4177 4.53839 10.4177 4.70518 10.2427 4.80943C10.0607 4.91367 9.77362 4.91367 9.5916 4.80943C9.40957 4.70518 9.40957 4.53839 9.5916 4.43414Z"
          fill="#535359"
        />
        <path
          d="M9.61918 4.67032C9.7802 4.76762 10.0532 4.76762 10.2143 4.67032C10.2983 4.62168 10.3403 4.55913 10.3403 4.49658V4.61473C10.3403 4.67727 10.2983 4.73982 10.2143 4.78847C10.0532 4.88577 9.7802 4.88577 9.61918 4.78847C9.53517 4.73982 9.49316 4.67727 9.49316 4.61473V4.49658C9.49316 4.55913 9.53517 4.62168 9.61918 4.67032Z"
          fill="#121214"
        />
        <path
          d="M9.61918 4.32981C9.7802 4.23251 10.0532 4.23251 10.2143 4.32981C10.3823 4.42015 10.3823 4.58 10.2143 4.67034C10.0532 4.76764 9.7802 4.76764 9.61918 4.67034C9.45116 4.58 9.45116 4.42015 9.61918 4.32981Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 4.50342V4.54512C10.3751 4.61461 10.3261 4.67716 10.2421 4.73276C10.0601 4.837 9.77305 4.837 9.59103 4.73276C9.50001 4.68411 9.45801 4.61461 9.45801 4.54512V4.50342C9.45801 4.57292 10.3751 4.57292 10.3751 4.50342Z"
          fill="#303033"
        />
        <path
          d="M9.5916 4.31598C9.77362 4.21173 10.0607 4.21173 10.2427 4.31598C10.4177 4.42022 10.4177 4.58702 10.2427 4.69126C10.0607 4.79551 9.77362 4.79551 9.5916 4.69126C9.40957 4.58702 9.40957 4.42022 9.5916 4.31598Z"
          fill="#535359"
        />
        <path
          d="M9.61918 4.55216C9.7802 4.64946 10.0532 4.64946 10.2143 4.55216C10.2983 4.50351 10.3403 4.44097 10.3403 4.37842V4.49656C10.3403 4.55911 10.2983 4.62166 10.2143 4.67031C10.0532 4.7676 9.7802 4.7676 9.61918 4.67031C9.53517 4.62166 9.49316 4.55911 9.49316 4.49656V4.37842C9.49316 4.44097 9.53517 4.50351 9.61918 4.55216Z"
          fill="#121214"
        />
        <path
          d="M9.61918 4.21164C9.7802 4.11435 10.0532 4.11435 10.2143 4.21164C10.3823 4.30894 10.3823 4.46183 10.2143 4.55218C10.0532 4.64948 9.7802 4.64948 9.61918 4.55218C9.45116 4.45488 9.45116 4.30199 9.61918 4.21164Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 4.37842V4.42012C10.3751 4.48961 10.3261 4.55216 10.2421 4.60776C10.0601 4.712 9.77305 4.712 9.59103 4.60776C9.50001 4.55911 9.45801 4.48961 9.45801 4.42012V4.37842C9.45801 4.44792 10.3751 4.44792 10.3751 4.37842Z"
          fill="#303033"
        />
        <path
          d="M9.5916 4.19781C9.77362 4.09357 10.0607 4.09357 10.2427 4.19781C10.4177 4.30206 10.4177 4.46885 10.2427 4.5731C10.0607 4.67734 9.77362 4.67734 9.5916 4.5731C9.40957 4.46885 9.40957 4.30206 9.5916 4.19781Z"
          fill="#535359"
        />
        <path
          d="M9.61918 4.434C9.7802 4.53129 10.0532 4.53129 10.2143 4.434C10.2983 4.38535 10.3403 4.3228 10.3403 4.26025V4.3784C10.3403 4.44095 10.2983 4.50349 10.2143 4.55214C10.0532 4.64944 9.7802 4.64944 9.61918 4.55214C9.53517 4.50349 9.49316 4.44095 9.49316 4.3784V4.26025C9.49316 4.3228 9.53517 4.38535 9.61918 4.434Z"
          fill="#121214"
        />
        <path
          d="M9.61918 4.08664C9.7802 3.98935 10.0532 3.98935 10.2143 4.08664C10.3823 4.18394 10.3823 4.33683 10.2143 4.42718C10.0532 4.52448 9.7802 4.52448 9.61918 4.42718C9.45116 4.32988 9.45116 4.17699 9.61918 4.08664Z"
          fill="#E6E7E8"
        />
        <path
          d="M10.3751 4.26025V4.30195C10.3751 4.37145 10.3261 4.434 10.2421 4.48959C10.0601 4.59384 9.77305 4.59384 9.59103 4.48959C9.50001 4.44095 9.45801 4.37145 9.45801 4.30195V4.26025C9.45801 4.32975 10.3751 4.32975 10.3751 4.26025Z"
          fill="#303033"
        />
        <path
          d="M9.5916 4.07232C9.77362 3.96808 10.0607 3.96808 10.2427 4.07232C10.4177 4.17657 10.4177 4.34336 10.2427 4.44761C10.0607 4.55185 9.77362 4.55185 9.5916 4.44761C9.40957 4.34336 9.40957 4.17657 9.5916 4.07232Z"
          fill="#535359"
        />
        <path
          d="M9.75246 3.92677C9.83647 3.97542 9.98349 3.97542 10.0675 3.92677C10.1095 3.89897 10.1305 3.87117 10.1305 3.83643V4.25341C10.1305 4.25341 10.1095 4.31596 10.0675 4.34375C9.98349 4.3924 9.83647 4.3924 9.75246 4.34375C9.71046 4.31596 9.68945 4.28816 9.68945 4.25341V3.83643C9.68945 3.83643 9.71046 3.89897 9.75246 3.92677Z"
          fill="#A7A9AC"
        />
        <path
          d="M9.75246 3.74596C9.83647 3.69731 9.98349 3.69731 10.0675 3.74596C10.1515 3.79461 10.1515 3.878 10.0675 3.92665C9.98349 3.9753 9.83647 3.9753 9.75246 3.92665C9.66845 3.878 9.66845 3.79461 9.75246 3.74596Z"
          fill="#E6E7E8"
        />
        <path
          d="M9.96244 3.50977V3.82945C9.96244 3.82945 9.96244 3.84335 9.94843 3.8503C9.92743 3.8642 9.89243 3.8642 9.87142 3.8503C9.85742 3.8503 9.85742 3.8364 9.85742 3.82945V3.50977C9.85742 3.50977 9.96244 3.51672 9.96244 3.50977Z"
          fill="#303033"
        />
        <path
          d="M9.87122 3.4886C9.87122 3.4886 9.92723 3.4747 9.94823 3.4886C9.96923 3.5025 9.96923 3.5164 9.94823 3.5303C9.92723 3.5442 9.89222 3.5442 9.87122 3.5303C9.85022 3.5164 9.85022 3.5025 9.87122 3.4886Z"
          fill="#535359"
        />
        <path
          d="M9.90643 3.55839C9.90643 3.55839 9.85742 3.53754 9.85742 3.50974C9.85742 3.48194 9.87842 3.46109 9.90643 3.46109C9.94143 3.46109 13.0639 3.42634 17.0334 0.702056C17.0544 0.688156 17.0894 0.695106 17.1034 0.715955C17.1174 0.736804 17.1174 0.771553 17.0894 0.785452C13.0919 3.53059 9.93443 3.55839 9.90643 3.55839Z"
          fill="#121214"
        />
        <path
          d="M0.0556194 10.5428C0.0556194 10.5428 0.0206146 10.5289 0.00661275 10.515C-0.00738914 10.4872 0.00661275 10.4594 0.0276156 10.4455C0.0696213 10.4247 4.3402 8.25637 6.67151 5.30274C6.68552 5.28189 6.72052 5.27494 6.74152 5.29579C6.76253 5.31664 6.76953 5.34444 6.74852 5.36529C4.41021 8.33281 0.118628 10.515 0.0766222 10.5359C0.0766222 10.5359 0.0626203 10.5359 0.0556194 10.5359V10.5428Z"
          fill="#121214"
        />
        <path
          opacity="0.3"
          d="M18.237 55.0004L11.4531 51.1155L18.237 47.2236L25.028 51.1155L18.237 55.0004Z"
          fill="black"
        />
        <path
          d="M18.0695 26.9785L14.163 33.1221L14.002 33.407L14.394 33.3653L18.2375 27.2148V27.0272L18.0695 26.9785Z"
          fill="#535359"
        />
        <path
          d="M18.0699 31.3154L13.3933 38.4528L13.2393 38.7308L13.6313 38.6891L18.2379 31.5448V31.3571L18.0699 31.3154Z"
          fill="#535359"
        />
        <path
          d="M18.0205 36.2568L12.5878 44.0683L12.4268 44.3532L12.8188 44.3115L18.1885 36.4862V36.2985L18.0205 36.2568Z"
          fill="#535359"
        />
        <path
          d="M18.4053 26.9785L22.3189 33.1221L22.4729 33.407L22.0878 33.3653L18.2373 27.2148V27.0272L18.4053 26.9785Z"
          fill="#535359"
        />
        <path
          d="M18.4053 31.3154L23.082 38.4528L23.243 38.7308L22.8509 38.6891L18.2373 31.5448V31.3571L18.4053 31.3154Z"
          fill="#535359"
        />
        <path
          d="M18.4542 36.2568L23.8939 44.0683L24.0479 44.3532L23.6629 44.3115L18.2861 36.4862V36.2985L18.4542 36.2568Z"
          fill="#535359"
        />
        <path
          d="M18.2443 36.3051L23.047 39.0572V38.6194L18.2373 35.8604L18.2443 36.3051Z"
          fill="#535359"
        />
        <path
          d="M18.2443 41.4545L23.9081 44.714V44.2692L18.2373 41.0098L18.2443 41.4545Z"
          fill="#535359"
        />
        <path
          d="M22.1648 33.2194L18.2373 30.9746L18.2443 31.4194L22.2698 33.7267L22.1648 33.2194Z"
          fill="#535359"
        />
        <path
          d="M18.2449 36.3051L13.4492 39.0572V38.6194L18.2519 35.8604L18.2449 36.3051Z"
          fill="#535359"
        />
        <path
          d="M18.2447 41.4545L12.5879 44.714V44.2692L18.2517 41.0098L18.2447 41.4545Z"
          fill="#535359"
        />
        <path
          d="M14.3308 33.2194L18.2513 30.9746L18.2443 31.4194L14.2188 33.7267L14.3308 33.2194Z"
          fill="#535359"
        />
        <path
          d="M21.6047 28.4517L18.2372 26.5127L14.9258 28.41L15.1918 28.8269L18.2372 27.0756L21.2896 28.8269L21.6047 28.4517Z"
          fill="#535359"
        />
        <path
          d="M21.8151 28.4525L18.2376 26.4023L14.6602 28.4525H15.1922L18.2376 26.7012L21.2901 28.4525H21.8151Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5038 51.1289H17.9717V26.8883L18.2377 26.4922L18.5038 26.8883V51.1289Z"
          fill="#535359"
        />
        <path
          d="M18.5038 5.76172H17.9717V26.8888H18.5038V5.76172Z"
          fill="#535359"
        />
        <path
          d="M20.2471 25.0602L21.6403 24.2471L21.8154 24.3514V24.5738L20.4292 25.3799L21.8154 27.8123V28.1459L21.4723 27.9513L20.1071 25.5606L18.7139 26.3668L18.5039 26.2486V26.0679L19.9321 25.2409L18.5039 22.746V22.4541L18.882 22.6695L20.2471 25.0602Z"
          fill="#535359"
        />
        <path
          d="M20.2471 20.8904L21.6403 20.0773L21.8154 20.1746V20.397L20.4292 21.2032L21.8154 23.6356V23.9761L21.4723 23.7746L20.1071 21.3908L18.7139 22.197L18.5039 22.0788V21.8981L19.9321 21.0711L18.5039 18.5762V18.2773L18.882 18.4928L20.2471 20.8904Z"
          fill="#535359"
        />
        <path
          d="M20.2471 16.7136L21.6403 15.9074L21.8154 16.0047V16.2271L20.4292 17.0333L21.8154 19.4656V19.8062L21.4723 19.6046L20.1071 17.2209L18.7139 18.0271L18.5039 17.902V17.7282L19.9321 16.9012L18.5039 14.4063V14.1074L18.882 14.3229L20.2471 16.7136Z"
          fill="#535359"
        />
        <path
          d="M20.2471 12.5446L21.6403 11.7315L21.8154 11.8357V12.0581L20.4292 12.8643L21.8154 15.2967V15.6303L21.4723 15.4357L20.1071 13.045L18.7139 13.8581L18.5039 13.733V13.5523L19.9321 12.7323L18.5039 10.2304V9.93848L18.882 10.1539L20.2471 12.5446Z"
          fill="#535359"
        />
        <path
          d="M18.5039 5.76172L18.882 5.97716L20.2471 8.37481L21.6403 7.56169L21.8154 7.66594V7.88833L20.4292 8.68755L21.8154 11.1199V11.4605L21.4723 11.2659L20.1071 8.87519L18.7139 9.68135L18.5039 9.56321V9.38252L19.9321 8.5555L18.5039 6.06056V5.76172Z"
          fill="#535359"
        />
        <path
          d="M16.2284 25.0602L14.8352 24.2471L14.6602 24.3514V24.5738L16.0533 25.3799L14.6602 27.8123V28.1459L15.0032 27.9513L16.3684 25.5606L17.7616 26.3668L17.9716 26.2486V26.0679L16.5504 25.2409L17.9716 22.746V22.4541L17.6006 22.6695L16.2284 25.0602Z"
          fill="#535359"
        />
        <path
          d="M16.2284 20.8904L14.8352 20.0773L14.6602 20.1746V20.397L16.0533 21.2032L14.6602 23.6356V23.9761L15.0032 23.7746L16.3684 21.3908L17.7616 22.197L17.9716 22.0788V21.8981L16.5504 21.0711L17.9716 18.5762V18.2773L17.6006 18.4928L16.2284 20.8904Z"
          fill="#535359"
        />
        <path
          d="M16.2284 16.7136L14.8352 15.9074L14.6602 16.0047V16.2271L16.0533 17.0333L14.6602 19.4656V19.8062L15.0032 19.6046L16.3684 17.2209L17.7616 18.0271L17.9716 17.902V17.7282L16.5504 16.9012L17.9716 14.4063V14.1074L17.6006 14.3229L16.2284 16.7136Z"
          fill="#535359"
        />
        <path
          d="M16.2284 12.5446L14.8352 11.7315L14.6602 11.8357V12.0581L16.0533 12.8643L14.6602 15.2967V15.6303L15.0032 15.4357L16.3684 13.045L17.7616 13.8581L17.9716 13.733V13.5523L16.5504 12.7323L17.9716 10.2304V9.93848L17.6006 10.1539L16.2284 12.5446Z"
          fill="#535359"
        />
        <path
          d="M17.9716 5.76172L17.6006 5.97716L16.2284 8.37481L14.8352 7.56169L14.6602 7.66594V7.88833L16.0533 8.68755L14.6602 11.1199V11.4605L15.0032 11.2659L16.3684 8.87519L17.7616 9.68135L17.9716 9.56321V9.38252L16.5504 8.5555L17.9716 6.06056V5.76172Z"
          fill="#535359"
        />
        <path
          d="M21.6047 7.58925L18.2372 5.65723L14.9258 7.5545L15.1918 7.96453L18.2372 6.22015L21.2896 7.96453L21.6047 7.58925Z"
          fill="#535359"
        />
        <path
          d="M21.8151 7.58923L18.2376 5.53906L14.6602 7.58923H15.1922L18.2376 5.84485L21.2901 7.58923H21.8151Z"
          fill="#BCBEC0"
        />
        <path
          d="M17.9508 4.37156L15.1995 7.29044L14.9404 7.44333V7.17924L17.8178 4.04492L17.9508 4.37156Z"
          fill="#535359"
        />
        <path
          d="M15.801 5.14941L18.0063 5.72624L18.0693 5.86523L17.9993 6.00423L15.801 5.4413L15.668 5.37875L15.801 5.14941Z"
          fill="#535359"
        />
        <path
          d="M18.2238 3.04405L15.8225 5.59459L15.5635 5.74054V5.4834L18.1888 2.62012L18.2238 3.04405Z"
          fill="#535359"
        />
        <path
          d="M16.424 3.4541L18.6293 4.02398L18.6923 4.16992L18.6223 4.30892L16.424 3.73904L16.291 3.68344L16.424 3.4541Z"
          fill="#535359"
        />
        <path
          d="M18.4263 1.70274L16.375 3.878L16.333 3.69731L16.445 3.41237L18.3913 1.27881L18.4263 1.70274Z"
          fill="#535359"
        />
        <path
          d="M16.9561 2.20312L18.3843 2.57146L18.4263 2.7591L18.3843 2.8564L16.8721 2.46026L16.8931 2.33517L16.9561 2.20312Z"
          fill="#535359"
        />
        <path
          d="M18.5312 4.37156L21.2756 7.29044L21.5347 7.44333V7.17924L18.6643 4.04492L18.5312 4.37156Z"
          fill="#535359"
        />
        <path
          d="M20.6734 5.14941L18.4751 5.72624L18.4121 5.86523L18.4821 6.00423L20.6734 5.4413L20.8064 5.37875L20.6734 5.14941Z"
          fill="#535359"
        />
        <path
          d="M18.252 3.04405L20.6533 5.59459L20.9123 5.74054V5.4834L18.287 2.62012L18.252 3.04405Z"
          fill="#535359"
        />
        <path
          d="M20.0505 3.4541L17.8522 4.02398L17.7822 4.16992L17.8522 4.30892L20.0505 3.73904L20.1836 3.68344L20.0505 3.4541Z"
          fill="#535359"
        />
        <path
          d="M18.0488 1.70274L20.1001 3.878L20.1421 3.69731L20.0371 3.41237L18.0838 1.27881L18.0488 1.70274Z"
          fill="#535359"
        />
        <path
          d="M19.519 2.20312L18.0908 2.57146L18.0488 2.7591L18.0908 2.8564L19.603 2.46026L19.582 2.33517L19.519 2.20312Z"
          fill="#535359"
        />
        <path
          d="M15.1916 28.8689V7.58887H14.6596V28.8272L11.4531 51.1149H11.9852L15.1916 28.8689Z"
          fill="#A7A9AC"
        />
        <path
          d="M12.6508 44.603L18.2585 47.8207V47.3759L12.9658 44.3389L12.4268 44.3528L12.6508 44.603Z"
          fill="#BCBEC0"
        />
        <path
          d="M12.3711 44.7636L17.9789 47.9813V47.5365L12.4271 44.3535L12.3711 44.7636Z"
          fill="#A7A9AC"
        />
        <path
          d="M14.226 33.6576L18.2585 35.9649V35.5271L14.541 33.3936L14.002 33.4075L14.226 33.6576Z"
          fill="#BCBEC0"
        />
        <path
          d="M13.9463 33.8173L17.9788 36.1315V35.6867L14.0023 33.4072L13.9463 33.8173Z"
          fill="#A7A9AC"
        />
        <path
          d="M13.4633 38.9809L18.2589 41.733V41.2882L13.7713 38.7168L13.2393 38.7307L13.4633 38.9809Z"
          fill="#BCBEC0"
        />
        <path
          d="M13.1758 39.1405L17.9784 41.8926V41.4548L13.2388 38.7305L13.1758 39.1405Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.5037 30.7247H17.9716L17.8456 30.4467L14.9892 28.8622L14.6602 28.4521H15.1922L18.5037 30.3494V30.7247Z"
          fill="#BCBEC0"
        />
        <path
          d="M17.9716 30.7247L14.6602 28.8274V28.4521L17.9716 30.3494V30.7247Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2934 26.4295L18.1184 26.3322L16.7182 27.1384L15.353 24.7477L14.975 24.5322V24.8311L16.4032 27.326L14.975 28.153L14.877 28.57L15.192 28.4519L16.5852 27.6387L17.9504 30.0294L18.2934 30.224V29.8905L16.9002 27.4581L18.2934 26.6519V26.4295Z"
          fill="#939598"
        />
        <path
          d="M17.9716 26.5477L17.7966 26.4504L16.4034 27.2635L15.0382 24.8658L14.6602 24.6504V24.9492L16.0883 27.4442L14.6602 28.2712V28.4519L14.8772 28.57L16.2704 27.7639L17.6286 30.1476L17.9716 30.3492V30.0086L16.5854 27.5762L17.9716 26.7701V26.5477Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.5037 26.5478H17.9716L17.8456 26.2768L14.9892 24.6923L14.6602 24.2822H15.1922L18.5037 26.1795V26.5478Z"
          fill="#BCBEC0"
        />
        <path
          d="M17.9716 26.5478L14.6602 24.6506V24.2822L17.9716 26.1795V26.5478Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2934 22.2596L18.1184 22.1553L16.7182 22.9684L15.353 20.5777L14.975 20.3623V20.6542L16.4032 23.1491L14.975 23.9762L14.877 24.4001L15.192 24.2819L16.5852 23.4688L17.9504 25.8595L18.2934 26.0541V25.7205L16.9002 23.2881L18.2934 22.482V22.2596Z"
          fill="#939598"
        />
        <path
          d="M17.9716 22.3777L17.7966 22.2804L16.4034 23.0866L15.0382 20.6959L14.6602 20.4805V20.7793L16.0883 23.2743L14.6602 24.1013V24.282L14.8772 24.4001L16.2704 23.5939L17.6286 25.9777L17.9716 26.1792V25.8387L16.5854 23.4063L17.9716 22.6001V22.3777Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.5037 22.378H17.9716L17.8456 22.107L14.9892 20.5225L14.6602 20.1055H15.1922L18.5037 22.0027V22.378Z"
          fill="#BCBEC0"
        />
        <path
          d="M17.9716 22.378L14.6602 20.4808V20.1055L17.9716 22.0027V22.378Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2934 18.0828L18.1184 17.9855L16.7182 18.7986L15.353 16.401L14.975 16.1855V16.4844L16.4032 18.9793L14.975 19.8063L14.877 20.2303L15.192 20.1052L16.5852 19.299L17.9504 21.6897L18.2934 21.8843V21.5438L16.9002 19.1114L18.2934 18.3122V18.0828Z"
          fill="#939598"
        />
        <path
          d="M17.9716 18.2078L17.7966 18.1105L16.4034 18.9167L15.0382 16.526L14.6602 16.3105V16.6094L16.0883 19.1043L14.6602 19.9313V20.1051L14.8772 20.2302L16.2704 19.424L17.6286 21.8078L17.9716 22.0024V21.6688L16.5854 19.2364L17.9716 18.4302V18.2078Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.5037 18.2081H17.9716L17.8456 17.9371L14.9892 16.3456L14.6602 15.9355H15.1922L18.5037 17.8328V18.2081Z"
          fill="#BCBEC0"
        />
        <path
          d="M17.9716 18.2081L14.6602 16.3108V15.9355L17.9716 17.8328V18.2081Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2934 13.9129L18.1184 13.8156L16.7182 14.6218L15.353 12.2311L14.975 12.0156V12.3145L16.4032 14.8094L14.975 15.6364L14.877 16.0534L15.192 15.9353L16.5852 15.1291L17.9504 17.5128L18.2934 17.7074V17.3739L16.9002 14.9415L18.2934 14.1353V13.9129Z"
          fill="#939598"
        />
        <path
          d="M17.9716 14.0389L17.7966 13.9346L16.4034 14.7477L15.0382 12.357L14.6602 12.1416V12.4335L16.0883 14.9284L14.6602 15.7555V15.9361L14.8772 16.0543L16.2704 15.2481L17.6286 17.6388L17.9716 17.8334V17.4998L16.5854 15.0674L17.9716 14.2613V14.0389Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.5037 14.0382H17.9716L17.8456 13.7602L14.9892 12.1757L14.6602 11.7656H15.1922L18.5037 13.6629V14.0382Z"
          fill="#BCBEC0"
        />
        <path
          d="M17.9716 14.0382L14.6602 12.1409V11.7656L17.9716 13.6629V14.0382Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2934 9.74395L18.1184 9.64665L16.7182 10.4528L15.353 8.06212L14.975 7.84668V8.13857L16.4032 10.6405L14.975 11.4675L14.877 11.8845L15.192 11.7663L16.5852 10.9532L17.9504 13.3439L18.2934 13.5385V13.2049L16.9002 10.7725L18.2934 9.96634V9.74395Z"
          fill="#939598"
        />
        <path
          d="M17.9716 9.86211L17.7966 9.76482L16.4034 10.5779L15.0382 8.18029L14.6602 7.96484V8.26368L16.0883 10.7586L14.6602 11.5856V11.7663L14.8772 11.8845L16.2704 11.0783L17.6286 13.4621L17.9716 13.6636V13.3231L16.5854 10.8907L17.9716 10.0845V9.86211Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.1885 26.4295L18.3635 26.3322L19.7567 27.1384L21.1219 24.7477L21.4999 24.5322V24.8311L20.0787 27.326L21.4999 28.153L21.6049 28.57L21.2899 28.4519L19.8967 27.6387L18.5315 30.0294L18.1885 30.224V29.8905L19.5747 27.4581L18.1885 26.6519V26.4295Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 26.5477L18.6789 26.4504L20.0721 27.2635L21.4373 24.8658L21.8154 24.6504V24.9492L20.3942 27.4442L21.8154 28.2712V28.4519L21.6053 28.57L20.2121 27.7639L18.847 30.1476L18.5039 30.3492V30.0086L19.8901 27.5762L18.5039 26.7701V26.5477Z"
          fill="#939598"
        />
        <path
          d="M18.1885 22.2596L18.3635 22.1553L19.7567 22.9684L21.1219 20.5777L21.4999 20.3623V20.6542L20.0787 23.1491L21.4999 23.9762L21.6049 24.4001L21.2899 24.2819L19.8967 23.4688L18.5315 25.8595L18.1885 26.0541V25.7205L19.5747 23.2881L18.1885 22.482V22.2596Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 22.3777L18.6789 22.2804L20.0721 23.0866L21.4373 20.6959L21.8154 20.4805V20.7793L20.3942 23.2743L21.8154 24.1013V24.282L21.6053 24.4001L20.2121 23.5939L18.847 25.9777L18.5039 26.1792V25.8387L19.8901 23.4063L18.5039 22.6001V22.3777Z"
          fill="#939598"
        />
        <path
          d="M18.1885 18.0828L18.3635 17.9855L19.7567 18.7986L21.1219 16.401L21.4999 16.1855V16.4844L20.0787 18.9793L21.4999 19.8063L21.6049 20.2303L21.2899 20.1052L19.8967 19.299L18.5315 21.6897L18.1885 21.8843V21.5438L19.5747 19.1114L18.1885 18.3122V18.0828Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 18.2078L18.6789 18.1105L20.0721 18.9167L21.4373 16.526L21.8154 16.3105V16.6094L20.3942 19.1043L21.8154 19.9313V20.1051L21.6053 20.2302L20.2121 19.424L18.847 21.8078L18.5039 22.0024V21.6688L19.8901 19.2364L18.5039 18.4302V18.2078Z"
          fill="#939598"
        />
        <path
          d="M18.1885 13.9129L18.3635 13.8156L19.7567 14.6218L21.1219 12.2311L21.4999 12.0156V12.3145L20.0787 14.8094L21.4999 15.6364L21.6049 16.0534L21.2899 15.9353L19.8967 15.1291L18.5315 17.5128L18.1885 17.7074V17.3739L19.5747 14.9415L18.1885 14.1353V13.9129Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 14.0389L18.6789 13.9346L20.0721 14.7477L21.4373 12.357L21.8154 12.1416V12.4335L20.3942 14.9284L21.8154 15.7555V15.9361L21.6053 16.0543L20.2121 15.2481L18.847 17.6388L18.5039 17.8334V17.4998L19.8901 15.0674L18.5039 14.2613V14.0389Z"
          fill="#939598"
        />
        <path
          d="M18.5037 9.86142H17.9716L17.8456 9.59038L14.9892 8.00585L14.6602 7.58887H15.1922L18.5037 9.49309V9.86142Z"
          fill="#BCBEC0"
        />
        <path
          d="M17.9716 9.86142L14.6602 7.96415V7.58887L17.9716 9.49309V9.86142Z"
          fill="#A7A9AC"
        />
        <path
          d="M25.0285 51.1149L21.8151 28.8272V7.58887H21.29V28.8689L24.4895 51.1149H25.0285Z"
          fill="#939598"
        />
        <path
          d="M23.8314 44.603L18.2236 47.8207V47.3759L23.5163 44.3389L24.0554 44.3528L23.8314 44.603Z"
          fill="#BCBEC0"
        />
        <path
          d="M24.1117 44.7636L18.5039 47.9813V47.5365L24.0557 44.3535L24.1117 44.7636Z"
          fill="#939598"
        />
        <path
          d="M22.2562 33.6576L18.2236 35.9649V35.5271L21.9411 33.3936L22.4802 33.4075L22.2562 33.6576Z"
          fill="#BCBEC0"
        />
        <path
          d="M22.5365 33.8173L18.5039 36.1315V35.6867L22.4804 33.4072L22.5365 33.8173Z"
          fill="#939598"
        />
        <path
          d="M23.0193 38.9809L18.2236 41.733V41.2882L22.7042 38.7168L23.2433 38.7307L23.0193 38.9809Z"
          fill="#BCBEC0"
        />
        <path
          d="M23.2996 39.1405L18.5039 41.8926V41.4548L23.2435 38.7305L23.2996 39.1405Z"
          fill="#939598"
        />
        <path
          d="M17.9717 30.7247H18.5038L18.6298 30.4467L21.4932 28.8622L21.8152 28.4521H21.2901L17.9717 30.3494V30.7247Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 30.7247L21.8154 28.8274V28.4521L18.5039 30.3494V30.7247Z"
          fill="#939598"
        />
        <path
          d="M17.9717 26.5478H18.5038L18.6298 26.2768L21.4932 24.6923L21.8152 24.2822H21.2901L17.9717 26.1795V26.5478Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 26.5478L21.8154 24.6506V24.2822L18.5039 26.1795V26.5478Z"
          fill="#939598"
        />
        <path
          d="M17.9717 22.378H18.5038L18.6298 22.107L21.4932 20.5225L21.8152 20.1055H21.2901L17.9717 22.0027V22.378Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 22.378L21.8154 20.4808V20.1055L18.5039 22.0027V22.378Z"
          fill="#939598"
        />
        <path
          d="M17.9717 18.2081H18.5038L18.6298 17.9371L21.4932 16.3456L21.8152 15.9355H21.2901L17.9717 17.8328V18.2081Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 18.2081L21.8154 16.3108V15.9355L18.5039 17.8328V18.2081Z"
          fill="#939598"
        />
        <path
          d="M17.9717 14.0382H18.5038L18.6298 13.7602L21.4932 12.1757L21.8152 11.7656H21.2901L17.9717 13.6629V14.0382Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 14.0382L21.8154 12.1409V11.7656L18.5039 13.6629V14.0382Z"
          fill="#939598"
        />
        <path
          d="M18.1885 9.74395L18.3635 9.64665L19.7567 10.4528L21.1219 8.06212L21.4999 7.84668V8.13857L20.0787 10.6405L21.4999 11.4675L21.6049 11.8845L21.2899 11.7663L19.8967 10.9532L18.5315 13.3439L18.1885 13.5385V13.2049L19.5747 10.7725L18.1885 9.96634V9.74395Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 9.86211L18.6789 9.76482L20.0721 10.5779L21.4373 8.18029L21.8154 7.96484V8.26368L20.3942 10.7586L21.8154 11.5856V11.7663L21.6053 11.8845L20.2121 11.0783L18.847 13.4621L18.5039 13.6636V13.3231L19.8901 10.8907L18.5039 10.0845V9.86211Z"
          fill="#939598"
        />
        <path
          d="M17.9717 9.86142H18.5038L18.6298 9.59038L21.4932 8.00585L21.8152 7.58887H21.2901L17.9717 9.49309V9.86142Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.5039 9.86142L21.8154 7.96415V7.58887L18.5039 9.49309V9.86142Z"
          fill="#939598"
        />
        <path
          d="M18.2377 55.0004L17.9717 54.8545V30.7251L18.2377 30.2178V55.0004Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.2373 30.2178L18.5033 30.7251V54.8545L18.2373 55.0004V30.2178Z"
          fill="#939598"
        />
        <path
          d="M18.2377 30.8705L17.9717 30.7245V9.49316L18.2377 9.64606V30.8705Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2373 9.64606L18.5033 9.49316V30.7245L18.2373 30.8705V9.64606Z"
          fill="#939598"
        />
        <path
          d="M19.2381 1.18848H18.7061L21.3874 7.83935L21.8145 7.58916L19.2381 1.18848Z"
          fill="#939598"
        />
        <path
          d="M17.2435 1.18848H17.7686L15.0942 7.83935L14.6602 7.58916L17.2435 1.18848Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2373 1.13975L17.2432 0.569876L18.2373 0L19.2384 0.569876L18.2373 1.13975Z"
          fill="#BCBEC0"
        />
        <path
          d="M18.2373 1.75822L17.2432 1.18835V0.569824L18.2373 1.1397V1.75822Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2373 1.75822L19.2384 1.18835V0.569824L18.2373 1.1397V1.75822Z"
          fill="#939598"
        />
        <path
          d="M18.2377 1.75836L17.9717 1.60547V9.49339L18.2377 9.64628V1.75836Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.2373 9.64628L18.5033 9.49339V1.60547L18.2373 1.75836V9.64628Z"
          fill="#939598"
        />
        <path
          d="M20.6112 5.90769L18.7629 9.34085L18.5039 9.49374V9.22965L20.4782 5.58105L20.6112 5.90769Z"
          fill="#939598"
        />
        <path
          d="M18.5031 6.70605L21.3035 7.63732L21.3875 7.83886L21.2615 7.90835L18.5031 6.99794L18.3701 6.9354L18.5031 6.70605Z"
          fill="#939598"
        />
        <path
          d="M19.8963 4.14218L18.5031 6.70662L18.3701 6.65797L18.5031 6.1437L19.7633 3.80859L19.8963 4.14218Z"
          fill="#939598"
        />
        <path
          d="M18.5031 4.53125L20.3444 5.24707L20.5754 5.42776L20.4774 5.58066L18.5031 4.81619L18.3701 4.69109L18.5031 4.53125Z"
          fill="#939598"
        />
        <path
          d="M19.5395 2.62711L18.3213 4.87187L18.3283 4.53133L18.3353 4.27419L19.3714 2.34912L19.5395 2.62711Z"
          fill="#939598"
        />
        <path
          d="M18.3706 2.97461L19.8828 3.56533L20.0228 3.72518L19.9178 3.85722L18.3356 3.24565L18.3076 3.07191L18.3706 2.97461Z"
          fill="#939598"
        />
        <path
          d="M15.8711 5.90769L17.7123 9.34085L17.9714 9.49374V9.22965L16.0041 5.58105L15.8711 5.90769Z"
          fill="#A7A9AC"
        />
        <path
          d="M17.9711 6.70605L15.1708 7.63732L15.0938 7.83886L15.2128 7.90835L17.9711 6.99794L18.1042 6.9354L17.9711 6.70605Z"
          fill="#A7A9AC"
        />
        <path
          d="M16.5781 4.14218L17.9713 6.70662L18.1043 6.65797L17.9713 6.1437L16.7111 3.80859L16.5781 4.14218Z"
          fill="#A7A9AC"
        />
        <path
          d="M17.9717 4.53125L16.1304 5.24707L15.8994 5.42776L16.0044 5.58066L17.9717 4.81619L18.1047 4.69109L17.9717 4.53125Z"
          fill="#A7A9AC"
        />
        <path
          d="M16.9424 2.62711L18.1605 4.87187L18.1465 4.53133V4.27419L17.1034 2.34912L16.9424 2.62711Z"
          fill="#A7A9AC"
        />
        <path
          d="M18.1042 2.97461L16.599 3.56533L16.459 3.72518L16.557 3.85722L18.1392 3.24565L18.1742 3.07191L18.1042 2.97461Z"
          fill="#A7A9AC"
        />
        <path
          d="M14.625 29.0982L17.6704 35.5058L17.9785 35.6865V35.3738L14.912 28.9661L14.66 28.8271L14.625 29.0982Z"
          fill="#A7A9AC"
        />
        <path
          d="M17.8455 30.6484L14.023 33.2546L14.002 33.4075L14.219 33.5326L17.9715 30.8986V30.7249L17.8455 30.6484Z"
          fill="#A7A9AC"
        />
        <path
          d="M13.9043 34.0884L17.6708 41.2744L17.9788 41.4551V41.1424L14.1913 33.9564L13.9463 33.8174L13.9043 34.0884Z"
          fill="#A7A9AC"
        />
        <path
          d="M17.8459 36.0547L13.2603 38.5774L13.2393 38.7303L13.4493 38.8554L17.9789 36.3049V36.1311L17.8459 36.0547Z"
          fill="#A7A9AC"
        />
        <path
          d="M13.1406 39.4117L17.6702 47.3621L17.9783 47.5359V47.2231L13.4277 39.2796L13.1756 39.1406L13.1406 39.4117Z"
          fill="#A7A9AC"
        />
        <path
          d="M17.8455 41.8232L12.4478 44.2L12.4268 44.3529L12.6438 44.478L17.9785 42.0734V41.8927L17.8455 41.8232Z"
          fill="#A7A9AC"
        />
        <path
          d="M21.8574 29.0982L18.8119 35.5058L18.5039 35.6865V35.3738L21.5703 28.9661L21.8154 28.8271L21.8574 29.0982Z"
          fill="#939598"
        />
        <path
          d="M18.6369 30.6484L22.4524 33.2546L22.4734 33.4075L22.2634 33.5326L18.5039 30.8986V30.7249L18.6369 30.6484Z"
          fill="#939598"
        />
        <path
          d="M22.5715 34.0884L18.8119 41.2744L18.5039 41.4551V41.1424L22.2844 33.9564L22.5365 33.8174L22.5715 34.0884Z"
          fill="#939598"
        />
        <path
          d="M18.6299 36.0547L23.2225 38.5774L23.2435 38.7303L23.0265 38.8554L18.5039 36.3049V36.1311L18.6299 36.0547Z"
          fill="#939598"
        />
        <path
          d="M23.3416 39.4117L18.8119 47.3621L18.5039 47.5359V47.2231L23.0545 39.2866L23.2996 39.1406L23.3416 39.4117Z"
          fill="#939598"
        />
        <path
          d="M18.6299 41.8232L24.0277 44.2L24.0487 44.3529L23.8386 44.478L18.5039 42.0734V41.8927L18.6299 41.8232Z"
          fill="#939598"
        />
        <path
          d="M28.4444 32.1908L21.8145 24.3516L21.6045 24.4697L27.6183 31.7183L21.8145 28.3963L21.6045 28.5144L21.8145 28.8411L29.8026 33.414L29.9706 33.7476L30.1807 33.6294V33.1846L28.4444 32.1908Z"
          fill="#BCBEC0"
        />
        <path
          d="M28.2344 32.3159L21.6045 24.4697V24.9701L27.4153 31.8434L21.6045 28.5145V28.9592L29.9706 33.7476V33.3028L28.2344 32.3159Z"
          fill="#A7A9AC"
        />
        <path
          d="M25.0772 34.1089L18.4473 26.2627L18.2373 26.3878L24.2511 33.6363L18.4473 30.3074L18.2373 30.4325L18.4473 30.7522L26.4354 35.3321L26.6034 35.6657L26.8135 35.5475V35.1027L25.0772 34.1089Z"
          fill="#BCBEC0"
        />
        <path
          d="M24.8672 34.227L18.2373 26.3877V26.8881L24.0411 33.7544L18.2373 30.4324V30.8703L26.6034 35.6656V35.2208L24.8672 34.227Z"
          fill="#A7A9AC"
        />
        <path
          d="M30.1811 33.1852L29.621 33.7481L27.4578 34.999L26.6036 35.2214L26.2256 35.006L29.8031 32.9697L30.1811 33.1852Z"
          fill="#BCBEC0"
        />
        <path
          d="M30.181 33.6294L26.6035 35.6656V35.2208L30.181 33.1846V33.6294Z"
          fill="#939598"
        />
        <path
          d="M26.3024 34.7556C26.4704 34.6583 26.7365 34.6583 26.8975 34.7556C27.0655 34.8529 27.0655 35.0058 26.8975 35.0961C26.7295 35.1934 26.4634 35.1934 26.3024 35.0961C26.1414 34.9988 26.1414 34.8529 26.3024 34.7556Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 34.9219V34.9636C27.0587 35.0331 27.0167 35.0956 26.9257 35.1512C26.7437 35.2555 26.4566 35.2555 26.2746 35.1512C26.1836 35.1026 26.1416 35.0331 26.1416 34.9636V34.9219C26.1416 34.9914 27.0587 34.9914 27.0587 34.9219Z"
          fill="#303033"
        />
        <path
          d="M26.2742 34.7413C26.4562 34.637 26.7433 34.637 26.9253 34.7413C27.1073 34.8455 27.1073 35.0123 26.9253 35.1166C26.7433 35.2208 26.4562 35.2208 26.2742 35.1166C26.0922 35.0123 26.0922 34.8455 26.2742 34.7413Z"
          fill="#535359"
        />
        <path
          d="M26.3028 34.9775C26.4708 35.0747 26.7368 35.0747 26.8979 34.9775C26.9819 34.9288 27.0239 34.8663 27.0239 34.8037V34.9219C27.0239 34.9844 26.9819 35.0469 26.8979 35.0956C26.7298 35.1929 26.4638 35.1929 26.3028 35.0956C26.2188 35.0469 26.1768 34.9844 26.1768 34.9219V34.8037C26.1768 34.8663 26.2188 34.9288 26.3028 34.9775Z"
          fill="#121214"
        />
        <path
          d="M26.3024 34.6374C26.4704 34.5401 26.7365 34.5401 26.8975 34.6374C27.0655 34.7347 27.0655 34.8876 26.8975 34.978C26.7295 35.0753 26.4634 35.0753 26.3024 34.978C26.1414 34.8807 26.1414 34.7278 26.3024 34.6374Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 34.8037V34.8454C27.0587 34.9149 27.0167 34.9775 26.9257 35.033C26.7437 35.1373 26.4566 35.1373 26.2746 35.033C26.1836 34.9844 26.1416 34.9149 26.1416 34.8454V34.8037C26.1416 34.8732 27.0587 34.8732 27.0587 34.8037Z"
          fill="#303033"
        />
        <path
          d="M26.2742 34.6163C26.4562 34.512 26.7433 34.512 26.9253 34.6163C27.1073 34.7205 27.1073 34.8873 26.9253 34.9916C26.7433 35.0958 26.4562 35.0958 26.2742 34.9916C26.0922 34.8873 26.0922 34.7205 26.2742 34.6163Z"
          fill="#535359"
        />
        <path
          d="M26.3028 34.8525C26.4708 34.9497 26.7368 34.9497 26.8979 34.8525C26.9819 34.8038 27.0239 34.7413 27.0239 34.6787V34.7969C27.0239 34.8594 26.9819 34.922 26.8979 34.9636C26.7298 35.0609 26.4638 35.0609 26.3028 34.9636C26.2188 34.915 26.1768 34.8525 26.1768 34.7969V34.6787C26.1768 34.7413 26.2188 34.8038 26.3028 34.8525Z"
          fill="#121214"
        />
        <path
          d="M26.3024 34.5124C26.4704 34.4151 26.7365 34.4151 26.8975 34.5124C27.0655 34.6097 27.0655 34.7626 26.8975 34.853C26.7295 34.9503 26.4634 34.9503 26.3024 34.853C26.1414 34.7557 26.1414 34.6028 26.3024 34.5124Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 34.6855V34.7272C27.0587 34.7967 27.0167 34.8662 26.9257 34.9149C26.7437 35.0191 26.4566 35.0191 26.2746 34.9149C26.1836 34.8662 26.1416 34.7967 26.1416 34.7272V34.6855C26.1416 34.755 27.0587 34.755 27.0587 34.6855Z"
          fill="#303033"
        />
        <path
          d="M26.2742 34.4981C26.4562 34.3939 26.7433 34.3939 26.9253 34.4981C27.1073 34.6024 27.1073 34.7691 26.9253 34.8734C26.7433 34.9776 26.4562 34.9776 26.2742 34.8734C26.0922 34.7691 26.0922 34.6024 26.2742 34.4981Z"
          fill="#535359"
        />
        <path
          d="M26.3026 34.7206C26.4706 34.8179 26.7367 34.8179 26.8977 34.7206C26.9817 34.672 27.0237 34.6094 27.0237 34.5469V34.665C27.0237 34.7276 26.9817 34.7901 26.8977 34.8388C26.7367 34.9361 26.4636 34.9361 26.3026 34.8388C26.2186 34.7901 26.1836 34.7276 26.1836 34.665V34.5469C26.1836 34.6094 26.2256 34.672 26.3026 34.7206Z"
          fill="#121214"
        />
        <path
          d="M26.3024 34.3806C26.4704 34.2833 26.7365 34.2833 26.8975 34.3806C27.0655 34.4709 27.0655 34.6238 26.8975 34.7211C26.7365 34.8184 26.4634 34.8184 26.3024 34.7211C26.1414 34.6238 26.1414 34.4709 26.3024 34.3806Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 34.5537V34.5954C27.0587 34.6649 27.0167 34.7275 26.9257 34.783C26.7437 34.8873 26.4566 34.8873 26.2746 34.783C26.1836 34.7344 26.1416 34.6649 26.1416 34.5954V34.5537C26.1416 34.6232 27.0587 34.6232 27.0587 34.5537Z"
          fill="#303033"
        />
        <path
          d="M26.2742 34.3663C26.4562 34.262 26.7433 34.262 26.9253 34.3663C27.1073 34.4705 27.1073 34.6373 26.9253 34.7416C26.7433 34.8458 26.4562 34.8458 26.2742 34.7416C26.0922 34.6373 26.0922 34.4705 26.2742 34.3663Z"
          fill="#535359"
        />
        <path
          d="M26.3026 34.6025C26.4706 34.6997 26.7367 34.6997 26.8977 34.6025C26.9817 34.5538 27.0237 34.4913 27.0237 34.4287V34.5469C27.0237 34.6094 26.9817 34.6719 26.8977 34.7206C26.7367 34.8179 26.4636 34.8179 26.3026 34.7206C26.2186 34.6719 26.1836 34.6094 26.1836 34.5469V34.4287C26.1836 34.4913 26.2256 34.5538 26.3026 34.6025Z"
          fill="#121214"
        />
        <path
          d="M26.3024 34.2624C26.4704 34.1651 26.7365 34.1651 26.8975 34.2624C27.0655 34.3597 27.0655 34.5126 26.8975 34.603C26.7365 34.7003 26.4634 34.7003 26.3024 34.603C26.1414 34.5057 26.1414 34.3528 26.3024 34.2624Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 34.4287V34.4704C27.0587 34.5399 27.0167 34.6025 26.9257 34.658C26.7437 34.7623 26.4566 34.7623 26.2746 34.658C26.1836 34.6094 26.1416 34.5399 26.1416 34.4704V34.4287C26.1416 34.4982 27.0587 34.4982 27.0587 34.4287Z"
          fill="#303033"
        />
        <path
          d="M26.2742 34.2413C26.4562 34.137 26.7433 34.137 26.9253 34.2413C27.1073 34.3455 27.1073 34.5123 26.9253 34.6166C26.7433 34.7208 26.4562 34.7208 26.2742 34.6166C26.0922 34.5123 26.0922 34.3455 26.2742 34.2413Z"
          fill="#535359"
        />
        <path
          d="M26.3026 34.4843C26.4706 34.5816 26.7367 34.5816 26.8977 34.4843C26.9817 34.4356 27.0237 34.3731 27.0237 34.3105V34.4287C27.0237 34.4912 26.9817 34.5538 26.8977 34.6024C26.7367 34.6997 26.4636 34.6997 26.3026 34.6024C26.2186 34.5538 26.1836 34.4912 26.1836 34.4287V34.3105C26.1836 34.3731 26.2256 34.4356 26.3026 34.4843Z"
          fill="#121214"
        />
        <path
          d="M26.3024 34.1374C26.4704 34.0401 26.7365 34.0401 26.8975 34.1374C27.0655 34.2347 27.0655 34.3876 26.8975 34.478C26.7365 34.5753 26.4634 34.5753 26.3024 34.478C26.1414 34.3807 26.1414 34.2278 26.3024 34.1374Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 34.3105V34.3522C27.0587 34.4217 27.0167 34.4843 26.9257 34.5399C26.7437 34.6441 26.4566 34.6441 26.2746 34.5399C26.1836 34.4912 26.1416 34.4217 26.1416 34.3522V34.3105C26.1416 34.38 27.0587 34.38 27.0587 34.3105Z"
          fill="#303033"
        />
        <path
          d="M26.2742 34.1231C26.4562 34.0189 26.7433 34.0189 26.9253 34.1231C27.1073 34.2274 27.1073 34.3941 26.9253 34.4984C26.7433 34.6026 26.4562 34.6026 26.2742 34.4984C26.0922 34.3941 26.0922 34.2274 26.2742 34.1231Z"
          fill="#535359"
        />
        <path
          d="M26.3026 34.3593C26.4706 34.4566 26.7367 34.4566 26.8977 34.3593C26.9817 34.3106 27.0237 34.2481 27.0237 34.1855V34.3037C27.0237 34.3662 26.9817 34.4288 26.8977 34.4774C26.7367 34.5747 26.4636 34.5747 26.3026 34.4774C26.2186 34.4288 26.1836 34.3662 26.1836 34.3037V34.1855C26.1836 34.2481 26.2256 34.3106 26.3026 34.3593Z"
          fill="#121214"
        />
        <path
          d="M26.3024 34.0189C26.4704 33.9286 26.7365 33.9286 26.8975 34.0189C27.0655 34.1162 27.0655 34.2691 26.8975 34.3595C26.7365 34.4568 26.4634 34.4568 26.3024 34.3595C26.1414 34.2622 26.1414 34.1093 26.3024 34.0189Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 34.1924V34.2341C27.0587 34.3036 27.0167 34.3661 26.9257 34.4217C26.7437 34.526 26.4566 34.526 26.2746 34.4217C26.1836 34.3731 26.1416 34.3036 26.1416 34.2341V34.1924C26.1416 34.2619 27.0587 34.2619 27.0587 34.1924Z"
          fill="#303033"
        />
        <path
          d="M26.2742 34.0049C26.4562 33.9007 26.7433 33.9007 26.9253 34.0049C27.1073 34.1092 27.1073 34.276 26.9253 34.3802C26.7433 34.4845 26.4562 34.4845 26.2742 34.3802C26.0922 34.276 26.0922 34.1092 26.2742 34.0049Z"
          fill="#535359"
        />
        <path
          d="M26.4361 33.8521C26.5201 33.9007 26.6671 33.9007 26.7511 33.8521C26.7931 33.8312 26.8141 33.7965 26.8141 33.7617V34.1787C26.8141 34.1787 26.7931 34.2412 26.7511 34.269C26.6671 34.3177 26.5201 34.3177 26.4361 34.269C26.394 34.2412 26.373 34.2134 26.373 34.1787V33.7617C26.373 33.7617 26.394 33.8243 26.4361 33.8521Z"
          fill="#A7A9AC"
        />
        <path
          d="M26.4361 33.6713C26.5201 33.6226 26.6671 33.6226 26.7511 33.6713C26.8351 33.7199 26.8351 33.8033 26.7511 33.8519C26.6671 33.9006 26.5201 33.9006 26.4361 33.8519C26.352 33.8033 26.352 33.7199 26.4361 33.6713Z"
          fill="#E6E7E8"
        />
        <path
          d="M26.6459 33.4346V33.7612C26.6459 33.7612 26.6459 33.7751 26.6319 33.7821C26.6109 33.796 26.5759 33.796 26.5619 33.7821C26.5479 33.7821 26.5479 33.7682 26.5479 33.7612V33.4346C26.5479 33.4346 26.6529 33.4415 26.6529 33.4346H26.6459Z"
          fill="#303033"
        />
        <path
          d="M26.5548 33.4144C26.5548 33.4144 26.6108 33.4005 26.6248 33.4144C26.6458 33.4283 26.6458 33.4422 26.6248 33.4561C26.6038 33.47 26.5688 33.47 26.5548 33.4561C26.5338 33.4422 26.5338 33.4283 26.5548 33.4144Z"
          fill="#535359"
        />
        <path
          d="M29.5016 32.9203C29.6626 32.83 29.9357 32.83 30.0967 32.9203C30.2647 33.0176 30.2647 33.1705 30.0967 33.2608C29.9287 33.3581 29.6626 33.3581 29.5016 33.2608C29.3406 33.1635 29.3406 33.0106 29.5016 32.9203Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 33.0947V33.1364C30.2579 33.2059 30.2159 33.2685 30.1249 33.3241C29.9429 33.4283 29.6559 33.4283 29.4738 33.3241C29.3828 33.2754 29.3408 33.2059 29.3408 33.1364V33.0947C29.3408 33.1642 30.2579 33.1642 30.2579 33.0947Z"
          fill="#303033"
        />
        <path
          d="M29.4744 32.9073C29.6564 32.803 29.9435 32.803 30.1255 32.9073C30.3075 33.0115 30.3075 33.1783 30.1255 33.2826C29.9435 33.3868 29.6564 33.3868 29.4744 33.2826C29.2924 33.1783 29.2924 33.0115 29.4744 32.9073Z"
          fill="#535359"
        />
        <path
          d="M29.502 33.1435C29.663 33.2338 29.9361 33.2338 30.0971 33.1435C30.1811 33.0948 30.2231 33.0323 30.2231 32.9697V33.0879C30.2231 33.1504 30.1811 33.213 30.0971 33.2616C29.9291 33.3589 29.663 33.3589 29.502 33.2616C29.418 33.213 29.376 33.1504 29.376 33.0879V32.9697C29.376 33.0323 29.418 33.0948 29.502 33.1435Z"
          fill="#121214"
        />
        <path
          d="M29.5016 32.8025C29.6626 32.7052 29.9357 32.7052 30.0967 32.8025C30.2647 32.8998 30.2647 33.0527 30.0967 33.143C29.9287 33.2333 29.6626 33.2333 29.5016 33.143C29.3406 33.0457 29.3406 32.8928 29.5016 32.8025Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 32.9697V33.0114C30.2579 33.0809 30.2159 33.1435 30.1249 33.1991C29.9429 33.3033 29.6559 33.3033 29.4738 33.1991C29.3828 33.1504 29.3408 33.0809 29.3408 33.0114V32.9697C29.3408 33.0392 30.2579 33.0392 30.2579 32.9697Z"
          fill="#303033"
        />
        <path
          d="M29.4744 32.7891C29.6564 32.6849 29.9435 32.6849 30.1255 32.7891C30.3075 32.8934 30.3075 33.0602 30.1255 33.1644C29.9435 33.2687 29.6564 33.2687 29.4744 33.1644C29.2924 33.0602 29.2924 32.8934 29.4744 32.7891Z"
          fill="#535359"
        />
        <path
          d="M29.502 33.0253C29.663 33.1226 29.9361 33.1226 30.0971 33.0253C30.1811 32.9767 30.2231 32.9141 30.2231 32.8516V32.9697C30.2231 33.0323 30.1811 33.0948 30.0971 33.1434C29.9291 33.2338 29.663 33.2338 29.502 33.1434C29.418 33.0948 29.376 33.0323 29.376 32.9697V32.8516C29.376 32.9141 29.418 32.9767 29.502 33.0253Z"
          fill="#121214"
        />
        <path
          d="M29.5016 32.6775C29.6626 32.5802 29.9357 32.5802 30.0967 32.6775C30.2647 32.7748 30.2647 32.9277 30.0967 33.018C29.9287 33.1153 29.6626 33.1153 29.5016 33.018C29.3406 32.9207 29.3406 32.7678 29.5016 32.6775Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 32.8516V32.8933C30.2579 32.9628 30.2159 33.0253 30.1249 33.0809C29.9429 33.1851 29.6559 33.1851 29.4738 33.0809C29.3828 33.0323 29.3408 32.9628 29.3408 32.8933V32.8516C29.3408 32.9211 30.2579 32.9211 30.2579 32.8516Z"
          fill="#303033"
        />
        <path
          d="M29.4744 32.6641C29.6564 32.5599 29.9435 32.5599 30.1255 32.6641C30.3075 32.7684 30.3075 32.9352 30.1255 33.0394C29.9435 33.1437 29.6564 33.1437 29.4744 33.0394C29.2924 32.9352 29.2924 32.7684 29.4744 32.6641Z"
          fill="#535359"
        />
        <path
          d="M29.502 32.8925C29.663 32.9898 29.9361 32.9898 30.0971 32.8925C30.1811 32.8438 30.2231 32.7813 30.2231 32.7188V32.8369C30.2231 32.8994 30.1811 32.962 30.0971 33.0106C29.9361 33.101 29.663 33.101 29.502 33.0106C29.418 32.962 29.376 32.8994 29.376 32.8369V32.7188C29.376 32.7813 29.418 32.8438 29.502 32.8925Z"
          fill="#121214"
        />
        <path
          d="M29.5016 32.5525C29.6626 32.4552 29.9357 32.4552 30.0967 32.5525C30.2647 32.6498 30.2647 32.8027 30.0967 32.893C29.9357 32.9903 29.6626 32.9903 29.5016 32.893C29.3406 32.7957 29.3406 32.6428 29.5016 32.5525Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 32.7188V32.7604C30.2579 32.8299 30.2159 32.8925 30.1249 32.9481C29.9429 33.0523 29.6559 33.0523 29.4738 32.9481C29.3828 32.8994 29.3408 32.8299 29.3408 32.7604V32.7188C29.3408 32.7882 30.2579 32.7882 30.2579 32.7188Z"
          fill="#303033"
        />
        <path
          d="M29.4744 32.5313C29.6564 32.4271 29.9435 32.4271 30.1255 32.5313C30.3075 32.6356 30.3075 32.8023 30.1255 32.9066C29.9435 33.0108 29.6564 33.0108 29.4744 32.9066C29.2924 32.8023 29.2924 32.6356 29.4744 32.5313Z"
          fill="#535359"
        />
        <path
          d="M29.502 32.7675C29.663 32.8648 29.9361 32.8648 30.0971 32.7675C30.1811 32.7188 30.2231 32.6563 30.2231 32.5938V32.7119C30.2231 32.7744 30.1811 32.837 30.0971 32.8856C29.9361 32.9829 29.663 32.9829 29.502 32.8856C29.418 32.837 29.376 32.7744 29.376 32.7119V32.5938C29.376 32.6563 29.418 32.7188 29.502 32.7675Z"
          fill="#121214"
        />
        <path
          d="M29.5016 32.4275C29.6626 32.3302 29.9357 32.3302 30.0967 32.4275C30.2647 32.5248 30.2647 32.6777 30.0967 32.768C29.9357 32.8653 29.6626 32.8653 29.5016 32.768C29.3406 32.6777 29.3406 32.5248 29.5016 32.4275Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 32.6016V32.6433C30.2579 32.7128 30.2159 32.7753 30.1249 32.8309C29.9429 32.9351 29.6559 32.9351 29.4738 32.8309C29.3828 32.7823 29.3408 32.7128 29.3408 32.6433V32.6016C29.3408 32.6711 30.2579 32.6711 30.2579 32.6016Z"
          fill="#303033"
        />
        <path
          d="M29.4744 32.4131C29.6564 32.3089 29.9435 32.3089 30.1255 32.4131C30.3075 32.5174 30.3075 32.6842 30.1255 32.7884C29.9435 32.8927 29.6564 32.8927 29.4744 32.7884C29.2924 32.6842 29.2924 32.5174 29.4744 32.4131Z"
          fill="#535359"
        />
        <path
          d="M29.502 32.6493C29.663 32.7466 29.9361 32.7466 30.0971 32.6493C30.1811 32.6007 30.2231 32.5381 30.2231 32.4756V32.5937C30.2231 32.6563 30.1811 32.7188 30.0971 32.7675C29.9361 32.8648 29.663 32.8648 29.502 32.7675C29.418 32.7188 29.376 32.6563 29.376 32.5937V32.4756C29.376 32.5381 29.418 32.6007 29.502 32.6493Z"
          fill="#121214"
        />
        <path
          d="M29.5016 32.3093C29.6626 32.212 29.9357 32.212 30.0967 32.3093C30.2647 32.4066 30.2647 32.5595 30.0967 32.6498C29.9357 32.7471 29.6626 32.7471 29.5016 32.6498C29.3406 32.5525 29.3406 32.3996 29.5016 32.3093Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 32.4756V32.5173C30.2579 32.5868 30.2159 32.6493 30.1249 32.7049C29.9429 32.8092 29.6559 32.8092 29.4738 32.7049C29.3828 32.6563 29.3408 32.5868 29.3408 32.5173V32.4756C29.3408 32.5451 30.2579 32.5451 30.2579 32.4756Z"
          fill="#303033"
        />
        <path
          d="M29.4744 32.2881C29.6564 32.1839 29.9435 32.1839 30.1255 32.2881C30.3075 32.3924 30.3075 32.5592 30.1255 32.6634C29.9435 32.7677 29.6564 32.7677 29.4744 32.6634C29.2924 32.5592 29.2924 32.3924 29.4744 32.2881Z"
          fill="#535359"
        />
        <path
          d="M29.502 32.5242C29.663 32.6215 29.9361 32.6215 30.0971 32.5242C30.1811 32.4756 30.2231 32.413 30.2231 32.3574V32.4756C30.2231 32.5381 30.1811 32.6007 30.0971 32.6493C29.9361 32.7466 29.663 32.7466 29.502 32.6493C29.418 32.6007 29.376 32.5381 29.376 32.4756V32.3574C29.376 32.42 29.418 32.4825 29.502 32.5242Z"
          fill="#121214"
        />
        <path
          d="M29.5016 32.1843C29.6626 32.087 29.9357 32.087 30.0967 32.1843C30.2647 32.2816 30.2647 32.4345 30.0967 32.5248C29.9357 32.6221 29.6626 32.6221 29.5016 32.5248C29.3406 32.4345 29.3406 32.2746 29.5016 32.1843Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 32.3574V32.3991C30.2579 32.4686 30.2159 32.5312 30.1249 32.5868C29.9429 32.691 29.6559 32.691 29.4738 32.5868C29.3828 32.5381 29.3408 32.4686 29.3408 32.3991V32.3574C29.3408 32.4269 30.2579 32.4269 30.2579 32.3574Z"
          fill="#303033"
        />
        <path
          d="M29.4744 32.17C29.6564 32.0657 29.9435 32.0657 30.1255 32.17C30.3075 32.2742 30.3075 32.441 30.1255 32.5453C29.9435 32.6495 29.6564 32.6495 29.4744 32.5453C29.2924 32.441 29.2924 32.2742 29.4744 32.17Z"
          fill="#535359"
        />
        <path
          d="M29.6353 32.0239C29.7193 32.0726 29.8593 32.0726 29.9503 32.0239C29.9923 31.9961 30.0133 31.9683 30.0133 31.9336V32.3506C30.0133 32.3506 29.9923 32.4131 29.9503 32.4409C29.8663 32.4896 29.7193 32.4896 29.6353 32.4409C29.5933 32.4201 29.5723 32.3853 29.5723 32.3506V31.9336C29.5723 31.9336 29.5933 31.9961 29.6353 32.0239Z"
          fill="#A7A9AC"
        />
        <path
          d="M29.6353 31.8431C29.7193 31.7945 29.8593 31.7945 29.9503 31.8431C30.0343 31.8918 30.0343 31.9752 29.9503 32.0238C29.8663 32.0725 29.7193 32.0725 29.6353 32.0238C29.5513 31.9752 29.5513 31.8918 29.6353 31.8431Z"
          fill="#E6E7E8"
        />
        <path
          d="M29.8451 31.6074V31.9271C29.8451 31.9271 29.8451 31.941 29.8311 31.948C29.8101 31.9619 29.7751 31.9619 29.7611 31.948C29.7541 31.948 29.7471 31.9341 29.7471 31.9271V31.6074C29.7471 31.6074 29.8521 31.6144 29.8521 31.6074H29.8451Z"
          fill="#303033"
        />
        <path
          d="M29.754 31.5863C29.754 31.5863 29.81 31.5724 29.824 31.5863C29.845 31.6002 29.845 31.6141 29.824 31.628C29.803 31.6419 29.768 31.6419 29.754 31.628C29.733 31.6141 29.733 31.6002 29.754 31.5863Z"
          fill="#535359"
        />
        <path
          d="M29.7892 31.6555C29.7892 31.6555 29.7402 31.6347 29.7402 31.6069C29.7402 31.5791 29.7612 31.5582 29.7892 31.5582C29.8172 31.5582 32.9467 31.5235 36.9162 28.7992C36.9372 28.7853 36.9722 28.7853 36.9862 28.8131C37.0002 28.8339 36.9932 28.8687 36.9722 28.8826C32.9747 31.6277 29.8172 31.6555 29.7892 31.6555Z"
          fill="#121214"
        />
        <path
          d="M19.9316 38.6405C19.9316 38.6405 19.8966 38.6335 19.8826 38.6127C19.8686 38.5849 19.8826 38.5571 19.9036 38.5432C19.9456 38.5223 24.2162 36.354 26.5475 33.4004C26.5685 33.3795 26.5965 33.3726 26.6175 33.3934C26.6385 33.4073 26.6455 33.4421 26.6245 33.4629C24.2792 36.4305 19.9946 38.6127 19.9456 38.6335C19.9456 38.6335 19.9316 38.6335 19.9246 38.6335L19.9316 38.6405Z"
          fill="#121214"
        />
        <path
          d="M28.4444 23.8443L21.8145 15.998L21.6045 16.1231L27.6183 23.3717L21.8145 20.0428L21.6045 20.1679L21.8145 20.4876L29.8026 25.0674L29.9706 25.401L30.1807 25.2829V24.8381L28.4444 23.8443Z"
          fill="#BCBEC0"
        />
        <path
          d="M28.2344 23.9623L21.6045 16.123V16.6234L27.4153 23.4897L21.6045 20.1678V20.6056L29.9706 25.4009V24.9561L28.2344 23.9623Z"
          fill="#A7A9AC"
        />
        <path
          d="M25.0772 25.7622L18.4473 17.916L18.2373 18.0342L24.2511 25.2897L18.4473 21.9607L18.2373 22.0789L18.4473 22.4055L26.4354 26.9854L26.6034 27.319L26.8135 27.2008V26.756L25.0772 25.7622Z"
          fill="#BCBEC0"
        />
        <path
          d="M24.8672 25.8804L18.2373 18.0342V18.5415L24.0411 25.4078L18.2373 22.0789V22.5237L26.6034 27.319V26.8742L24.8672 25.8804Z"
          fill="#A7A9AC"
        />
        <path
          d="M30.1811 24.8385L29.621 25.4014L27.4578 26.6524L26.6036 26.8748L26.2256 26.6593L29.8031 24.623L30.1811 24.8385Z"
          fill="#BCBEC0"
        />
        <path
          d="M30.181 25.2827L26.6035 27.3189V26.8742L30.181 24.8379V25.2827Z"
          fill="#939598"
        />
        <path
          d="M26.3024 26.4089C26.4704 26.3116 26.7365 26.3116 26.8975 26.4089C27.0655 26.5062 27.0655 26.6591 26.8975 26.7494C26.7295 26.8467 26.4634 26.8467 26.3024 26.7494C26.1414 26.6522 26.1414 26.4993 26.3024 26.4089Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 26.5762V26.6179C27.0587 26.6874 27.0167 26.7499 26.9257 26.8055C26.7437 26.9098 26.4566 26.9098 26.2746 26.8055C26.1836 26.7569 26.1416 26.6874 26.1416 26.6179V26.5762C26.1416 26.6457 27.0587 26.6457 27.0587 26.5762Z"
          fill="#303033"
        />
        <path
          d="M26.2742 26.3878C26.4562 26.2835 26.7433 26.2835 26.9253 26.3878C27.1073 26.492 27.1073 26.6588 26.9253 26.763C26.7433 26.8673 26.4562 26.8673 26.2742 26.763C26.0922 26.6588 26.0922 26.492 26.2742 26.3878Z"
          fill="#535359"
        />
        <path
          d="M26.3028 26.6239C26.4708 26.7212 26.7368 26.7212 26.8979 26.6239C26.9819 26.5753 27.0239 26.5127 27.0239 26.4502V26.5683C27.0239 26.6309 26.9819 26.6934 26.8979 26.7421C26.7298 26.8394 26.4638 26.8394 26.3028 26.7421C26.2188 26.6934 26.1768 26.6309 26.1768 26.5683V26.4502C26.1768 26.5127 26.2188 26.5753 26.3028 26.6239Z"
          fill="#121214"
        />
        <path
          d="M26.3024 26.2839C26.4704 26.1866 26.7365 26.1866 26.8975 26.2839C27.0655 26.3812 27.0655 26.5341 26.8975 26.6244C26.7295 26.7217 26.4634 26.7217 26.3024 26.6244C26.1414 26.5272 26.1414 26.3743 26.3024 26.2839Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 26.458V26.4997C27.0587 26.5692 27.0167 26.6318 26.9257 26.6873C26.7437 26.7916 26.4566 26.7916 26.2746 26.6873C26.1836 26.6387 26.1416 26.5692 26.1416 26.4997V26.458C26.1416 26.5275 27.0587 26.5275 27.0587 26.458Z"
          fill="#303033"
        />
        <path
          d="M26.2742 26.2696C26.4562 26.1653 26.7433 26.1653 26.9253 26.2696C27.1073 26.3738 27.1073 26.5406 26.9253 26.6449C26.7433 26.7491 26.4562 26.7491 26.2742 26.6449C26.0922 26.5406 26.0922 26.3738 26.2742 26.2696Z"
          fill="#535359"
        />
        <path
          d="M26.3028 26.5058C26.4708 26.6031 26.7368 26.6031 26.8979 26.5058C26.9819 26.4571 27.0239 26.3946 27.0239 26.332V26.4502C27.0239 26.5127 26.9819 26.5753 26.8979 26.6239C26.7298 26.7212 26.4638 26.7212 26.3028 26.6239C26.2188 26.5753 26.1768 26.5127 26.1768 26.4502V26.332C26.1768 26.3946 26.2188 26.4571 26.3028 26.5058Z"
          fill="#121214"
        />
        <path
          d="M26.3024 26.1657C26.4704 26.0684 26.7365 26.0684 26.8975 26.1657C27.0655 26.263 27.0655 26.4159 26.8975 26.5063C26.7295 26.6036 26.4634 26.6036 26.3024 26.5063C26.1414 26.409 26.1414 26.2561 26.3024 26.1657Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 26.332V26.3737C27.0587 26.4432 27.0167 26.5127 26.9257 26.5614C26.7437 26.6656 26.4566 26.6656 26.2746 26.5614C26.1836 26.5127 26.1416 26.4432 26.1416 26.3737V26.332C26.1416 26.4015 27.0587 26.4015 27.0587 26.332Z"
          fill="#303033"
        />
        <path
          d="M26.2742 26.1514C26.4562 26.0472 26.7433 26.0472 26.9253 26.1514C27.1073 26.2557 27.1073 26.4225 26.9253 26.5267C26.7433 26.631 26.4562 26.631 26.2742 26.5267C26.0922 26.4225 26.0922 26.2557 26.2742 26.1514Z"
          fill="#535359"
        />
        <path
          d="M26.3026 26.3739C26.4706 26.4712 26.7367 26.4712 26.8977 26.3739C26.9817 26.3253 27.0237 26.2627 27.0237 26.2002V26.3183C27.0237 26.3809 26.9817 26.4434 26.8977 26.4921C26.7367 26.5894 26.4636 26.5894 26.3026 26.4921C26.2186 26.4434 26.1836 26.3809 26.1836 26.3183V26.2002C26.1836 26.2627 26.2256 26.3253 26.3026 26.3739Z"
          fill="#121214"
        />
        <path
          d="M26.3024 26.0339C26.4704 25.9366 26.7365 25.9366 26.8975 26.0339C27.0655 26.1312 27.0655 26.2772 26.8975 26.3744C26.7365 26.4717 26.4634 26.4717 26.3024 26.3744C26.1414 26.2772 26.1414 26.1243 26.3024 26.0339Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 26.2002V26.2419C27.0587 26.3114 27.0167 26.3739 26.9257 26.4295C26.7437 26.5338 26.4566 26.5338 26.2746 26.4295C26.1836 26.3809 26.1416 26.3114 26.1416 26.2419V26.2002C26.1416 26.2697 27.0587 26.2697 27.0587 26.2002Z"
          fill="#303033"
        />
        <path
          d="M26.2742 26.0196C26.4562 25.9153 26.7433 25.9153 26.9253 26.0196C27.1073 26.1238 27.1073 26.2906 26.9253 26.3949C26.7433 26.4991 26.4562 26.4991 26.2742 26.3949C26.0922 26.2906 26.0922 26.1238 26.2742 26.0196Z"
          fill="#535359"
        />
        <path
          d="M26.3026 26.2558C26.4706 26.3531 26.7367 26.3531 26.8977 26.2558C26.9817 26.2071 27.0237 26.1446 27.0237 26.082V26.2002C27.0237 26.2627 26.9817 26.3253 26.8977 26.3739C26.7367 26.4712 26.4636 26.4712 26.3026 26.3739C26.2186 26.3253 26.1836 26.2627 26.1836 26.2002V26.082C26.1836 26.1446 26.2256 26.2071 26.3026 26.2558Z"
          fill="#121214"
        />
        <path
          d="M26.3024 25.9157C26.4704 25.8184 26.7365 25.8184 26.8975 25.9157C27.0655 26.013 27.0655 26.1659 26.8975 26.2563C26.7365 26.3536 26.4634 26.3536 26.3024 26.2563C26.1414 26.159 26.1414 26.0061 26.3024 25.9157Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 26.082V26.1237C27.0587 26.1932 27.0167 26.2558 26.9257 26.3114C26.7437 26.4156 26.4566 26.4156 26.2746 26.3114C26.1836 26.2627 26.1416 26.1932 26.1416 26.1237V26.082C26.1416 26.1515 27.0587 26.1515 27.0587 26.082Z"
          fill="#303033"
        />
        <path
          d="M26.2742 25.8946C26.4562 25.7903 26.7433 25.7903 26.9253 25.8946C27.1073 25.9988 27.1073 26.1656 26.9253 26.2699C26.7433 26.3741 26.4562 26.3741 26.2742 26.2699C26.0922 26.1656 26.0922 25.9988 26.2742 25.8946Z"
          fill="#535359"
        />
        <path
          d="M26.3026 26.1308C26.4706 26.2211 26.7367 26.2211 26.8977 26.1308C26.9817 26.0821 27.0237 26.0196 27.0237 25.957V26.0752C27.0237 26.1377 26.9817 26.2003 26.8977 26.2489C26.7367 26.3462 26.4636 26.3462 26.3026 26.2489C26.2186 26.2003 26.1836 26.1377 26.1836 26.0752V25.957C26.1836 26.0196 26.2256 26.0821 26.3026 26.1308Z"
          fill="#121214"
        />
        <path
          d="M26.3024 25.7907C26.4704 25.6934 26.7365 25.6934 26.8975 25.7907C27.0655 25.888 27.0655 26.0409 26.8975 26.1313C26.7365 26.2216 26.4634 26.2216 26.3024 26.1313C26.1414 26.034 26.1414 25.8811 26.3024 25.7907Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 25.9639V26.0056C27.0587 26.0751 27.0167 26.1376 26.9257 26.1932C26.7437 26.2975 26.4566 26.2975 26.2746 26.1932C26.1836 26.1446 26.1416 26.0751 26.1416 26.0056V25.9639C26.1416 26.0334 27.0587 26.0334 27.0587 25.9639Z"
          fill="#303033"
        />
        <path
          d="M26.2742 25.7764C26.4562 25.6722 26.7433 25.6722 26.9253 25.7764C27.1073 25.8807 27.1073 26.0475 26.9253 26.1517C26.7433 26.256 26.4562 26.256 26.2742 26.1517C26.0922 26.0475 26.0922 25.8807 26.2742 25.7764Z"
          fill="#535359"
        />
        <path
          d="M26.3026 26.0125C26.4706 26.1098 26.7367 26.1098 26.8977 26.0125C26.9817 25.9638 27.0237 25.9013 27.0237 25.8457V25.9638C27.0237 26.0264 26.9817 26.0889 26.8977 26.1376C26.7367 26.2279 26.4636 26.2279 26.3026 26.1376C26.2186 26.0889 26.1836 26.0264 26.1836 25.9638V25.8457C26.1836 25.9083 26.2256 25.9708 26.3026 26.0125Z"
          fill="#121214"
        />
        <path
          d="M26.3024 25.6726C26.4704 25.5753 26.7365 25.5753 26.8975 25.6726C27.0655 25.7699 27.0655 25.9228 26.8975 26.0131C26.7365 26.1104 26.4634 26.1104 26.3024 26.0131C26.1414 25.9158 26.1414 25.7629 26.3024 25.6726Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 25.8389V25.8806C27.0587 25.9501 27.0167 26.0126 26.9257 26.0682C26.7437 26.1725 26.4566 26.1725 26.2746 26.0682C26.1836 26.0196 26.1416 25.9501 26.1416 25.8806V25.8389C26.1416 25.9084 27.0587 25.9084 27.0587 25.8389Z"
          fill="#303033"
        />
        <path
          d="M26.2742 25.6583C26.4562 25.554 26.7433 25.554 26.9253 25.6583C27.1073 25.7625 27.1073 25.9293 26.9253 26.0335C26.7433 26.1378 26.4562 26.1378 26.2742 26.0335C26.0922 25.9293 26.0922 25.7625 26.2742 25.6583Z"
          fill="#535359"
        />
        <path
          d="M26.4361 25.5054C26.5201 25.554 26.6671 25.554 26.7511 25.5054C26.7931 25.4776 26.8141 25.4498 26.8141 25.415V25.832C26.8141 25.832 26.7931 25.8946 26.7511 25.9224C26.6671 25.971 26.5201 25.971 26.4361 25.9224C26.394 25.8946 26.373 25.8668 26.373 25.832V25.415C26.373 25.415 26.394 25.4776 26.4361 25.5054Z"
          fill="#A7A9AC"
        />
        <path
          d="M26.4361 25.3246C26.5201 25.2759 26.6671 25.2759 26.7511 25.3246C26.8351 25.3732 26.8351 25.4566 26.7511 25.5053C26.6671 25.5539 26.5201 25.5539 26.4361 25.5053C26.352 25.4566 26.352 25.3732 26.4361 25.3246Z"
          fill="#E6E7E8"
        />
        <path
          d="M26.6459 25.0889V25.4086C26.6459 25.4086 26.6459 25.4225 26.6319 25.4294C26.6109 25.4433 26.5759 25.4433 26.5619 25.4294C26.5479 25.4294 26.5479 25.4155 26.5479 25.4086V25.0889C26.5479 25.0889 26.6529 25.0958 26.6529 25.0889H26.6459Z"
          fill="#303033"
        />
        <path
          d="M26.5548 25.0677C26.5548 25.0677 26.6108 25.0538 26.6248 25.0677C26.6458 25.0816 26.6458 25.0955 26.6248 25.1094C26.6038 25.1233 26.5688 25.1233 26.5548 25.1094C26.5338 25.0955 26.5338 25.0816 26.5548 25.0677Z"
          fill="#535359"
        />
        <path
          d="M29.5016 24.5746C29.6626 24.4842 29.9357 24.4842 30.0967 24.5746C30.2647 24.6719 30.2647 24.8248 30.0967 24.9151C29.9287 25.0124 29.6626 25.0124 29.5016 24.9151C29.3406 24.8178 29.3406 24.6719 29.5016 24.5746Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 24.748V24.7897C30.2579 24.8592 30.2159 24.9218 30.1249 24.9774C29.9429 25.0816 29.6559 25.0816 29.4738 24.9774C29.3828 24.9287 29.3408 24.8592 29.3408 24.7897V24.748C29.3408 24.8175 30.2579 24.8175 30.2579 24.748Z"
          fill="#303033"
        />
        <path
          d="M29.4744 24.5606C29.6564 24.4564 29.9435 24.4564 30.1255 24.5606C30.3075 24.6649 30.3075 24.8316 30.1255 24.9359C29.9435 25.0401 29.6564 25.0401 29.4744 24.9359C29.2924 24.8316 29.2924 24.6649 29.4744 24.5606Z"
          fill="#535359"
        />
        <path
          d="M29.502 24.7968C29.663 24.8871 29.9361 24.8871 30.0971 24.7968C30.1811 24.7481 30.2231 24.6856 30.2231 24.623V24.7412C30.2231 24.8037 30.1811 24.8663 30.0971 24.9149C29.9291 25.0122 29.663 25.0122 29.502 24.9149C29.418 24.8663 29.376 24.8037 29.376 24.7412V24.623C29.376 24.6856 29.418 24.7481 29.502 24.7968Z"
          fill="#121214"
        />
        <path
          d="M29.5016 24.4558C29.6626 24.3585 29.9357 24.3585 30.0967 24.4558C30.2647 24.5531 30.2647 24.706 30.0967 24.7963C29.9287 24.8867 29.6626 24.8867 29.5016 24.7963C29.3406 24.699 29.3406 24.5461 29.5016 24.4558Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 24.623V24.6647C30.2579 24.7342 30.2159 24.7968 30.1249 24.8524C29.9429 24.9566 29.6559 24.9566 29.4738 24.8524C29.3828 24.8037 29.3408 24.7342 29.3408 24.6647V24.623C29.3408 24.6925 30.2579 24.6925 30.2579 24.623Z"
          fill="#303033"
        />
        <path
          d="M29.4744 24.4356C29.6564 24.3314 29.9435 24.3314 30.1255 24.4356C30.3075 24.5399 30.3075 24.7066 30.1255 24.8109C29.9435 24.9151 29.6564 24.9151 29.4744 24.8109C29.2924 24.7066 29.2924 24.5399 29.4744 24.4356Z"
          fill="#535359"
        />
        <path
          d="M29.502 24.6718C29.663 24.7691 29.9361 24.7691 30.0971 24.6718C30.1811 24.6231 30.2231 24.5606 30.2231 24.498V24.6162C30.2231 24.6787 30.1811 24.7413 30.0971 24.7899C29.9291 24.8803 29.663 24.8803 29.502 24.7899C29.418 24.7413 29.376 24.6787 29.376 24.6162V24.498C29.376 24.5606 29.418 24.6231 29.502 24.6718Z"
          fill="#121214"
        />
        <path
          d="M29.5016 24.3308C29.6626 24.2335 29.9357 24.2335 30.0967 24.3308C30.2647 24.4281 30.2647 24.581 30.0967 24.6713C29.9287 24.7686 29.6626 24.7686 29.5016 24.6713C29.3406 24.574 29.3406 24.4211 29.5016 24.3308Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 24.5049V24.5466C30.2579 24.6161 30.2159 24.6856 30.1249 24.7342C29.9429 24.8385 29.6559 24.8385 29.4738 24.7342C29.3828 24.6856 29.3408 24.6161 29.3408 24.5466V24.5049C29.3408 24.5744 30.2579 24.5744 30.2579 24.5049Z"
          fill="#303033"
        />
        <path
          d="M29.4744 24.3174C29.6564 24.2132 29.9435 24.2132 30.1255 24.3174C30.3075 24.4217 30.3075 24.5885 30.1255 24.6927C29.9435 24.797 29.6564 24.797 29.4744 24.6927C29.2924 24.5885 29.2924 24.4217 29.4744 24.3174Z"
          fill="#535359"
        />
        <path
          d="M29.502 24.539C29.663 24.6363 29.9361 24.6363 30.0971 24.539C30.1811 24.4903 30.2231 24.4278 30.2231 24.3652V24.4834C30.2231 24.5459 30.1811 24.6085 30.0971 24.6571C29.9361 24.7475 29.663 24.7475 29.502 24.6571C29.418 24.6085 29.376 24.5459 29.376 24.4834V24.3652C29.376 24.4278 29.418 24.4903 29.502 24.539Z"
          fill="#121214"
        />
        <path
          d="M29.5016 24.1989C29.6626 24.1017 29.9357 24.1017 30.0967 24.1989C30.2647 24.2962 30.2647 24.4422 30.0967 24.5395C29.9357 24.6368 29.6626 24.6368 29.5016 24.5395C29.3406 24.4491 29.3406 24.2962 29.5016 24.1989Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 24.373V24.4147C30.2579 24.4842 30.2159 24.5468 30.1249 24.6024C29.9429 24.7066 29.6559 24.7066 29.4738 24.6024C29.3828 24.5537 29.3408 24.4842 29.3408 24.4147V24.373C29.3408 24.4425 30.2579 24.4425 30.2579 24.373Z"
          fill="#303033"
        />
        <path
          d="M29.4744 24.1846C29.6564 24.0804 29.9435 24.0804 30.1255 24.1846C30.3075 24.2889 30.3075 24.4557 30.1255 24.5599C29.9435 24.6642 29.6564 24.6642 29.4744 24.5599C29.2924 24.4557 29.2924 24.2889 29.4744 24.1846Z"
          fill="#535359"
        />
        <path
          d="M29.502 24.4208C29.663 24.5181 29.9361 24.5181 30.0971 24.4208C30.1811 24.3722 30.2231 24.3096 30.2231 24.2471V24.3652C30.2231 24.4278 30.1811 24.4903 30.0971 24.539C29.9361 24.6363 29.663 24.6363 29.502 24.539C29.418 24.4903 29.376 24.4278 29.376 24.3652V24.2471C29.376 24.3096 29.418 24.3722 29.502 24.4208Z"
          fill="#121214"
        />
        <path
          d="M29.5016 24.0808C29.6626 23.9835 29.9357 23.9835 30.0967 24.0808C30.2647 24.1711 30.2647 24.331 30.0967 24.4213C29.9357 24.5186 29.6626 24.5186 29.5016 24.4213C29.3406 24.331 29.3406 24.1711 29.5016 24.0808Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 24.2471V24.2888C30.2579 24.3583 30.2159 24.4208 30.1249 24.4764C29.9429 24.5807 29.6559 24.5807 29.4738 24.4764C29.3828 24.4278 29.3408 24.3583 29.3408 24.2888V24.2471C29.3408 24.3166 30.2579 24.3166 30.2579 24.2471Z"
          fill="#303033"
        />
        <path
          d="M29.4744 24.0665C29.6564 23.9622 29.9435 23.9622 30.1255 24.0665C30.3075 24.1707 30.3075 24.3375 30.1255 24.4417C29.9435 24.546 29.6564 24.546 29.4744 24.4417C29.2924 24.3375 29.2924 24.1707 29.4744 24.0665Z"
          fill="#535359"
        />
        <path
          d="M29.502 24.3026C29.663 24.3999 29.9361 24.3999 30.0971 24.3026C30.1811 24.254 30.2231 24.1915 30.2231 24.1289V24.2471C30.2231 24.3096 30.1811 24.3721 30.0971 24.4208C29.9361 24.5181 29.663 24.5181 29.502 24.4208C29.418 24.3721 29.376 24.3096 29.376 24.2471V24.1289C29.376 24.1915 29.418 24.254 29.502 24.3026Z"
          fill="#121214"
        />
        <path
          d="M29.5016 23.9626C29.6626 23.8653 29.9357 23.8653 30.0967 23.9626C30.2647 24.0599 30.2647 24.2128 30.0967 24.3032C29.9357 24.4005 29.6626 24.4005 29.5016 24.3032C29.3406 24.2059 29.3406 24.0599 29.5016 23.9626Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 24.1289V24.1706C30.2579 24.2401 30.2159 24.3026 30.1249 24.3582C29.9429 24.4625 29.6559 24.4625 29.4738 24.3582C29.3828 24.3096 29.3408 24.2401 29.3408 24.1706V24.1289C29.3408 24.1984 30.2579 24.1984 30.2579 24.1289Z"
          fill="#303033"
        />
        <path
          d="M29.4744 23.9415C29.6564 23.8372 29.9435 23.8372 30.1255 23.9415C30.3075 24.0457 30.3075 24.2125 30.1255 24.3168C29.9435 24.421 29.6564 24.421 29.4744 24.3168C29.2924 24.2125 29.2924 24.0457 29.4744 23.9415Z"
          fill="#535359"
        />
        <path
          d="M29.502 24.1776C29.663 24.2749 29.9361 24.2749 30.0971 24.1776C30.1811 24.129 30.2231 24.0665 30.2231 24.0039V24.1221C30.2231 24.1846 30.1811 24.2471 30.0971 24.2958C29.9361 24.3931 29.663 24.3931 29.502 24.2958C29.418 24.2471 29.376 24.1846 29.376 24.1221V24.0039C29.376 24.0665 29.418 24.129 29.502 24.1776Z"
          fill="#121214"
        />
        <path
          d="M29.5016 23.8373C29.6626 23.7469 29.9357 23.7469 30.0967 23.8373C30.2647 23.9346 30.2647 24.0875 30.0967 24.1778C29.9357 24.2751 29.6626 24.2751 29.5016 24.1778C29.3406 24.0805 29.3406 23.9276 29.5016 23.8373Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 24.0117V24.0534C30.2579 24.1229 30.2159 24.1855 30.1249 24.2411C29.9429 24.3453 29.6559 24.3453 29.4738 24.2411C29.3828 24.1924 29.3408 24.1229 29.3408 24.0534V24.0117C29.3408 24.0812 30.2579 24.0812 30.2579 24.0117Z"
          fill="#303033"
        />
        <path
          d="M29.4744 23.8233C29.6564 23.7191 29.9435 23.7191 30.1255 23.8233C30.3075 23.9275 30.3075 24.0943 30.1255 24.1986C29.9435 24.3028 29.6564 24.3028 29.4744 24.1986C29.2924 24.0943 29.2924 23.9275 29.4744 23.8233Z"
          fill="#535359"
        />
        <path
          d="M29.6353 23.6704C29.7193 23.7191 29.8593 23.7191 29.9503 23.6704C29.9923 23.6426 30.0133 23.6148 30.0133 23.5801V23.9971C30.0133 23.9971 29.9923 24.0596 29.9503 24.0874C29.8663 24.1361 29.7193 24.1361 29.6353 24.0874C29.5933 24.0596 29.5723 24.0318 29.5723 23.9971V23.5801C29.5723 23.5801 29.5933 23.6426 29.6353 23.6704Z"
          fill="#A7A9AC"
        />
        <path
          d="M29.6353 23.4896C29.7193 23.441 29.8593 23.441 29.9503 23.4896C30.0343 23.5383 30.0343 23.6217 29.9503 23.6703C29.8663 23.719 29.7193 23.719 29.6353 23.6703C29.5513 23.6217 29.5513 23.5383 29.6353 23.4896Z"
          fill="#E6E7E8"
        />
        <path
          d="M29.8451 23.2539V23.5736C29.8451 23.5736 29.8451 23.5875 29.8311 23.5944C29.8101 23.6083 29.7751 23.6083 29.7611 23.5944C29.7541 23.5944 29.7471 23.5805 29.7471 23.5736V23.2539C29.7471 23.2539 29.8521 23.2609 29.8521 23.2539H29.8451Z"
          fill="#303033"
        />
        <path
          d="M29.754 23.2327C29.754 23.2327 29.81 23.2188 29.824 23.2327C29.845 23.2466 29.845 23.2605 29.824 23.2744C29.803 23.2883 29.768 23.2883 29.754 23.2744C29.733 23.2605 29.733 23.2466 29.754 23.2327Z"
          fill="#535359"
        />
        <path
          d="M29.7892 23.3098C29.7892 23.3098 29.7402 23.2889 29.7402 23.2611C29.7402 23.2334 29.7612 23.2125 29.7892 23.2125C29.8172 23.2125 32.9467 23.1778 36.9162 20.4535C36.9372 20.4396 36.9722 20.4396 36.9862 20.4674C37.0002 20.4882 36.9932 20.523 36.9722 20.5369C32.9747 23.282 29.8172 23.3098 29.7892 23.3098Z"
          fill="#121214"
        />
        <path
          d="M19.9316 30.287C19.9316 30.287 19.8966 30.28 19.8826 30.2592C19.8686 30.2314 19.8826 30.2036 19.9036 30.1897C19.9456 30.1688 24.2162 28.0005 26.5475 25.0469C26.5685 25.026 26.5965 25.0191 26.6175 25.0399C26.6385 25.0608 26.6455 25.0886 26.6245 25.1164C24.2792 28.0839 19.9946 30.2661 19.9456 30.287C19.9456 30.287 19.9316 30.287 19.9246 30.287H19.9316Z"
          fill="#121214"
        />
        <path
          d="M28.4444 15.4906L21.8145 7.65137L21.6045 7.76951L27.6183 15.0181L21.8145 11.6891L21.6045 11.8142L21.8145 12.1339L29.8026 16.7138L29.9706 17.0474L30.1807 16.9292V16.4844L28.4444 15.4906Z"
          fill="#BCBEC0"
        />
        <path
          d="M28.2344 15.6088L21.6045 7.76953V8.26991L27.4153 15.1362L21.6045 11.8143V12.259L29.9706 17.0474V16.6026L28.2344 15.6088Z"
          fill="#A7A9AC"
        />
        <path
          d="M25.0772 17.4087L18.4473 9.5625L18.2373 9.68759L24.2511 16.9361L18.4473 13.6072L18.2373 13.7254L18.4473 14.052L26.4354 18.6319L26.6034 18.9655L26.8135 18.8473V18.4025L25.0772 17.4087Z"
          fill="#BCBEC0"
        />
        <path
          d="M24.8672 17.5268L18.2373 9.6875V10.1879L24.0411 17.0542L18.2373 13.7253V14.1701L26.6034 18.9654V18.5206L24.8672 17.5268Z"
          fill="#A7A9AC"
        />
        <path
          d="M30.1811 16.485L29.621 17.0479L27.4578 18.2988L26.6036 18.5212L26.2256 18.3058L29.8031 16.2695L30.1811 16.485Z"
          fill="#BCBEC0"
        />
        <path
          d="M30.181 16.9292L26.6035 18.9654V18.5206L30.181 16.4844V16.9292Z"
          fill="#939598"
        />
        <path
          d="M26.3024 18.0554C26.4704 17.9581 26.7365 17.9581 26.8975 18.0554C27.0655 18.1527 27.0655 18.3056 26.8975 18.3959C26.7295 18.4863 26.4634 18.4863 26.3024 18.3959C26.1414 18.2986 26.1414 18.1457 26.3024 18.0554Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 18.2217V18.2634C27.0587 18.3329 27.0167 18.3954 26.9257 18.451C26.7437 18.5553 26.4566 18.5553 26.2746 18.451C26.1836 18.4024 26.1416 18.3329 26.1416 18.2634V18.2217C26.1416 18.2912 27.0587 18.2912 27.0587 18.2217Z"
          fill="#303033"
        />
        <path
          d="M26.2742 18.0411C26.4562 17.9368 26.7433 17.9368 26.9253 18.0411C27.1073 18.1453 27.1073 18.3121 26.9253 18.4164C26.7433 18.5206 26.4562 18.5206 26.2742 18.4164C26.0922 18.3121 26.0922 18.1453 26.2742 18.0411Z"
          fill="#535359"
        />
        <path
          d="M26.3028 18.2773C26.4708 18.3746 26.7368 18.3746 26.8979 18.2773C26.9819 18.2286 27.0239 18.1661 27.0239 18.1035V18.2217C27.0239 18.2842 26.9819 18.3468 26.8979 18.3954C26.7298 18.4857 26.4638 18.4857 26.3028 18.3954C26.2188 18.3468 26.1768 18.2842 26.1768 18.2217V18.1035C26.1768 18.1661 26.2188 18.2286 26.3028 18.2773Z"
          fill="#121214"
        />
        <path
          d="M26.3024 17.9304C26.4704 17.8331 26.7365 17.8331 26.8975 17.9304C27.0655 18.0277 27.0655 18.1806 26.8975 18.2709C26.7295 18.3682 26.4634 18.3682 26.3024 18.2709C26.1414 18.1736 26.1414 18.0207 26.3024 17.9304Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 18.1035V18.1452C27.0587 18.2147 27.0167 18.2773 26.9257 18.3329C26.7437 18.4371 26.4566 18.4371 26.2746 18.3329C26.1836 18.2842 26.1416 18.2147 26.1416 18.1452V18.1035C26.1416 18.173 27.0587 18.173 27.0587 18.1035Z"
          fill="#303033"
        />
        <path
          d="M26.2742 17.9161C26.4562 17.8118 26.7433 17.8118 26.9253 17.9161C27.1073 18.0203 27.1073 18.1871 26.9253 18.2914C26.7433 18.3956 26.4562 18.3956 26.2742 18.2914C26.0922 18.1871 26.0922 18.0203 26.2742 17.9161Z"
          fill="#535359"
        />
        <path
          d="M26.3028 18.1523C26.4708 18.2496 26.7368 18.2496 26.8979 18.1523C26.9819 18.1036 27.0239 18.0411 27.0239 17.9785V18.0967C27.0239 18.1592 26.9819 18.2218 26.8979 18.2635C26.7298 18.3607 26.4638 18.3607 26.3028 18.2635C26.2188 18.2148 26.1768 18.1523 26.1768 18.0967V17.9785C26.1768 18.0411 26.2188 18.1036 26.3028 18.1523Z"
          fill="#121214"
        />
        <path
          d="M26.3024 17.8122C26.4704 17.7149 26.7365 17.7149 26.8975 17.8122C27.0585 17.9095 27.0655 18.0624 26.8975 18.1528C26.7295 18.2501 26.4634 18.2501 26.3024 18.1528C26.1414 18.0555 26.1414 17.9026 26.3024 17.8122Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 17.9863V18.028C27.0587 18.0975 27.0167 18.167 26.9257 18.2157C26.7437 18.3199 26.4566 18.3199 26.2746 18.2157C26.1836 18.167 26.1416 18.0975 26.1416 18.028V17.9863C26.1416 18.0558 27.0587 18.0558 27.0587 17.9863Z"
          fill="#303033"
        />
        <path
          d="M26.2742 17.7979C26.4562 17.6937 26.7433 17.6937 26.9253 17.7979C27.1073 17.9022 27.1073 18.0689 26.9253 18.1732C26.7433 18.2774 26.4562 18.2774 26.2742 18.1732C26.0922 18.0689 26.0922 17.9022 26.2742 17.7979Z"
          fill="#535359"
        />
        <path
          d="M26.3026 18.0203C26.4706 18.1176 26.7367 18.1176 26.8977 18.0203C26.9817 17.9717 27.0237 17.9091 27.0237 17.8535V17.9717C27.0237 18.0342 26.9817 18.0968 26.8977 18.1454C26.7367 18.2427 26.4636 18.2427 26.3026 18.1454C26.2186 18.0968 26.1836 18.0342 26.1836 17.9717V17.8535C26.1836 17.9161 26.2256 17.9786 26.3026 18.0203Z"
          fill="#121214"
        />
        <path
          d="M26.3024 17.6804C26.4704 17.5831 26.7365 17.5831 26.8975 17.6804C27.0655 17.7777 27.0655 17.9236 26.8975 18.0209C26.7365 18.1182 26.4634 18.1182 26.3024 18.0209C26.1414 17.9236 26.1414 17.7777 26.3024 17.6804Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 17.8535V17.8952C27.0587 17.9647 27.0167 18.0273 26.9257 18.0829C26.7437 18.1871 26.4566 18.1871 26.2746 18.0829C26.1836 18.0342 26.1416 17.9647 26.1416 17.8952V17.8535C26.1416 17.923 27.0587 17.923 27.0587 17.8535Z"
          fill="#303033"
        />
        <path
          d="M26.2742 17.6661C26.4562 17.5618 26.7433 17.5618 26.9253 17.6661C27.1073 17.7703 27.1073 17.9371 26.9253 18.0414C26.7433 18.1456 26.4562 18.1456 26.2742 18.0414C26.0922 17.9371 26.0922 17.7703 26.2742 17.6661Z"
          fill="#535359"
        />
        <path
          d="M26.3026 17.9023C26.4706 17.9996 26.7367 17.9996 26.8977 17.9023C26.9817 17.8536 27.0237 17.7911 27.0237 17.7285V17.8467C27.0237 17.9092 26.9817 17.9718 26.8977 18.0135C26.7367 18.1107 26.4636 18.1107 26.3026 18.0135C26.2186 17.9648 26.1836 17.9023 26.1836 17.8467V17.7285C26.1836 17.7911 26.2256 17.8536 26.3026 17.9023Z"
          fill="#121214"
        />
        <path
          d="M26.3024 17.5622C26.4704 17.4649 26.7365 17.4649 26.8975 17.5622C27.0655 17.6526 27.0655 17.8124 26.8975 17.9028C26.7365 18.0001 26.4634 18.0001 26.3024 17.9028C26.1414 17.8055 26.1414 17.6526 26.3024 17.5622Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 17.7285V17.7702C27.0587 17.8397 27.0167 17.9023 26.9257 17.9579C26.7437 18.0621 26.4566 18.0621 26.2746 17.9579C26.1836 17.9092 26.1416 17.8397 26.1416 17.7702V17.7285C26.1416 17.798 27.0587 17.798 27.0587 17.7285Z"
          fill="#303033"
        />
        <path
          d="M26.2742 17.5411C26.4562 17.4368 26.7433 17.4368 26.9253 17.5411C27.1073 17.6453 27.1073 17.8121 26.9253 17.9164C26.7433 18.0206 26.4562 18.0206 26.2742 17.9164C26.0922 17.8121 26.0922 17.6453 26.2742 17.5411Z"
          fill="#535359"
        />
        <path
          d="M26.3026 17.7773C26.4706 17.8746 26.7367 17.8746 26.8977 17.7773C26.9817 17.7286 27.0237 17.6661 27.0237 17.6035V17.7217C27.0237 17.7842 26.9817 17.8468 26.8977 17.8954C26.7367 17.9927 26.4636 17.9927 26.3026 17.8954C26.2186 17.8468 26.1836 17.7842 26.1836 17.7217V17.6035C26.1836 17.6661 26.2256 17.7286 26.3026 17.7773Z"
          fill="#121214"
        />
        <path
          d="M26.3024 17.4372C26.4704 17.3399 26.7365 17.3399 26.8975 17.4372C27.0655 17.5345 27.0655 17.6874 26.8975 17.7778C26.7365 17.8751 26.4634 17.8751 26.3024 17.7778C26.1414 17.6874 26.1414 17.5276 26.3024 17.4372Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 17.6104V17.652C27.0587 17.7215 27.0167 17.7841 26.9257 17.8397C26.7437 17.9439 26.4566 17.9439 26.2746 17.8397C26.1836 17.791 26.1416 17.7215 26.1416 17.652V17.6104C26.1416 17.6798 27.0587 17.6798 27.0587 17.6104Z"
          fill="#303033"
        />
        <path
          d="M26.2742 17.4229C26.4562 17.3187 26.7433 17.3187 26.9253 17.4229C27.1073 17.5272 27.1073 17.694 26.9253 17.7982C26.7433 17.9024 26.4562 17.9024 26.2742 17.7982C26.0922 17.694 26.0922 17.5272 26.2742 17.4229Z"
          fill="#535359"
        />
        <path
          d="M26.3026 17.6591C26.4706 17.7564 26.7367 17.7564 26.8977 17.6591C26.9817 17.6104 27.0237 17.5479 27.0237 17.4854V17.6035C27.0237 17.666 26.9817 17.7286 26.8977 17.7772C26.7367 17.8745 26.4636 17.8745 26.3026 17.7772C26.2186 17.7286 26.1836 17.666 26.1836 17.6035V17.4854C26.1836 17.5479 26.2256 17.6104 26.3026 17.6591Z"
          fill="#121214"
        />
        <path
          d="M26.3024 17.3191C26.4704 17.2218 26.7365 17.2218 26.8975 17.3191C27.0655 17.4164 27.0655 17.5693 26.8975 17.6596C26.7365 17.7569 26.4634 17.7569 26.3024 17.6596C26.1414 17.5623 26.1414 17.4094 26.3024 17.3191Z"
          fill="#E6E7E8"
        />
        <path
          d="M27.0587 17.4854V17.527C27.0587 17.5965 27.0167 17.6591 26.9257 17.7147C26.7437 17.8189 26.4566 17.8189 26.2746 17.7147C26.1836 17.666 26.1416 17.5965 26.1416 17.527V17.4854C26.1416 17.5548 27.0587 17.5548 27.0587 17.4854Z"
          fill="#303033"
        />
        <path
          d="M26.2742 17.3047C26.4562 17.2005 26.7433 17.2005 26.9253 17.3047C27.1073 17.409 27.1073 17.5758 26.9253 17.68C26.7433 17.7843 26.4562 17.7843 26.2742 17.68C26.0922 17.5758 26.0922 17.409 26.2742 17.3047Z"
          fill="#535359"
        />
        <path
          d="M26.4361 17.1519C26.5201 17.2005 26.6671 17.2005 26.7511 17.1519C26.7931 17.1241 26.8141 17.0963 26.8141 17.0615V17.4785C26.8141 17.4785 26.7931 17.5411 26.7511 17.5689C26.6671 17.6175 26.5201 17.6175 26.4361 17.5689C26.394 17.5411 26.373 17.5133 26.373 17.4785V17.0615C26.373 17.0615 26.394 17.1241 26.4361 17.1519Z"
          fill="#A7A9AC"
        />
        <path
          d="M26.4361 16.9711C26.5201 16.9224 26.6671 16.9224 26.7511 16.9711C26.8351 17.0197 26.8351 17.1031 26.7511 17.1517C26.6671 17.2004 26.5201 17.2004 26.4361 17.1517C26.352 17.1031 26.352 17.0197 26.4361 16.9711Z"
          fill="#E6E7E8"
        />
        <path
          d="M26.6459 16.7354V17.062C26.6459 17.062 26.6459 17.0759 26.6319 17.0828C26.6109 17.0967 26.5759 17.0967 26.5619 17.0828C26.5479 17.0828 26.5479 17.0689 26.5479 17.062V16.7354C26.5479 16.7354 26.6529 16.7423 26.6529 16.7354H26.6459Z"
          fill="#303033"
        />
        <path
          d="M26.5548 16.7142C26.5548 16.7142 26.6108 16.7003 26.6248 16.7142C26.6458 16.7281 26.6458 16.742 26.6248 16.7559C26.6038 16.7698 26.5688 16.7698 26.5548 16.7559C26.5338 16.742 26.5338 16.7281 26.5548 16.7142Z"
          fill="#535359"
        />
        <path
          d="M29.5016 16.2211C29.6626 16.1307 29.9357 16.1307 30.0967 16.2211C30.2647 16.3184 30.2647 16.4713 30.0967 16.5616C29.9287 16.6589 29.6626 16.6589 29.5016 16.5616C29.3406 16.4643 29.3406 16.3184 29.5016 16.2211Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 16.3945V16.4362C30.2579 16.5057 30.2159 16.5683 30.1249 16.6239C29.9429 16.7281 29.6559 16.7281 29.4738 16.6239C29.3828 16.5752 29.3408 16.5057 29.3408 16.4362V16.3945C29.3408 16.464 30.2579 16.464 30.2579 16.3945Z"
          fill="#303033"
        />
        <path
          d="M29.4744 16.2071C29.6564 16.1028 29.9435 16.1028 30.1255 16.2071C30.3075 16.3113 30.3075 16.4781 30.1255 16.5824C29.9435 16.6866 29.6564 16.6866 29.4744 16.5824C29.2924 16.4781 29.2924 16.3113 29.4744 16.2071Z"
          fill="#535359"
        />
        <path
          d="M29.502 16.4433C29.663 16.5406 29.9361 16.5406 30.0971 16.4433C30.1811 16.3946 30.2231 16.3321 30.2231 16.2695V16.3877C30.2231 16.4502 30.1811 16.5128 30.0971 16.5614C29.9291 16.6587 29.663 16.6587 29.502 16.5614C29.418 16.5128 29.376 16.4502 29.376 16.3877V16.2695C29.376 16.3321 29.418 16.3946 29.502 16.4433Z"
          fill="#121214"
        />
        <path
          d="M29.5016 16.1023C29.6626 16.005 29.9357 16.005 30.0967 16.1023C30.2647 16.1996 30.2647 16.3525 30.0967 16.4428C29.9287 16.5401 29.6626 16.5401 29.5016 16.4428C29.3406 16.3455 29.3406 16.1926 29.5016 16.1023Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 16.2695V16.3112C30.2579 16.3807 30.2159 16.4433 30.1249 16.4989C29.9429 16.6031 29.6559 16.6031 29.4738 16.4989C29.3828 16.4502 29.3408 16.3807 29.3408 16.3112V16.2695C29.3408 16.339 30.2579 16.339 30.2579 16.2695Z"
          fill="#303033"
        />
        <path
          d="M29.4744 16.0821C29.6564 15.9778 29.9435 15.9778 30.1255 16.0821C30.3075 16.1863 30.3075 16.3531 30.1255 16.4574C29.9435 16.5616 29.6564 16.5616 29.4744 16.4574C29.2924 16.3531 29.2924 16.1863 29.4744 16.0821Z"
          fill="#535359"
        />
        <path
          d="M29.502 16.3251C29.663 16.4155 29.9361 16.4155 30.0971 16.3251C30.1811 16.2765 30.2231 16.2139 30.2231 16.1514V16.2695C30.2231 16.3321 30.1811 16.3946 30.0971 16.4433C29.9291 16.5406 29.663 16.5406 29.502 16.4433C29.418 16.3946 29.376 16.3321 29.376 16.2695V16.1514C29.376 16.2139 29.418 16.2765 29.502 16.3251Z"
          fill="#121214"
        />
        <path
          d="M29.5016 15.9773C29.6626 15.88 29.9357 15.88 30.0967 15.9773C30.2647 16.0746 30.2647 16.2275 30.0967 16.3178C29.9287 16.4082 29.6626 16.4082 29.5016 16.3178C29.3406 16.2205 29.3406 16.0676 29.5016 15.9773Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 16.1514V16.1931C30.2579 16.2626 30.2159 16.3251 30.1249 16.3807C29.9429 16.485 29.6559 16.485 29.4738 16.3807C29.3828 16.3321 29.3408 16.2626 29.3408 16.1931V16.1514C29.3408 16.2209 30.2579 16.2209 30.2579 16.1514Z"
          fill="#303033"
        />
        <path
          d="M29.4744 15.9639C29.6564 15.8597 29.9435 15.8597 30.1255 15.9639C30.3075 16.0682 30.3075 16.235 30.1255 16.3392C29.9435 16.4435 29.6564 16.4435 29.4744 16.3392C29.2924 16.235 29.2924 16.0682 29.4744 15.9639Z"
          fill="#535359"
        />
        <path
          d="M29.502 16.1933C29.663 16.2906 29.9361 16.2906 30.0971 16.1933C30.1811 16.1446 30.2231 16.0821 30.2231 16.0195V16.1377C30.2231 16.2002 30.1811 16.2628 30.0971 16.3114C29.9361 16.4087 29.663 16.4087 29.502 16.3114C29.418 16.2628 29.376 16.2002 29.376 16.1377V16.0195C29.376 16.0821 29.418 16.1446 29.502 16.1933Z"
          fill="#121214"
        />
        <path
          d="M29.5016 15.8454C29.6626 15.7481 29.9357 15.7481 30.0967 15.8454C30.2647 15.9427 30.2647 16.0956 30.0967 16.186C29.9357 16.2833 29.6626 16.2833 29.5016 16.186C29.3406 16.0887 29.3406 15.9358 29.5016 15.8454Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 16.0195V16.0612C30.2579 16.1307 30.2159 16.1933 30.1249 16.2489C29.9429 16.3531 29.6559 16.3531 29.4738 16.2489C29.3828 16.2002 29.3408 16.1307 29.3408 16.0612V16.0195C29.3408 16.089 30.2579 16.089 30.2579 16.0195Z"
          fill="#303033"
        />
        <path
          d="M29.4744 15.8311C29.6564 15.7269 29.9435 15.7269 30.1255 15.8311C30.3075 15.9354 30.3075 16.1022 30.1255 16.2064C29.9435 16.3106 29.6564 16.3106 29.4744 16.2064C29.2924 16.1022 29.2924 15.9354 29.4744 15.8311Z"
          fill="#535359"
        />
        <path
          d="M29.502 16.0673C29.663 16.1646 29.9361 16.1646 30.0971 16.0673C30.1811 16.0186 30.2231 15.9561 30.2231 15.8936V16.0117C30.2231 16.0742 30.1811 16.1368 30.0971 16.1854C29.9361 16.2827 29.663 16.2827 29.502 16.1854C29.418 16.1368 29.376 16.0742 29.376 16.0117V15.8936C29.376 15.9561 29.418 16.0186 29.502 16.0673Z"
          fill="#121214"
        />
        <path
          d="M29.5016 15.7273C29.6626 15.63 29.9357 15.63 30.0967 15.7273C30.2647 15.8246 30.2647 15.9775 30.0967 16.0678C29.9357 16.1651 29.6626 16.1651 29.5016 16.0678C29.3406 15.9775 29.3406 15.8246 29.5016 15.7273Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 15.9014V15.9431C30.2579 16.0126 30.2159 16.0751 30.1249 16.1307C29.9429 16.235 29.6559 16.235 29.4738 16.1307C29.3828 16.0821 29.3408 16.0126 29.3408 15.9431V15.9014C29.3408 15.9709 30.2579 15.9709 30.2579 15.9014Z"
          fill="#303033"
        />
        <path
          d="M29.4744 15.7129C29.6564 15.6087 29.9435 15.6087 30.1255 15.7129C30.3075 15.8172 30.3075 15.984 30.1255 16.0882C29.9435 16.1925 29.6564 16.1925 29.4744 16.0882C29.2924 15.984 29.2924 15.8172 29.4744 15.7129Z"
          fill="#535359"
        />
        <path
          d="M29.502 15.9491C29.663 16.0395 29.9361 16.0395 30.0971 15.9491C30.1811 15.9005 30.2231 15.8379 30.2231 15.7754V15.8935C30.2231 15.9561 30.1811 16.0186 30.0971 16.0673C29.9361 16.1646 29.663 16.1646 29.502 16.0673C29.418 16.0186 29.376 15.9561 29.376 15.8935V15.7754C29.376 15.8379 29.418 15.9005 29.502 15.9491Z"
          fill="#121214"
        />
        <path
          d="M29.5016 15.6091C29.6626 15.5118 29.9357 15.5118 30.0967 15.6091C30.2647 15.7064 30.2647 15.8593 30.0967 15.9496C29.9357 16.04 29.6626 16.04 29.5016 15.9496C29.3406 15.8523 29.3406 15.6995 29.5016 15.6091Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 15.7754V15.8171C30.2579 15.8866 30.2159 15.9491 30.1249 16.0047C29.9429 16.109 29.6559 16.109 29.4738 16.0047C29.3828 15.9561 29.3408 15.8866 29.3408 15.8171V15.7754C29.3408 15.8449 30.2579 15.8449 30.2579 15.7754Z"
          fill="#303033"
        />
        <path
          d="M29.4744 15.5879C29.6564 15.4837 29.9435 15.4837 30.1255 15.5879C30.3075 15.6922 30.3075 15.859 30.1255 15.9632C29.9435 16.0675 29.6564 16.0675 29.4744 15.9632C29.2924 15.859 29.2924 15.6922 29.4744 15.5879Z"
          fill="#535359"
        />
        <path
          d="M29.502 15.824C29.663 15.9213 29.9361 15.9213 30.0971 15.824C30.1811 15.7754 30.2231 15.7128 30.2231 15.6572V15.7754C30.2231 15.8379 30.1811 15.9005 30.0971 15.9491C29.9361 16.0395 29.663 16.0395 29.502 15.9491C29.418 15.9005 29.376 15.8379 29.376 15.7754V15.6572C29.376 15.7198 29.418 15.7823 29.502 15.824Z"
          fill="#121214"
        />
        <path
          d="M29.5016 15.4841C29.6626 15.3868 29.9357 15.3868 30.0967 15.4841C30.2647 15.5814 30.2647 15.7343 30.0967 15.8246C29.9357 15.9219 29.6626 15.9219 29.5016 15.8246C29.3406 15.7273 29.3406 15.5745 29.5016 15.4841Z"
          fill="#E6E7E8"
        />
        <path
          d="M30.2579 15.6572V15.6989C30.2579 15.7684 30.2159 15.831 30.1249 15.8866C29.9429 15.9908 29.6559 15.9908 29.4738 15.8866C29.3828 15.8379 29.3408 15.7684 29.3408 15.6989V15.6572C29.3408 15.7267 30.2579 15.7267 30.2579 15.6572Z"
          fill="#303033"
        />
        <path
          d="M29.4744 15.4698C29.6564 15.3655 29.9435 15.3655 30.1255 15.4698C30.3075 15.574 30.3075 15.7408 30.1255 15.8451C29.9435 15.9493 29.6564 15.9493 29.4744 15.8451C29.2924 15.7408 29.2924 15.574 29.4744 15.4698Z"
          fill="#535359"
        />
        <path
          d="M29.6353 15.3169C29.7193 15.3656 29.8593 15.3656 29.9503 15.3169C29.9923 15.2891 30.0133 15.2613 30.0133 15.2266V15.6435C30.0133 15.6435 29.9923 15.7061 29.9503 15.7339C29.8663 15.7825 29.7193 15.7825 29.6353 15.7339C29.5933 15.713 29.5723 15.6783 29.5723 15.6435V15.2266C29.5723 15.2266 29.5933 15.2891 29.6353 15.3169Z"
          fill="#A7A9AC"
        />
        <path
          d="M29.6353 15.1429C29.7193 15.0943 29.8593 15.0943 29.9503 15.1429C30.0343 15.1916 30.0343 15.275 29.9503 15.3236C29.8663 15.3723 29.7193 15.3723 29.6353 15.3236C29.5513 15.275 29.5513 15.1916 29.6353 15.1429Z"
          fill="#E6E7E8"
        />
        <path
          d="M29.8451 14.9072V15.2339C29.8451 15.2339 29.8451 15.2478 29.8311 15.2547C29.8101 15.2686 29.7751 15.2686 29.7611 15.2547C29.7541 15.2547 29.7471 15.2408 29.7471 15.2339V14.9072C29.7471 14.9072 29.8521 14.9142 29.8521 14.9072H29.8451Z"
          fill="#303033"
        />
        <path
          d="M29.754 14.8861C29.754 14.8861 29.81 14.8722 29.824 14.8861C29.845 14.9 29.845 14.9139 29.824 14.9278C29.803 14.9417 29.768 14.9417 29.754 14.9278C29.733 14.9139 29.733 14.9 29.754 14.8861Z"
          fill="#535359"
        />
        <path
          d="M29.7892 14.9554C29.7892 14.9554 29.7402 14.9345 29.7402 14.9067C29.7402 14.8789 29.7612 14.8581 29.7892 14.8581C29.8172 14.8581 32.9467 14.8233 36.9162 12.099C36.9372 12.0851 36.9722 12.0921 36.9862 12.1129C37.0002 12.1338 36.9932 12.1685 36.9722 12.1824C32.9747 14.9276 29.8172 14.9554 29.7892 14.9554Z"
          fill="#121214"
        />
        <path
          d="M19.9316 21.9403C19.9316 21.9403 19.8966 21.9333 19.8826 21.9125C19.8686 21.8847 19.8826 21.8569 19.9036 21.843C19.9456 21.8221 24.2162 19.6538 26.5475 16.7002C26.5685 16.6794 26.5965 16.6724 26.6175 16.6933C26.6385 16.7141 26.6455 16.7419 26.6245 16.7627C24.2792 19.7303 19.9946 21.9125 19.9456 21.9333C19.9456 21.9333 19.9316 21.9333 19.9246 21.9333L19.9316 21.9403Z"
          fill="#121214"
        />
      </g>
      <defs>
        <clipPath id="clip0_479_15240">
          <rect width="37" height="55" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GridIcon;
