import React from 'react';
import { Box, Grid } from '@mui/material';
import ProjectDetails from '../blocks/ProjectDetails.tsx';
import BottomHeader from '../blocks/BottomHeader.tsx';
import ETForm from '../blocks/ETblocks/ETForm.tsx';
interface WithProjectDetailsProps {
  isProjectDetailsOpen: boolean;
}
const withProjectDetails = <T extends WithProjectDetailsProps>({
  WrappedComponent,
  className,
  withForm,
  defaultValues = {},
}: {
  WrappedComponent: React.FC<T>;
  className: string;
  withForm?: boolean;
  defaultValues?: any;
}) => {
  // Return a new functional component that wraps the provided component
  return ({ isProjectDetailsOpen, ...props }: T) => (
    <>
      <Box className={className}>
        <BottomHeader />
        <Grid container>
          <Grid
            item
            xs={isProjectDetailsOpen ? 9 : 12}
            style={{ transition: 'ease-in-out 0.4s' }}
            px={2.5}
            pt={4}
          >
            {withForm ? (
              <ETForm defaultValues={defaultValues}>
                <WrappedComponent {...(props as T)} />
              </ETForm>
            ) : (
              <WrappedComponent {...(props as T)} />
            )}
          </Grid>

          {/* Render the ProjectDetails component */}
          <ProjectDetails />
        </Grid>
      </Box>
    </>
  );
};

export default withProjectDetails;
