import { Box } from '@mui/material';
import { DataKeysType, UseOfPvEnergyPropsType } from '../ResultTypes';
import { useRef } from 'react';
import PrintButton from '../PrintButton';
import ApexBarChart from '../../../blocks/charts/BarChart2/ApexBarChart';

const UseOfPvEnergy = ({ data = null }: { data: UseOfPvEnergyPropsType }) => {
  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton
        id={'USE_OF_PV_ENERGY'}
        componentName="Simulation"
        element={ref}
      />
      <Box m={'25px'} p={'25px'} className="results__info" ref={ref}>
        <h2>Use Of PV Energy</h2>
        <ApexBarChart
          data={data?.data || []}
          title={'Energy Forcast With Consumption'}
          height={400}
          withTabs={false}
          dataKeys={data?.dataKeys as DataKeysType}
          barSize={20}
          yAxisLabel="Energy [kWh]"
        />
      </Box>
    </>
  );
};

export default UseOfPvEnergy;
