
const MoonIcon = ({
	fill,
	width,
	height,
	...props
}: { fill?: string; width?: number; height?: number } & Record<
	string,
	number | string
>) => (
<svg width={width ?? '25'} height={height ?? '24'} viewBox="0 0 25 24" fill={fill ?? 'none'} {...props} xmlns="http://www.w3.org/2000/svg">
	<path d="M12.2985 23.9972C10.8896 23.9943 9.7224 23.8074 8.58923 23.4476C6.49286 22.7818 4.68357 21.6553 3.20289 20.015C2.21986 18.9253 1.43137 17.7118 0.88461 16.3492C0.500276 15.3898 0.258533 14.3907 0.111221 13.3661C-0.0162608 12.4823 -0.032314 11.5974 0.0536181 10.7145C0.162214 9.60401 0.408678 8.52183 0.805288 7.47365C1.20473 6.41791 1.7411 5.43771 2.41722 4.53779C2.72507 4.12796 3.07446 3.74929 3.42008 3.37062C4.12076 2.60384 4.94892 1.99287 5.81674 1.43007C6.64112 0.894642 7.53349 0.512197 8.46175 0.205296C8.67705 0.134473 8.89141 0.0504297 9.11332 0.0126573C9.4325 -0.0411683 9.7054 0.0778146 9.88765 0.347887C10.0775 0.629291 10.0935 0.932415 9.89804 1.21948C9.71107 1.49522 9.49765 1.75207 9.30974 2.02781C8.67988 2.9504 8.29177 3.97687 8.07552 5.0666C7.85456 6.17994 7.82151 7.30084 8.01226 8.42362C8.2625 9.89769 8.82625 11.2405 9.74034 12.4275C10.6714 13.6372 11.8282 14.5616 13.2239 15.1877C13.9642 15.5201 14.731 15.76 15.5337 15.8761C16.1003 15.9583 16.6706 15.9989 17.2448 15.979C18.207 15.946 19.1447 15.7892 20.0475 15.4464C21.0182 15.0782 21.9153 14.5824 22.7085 13.9063C22.9635 13.6891 23.2487 13.5965 23.5801 13.6976C23.9258 13.8024 24.1562 14.1754 24.0731 14.5267C23.8795 15.336 23.6094 16.1179 23.2742 16.8809C22.8842 17.7666 22.3658 18.5712 21.7775 19.3323C20.7831 20.6175 19.5574 21.6458 18.1466 22.4381C17.0672 23.0444 15.919 23.4863 14.6961 23.728C13.8311 23.8999 12.9614 23.9849 12.2994 24L12.2985 23.9972Z" fill="#5E6D99"/>
</svg>
)


export default MoonIcon;
