import { createTheme, ThemeOptions } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';

type CustomPaletteOptions = PaletteOptions & {
  aqua?: string;
};

type CustomThemeOptions = ThemeOptions & {
  palette?: CustomPaletteOptions & {
    white?: string;
  } & any;
};

export const customTheme: CustomThemeOptions = createTheme({
  typography: {
    fontFamily: 'inter-reguler',
    fontSize: 12,
    h6: {
      color: 'var(--primary-color)',
    },
    subtitle1: {
      color: 'var(--primary-color)',
    },
  },

  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '13px', // Set your desired font size here
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: 'var(--primary-color)',
        },
      },
    },

    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'var(--background-color-primary)',
    //     },
    //   },
    // },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'var(--primary-color)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: 'var(--primary-color)',
        },
        root: {
          color: 'var(--primary-color)',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--background-color-drop-down)',
          ':before': {
            backgroundColor: 'var(--background-color-drop-down) !important',
          },
        },
      },
    },
  },
  palette: {
    // primary: {
    //   main: 'var(--primary-color)',
    // },
    aqua: '#169BFB',
    main: 'var(--primary-color)',
    // secondary: 'var(--secondary-color)',
    bgHeader: 'var(--background-color-header)',
    gray: {
      '0': '#F8F8F8',
      '5': '#CED1D6',
      '10': '#f8f9f9',
      '25': '#f3f3f370',
      '50': '#f3f3f3',
      '75': '#e9ebed',
      '80': '#F7F8FB',
      '100': '#DCDFE7',
      '150': '#c2c8cc',
      '200': '#B3B7C1',
      '300': '#767e91',
      '400': '#767E90',
      '500': '#484E5E',
      '600': '#565960',
      '800': '#484456',
      '900': '#484E5D',
      '94': '#edeff2',
      '95': '#8C9099',
      '950': '#EBEEF3',
    },
    blue: {
      '100': '#edf7ff',
      '200': '#cee2f2',
      '300': '#adcce4',
      '400': '#5293c7',
      '450': '#1270B4',
      '500': '#337fbd',
      '600': '#10489b',
      '700': '#144a75',
      '800': '#0f3554',
      '900': '#f5f9ff',
      aliceBlue: '#ebf7ff',
      dark: '#004890',
      '950': '#096BCE',
    },
    green: {
      '10': '#008000',
      '50': '#e5f9ee',
      '100': '#edf8f4',
      '200': '#d1e8df',
      '300': '#aecfc2',
      '350': '#2b956f',
      '400': '#5eae91',
      '500': '#228f67',
      '600': '#038153',
      '700': '#186146',
      '800': '#0b3b29',
      '900': '#008558',
    },
    red: {
      '0': '#F9EDEE',
      '5': '#B22E3B',
      '10': '#e60000',
      '50': '#fff0f18a',
      '100': '#fff0f1',
      '200': '#f5d5d8',
      '300': '#f5b5ba',
      '400': '#e35b66',
      '500': '#d93f4c',
      '600': '#cc3340',
      '700': '#8c232c',
      '800': '#681219',
      '900': '#E54353',
      '950': '#DF2C2C',
    },
    yellow: {
      '10': '#fffaeb',
      '50': '#996A0F',
      '100': '#D97706',
      '101': '#F0A81C',
    },
    spaceWhite: '#f3faf9',
    lightGreen: {
      '50': '#F3FAF9',
      '100': '#EEFBFA',
    },
    orange: '#E95635',
    black: '#000000',
    white: '#ffffff',
    clearSkyLight: '#F5F8FD',
    darkSkyLight: '#EBF1FB',
    jeansLight: '#E2E9F3',
    mint: '#368F8B',
    mintLight: '#20A19C',
    mintText: '#20A29C',
    jeansLight2: '#E5E5E5',
    jeans: '#10489B',
    cosmicLatte: '#FFFAEB',
    goldenBrown: '#996A0F',
    dariBlack: '#130F26',
    neutralLight: '#172B4D',
    requiredAsterik: '#D2374F',
    adminSkyBackground: '#F5F9FF',
  } as CustomPaletteOptions,

  // typography: {
  //   fontFamily: 'inter',
  // },
  // palette: {
  //   // white: '#fff',

  //   primary: {
  //     // light: will be calculated from palette.primary.main,
  //     main: '#0098E2',
  //     // dark: will be calculated from palette.primary.main,
  //     // contrastText: will be calculated to contrast with palette.primary.main
  //   },
  //   secondary: {
  //     light: '#0066ff',
  //     main: '#0044ff',
  //     // dark: will be calculated from palette.secondary.main,
  //     contrastText: '#ffcc00',
  //   },
  //   info: {
  //     light: '#0066ff',
  //     main: '#0044ff',
  //     // dark: will be calculated from palette.secondary.main,
  //     contrastText: '#ffcc00',
  //   },
  //   // Provide every color token (light, main, dark, and contrastText) when using
  //   // custom colors for props in Material UI's components.
  //   // Then you will be able to use it like this: `<Button color="custom">`
  //   // (For TypeScript, you need to add module augmentation for the `custom` value)
  //   // custom: {
  //   //   light: '#ffa726',
  //   //   main: '#f57c00',
  //   //   dark: '#ef6c00',
  //   //   contrastText: 'rgba(0, 0, 0, 0.87)',
  //   // },
  //   // Used by `getContrastText()` to maximize the contrast between
  //   // the background and the text.
  //   contrastThreshold: 3,
  //   // Used by the functions below to shift a color's luminance by approximately
  //   // two indexes within its tonal palette.
  //   // E.g., shift from Red 500 to Red 300 or Red 700.
  //   tonalOffset: 0.2,
  // },
  // components: {
  //   MuiTypography: {
  //     defaultProps: {
  //       variantMapping: {
  //         h1: 'h1',
  //         h2: 'h2',
  //         h3: 'h3',
  //         h4: 'h4',
  //         h5: 'h5',
  //         h6: 'h6',
  //         subtitle1: 'h2',
  //         subtitle2: 'h3',
  //         body1: 'span',
  //         body2: 'span',
  //       },
  //     },
  //   },
  // },
});

//// additional code :
// space: {
//   base: 3.8,
// },
// fontSizes: {
//   lg: '18px',
// },
// lineHeights: {
//   md: '22px',
//   lg: '26px',
//   xl: '28px',
// },
// borderRadii: {
//   sm: '2px',
//   md: '5px',
// },
// colors: {
//   base: 'light',
//   background: 'white',
//   foreground: '#181B23',
//   link: 'mint',
//   border: '#edeff2',
//   darkRed: '#B22E3B',
// },
