import { combineReducers } from 'redux';
import currentUserReducer from './actions-reducers/user';
import settingsReducer from './actions-reducers/settings.ts';
import projectReducer from './actions-reducers/project.ts';
import projectResultReducer from './actions-reducers/projectResult.ts';
import printReducer from './actions-reducers/print.ts';
import paramsReducer from './actions-reducers/params.ts';
import lookupsReducer from './actions-reducers/lookups.ts';
import toastReducer from './actions-reducers/toast.ts';

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  settings: settingsReducer,
  project: projectReducer,
  projectResult: projectResultReducer,
  print: printReducer,
  params: paramsReducer,
  lookups: lookupsReducer,
  toast: toastReducer,
});

export default rootReducer;
