import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiService } from '../../services/ApiService';
import { apiEndPoints } from '../../api';

export const getProjetcDetails = createAsyncThunk(
  'project/getDetails',
  async (projectId: number) => {
    const { data } = await apiService
      .api()
      .get(apiEndPoints.projectData.getProjectData + projectId)
      .then(({ data }) => data);

    return data;
  }
);

const projectSlice = createSlice({
  name: 'project',
  initialState: { id: '', isloader: false },
  reducers: {
    setProject: (projectState, { payload }) => {
      Object.assign(projectState, payload);
    },
    resetProject: () => ({ id: '', isloader: false }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProjetcDetails.pending, (state) => {
        state.isloader = true;
      })
      .addCase(getProjetcDetails.fulfilled, (state, { payload }) => {
        state.isloader = false;

        Object.assign(state, payload);
      })
      .addCase(getProjetcDetails.rejected, (state, { payload }) => {
        console.log(state, payload);
      });
  },
});

export const { setProject, resetProject } = projectSlice.actions;

export default projectSlice.reducer;
