import { SVGProps } from 'react';

const TrueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 1 18 10`}
    // stroke={null}
    {...props}
  >
    <path
      d="M6.058 11.687a1.994 1.994 0 0 1-1.414-.585L.915 7.374l.943-.943 3.728 3.728a.667.667 0 0 0 .943 0L15.858.831l.942.942-9.328 9.329a1.994 1.994 0 0 1-1.414.585Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);

export default TrueIcon;
