import { useNavigate } from 'react-router-dom';

function useCustomNavigate() {
	const navigate = useNavigate();

	const redirectToLogin = () => {
		navigate('/login');
	};

	// Add more custom navigation methods if needed

	return { navigate, redirectToLogin };
}

export default useCustomNavigate;
