import { useEffect, useState } from 'react';
import { Box, Chip, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddIcon from '../SvgIcon/AddIcon';

const ETTabs = ({
  tabs = [],
  getActiveTab,
  getSubTabSelected,
  withSubTabs = false,
  withAddTab = false,
  activeTab,
  aside,
  onAddTabClick,
}: {
  tabs: { name: string; id: string | number }[];
  getActiveTab?: (id: string | number) => void;
  getSubTabSelected?: (id: string | number) => void;
  withSubTabs?: boolean;
  withAddTab?: boolean;
  activeTab?: string | number;
  aside?: any;
  onAddTabClick?: any;
}) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string | number>(
    activeTab ?? '1'
  );

  const [selectedSubTab, setSelectedSubTab] = useState<string>('grouped');
  const isGrouped = selectedSubTab === 'grouped';
  const isStacked = selectedSubTab === 'stacked';

  useEffect(() => {
    if (typeof activeTab === 'number')
      setSelectedTab(activeTab as number);

    if (typeof activeTab === 'string')
      setSelectedTab(activeTab as string);

    if (activeTab === -1)
      setSelectedTab(activeTab.toString());


  }, [activeTab]);
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      // m={'25px'}
      flexWrap={'wrap'}
      gap={'20px'}
      className="tabs"
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        flexWrap={'wrap'}
        gap={'12px'}
        className={'load-flow__charts__taps'}
      >
        {tabs.map(({ name, id }) => {
          return (
            <Box
              key={name+id}
              onClick={() => {
                setSelectedTab(id);
                getActiveTab && getActiveTab(id);
              }}
              className={selectedTab === id ? 'active-tab' : ''}
            >
              {t(name)}
            </Box>
          );
        })}
        {withAddTab && (
          <Box
            onClick={() => {
              setSelectedTab(0);
              getActiveTab && getActiveTab(0);
              onAddTabClick && onAddTabClick();
            }}
            // className={selectedTab === 0 ? 'active-tab' : ''}
            className="add-tab"
          >
            <AddIcon />
          </Box>
        )}
      </Stack>

      {withSubTabs && (
        <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
          <Chip
            label={t('Grouped_bar_chart')}
            variant={isGrouped ? 'filled' : 'outlined'}
            color={isGrouped ? 'primary' : 'default'}
            onClick={() => {
              setSelectedSubTab('grouped');
              getSubTabSelected && getSubTabSelected('grouped');
            }}
          />
          <Chip
            label={t('Stacked_bar_chart')}
            variant={isStacked ? 'filled' : 'outlined'}
            color={isStacked ? 'primary' : 'default'}
            onClick={() => {
              setSelectedSubTab('stacked');
              getSubTabSelected && getSubTabSelected('stacked');
            }}
          />
        </Stack>
      )}
      {aside && aside}
    </Stack>
  );
};

export default ETTabs;
