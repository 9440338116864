import React from 'react';
import TrueIcon from '../SvgIcon/TrueIcone';
import { customTheme } from '../../utils/style';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router-dom';

type StepperContainerProps = {
  labelsList: any; // Replace 'any' with the appropriate type
  children: React.ReactNode; // Replace 'React.ReactNode' with the appropriate type
};

const StepperContainer: React.FC<StepperContainerProps> = ({
  labelsList,
  children,
}) => (
  <div
    style={{
      padding: '2rem 6rem 3.25rem',
      backgroundColor: 'var(--background-color-accordion)',
      borderRadius: '15px',
      ...(labelsList === 2 && {
        width: '600px',
        margin: 'auto',
      }),
    }}
  >
    {children}
  </div>
);

type StepperWrapperProps = {
  children: React.ReactNode; // Replace 'React.ReactNode' with the appropriate type
};

const StepperWrapper: React.FC<StepperWrapperProps> = ({ children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </div>
);

type StepWrapperProps = {
  activeStep: boolean;
  children: React.ReactNode; // Replace 'React.ReactNode' with the appropriate type
  onClick?: () => void;
};

const StepWrapper: React.FC<StepWrapperProps> = ({
  activeStep,
  children,
  onClick,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',

      border: activeStep ? '2px solid var(--background-color-primary)' : 'none',
      borderRadius: '50%',
      padding: '1px',
    }}
    onClick={onClick}
  >
    {children}
  </div>
);

type StepNumberProps = {
  activeStep?: any; // Replace 'any' with the appropriate type
  completeStep: any; // Replace 'any' with the appropriate type
  isCursorPointer: any; // Replace 'any' with the appropriate type
  children: any; // Replace 'any' with the appropriate type
};

const StepNumber: React.FC<StepNumberProps> = ({
  activeStep,
  completeStep,
  isCursorPointer,
  children,
}) => (
  <div
    style={{
      width: '2rem',
      height: '2rem',
      borderRadius: '50%',
      backgroundColor: activeStep
        ? 'var(--background-color-primary)'
        : completeStep
        ? 'var(--background-color-primary)'
        : 'var(--upload-file-bg)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: completeStep ? customTheme.palette.main : '#ffffff',
      fontSize: '0.875rem',
      ...(activeStep && {
        border: `0.0625rem solid var(--background-color-primary)`,
      }),
      fontWeight: 500,
      cursor: isCursorPointer ? 'pointer' : 'initial',
    }}
  >
    {children}
  </div>
);

type StepLabelProps = {
  activeStep: boolean;
  children: React.ReactNode; // Replace 'React.ReactNode' with the appropriate type
};

const StepLabel: React.FC<StepLabelProps> = ({ activeStep, children }) => (
  <div
    style={{
      position: 'absolute',
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: '0.875rem',
      minWidth: 'max-content',
      fontWeight: activeStep ? '500' : '400',
      marginTop: '0.5rem',
      color: customTheme.palette.main,
      textTransform: 'capitalize',
    }}
  >
    {children}
  </div>
);

type StepSeparatorProps = {
  completeStep: any; // Replace 'any' with the appropriate type
};

const StepSeparator: React.FC<StepSeparatorProps> = ({ completeStep }) => (
  <div
    style={{
      flexGrow: 1,
      height: '0.0625rem',
      backgroundColor: completeStep ? customTheme.palette.main : '#cccccc',
      margin: '0 0.25rem',
    }}
  />
);

type ETStepperProps = {
  activeStep: number;
  setActiveStep?: (step: number) => void;
  stepValidTill?: number;
  labelsList: string[];
  readOnly?: boolean;
};

const ETStepper: React.FC<ETStepperProps> = ({
  activeStep,
  setActiveStep,
  stepValidTill = 0,
  labelsList = [],
  readOnly = false,
}) => {
  const { t } = useTranslation();

  //   const history = useHistory();
  return (
    <StepperContainer labelsList={labelsList.length}>
      <StepperWrapper>
        {labelsList.map((label, index) => {
          const isCursorPointer = index <= stepValidTill && !readOnly; // && index !== activeStep
          return (
            <React.Fragment key={label}>
              {index > 0 && (
                <StepSeparator completeStep={index <= activeStep} />
              )}
              <StepWrapper
                activeStep={activeStep === index}
                onClick={() => {
                  if (index <= stepValidTill && !readOnly) {
                    setActiveStep && setActiveStep(index);
                    // history.push({ hash: `${index + 1}` });
                  }
                }}
              >
                {index < activeStep || index < stepValidTill ? (
                  <StepNumber isCursorPointer={isCursorPointer} completeStep>
                    <TrueIcon fill="#fff" />
                  </StepNumber>
                ) : (
                  <StepNumber
                    isCursorPointer={isCursorPointer}
                    activeStep={activeStep === index}
                    completeStep={false}
                  >
                    {index + 1}
                  </StepNumber>
                )}

                <StepLabel activeStep={activeStep === index}>
                  {t(label)}
                </StepLabel>
              </StepWrapper>
            </React.Fragment>
          );
        })}
      </StepperWrapper>
    </StepperContainer>
  );
};

export default ETStepper;
