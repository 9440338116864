export const ManufacturerOptions = [
  {
    label: 'Huawei',
    value: 'Huawei',
  },
  {
    label: 'Varta',
    value: 'Varta',
  },
  {
    label: 'BYD',
    value: 'BYD',
  },
];

export const ModelOptions = [
  {
    label: 'LUNA2000-5-S0',
    value: 'LUNA2000-5-S0',
  },
  {
    label: 'Varta Wall',
    value: 'Varta Wall',
  },
  {
    label: 'BYD Box',
    value: 'BYD Box',
  },
];

export const TypeOfBatteryOptions = [
  {
    label: 'LFP',
    value: 'LFP',
  },
  {
    label: 'NCA',
    value: 'NCA',
  },
  {
    label: 'LFP',
    value: 'LFP',
  },
];