import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiService } from '../../services/ApiService';

interface LookupState {
  [key: string]:
    | string
    | Record<string, string>[]
    | boolean
    | Record<string, boolean>;
  loading: Record<string, boolean>;
}

const initialState: LookupState = {
  isLoader: false,
  loading: {},
};

/**
 * Fetches a lookup data from the specified endpoint.
 * @param key - The key associated with the lookup data.
 * @param endpoint - The endpoint to fetch the lookup data from.
 * @returns A promise that resolves to an object containing the key and the fetched data.
 */
export const fetchLookup: any = createAsyncThunk(
  'lookups/fetchLookup',
  async ({ key, endpoint }: { key: string; endpoint: string }) => {
    const { data } = await apiService
      .api()
      .get(endpoint)
      .then(({ data }) => data);
    return { key, data };
  }
);

const lookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {
    setLookup(
      state,
      { payload }: PayloadAction<{ key: string; value: string }>
    ) {
      const { key, value } = payload;
      state[key] = value;
    },
    removeLookup(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchLookup.pending, (state, action) => {
        state.loading[action.meta.arg.key] = true;
      })
      .addCase(fetchLookup.fulfilled, (state, { payload }) => {
        state.loading[payload.key] = false;
        state[payload.key] = payload.data;
      })
      .addCase(fetchLookup.rejected, (state, action) => {
        state.loading[action.meta.arg.key] = false;
        console.error(
          `Lookup API ${action.meta.arg.key} failed:`,
          action.error
        );
      });
  },
});

export const { setLookup, removeLookup } = lookupsSlice.actions;

export default lookupsSlice.reducer;
