import { NumericFormat } from 'react-number-format';

export const formattedNumber = (value: number | string | null) => {
  return (
    <>
      <NumericFormat
        value={value ? (+value).toFixed(0) : 0}
        thousandSeparator=","
        decimalSeparator="."
        displayType="text"
      />
    </>
  );
};

// this function is used to format the number with thousand separator and decimal separator ##(TEMP NOT USED YET)##
export const formatNumber = (
  value: number | string,
  thousandSeparator = '.',
  decimalSeparator = ','
) => {
  if (typeof value === 'number') {
    value = value.toString();
  }

  const parts = value.split('.');
  let integerPart = parts[0];
  const decimalPart = parts.length > 1 ? parts[1] : '';

  // Format the integer part with the thousand separator
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

  // Combine the integer and decimal parts with the decimal separator
  return decimalPart
    ? integerPart + decimalSeparator + decimalPart
    : integerPart;
};

// Usage examples
// console.log(formatNumber(1234567.89)); // Output: "1.234.567,89"
// console.log(formatNumber(1234567.89, ',', '.')); // Output: "1,234,567.89"
// console.log(formatNumber('1234567.89', ' ', ',')); // Output: "1 234 567,89"

// this function is used to format the number with K and M suffixes
export const thousandMillionNumberFormatter = (
  value: number | string,
  toFixedCount = 2
) => {
  const numValue = +value;
  const isNegative = numValue < 0;
  const absoluteValue = Math.abs(numValue);

  let formattedValue;

  if (absoluteValue >= 1000000) {
    formattedValue = `${(absoluteValue / 1000000).toFixed(toFixedCount)}M`;
  } else if (absoluteValue >= 1000) {
    formattedValue = `${(absoluteValue / 1000).toFixed(toFixedCount)}K`;
  } else {
    formattedValue = `${absoluteValue.toFixed(toFixedCount)}`;
  }

  return isNegative ? `-${formattedValue}` : formattedValue;
};
