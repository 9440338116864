import { Box, Typography } from '@mui/material';
import { AccruedCashFlowDataTypes, DataKeysType } from '../ResultTypes';
import PrintButton from '../PrintButton';
import { useRef } from 'react';
import ApexBarChart from '../../../blocks/charts/BarChart2/ApexBarChart';
import { useSelector } from 'react-redux';

const AccruedCashFlow = ({ data }: { data: AccruedCashFlowDataTypes }) => {
  const ref = useRef<HTMLElement | null>(null);
  const { project } = useSelector(
    ({ project }: { project: Record<string, any> }) => ({
      project,
    })
  );
  return (
    <>
      <PrintButton
        id={'LOCE_SYSTEM_UTILIZATION_COSTS'}
        componentName="Financial Analysis"
        element={ref}
      />
      <Box m={'25px'} p={'25px'} className="results__info" ref={ref}>
        <Typography variant={'h5'} mb={5} fontWeight={'bold'}>
          Accrued Cash Flow
        </Typography>
        <ApexBarChart
          data={data?.data || []}
          title={'Cash Flow Balance'}
          height={400}
          withLegend={false}
          withTabs={false}
          dataKeys={data?.dataKeys as DataKeysType}
          yAxisLabel={project?.currency}
        />
      </Box>
    </>
  );
};

export default AccruedCashFlow;
