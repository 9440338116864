import { Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';

const ConditionalLinkWrapper = ({
  link,
  children,
  className = '',
}: {
  link?: string;
  children: ReactNode;
  className?: any;
}) => {
  return link ? (
    <Link to={link} className={className}>
      {children}
    </Link>
  ) : (
    <Fragment>{children}</Fragment>
  );
};

export default ConditionalLinkWrapper;
