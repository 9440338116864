import { FC } from 'react';
import './_EmptyChart.scss';

interface EmptyChartProps {
  height: string;
}

const EmptyChart: FC<EmptyChartProps> = ({ height }) => {
  const leftBarItems: string[] = ['6k', '5k', '4k', '3k', '2k', '1k', '0'];
  const months: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return (
    <div className="empty-chart" style={{ width: '100%', height }}>
      {/* the following code handles the horizontal scrollbar */}
      {/* <div className="timeline-range"></div> */}
      <div className="empty-chart-content">
        <div className="left-bar">
          {leftBarItems.map((item) => (
            <div className="left-bar-item" key={Math.random()}>{item}</div>
          ))}
        </div>
        <div className="empty-chart-data">
          {Array(6)
            .fill('')
            .map(() => (
              <div className="empty-content-line" key={Math.random()}/>
            ))}
        </div>
      </div>

      <div className="months-calendar">
        {months.map((month) => (
          <div className="month" key={month}>{month.toUpperCase()}</div>
        ))}
      </div>
    </div>
  );
};

export default EmptyChart;
