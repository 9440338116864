import { formattedNumber } from '../../../../../utils';
import { COLORS } from '../../../EnergyLayout/components/LoadFlow/utils';
import { Months } from '../../OverviewResult/helper';
import { SimulationData } from '../../ResultTypes';

export const getSimulationData = (simulationData: SimulationData) => {
  const {
    pvSystemRatedPower = null,
    specificAnnualYield = null,
    performanceRatio = null,

    pvGeneratedEnergy = null,
    directlyConsumed = null,
    feedIntoTheGrid = null,

    curtailedGeneration = null,
    directPowerConsumption = null,
    pvCO2EmissionAvoided = null,

    totalLoad = null,
    coveredByPv = null,
    coveredByGrid = null,
    loadSelfSufficiency = null,
  } = simulationData;

  const PvPlant = {
    title: 'PV-Plant',
    items: [
      {
        name: 'PV System rated power',
        value: formattedNumber(pvSystemRatedPower),
        unit: 'kWp',
      },
      {
        name: 'Specific Annual Yield',
        value: formattedNumber(specificAnnualYield),
        unit: 'kWh/kWp',
      },
      {
        name: 'Performance Ratio (PR)',
        value: formattedNumber(performanceRatio),
        unit: '%',
      },
      {
        name: 'PV generated energy',
        value: formattedNumber(pvGeneratedEnergy),
        unit: 'kWh/a',
      },
      {
        name: 'Directly consumed',
        value: formattedNumber(directlyConsumed),
        unit: 'kWh/a',
        isSubItem: true,
      },
      {
        name: 'Feed into the grid',
        value: formattedNumber(feedIntoTheGrid),
        unit: 'kWh/a',
        isSubItem: true,
      },
      {
        name: 'Curtailed Generation',
        value: formattedNumber(curtailedGeneration),
        unit: 'kWh/a',
        isSubItem: true,
      },
      {
        name: 'Direct power consumption',
        value: formattedNumber(directPowerConsumption),
        unit: '%',
      },
      {
        name: 'CO2 emissions avoided',
        value: formattedNumber(pvCO2EmissionAvoided),
        unit: 'Kg/year',
      },
    ],
    chartData: [
      {
        name: 'Own Consputinon',
        value: directlyConsumed,
        fill: COLORS[3],
      },
      {
        name: 'Down-regulation at feed in',
        value: curtailedGeneration,
        fill: COLORS[4],
      },
      {
        name: 'Grid feed in',
        value: feedIntoTheGrid,
        fill: COLORS[2],
      },
    ],
  };
  const Consumption = {
    title: 'Consumption',
    items: [
      {
        name: 'Total load',
        value: formattedNumber(totalLoad),
        unit: 'kWh/a',
      },
      {
        name: 'Covered by PV',
        value: formattedNumber(coveredByPv),
        unit: 'kWh/a',
      },
      {
        name: 'Covered by grid',
        value: formattedNumber(coveredByGrid),
        unit: 'kWh/a',
      },
      {
        name: 'Level of self-sufficiency',
        value: loadSelfSufficiency,
        unit: '%',
      },
    ],
    chartData: [
      {
        name: 'Covered by PV',
        value: coveredByPv,
        fill: COLORS[3],
      },
      {
        name: 'Covered by grid',
        value: coveredByGrid,
        fill: COLORS[1],
      },
    ],
  };
  return [PvPlant, Consumption];
};
export const getEnergyForcastWithConsmptionData = (
  simulationData: SimulationData
) => {
  const {
    numberOfLoad = null,
    totalConsumptions = null,
    maximumTotalPeak = null,
    pvGeneratedEnergyMonthly = null,
    totalGrid = null,
    loadProfileData = [],

    consumer = null,
    gridFeedIn = null,
    curtailmentEnergy = null,
  } = simulationData;

  const electricLoad = {
    title: 'PV-Plant',
    items: [
      {
        name: 'Number of profiles',
        value: formattedNumber(numberOfLoad),
        unit: ' ',
      },
      {
        name: 'Total consumptions',
        value: formattedNumber(totalConsumptions),
        unit: 'kWh',
      },
      ...Object.keys(loadProfileData).map((key) => ({
        name: key,
        value: formattedNumber(
          loadProfileData[key as keyof typeof loadProfileData] as string
        ),
        unit: 'kWh',
      })),
      {
        name: 'Maximum total peak',
        value: formattedNumber(maximumTotalPeak),
        unit: 'kW',
      },
    ],
  };

  // Energy Forcast With Consumption chart data
  const EnergyForcastWithConsumptionData = Months.map((name, index) => ({
    name: name,
    pvGeneratedEnergyMonthly: pvGeneratedEnergyMonthly?.[index + 1],
    totalGrid: totalGrid?.[index + 1],
    consumer: consumer?.[index + 1],
    gridFeedIn: gridFeedIn?.[index + 1],
    curtailmentEnergy: curtailmentEnergy?.[index + 1],
  }));

  const energyForcastWithConsumptionWithDataKeys = {
    dataKeys: [
      {
        label: 'PV Generated Energy',
        key: 'pvGeneratedEnergyMonthly',
        color: '#ffa500',
      },
      {
        label: 'Grid',
        key: 'totalGrid',
        color: '#d37787',
      },
      {
        label: 'Consumer',
        key: 'consumer',
        color: '#808080',
      },
      {
        label: 'Grid Feed-in',
        key: 'gridFeedIn',
        color: '#008000',
      },
      {
        label: 'Curtailment Energy',
        key: 'curtailmentEnergy',
        color: '#760808',
      },
    ],
    data: EnergyForcastWithConsumptionData,
    unit: 'Energy [kW]',
  };

  return { electricLoad, energyForcastWithConsumptionWithDataKeys };
};

export const getUseOfPvEnergyData = (simulationData: SimulationData) => {
  const {
    pvGeneratedEnergyMonthly = null,
    directlyConsumedMonthly = null,
    gridFeedIn = null,
    curtailmentEnergy = null,
  } = simulationData;

  // Use Of Pv Energy  chart data
  const UseOfPvEnergyData = Months.map((name, index) => ({
    name: name,
    pvGeneratedEnergyMonthly: pvGeneratedEnergyMonthly?.[index + 1],
    directlyConsumedMonthly: directlyConsumedMonthly?.[index + 1],
    gridFeedIn: gridFeedIn?.[index + 1],
    curtailmentEnergy: curtailmentEnergy?.[index + 1],
  }));

  const useOfPvEnergyWithDataKeys = {
    dataKeys: [
      {
        label: 'PV Generated Energy',
        key: 'pvGeneratedEnergyMonthly',
        color: '#ffa500',
      },
      {
        label: 'Directly Consumed ',
        key: 'directlyConsumedMonthly',
        color: '#00275c',
      },
      {
        label: 'Grid Feed-in',
        key: 'gridFeedIn',
        color: '#008000',
      },
      {
        label: 'Curtailment Energy',
        key: 'curtailmentEnergy',
        color: '#760808',
      },
    ],
    data: UseOfPvEnergyData,
  };

  return useOfPvEnergyWithDataKeys;
};
export const getConverageOfConsumptionData = (
  simulationData: SimulationData
) => {
  const {
    consumer = null,
    coveredByPvMonthly = null,
    coveredByGridMonthly = null,
  } = simulationData;

  // converage Of Consumption Data
  const converageOfConsumptionData = Months.map((name, index) => ({
    name: name,
    consumer: consumer?.[index + 1],
    coveredByPvMonthly: coveredByPvMonthly?.[index + 1],
    coveredByGridMonthly: coveredByGridMonthly?.[index + 1],
  }));

  const converageOfConsumptionWithDataKeys = {
    dataKeys: [
      {
        label: 'Consumer',
        key: 'consumer',
        color: '#808080',
      },
      {
        label: 'Covered by PV',
        key: 'coveredByPvMonthly',
        color: '#ffa500',
      },
      {
        label: 'Covered by Grid',
        key: 'coveredByGridMonthly',
        color: '#760808',
      },
    ],
    data: converageOfConsumptionData,
  };

  return converageOfConsumptionWithDataKeys;
};
export const getMonthlyPeacksData = (simulationData: SimulationData) => {
  const { gridAlone = null, gridAndPV = null } = simulationData;

  // converage Of Consumption Data
  const monthlyPeacksData = Months.map((name, index) => ({
    name,
    gridAlone: gridAlone?.[index + 1],
    gridAndPV: gridAndPV?.[index + 1],
  }));

  const monthlyPeacksWithDataKeys = {
    dataKeys: [
      {
        label: 'Grid Alone',
        key: 'gridAlone',
        color: '#760808',
      },
      {
        label: 'Grid And PV',
        key: 'gridAndPV',
        color: '#ffa500',
      },
    ],
    data: monthlyPeacksData,
  };

  return monthlyPeacksWithDataKeys;
};
// TODO: need to refactoring data before sending to the component
export const getDigramEditorData = (simulationData: SimulationData) => {
  const {
    gridTimeSeriesElectricLoadData = [],
    pvTimeSeriesElectricLoadData = [],
    loadTimeSeriesElectricLoadData = [],
  } = simulationData;

  const digramEditorData = [
    {
      name: 'Grid',
      data: gridTimeSeriesElectricLoadData,
    },
    {
      name: 'PV',
      data: pvTimeSeriesElectricLoadData,
    },
    {
      name: 'Load',
      data: loadTimeSeriesElectricLoadData,
    },
  ];

  return digramEditorData;
};
export const getEnergyFlowDigramData = (simulationData: SimulationData) => {
  const {
    feedIntoTheGrid = null,
    curtailedGeneration = null,
    pvGeneratedEnergy = null,
    coveredByPv = null,
    totalLoad = null,
    coveredByGrid = null,
  } = simulationData;

  return {
    feedIntoTheGrid: formattedNumber(feedIntoTheGrid),
    curtailedGeneration: formattedNumber(curtailedGeneration),
    pvGeneratedEnergy: formattedNumber(pvGeneratedEnergy),
    coveredByPv: formattedNumber(coveredByPv),
    totalLoad: formattedNumber(totalLoad),
    coveredByGrid: formattedNumber(coveredByGrid),
  };
};
