import { Box, Button, CircularProgress } from '@mui/material';
type ETButtonPropsType = {
  color?:
    | 'primary'
    | 'inherit'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  isLoading: boolean;
  disabled?: boolean;
  label: string;
  className?: string;
  variant?: 'text' | 'contained' | 'outlined';
  [key: string]: any; // Rest parameter for additional props
};
const ETButton = ({
  isLoading,
  label,
  variant,
  color,
  disabled,
  className = '',
  ...props
}: ETButtonPropsType) => {
  return (
    <Box
      {...props}
      className={`r-8 ${className}`}
      whiteSpace={'nowrap'}
      component={Button}
      variant={variant}
      color={color}
      disabled={isLoading || disabled}
      endIcon={isLoading && <CircularProgress color="inherit" size="1.3rem" />}
    >
      {label}
    </Box>
  );
};

export default ETButton;
