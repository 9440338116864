import { Box, Grid, Typography } from '@mui/material';
import {
  DataKeysType,
  LoceAndSystemUrilitizationCostPropsType,
} from '../ResultTypes';
import PrintButton from '../PrintButton';
import { useRef } from 'react';
import ApexBarChart from '../../../blocks/charts/BarChart2/ApexBarChart';
import { useSelector } from 'react-redux';

const LoceAndSystemUtilitizationCost = ({
  data,
}: LoceAndSystemUrilitizationCostPropsType) => {
  const { costOfElectricty, dataKeys, yearlySystemUtilizationCost } = data;
  const ref = useRef<HTMLElement | null>(null);

  const { project } = useSelector(
    ({ project }: { project: Record<string, any> }) => ({
      project,
    })
  );

  return (
    <>
      <PrintButton
        id={'LOCE_SYSTEM_UTILIZATION_COSTS'}
        componentName="Financial Analysis"
        element={ref}
      />
      <Box m={'25px'} p={'25px'} className="results__info" ref={ref}>
        <Typography variant={'h5'} mb={5} fontWeight={'bold'}>
          LOCE And System Utilitization Cost{' '}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ApexBarChart
              data={costOfElectricty || []}
              title={'Cost of Electricty'}
              height={400}
              withLegend={false}
              withTabs={false}
              dataKeys={dataKeys as DataKeysType}
              yAxisLabel={`${project?.currency}/kwh`}
              yAxisDigits={4}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ApexBarChart
              data={yearlySystemUtilizationCost || []}
              title={'Yearly System Utilization Cost'}
              height={400}
              withLegend={false}
              withTabs={false}
              dataKeys={dataKeys as DataKeysType}
              yAxisLabel={`${project?.currency}/year`}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LoceAndSystemUtilitizationCost;
