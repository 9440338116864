import { Box, Modal, Stack } from '@mui/material';

interface Props {
  open: boolean;
  onClose: any;
  title: string;
  desc?: string | null;
  children: any;
}

const Popup = (props: Props) => {
  const { open, onClose, title, desc, children } = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal">
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          className="modal__title"
        >
          {title}
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          className="modal__desc"
        >
          {desc}
        </Stack>
        <Box className="modal__body">{children}</Box>
      </Box>
    </Modal>
  );
};

export default Popup;
