import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@mui/material';
import { StorageformVals, EconomicformVals,doAllEcoFieldsHaveValue,
  doAllStrFeildseHaveValue, useWatchFormValues,
  GetStorageformVals,
  GetEconomicformVals,
  StorageTaps,
  EnergyLayoutModelType} from './helper';
import { apiEndPoints } from '../../../../../api';
import { scrollToTop } from '../../../../../utils/misc';
import { openToast } from '../../../../../redux/actions-reducers/toast';
import StorageEconomicsForm from './components/StorageEconomics';
import StorageSelectionForm from './components/StorageSelection';
import withProjectDetails from '../../../../HOC/withProjectDetails';
import RightArrowIcon from '../../../../SvgIcon/RightArrowIcon';
import useApi from '../../../../../hooks/useApi';
import GridIcon from '../../../../SvgIcon/LoadFlowIcons/GridIcon';
import ETHeader from '../../../../blocks/ETHeader';
import ETStepper from '../../../../blocks/ETStepper';
import LeftArrowIcon from '../../../../SvgIcon/LeftArrowIcon';
import ETButton from '../../../../blocks/ETblocks/ETBotton';
import ETTabs from '../../../../blocks/ETTabs';

const steps = ['storage.Storage_Selection', 'storage.Economics'];

const StorageFlow = () => {
  const { t } = useTranslation();
  const { projectId, componentId, configrationId} = useParams();
  const { autoSize, selectStorageSystem, manuallyStorageSystem }
    = useWatchFormValues();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>(configrationId!);
  const [compId, setCompId] = useState<string | number | null>(componentId!);
  const [storageSystem, setStorageSystem] = useState<StorageTaps[]>([]);
  const [storageModels, setStorageModels] = useState<any>([]);
  const [skipped, setSkipped] = useState(new Set());
  const { watch, trigger, setValue, reset} = useFormContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    makeRequest: saveRequest,
    isLoading: isSaveRequestLoading,
    error: saveRequestError,
  } = useApi();

  const getSelectedItemsModel = (addNew = false) => {
    saveRequest(
      'get',
      apiEndPoints.energyLayout.getSelectedItemsModel + projectId
    ).then(({ data }: any) => {
      if (data) {
        const storageSystemModels = data
          .filter((item: any) => item.componentModel.name === 'Storege');
        const StorageProfiles = storageSystemModels.map((item: EnergyLayoutModelType,  index: number) => {
          return {
            name: item.isConfigured
              ? item.configuration.storageName
              : `Storage System ${index + 1}`,
            id: item.configuration.id,
            componentId: item.id
          };
        });

        setStorageModels(storageSystemModels);
        setStorageSystem(StorageProfiles);
        if(addNew){
          const newStorage = StorageProfiles[StorageProfiles.length - 1];
          setSelectedTab(newStorage.id);
          setCompId(newStorage.componentId)
        }
      }
    });
  };
  const addNewModelItem = () => {
    saveRequest('post', apiEndPoints.energyLayout.addModelItem + projectId, {
      componentModelId: 5,
    }).then(() => {
      getSelectedItemsModel(true);
    });
  };

  const isStepSkipped = (step: any) => {
    return skipped.has(step);
  };
  const storageConfigured = storageModels?.find((storage: EnergyLayoutModelType) => +storage.configuration.id === +selectedTab)?.isConfigured;

  useEffect(() => {
    const selectedStorage = storageSystem?.find((storage: StorageTaps) => +storage.id == +selectedTab);

    selectedStorage && setCompId(selectedStorage!.componentId)
    selectedStorage && navigate(
      `/energy-layout/${projectId}/storage/${selectedStorage!.componentId}/${selectedTab}`
    );
    if (!storageConfigured) {
      reset();
      setValue('profileName', 'Storage System');
      return;
    }

    HandleStorageSystemVals(+selectedTab);

  }, [selectedTab]);

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep == 0  && (doAllStrFeildseHaveValue(StorageformVals(watch, selectedOption)))){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    else
      trigger();
  };

  const handleSubmit = async () => {
    const message = 'submitted successfully';
    const storageName = "jjjj"
    const data = {
      storageName,
      autoSize,
      selectStorageSystem,
      manuallyStorageSystem,
      ...StorageformVals(watch, selectedOption),
      economics: {
        ...EconomicformVals(watch)
      }
    };
    const storageId = storageModels?.find(
      (storage: EnergyLayoutModelType) => +storage.configuration.id === +selectedTab
    )?.configuration.id;

    trigger();

    if (activeStep == 1 && doAllEcoFieldsHaveValue(EconomicformVals(watch)))
      return await saveRequest(
        'post',
        storageConfigured ? `${apiEndPoints.storage.save}${componentId}/storage/${configrationId}`
        : `${apiEndPoints.storage.save}${compId}/storage/${storageId}`,
        data
        ).then(({ data }: any) => {
          if (data) {
            navigate(`/energy-layout/${projectId}`);
            dispatch(openToast({ message, mode: 'success' }));
          }
      }).finally(() => {
        getSelectedItemsModel();
      });
  }

  const HandleStorageSystemVals = (storageId: number) => {
    saveRequest('get', `${apiEndPoints.storage.get}/${storageId}`)
    .then(response => {
      if (response && response.data) {
        const data = response.data;

        setValue('storageName', data.storageName);
        GetStorageformVals(data, setValue);
        GetEconomicformVals(data, setValue);

        if (data.autoSize) {
          setSelectedOption('autoSize');
        } else if (data.selectStorageSystem) {
          setSelectedOption('selectStorageSystem');
        } else if (data.manuallyStorageSystem) {
          setSelectedOption('manuallyStorageSystem');
        }
      }
    });
  }
  useEffect(() => {
    getSelectedItemsModel();
    HandleStorageSystemVals(Number(configrationId));

  }, []);

  useEffect(() => {
    if (autoSize) {
      setSelectedOption('autoSize');
    } else if (selectStorageSystem) {
      setSelectedOption('selectStorageSystem');
    } else if (manuallyStorageSystem) {
      setSelectedOption('manuallyStorageSystem');
    }
  }, [autoSize, selectStorageSystem, manuallyStorageSystem]);

  useEffect(() => {
    if (saveRequestError) {
      dispatch(
        openToast({
          message: saveRequestError,
          mode: 'error',
        })
      );
    }
  }, [saveRequestError]);

  useEffect(() => {
    scrollToTop();
  }, [activeStep]);

  return (
    <Box style={{ transition: 'ease-in-out 0.3s' }}>
      <Box mb={'2rem'}>
        {/* active page title */}
        <ETHeader
          title={t('storage.energy_storage_system_setting')}
          icon={<GridIcon />}
        />

        <Box mb={'3rem'}>
          <Stack
            className={'load-tabs'}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <ETTabs
              tabs={storageSystem}
              getActiveTab={(data: string | number) => {
                setSelectedTab(data as string);
              }}
              withAddTab={storageSystem.length < 3}
              onAddTabClick={addNewModelItem}
              activeTab={Number(selectedTab)}
            />
          </Stack>
        </Box>
        {/* Storage tabs */}
        <ETStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          labelsList={steps}
        />

        <br />
        {/* Tabs contents */}
        <Box minHeight={'550px'}>
          {activeStep === 0 && <StorageSelectionForm />}
          {activeStep === 1 && <StorageEconomicsForm />}
        </Box>

        {/* action bar buttons */}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          {activeStep > 0 && (
            <Box mt={6} mb={5}>
              <Button
                variant={'outlined'}
                color={'inherit'}
                startIcon={<LeftArrowIcon />}
                className={'submit-button'}
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {t('Back')}{' '}
              </Button>
            </Box>
          )}
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'end'}
            gap={'24px'}
            mt={6}
            mb={5}
            className={'action-bar'}
            width={'100%'}
          >
            {activeStep !== steps.length - 1 ? (
              <ETButton
                variant={'contained'}
                color={'primary'}
                endIcon={<RightArrowIcon />}
                className={'submit-button'}
                onClick={handleNext}
                isLoading={isSaveRequestLoading}
                label={t('save_and_continue')}
              />
            ) :
              <ETButton
                variant={'contained'}
                color={'primary'}
                endIcon={<RightArrowIcon />}
                className={'submit-button'}
                onClick={handleSubmit}
                isLoading={isSaveRequestLoading}
                label={t('save_and_continue')}
              />}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default withProjectDetails({
  WrappedComponent: StorageFlow,
  className: 'storage-flow full-height',
  withForm: true,
});
