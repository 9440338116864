import { ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const ETForm = ({
  children,
  defaultValues,
  onSubmit,
  onSubmitError,
}: {
  children: ReactNode;
  defaultValues?: Record<string, any>;
  onSubmit?: any;
  onSubmitError?: () => void;
}) => {
  const methods = useForm({
    defaultValues: defaultValues,
    mode: 'all',
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={(e) => methods.handleSubmit(onSubmit, onSubmitError)(e)}>
        {children}
      </form>
    </FormProvider>
  );
};

export default ETForm;
