import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomDivider from '../../../blocks/CustomDivider';
import ApexBarChart from '../../../blocks/charts/BarChart2/ApexBarChart';
import { DataKeysType } from '../ResultTypes';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getEnergyPriceAnalysisData } from './helper';
import RowspanTable from '../../../blocks/rowspanTable';
import TimeSeriesTestChart from '../../../blocks/charts/POC/TimeSeriesTestChart';

type energyPricesStatisticsType = {
  title: string;
  items: {
    name: string;
    value: JSX.Element;
    unit: string;
  }[];
};

const EnergyPricesAnalysisResult = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(null);
  const { energyPricesAnalysisData } = useSelector(
    ({ projectResult }: any) => projectResult
  );
  const { project } = useSelector(
    ({ project }: { project: Record<string, any> }) => ({
      project,
    })
  );
  useEffect(() => {
    const refactoredData = getEnergyPriceAnalysisData(
      energyPricesAnalysisData,
      project?.currency
    );
    setData(refactoredData);
  }, [energyPricesAnalysisData]);

  return (
    <Box m={'25px'} p={'25px'} className="results__info">
      <Typography variant={'h5'} mb={5} fontWeight={'bold'}>
        Energy Prices Analysis
      </Typography>
      <RowspanTable
        data={data?.energyPricesAnalysisTable?.data as []}
        columns={data?.energyPricesAnalysisTable?.columns}
        isUnitFirst={false}
      />

      {data?.energyPricesStatistics?.map(
        ({ title, items }: energyPricesStatisticsType) => (
          <Box py={6} key={title}>
            <Typography variant={'h6'}>{title}</Typography>
            {items?.map(({ name, value }) => (
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                key={Math.random()}
                mb={0.5}
                maxWidth={'98%'}
              >
                <Typography className="label">{t(name)}: </Typography>
                <Typography>{value}</Typography>
              </Stack>
            ))}
          </Box>
        )
      )}
      <CustomDivider bg={'var(--text-color)'} mb={3} />
      <ApexBarChart
        data={data?.hourlyPatternWithDataKeys.data ?? []}
        title={'Hourly Pattern'}
        height={400}
        withTabs={false}
        withLegend={false}
        barSize={20}
        dataKeys={
          (data?.hourlyPatternWithDataKeys?.dataKeys as DataKeysType) ?? []
        }
      />
      <CustomDivider bg={'var(--text-color)'} mb={3} />
      <ApexBarChart
        data={data?.monthlyTrendsWithDataKeys.data ?? []}
        title={'Monthly Trends'}
        height={400}
        withTabs={false}
        withLegend={false}
        barSize={20}
        dataKeys={
          (data?.monthlyTrendsWithDataKeys?.dataKeys as DataKeysType) ?? []
        }
      />
      {data?.energyCostData.lenght > 0 && (
        <CustomDivider bg={'var(--text-color)'} mb={6} />
      )}
      <TimeSeriesTestChart
        seriesData={(data?.energyCostData as any) || []}
        height={500}
        title="Price Curve"
      />

      <CustomDivider bg={'var(--text-color)'} mb={6} mt={5} />
      <TimeSeriesTestChart
        seriesData={(data?.priceData as any) || []}
        height={500}
        title="Energy Cost"
      />
    </Box>
  );
};

export default EnergyPricesAnalysisResult;
