import { FieldValues, UseFormWatch, UseFormSetValue } from "react-hook-form";
import { useFormContext } from 'react-hook-form';
import { ModelComponentType } from '../..';

export const useWatchFormValues = () => {
  const { watch } = useFormContext();

  const autoSize = watch('autoSize', true);
  const selectStorageSystem = watch('selectStorageSystem', false);
  const manuallyStorageSystem = watch('manuallyStorageSystem', false);

  return { autoSize, selectStorageSystem, manuallyStorageSystem };
};

export const StorageformVals = (watch: UseFormWatch<FieldValues>,
    selectedOption: string) => {
  const [
    minBatteryCapacity,
    maxBatteryCapacity,
    minBatteryPower,
    maxBatteryPower,
    depthOfDischarge,
    maxNumberOfCycles,
    yearlyDepreciation,
    roundTripEfficiency,
    initialStateOfCharge,
    minStateOfCharge,
    maxStateOfCharge,
    manufacturer,
    model,
    typeOfBattery,
    totalSizeOfStorage,
    maxCRate,
    numberOfCyclesReached,
    cycles,
    throughputEnergyReached,
    throughputEnergy,
    bothValues,
    chargingPower,
    dischargingPower,
    chargingPowerEfficiency,
    dischargingPowerEfficiency,
    averageSelfConsumption,
  ] =
  watch([
    'minBatteryCapacity',
    'maxBatteryCapacity',
    'minBatteryPower',
    'maxBatteryPower',
    'depthOfDischarge',
    'maxNumberOfCycles',
    'yearlyDepreciation',
    'roundTripEfficiency',
    'initialStateOfCharge',
    'minStateOfCharge',
    'maxStateOfCharge',
    'manufacturer',
    'model',
    'typeOfBattery',
    'totalSizeOfStorage',
    'maxCRate',
    'numberOfCyclesReached',
    'cycles',
    'throughputEnergyReached',
    'throughputEnergy',
    'bothValues',
    'chargingPower',
    'dischargingPower',
    'chargingPowerEfficiency',
    'dischargingPowerEfficiency',
    'averageSelfConsumption',
  ]);

  let Config = {};

  switch (selectedOption) {
    case 'autoSize':
      Config = {
        storageAutoSizeConfiguration: {
          minBatteryCapacity,
          maxBatteryCapacity,
          minBatteryPower,
          maxBatteryPower,
          depthOfDischarge,
          maxNumberOfCycles,
          yearlyDepreciation,
          roundTripEfficiency,
          initialStateOfCharge,
          minStateOfCharge,
          maxStateOfCharge,
      }};
      break;

    case 'selectStorageSystem':
      Config = {
        selectStorageSystemConfiguration: {
          manufacturer,
          model
      }};
      break;

    case 'manuallyStorageSystem':
      Config = {
        manuallyStorageSystemConfiguration: {
          manufacturer,
          model,
          typeOfBattery,
          totalSizeOfStorage,
          maxCRate,
          numberOfCyclesReached,
          cycles,
          throughputEnergyReached,
          throughputEnergy,
          bothValues,
          chargingPower,
          dischargingPower,
          chargingPowerEfficiency,
          dischargingPowerEfficiency,
          averageSelfConsumption
      }};
      break;
    }

    return Config;
};

export const GetStorageformVals = (
  data: any,
  setValue: UseFormSetValue<FieldValues>
  ) => {
    const {
      autoSize,
      selectStorageSystem,
      manuallyStorageSystem,
    } = data;

    setValue('autoSize', autoSize);
    setValue('selectStorageSystem', selectStorageSystem);
    setValue('manuallyStorageSystem', manuallyStorageSystem);

    if (autoSize) {
      const { storageAutoSizeConfiguration: {
        minBatteryCapacity,
        maxBatteryCapacity,
        minBatteryPower,
        maxBatteryPower,
        depthOfDischarge,
        maxNumberOfCycles,
        yearlyDepreciation,
        roundTripEfficiency,
        initialStateOfCharge,
        minStateOfCharge,
        maxStateOfCharge,
      }} = data;

      setValue('minBatteryCapacity', minBatteryCapacity);
      setValue('maxBatteryCapacity', maxBatteryCapacity);
      setValue('minBatteryPower', minBatteryPower);
      setValue('maxBatteryPower', maxBatteryPower);
      setValue('depthOfDischarge', depthOfDischarge);
      setValue('maxNumberOfCycles', maxNumberOfCycles);
      setValue('yearlyDepreciation', yearlyDepreciation);
      setValue('roundTripEfficiency', roundTripEfficiency);
      setValue('initialStateOfCharge', initialStateOfCharge);
      setValue('minStateOfCharge', minStateOfCharge);
      setValue('maxStateOfCharge', maxStateOfCharge);
    }

    if (selectStorageSystem) {
      const { selectStorageSystemConfiguration: {
        manufacturer,
        model
      }} = data;
      setValue('manufacturer', manufacturer);
      setValue('model', model);
    }

    if (manuallyStorageSystem) {
      const { manuallyStorageSystemConfiguration: {
        manufacturer,
        model,
        typeOfBattery,
        totalSizeOfStorage,
        maxCRate,
        numberOfCyclesReached,
        cycles,
        throughputEnergyReached,
        throughputEnergy,
        bothValues,
        chargingPower,
        dischargingPower,
        chargingPowerEfficiency,
        dischargingPowerEfficiency,
        averageSelfConsumption
      }} = data;

      setValue('manufacturer', manufacturer);
      setValue('model', model);
      setValue('typeOfBattery', typeOfBattery);
      setValue('totalSizeOfStorage', totalSizeOfStorage);
      setValue('maxCRate', maxCRate);
      setValue('numberOfCyclesReached', numberOfCyclesReached);
      setValue('cycles', cycles);
      setValue('throughputEnergyReached', throughputEnergyReached);
      setValue('throughputEnergy', throughputEnergy);
      setValue('bothValues', bothValues);
      setValue('chargingPower', chargingPower);
      setValue('dischargingPower', dischargingPower);
      setValue('chargingPowerEfficiency', chargingPowerEfficiency);
      setValue('dischargingPowerEfficiency', dischargingPowerEfficiency);
      setValue('averageSelfConsumption', averageSelfConsumption);
    }
};

export const EconomicformVals = (watch: UseFormWatch<FieldValues>) => {

  const [ initialCapacity,
          replacementCosts,
          detailedCost,
          batteryCost,
          pcsCost,
          pmsCost,
          energyManagementCost,
          otherCosts,
          maxCapitalCost,
          energyCapacityCost,
          country,
          region,
          availableSubsided,
          detailedSubsided,
          fixedSubsidies,
          minSubsidyBaseOnStorageSize,
          maxSubsidyBaseOnStorageSize,
          percentageOfReducedTaxes,
          omcosts ]
        = watch([
          'initialCapacity',
          'replacementCosts',
          'detailedCost',
          'batteryCost',
          'pcsCost',
          'pmsCost',
          'energyManagementCost',
          'otherCosts',
          'maxCapitalCost',
          'energyCapacityCost',
          'country',
          'region',
          'availableSubsided',
          'detailedSubsided',
          'fixedSubsidies',
          'minSubsidyBaseOnStorageSize',
          'maxSubsidyBaseOnStorageSize',
          'percentageOfReducedTaxes',
          'omcosts']);

  const config = {
    initialCapacity,
    replacementCosts,
    detailedCost,
    batteryCost,
    pcsCost,
    pmsCost,
    energyManagementCost,
    otherCosts,
    maxCapitalCost,
    energyCapacityCost,
    country,
    region,
    availableSubsided,
    detailedSubsided,
    fixedSubsidies,
    minSubsidyBaseOnStorageSize,
    maxSubsidyBaseOnStorageSize,
    percentageOfReducedTaxes,
    omcosts
  };
  return config;
};

export const GetEconomicformVals = (
  data: any,
  setValue: UseFormSetValue<FieldValues>
) => {
      const { economics: {
        initialCapacity,
        replacementCosts,
        detailedCost,
        batteryCost,
        pcsCost,
        pmsCost,
        energyManagementCost,
        otherCosts,
        maxCapitalCost,
        energyCapacityCost,
        country,
        region,
        availableSubsided,
        detailedSubsided,
        fixedSubsidies,
        minSubsidyBaseOnStorageSize,
        maxSubsidyBaseOnStorageSize,
        percentageOfReducedTaxes,
        omcosts
        }} = data;

      setValue('initialCapacity', initialCapacity);
      setValue('replacementCosts', replacementCosts);
      setValue('detailedCost', detailedCost);
      setValue('batteryCost', batteryCost);
      setValue('pcsCost', pcsCost);
      setValue('pmsCost', pmsCost);
      setValue('energyManagementCost', energyManagementCost);
      setValue('otherCosts', otherCosts);
      setValue('maxCapitalCost', maxCapitalCost);
      setValue('energyCapacityCost', energyCapacityCost);
      setValue('country', country);
      setValue('region', region);
      setValue('availableSubsided', availableSubsided);
      setValue('detailedSubsided', detailedSubsided);
      setValue('fixedSubsidies', fixedSubsidies);
      setValue('minSubsidyBaseOnStorageSize', minSubsidyBaseOnStorageSize);
      setValue('maxSubsidyBaseOnStorageSize', maxSubsidyBaseOnStorageSize);
      setValue('percentageOfReducedTaxes', percentageOfReducedTaxes);
      setValue('omcosts', omcosts);
}

export const doAllEcoFieldsHaveValue = (fields: Record<string, string>): boolean => {
  return Object.values(fields).every((item) => item !== '');
};
export const doAllStrFeildseHaveValue = (fields: Record<string, Record<string, string>>): boolean => {
  return Object.values(fields).every((nestedFields) =>
    Object.values(nestedFields).every((value) => value !== '')
  );
};
export interface StorageTaps {
  name: string;
  id: number;
  componentId: number;
}
export type EnergyLayoutModelType = {
  componentModel: ModelComponentType;
  id: number;
  configuration: { id: number; storageName?: string };
  isConfigured: boolean;
};