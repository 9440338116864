import axios from 'axios';
import { getResponseErrors } from '../utils/misc';
import { ErrorResponseAPI } from '../types/miscTypes';

class ApiService {
  baseURL = import.meta.env.VITE_API_ENDPOINT as string;
  token = import.meta.env.VITE_API_TOKEN as string;

  get accessToken() {
    return window.localStorage.getItem('ETAccessToken') ?? 0;
  }

  get language() {
    return window.localStorage.getItem('language') === 'ar' ? 2 : 1;
  }

  api() {
    if (!this.accessToken || this.accessToken === 'undefined') {
      delete axios.defaults.headers.common.Authorization;
    } else {
      axios.defaults.headers.common.Authorization = `Bearer ${this.accessToken}`;
    }

    return axios.create({
      baseURL: this.baseURL,
      headers: {
        // Token: this.token,
        Language: this.language,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  storeToken(token: string) {
    window.localStorage.setItem('ETAccessToken', token);
  }
  storeRefershToken(rToken: string) {
    window.localStorage.setItem('ETRefershAccessToken', rToken);
  }

  makeApiRequest = async (
    method: string,
    endpoint: string,
    data: any
  ): Promise<{ success: boolean; data: any }> => {
    try {
      let response = { data: {} };

      switch (method) {
        case 'get':
          response = await apiService.api().get(endpoint);
          break;
        case 'post':
          response = await apiService.api().post(endpoint, data);
          break;
        case 'put':
          response = await apiService.api().put(endpoint, data);
          break;
        case 'delete':
          await apiService.api().delete(endpoint);
          return { success: true, data: null };
        //   return; // Early return after deleting the resource
        default:
          throw new Error(`Unsupported HTTP method: ${method}`);
      }

      //   const response = await apiService.api().post(endpoint, data);
      const responseData = response.data;
      return { success: true, data: responseData };
    } catch ({ response }: { response: ErrorResponseAPI } | any) {
      return { success: false, data: getResponseErrors(response?.data) };
    }
  };
}

export const apiService = new ApiService();
