import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ArrowIcon from '../SvgIcon/ArrowIcon';
import { SettingsTypes } from '../../types/reduxTypes';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import FormTitle from './FormTitle';

const ETAccordion = ({
  children,
  title,
  mb = 0,
  dividerWidth = '-webkit-fill-available',
  bgColor = '',
  ...props
}: {
  children: ReactNode;
  title: string;
  mb?: string | number;
  dividerWidth?: string;
  bgColor: string;
} & any) => {
  const { t } = useTranslation();
  const { settings } = useSelector(
    ({ settings }: { settings: SettingsTypes }) => ({
      settings,
    })
  );

  const { isDarkMode } = settings;

  return (
    <Box
      component={Accordion}
      defaultExpanded={true}
      className="ETAccordion"
      style={{
        backgroundColor: bgColor || 'var(--background-color-accordion)',
        borderColor: bgColor || 'fff',
      }}
      mb={`${mb}rem !important`}
      p={title ? '25px' : '40px 25px'}
      backgroundColor={isDarkMode ? '#000' : '#000'}
      {...props}
    >
      <AccordionSummary
        expandIcon={<ArrowIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ display: title ? 'flex' : 'none' }}
      >
        <FormTitle
          title={t(title)}
          dividerWidth={dividerWidth}
          mb={0}
          mr={2}
          width={'100%'}
        />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Box>
  );
};

export default ETAccordion;
