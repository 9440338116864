import { Box, Typography } from '@mui/material';
import { FinancialCashFlowPropsType } from '../ResultTypes';
import PrintButton from '../PrintButton';
import { useRef } from 'react';
import RowspanTable from '../../../blocks/rowspanTable';

const CashFlowTables = ({
  data,
  className = '',
}: {
  data: FinancialCashFlowPropsType;
  className: string;
}) => {
  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton
        id={'CASH_FLOW_TABLES'}
        componentName="Financial Analysis"
        element={ref}
      />
      <Box
        className={`results__info ${className}`}
        m={'25px'}
        p={'25px'}
        ref={ref}
      >
        <Typography variant={'h5'} mb={4} fontWeight={'bold'}>
          Cash Flow
        </Typography>
        {data.financialCashFlowTablsData.map((item) => (
          <>
            <RowspanTable
              data={item.data as []}
              columns={item.columns}
              key={Math.random()}
              period={item?.period}
            />
            <br />
          </>
        ))}
      </Box>
    </>
  );
};

export default CashFlowTables;
