import { useRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PrintButton from '../PrintButton';
import TimeSeriesTestChart from '../../../blocks/charts/POC/TimeSeriesTestChart';
import { useDispatch, useSelector } from 'react-redux';
import { PrintTypes } from '../../../../types/reduxTypes';
import { removePrint } from '../../../../redux/actions-reducers/print';

const DigramEditor = (digramEditorData = []) => {
  const ref = useRef<HTMLElement | null>(null);

  const print = useSelector(({ print }: { print: PrintTypes }) => print);
  const digramEditorShots = print.data.filter(({ id }) =>
    id.startsWith('DIGRAM_EDITOR')
  );
  const dispatch = useDispatch();

  return (
    <>
      <PrintButton
        id={'DIGRAM_EDITOR'}
        componentName="Simulation"
        element={ref}
        withShot={true}
      />
      <Box className={`results__info `} m={'25px'} p={'25px'} ref={ref}>
        <Typography variant="h5" mb={3} fontWeight={'bold'}>
          Digram Editor
        </Typography>
        <TimeSeriesTestChart
          seriesData={(digramEditorData as any)?.data}
          height={500}
        />
      </Box>
      {/* Snapshots of the digram editor */}
      <Stack
        direction={'row'}
        spacing={2}
        m={'25px'}
        className="snapshot-preview"
      >
        {digramEditorShots.map(({ image, id }) => (
          <Box
            className={`results__info`}
            p={'10px'}
            position={'relative'}
            overflow={'initial'}
          >
            <img
              src={'/images/closeIcon.svg'}
              alt={'remove'}
              className="remove-shot"
              onClick={() => dispatch(removePrint(id))}
            />
            <img
              key={id}
              style={{ width: '200px', height: '100px' }}
              src={image}
              alt={id}
            />
          </Box>
        ))}
      </Stack>
    </>
  );
};

export default DigramEditor;
