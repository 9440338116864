import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeToast } from '../../../redux/actions-reducers/toast';

export type ToastType = {
  message: string;
  mode: string;
  withSuccussToast: boolean;
  overlay: boolean;
  isToastOpen: boolean;
  manualClose: boolean;
  resetTimer: boolean;
};
const ETToast = () => {
  const [ready, setReady] = useState<boolean>(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { toast } = useSelector(({ toast }: { toast: ToastType }) => ({
    toast,
  }));

  const dispatch = useDispatch();
  const defualtMessages: { success: string; error: string } = {
    success: 'The data has been submitted succussfully!',
    error:
      'The Request has faild, please contact your system adminstrator for assistance!',
  };
  const handleClose = () => {
    dispatch(closeToast());
  };
  const { isToastOpen, manualClose, mode, message, resetTimer } = toast || {};

  useEffect(() => {
    if (!isToastOpen) return;
    setReady(true);

    if (!manualClose || toast.withSuccussToast) {
      timeoutRef.current = setTimeout(() => {
        setReady(false);
        handleClose();
      }, 7000);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isToastOpen, resetTimer]);

  if (!isToastOpen) return null;

  const key = resetTimer ? Math.random().toString() : null;

  return (
    ready &&
    ReactDOM.createPortal(
      <div
        className={`toast-container ${toast.overlay && 'overlay'}`}
        data-id="TOAST_MESSAGE"
        key={key}
      >
        <div className="toast__header">
          <img
            src={`/images/icons/${mode}Icon.svg`}
            width="40px"
            height="40px"
            alt={`${toast?.mode} Icon`}
          />
          <span className={`toast__title ${mode}`}>{mode}</span>
          <img
            src={`/images/icons/closeIcon.svg`}
            className="close"
            width="20px"
            height="20px"
            alt="Close Icon"
            onClick={handleClose}
          />
        </div>

        <div className="message">
          {(typeof message === 'string' && message) ||
            defualtMessages[mode as keyof typeof defualtMessages]}
        </div>

        {(!manualClose || toast.withSuccussToast) && (
          <span className="track">
            <span className={`progress ${mode}`} />
          </span>
        )}
      </div>,
      document.querySelector('.header-container') as Element | DocumentFragment
    )
  );
};

export default ETToast;
