import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { Badge, Button, Stack } from '@mui/material';
import ArrowIcon from '../SvgIcon/ArrowIcon.tsx';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import SearchIcon from '../SvgIcon/SearchIcon.tsx';
import NotificationIcon from '../SvgIcon/NotificationIcon.tsx';
import LanguageIcon from '../SvgIcon/LanguageIcon.tsx';
import DarkMode from './DarkMode.tsx';
import useLanguageSelector from '../LanguageSelector.tsx';
import { useTranslation } from 'react-i18next';
import FloatingMenu from './FloatingMenu.tsx';
import { authService } from '../../services/AuthService.ts';
import ETToast from './ETblocks/ETToast.tsx';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { changeLanguage, currentLanguage } = useLanguageSelector();

  // const { isDarkMode } = useSelector(
  //   ({ settings }: { settings: SettingsTypes }) => settings
  // );

  const currentUser = useSelector(
    ({ currentUser }: { currentUser: any }) => currentUser
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');

  const [anchorNotification, setAnchorNotification] =
    useState<null | HTMLElement>(null);
  const openNotification = Boolean(anchorNotification);
  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorNotification(event.currentTarget);
  };
  const handleNotificationClose = () => {
    setAnchorNotification(null);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnBlur = () => {
    setOpenSearch(false);
  };

  const [anchorLanguage, setAnchorLanguage] = useState<null | HTMLElement>(
    null
  );
  const openLanguage = Boolean(anchorLanguage);
  const handleClickLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorLanguage(event.currentTarget);
  };
  const handleSearch = () => {
    console.log({ inputValue });
  };

  const notificationItems = [
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle:
        'Someone in your area has tested positive for COVID-19, Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle:
        'Someone in your area has tested positive for COVID-19, Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle:
        'Someone in your area has tested positive for COVID-19, Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle:
        'Someone in your area has tested positive for COVID-19, Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle:
        'Someone in your area has tested positive for COVID-19, Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
    {
      title: 'PV system alert',
      subTitle: 'Someone in your area has tested positive for COVID-19',
      alt: 'noti',

      onClick: () => alert('Test'),
    },
  ];

  return (
    <div className="header-container">
      <ETToast />
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction={'row'}
        px={'23px'}
        py={'16px'}
        className={'header'}
      >
        {/*Logo*/}
        <Link to={'/'}>
          {/* <HeaderLogoIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} /> */}
          <img
            src="/images/logos/Watt Fusion white and colour symbol logo .png"
            width="203"
            // height="40"
            alt="logo"
          />
        </Link>

        <Stack alignItems="center" justifyContent="space-between">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              gap: '15px',
            }}
          >
            {/* Search input */}
            <div className="box" style={{ display: 'none' }}>
              <form name="search">
                <input
                  type="text"
                  className="input"
                  autoFocus={openSearch}
                  name="txt"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onBlur={handleOnBlur}
                  style={{
                    width: openSearch ? '350px' : '0px',
                    padding: openSearch ? '10px 0px 10px 10px' : 0,
                  }}
                />
              </form>
              {!openSearch && (
                <SearchIcon
                  className={'fa-search'}
                  onClick={() => setOpenSearch(true)}
                  // fill={isDarkMode ? '#D3D7E4' : '#042F4D'}
                  fill={'#D3D7E4'}
                />
              )}

              <div
                className={'icon-button-t'}
                style={
                  openSearch
                    ? {
                        display: 'flex',
                        backgroundColor: '#007fbd',
                        padding: '15px',
                        width: '50px',
                      }
                    : undefined
                }
              >
                <SearchIcon
                  className={'search-sub'}
                  onClick={() => handleSearch()}
                  width={19}
                  height={19}
                  // fill={isDarkMode ? '#D3D7E4' : '#042F4D'}
                  fill={'#D3D7E4'}
                />
              </div>
            </div>

            {/* Notification */}
            <Badge
              badgeContent={notificationItems.length}
              color="warning"
              className={'clickable'}
              onClick={handleNotificationClick}
              style={{ display: 'none' }}
            >
              <NotificationIcon fill={'#D3D7E4'} />
              {/* <NotificationIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} /> */}
            </Badge>
            <FloatingMenu
              anchorEl={anchorNotification}
              open={openNotification}
              handleClose={handleNotificationClose}
              items={notificationItems}
              className={'notification-dropdown'}
              headerTitle={'Notification'}
              withoutArrow
              isNotification
            />

            {/* Language */}
            <div className="header__language">
              <Button
                id="basic-button"
                aria-controls={openLanguage ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openLanguage ? 'true' : undefined}
                onClick={handleClickLanguage}
              >
                {currentLanguage}
                {/* <LanguageIcon fill={isDarkMode ? '#D3D7E4' : '#042F4D'} /> */}
                <LanguageIcon fill={'#D3D7E4'} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorLanguage}
                open={openLanguage}
                onClose={() => setAnchorLanguage(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    color: 'var(--primary-color)',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: '50%',
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorLanguage(null);
                    changeLanguage('en');
                  }}
                >
                  English
                </MenuItem>
                <Divider style={{ margin: 0 }} />
                <MenuItem
                  onClick={() => {
                    setAnchorLanguage(null);
                    changeLanguage('de');
                  }}
                >
                  German
                </MenuItem>
              </Menu>
            </div>
            {/* DarkMode Toggle */}
            <DarkMode />
            {/* Account Button and list */}
            <Stack
              onClick={handleClick}
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              className="clickable"
              gap={'10px'}
            >
              <IconButton
                size="small"
                // sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32, bgcolor: '#002233' }}>
                  {currentUser?.firstName && currentUser?.firstName[0]}
                </Avatar>
              </IconButton>
              {`${currentUser?.firstName} ${currentUser?.lastName}`}
              <ArrowIcon
                fill={'#D3D7E4'} // fill={isDarkMode ? '#D3D7E4' : '#042F4D'}
              />
            </Stack>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 30,
                  height: 30,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            className="account-dropdown"
          >
            <MenuItem onClick={() => navigate('/profile')}>
              {/* <Avatar
              sx={{ width: 25, height: 25, bgcolor: '#002233' }}
              sizes={'large'}
            />{' '} */}
              <ListItemIcon>
                <img src="/images/user.png" alt="user" />
              </ListItemIcon>
              {t('My_Profile')}
            </MenuItem>
            <Divider />
            <MenuItem onClick={authService.logout}>
              <ListItemIcon>
                <img src="/images/logout.png" alt="logout" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </div>
  );
};

export default Header;
