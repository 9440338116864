import { formattedNumber } from '../../../../../utils';
import { Hours, Months } from '../../OverviewResult/helper';
import {
  EnergyPriceAnalysisDataTypes,
  EnergyPricesAnalysisTableType,
} from '../../ResultTypes';

export const getEnergyPriceAnalysisData = (
  energyPriceAnalysisData: EnergyPriceAnalysisDataTypes,
  currency: string
) => {
  const {
    avgPurchaseCostHourlyGrid = '',
    maxPurchaseCostHourlyGrid = '',
    minPurchaseCostHourlyGrid = '',
    avgPurchaseCostHourlyPV = '',
    maxPurchaseCostHourlyPV = '',
    minPurchaseCostHourlyPV = '',
    energyTotalCostGrid = '',
    energyTotalCostPV = '',
    powerTotalCostGrid = '',
    powerTotalCostPV = '',
    loadMaxPower = '',
    pvNewMaxPower = '',
    totalCostGridYearly = '',
    totalCostPVYearly = '',
    lcoeGrid = '',
    lcoePV = '',

    mean = null,
    median = null,
    min = null,
    max = null,
    standardDeviation = null,
    skewness = null,
    kurtosis = null,

    hourlyPatterns = null,
    seasonalTrends = null,

    energyCostGridAlone = [],
    energyCostGridAndPV = [],
    energyPriceCurve = [],
    powerPriceCurve = [],

    // TODO: Add the rest of the properties
    // priceRange = null,
    // iqr = null,
    // correlationWithLagged = null,
    // peakHoursFrequency = null,
    // offPeakHoursFrequency = null,
    // percentageOfNegativePrices = null,
  } = energyPriceAnalysisData;

  const energyPricesStatistics = [
    {
      title: 'Energy prices statistics',
      items: [
        {
          name: 'mean',
          value: formattedNumber(mean),
          unit: 'KWp',
        },
        {
          name: 'median',
          value: formattedNumber(median),
          unit: 'KWp',
        },
        {
          name: 'min',
          value: formattedNumber(min),
          unit: '%',
        },
        {
          name: 'max',
          value: formattedNumber(max),
          unit: 'kWh/a',
        },
        {
          name: 'standerd deviation',
          value: formattedNumber(standardDeviation),
          unit: 'kWh/a',
        },
        {
          name: 'skewness',
          value: formattedNumber(skewness),
          unit: 'kWh/a',
        },
        {
          name: 'kurtois',
          value: formattedNumber(kurtosis),
          unit: 'kWh/a',
        },
      ],
    },
  ];

  // Energy Consumption

  const hourlyPatternData = Hours.map((name, index) => ({
    name: name,
    hourlyPatterns: hourlyPatterns?.[index + 1] ?? 0,
  }));

  const hourlyPatternWithDataKeys = {
    dataKeys: [
      {
        label: 'Hourly Patterns',
        key: 'hourlyPatterns',
        color: '#808080',
      },
    ],
    data: hourlyPatternData,
    unit: 'Energy [kWh]',
  };

  const monthlyTrendsData = Months.map((name, index) => ({
    name: name,
    seasonalTrends: seasonalTrends?.[index + 1] ?? 0,
  }));

  const monthlyTrendsWithDataKeys = {
    dataKeys: [
      {
        label: 'Hourly Patterns',
        key: 'seasonalTrends',
        color: '#808080',
      },
    ],
    data: monthlyTrendsData,
    unit: 'Energy [kWh]',
  };

  // const monthlyTrends = [
  //   {
  //     name: 'Own Consputinon',
  //     value: seasonalTrends,
  //     fill: COLORS[3],
  //   },
  // ];

  const energyPricesAnalysisTable: EnergyPricesAnalysisTableType = {
    data: [
      {
        details: {
          avgPurchaseCostHourly: [
            avgPurchaseCostHourlyGrid,
            avgPurchaseCostHourlyPV,
          ],
          maxPurchaseCostHourly: [
            maxPurchaseCostHourlyGrid,
            maxPurchaseCostHourlyPV,
          ],
          minPurchaseCostHourly: [
            minPurchaseCostHourlyGrid,
            minPurchaseCostHourlyPV,
          ],
          energyTotalCost: [energyTotalCostGrid, energyTotalCostPV],
          powerTotalCost: [powerTotalCostGrid, powerTotalCostPV],
          maximumPower: [loadMaxPower, pvNewMaxPower],
          totalCostEnergyAndPower: [totalCostGridYearly, totalCostPVYearly],
          levelizedCostOfEnergy: [lcoeGrid, lcoePV],
        },
        units: [
          currency,
          currency,
          currency,
          currency,
          'kW',
          currency,
          currency,
          `${currency}/kWh`,
        ],
      },
    ],
    columns: ['', 'Grid alone', 'Grid with PV'],
  };

  const energyCostData = [
    {
      name: 'Energy Cost Grid',
      data: energyCostGridAlone,
    },
    {
      name: 'Energy Cost Grid & PV',
      data: energyCostGridAndPV,
    },
  ];

  const priceData = [
    {
      name: 'Energy Price',
      data: energyPriceCurve,
    },
    {
      name: 'Power Price',
      data: powerPriceCurve,
    },
  ];

  return {
    energyPricesAnalysisTable,
    monthlyTrendsWithDataKeys,
    hourlyPatternWithDataKeys,
    energyPricesStatistics,
    energyCostData,
    priceData,
  };
};
