import { createSlice } from '@reduxjs/toolkit';

const paramsSlice = createSlice({
  name: 'project',
  initialState: { id: '' },
  reducers: {
    setParams: (paramsState, { payload }) => {
      Object.assign(paramsState, payload);
    },
  },
});

export const { setParams } = paramsSlice.actions;

export default paramsSlice.reducer;
