import { formattedNumber } from '../../../../utils';
import { toFixed } from '../../../../utils/misc';
import { OverviewChartDataType, OverviewDataType } from '../ResultTypes';

export const Months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const Hours = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
];

export const getOverviewCards = (
  overviewData: OverviewDataType,
  currency: string
) => {
  return [
    {
      title: 'Project details',
      data: [
        {
          name: 'Project_Name',
          value: overviewData?.projectName ?? '-',
        },
        {
          name: 'project_Number',
          value: overviewData?.projectNumber ?? '-',
        },
        {
          name: 'Project_Address',
          value: overviewData?.projectAddress ?? '-',
        },
        {
          name: 'Contact person',
          value: overviewData?.contactPerson ?? '-',
        },
        {
          name: 'Contact_number',
          value: overviewData?.contactNumber ?? '-',
        },
      ],
    },

    {
      title: 'System Type',
      data: [
        {
          name: 'System Configuration',
          value: overviewData?.systemConfiguration,
          justifyContent: 'flex-start',
        },
        {
          name: 'Business Models',
          value: overviewData?.businessLayoutModels.join(', ') ?? '-',
          mt: 2,
          justifyContent: 'flex-start',
        },
      ],
    },
    {
      title: 'Financial Analysis',
      data: [
        {
          name: 'Internal Rate of Return (IRR)',
          value: toFixed(overviewData?.internalRateOfReturn, 2) ?? '-',
          unit: '%',
        },
        {
          name: 'Amortisation period',
          value: toFixed(overviewData?.amortisationPeriod, 2) ?? '-',
          unit: 'Years',
        },
        {
          name: 'Accrued Cash Flow',
          value: formattedNumber(overviewData?.accruedCashFlow) ?? '-',
          unit: currency,
        },
      ],
    },
    {
      title: 'Load Analysis',
      data: [
        {
          name: 'Total Consumption',
          value: formattedNumber(overviewData?.totalConsumptions) ?? '-',
          unit: 'kWh',
        },
        {
          name: 'Max. Power',
          value: toFixed(overviewData?.loadMaxPower, 2) ?? '-',
          unit: 'kW',
        },
        {
          name: 'Average Power',
          value: toFixed(overviewData?.loadAvgPower, 2) ?? '-',
          unit: 'kW',
        },
      ],
    },
    {
      title: 'PV Analysis',
      data: [
        {
          name: 'PV System rated power',
          value: formattedNumber(overviewData?.pvSystemRatedPower) ?? '-',
          unit: 'kWp',
        },
        {
          name: 'Specific Annual Yield',
          value: formattedNumber(overviewData?.specificAnnualYield) ?? '-',
          unit: 'kWh/kWp',
        },
        {
          name: 'Performance Ratio (PR)',
          value: overviewData?.performanceRatio ?? '-',
          unit: '%',
        },
        {
          name: 'PV generated energy',
          value: formattedNumber(overviewData?.pvGeneratedEnergy) ?? '-',
          unit: 'kWh/a',
        },
        {
          name: 'Direct power consumption',
          value: overviewData?.directPowerConsumption ?? '-',
          unit: '%',
        },
        {
          name: 'CO2 emissions avoided',
          value: toFixed(overviewData?.pvCO2EmissionAvoided, 2) ?? '-',
          unit: 'Kg/year',
        },
        {
          name: 'Level of self-sufficiency',
          value: overviewData?.loadSelfSufficiency ?? '-',
          unit: '%',
        },
      ],
    },
    {
      title: 'Grid System',
      data: [
        {
          name: 'Grid operator',
          value: overviewData?.gridOperation ?? '-', // TODO: need data
          unit: '',
        },
        {
          name: 'Number of phases',
          value: overviewData?.numberOfPhases ?? '-',
          unit: '',
        },
        {
          name: 'Grid voltage',
          value: overviewData?.gridVoltage ?? '-',
          unit: 'V',
        },
        {
          name: 'Power factor (cos phi)',
          value: overviewData?.powerFactor ?? '-', // TODO: need data
          unit: '+/-',
        },
      ],
    },
  ];
};

export const getOverviewChartsData = (overviewData: {
  chartData: OverviewChartDataType;
}) => {
  // Energy Consumption
  const energyConsumptionData = Months.map((name, index) => ({
    name: name,
    consumer: overviewData?.chartData?.consumer?.[index + 1],
  }));

  const energyConsumptionWithDataKeys = {
    dataKeys: [
      {
        label: 'Consumer',
        key: 'consumer',
        color: '#808080',
      },
    ],
    data: energyConsumptionData,
    unit: 'Energy [kWh]', // TODO: need to reflect
  };

  // // Power Demand
  const powerDemandData = Months.map((name, index) => ({
    name: name,
    gridAlone: overviewData?.chartData?.gridAlone?.[index + 1],
    gridAndPV: overviewData?.chartData?.gridAndPV?.[index + 1],
  }));
  const powerDemandWithDataKeys = {
    dataKeys: [
      {
        label: 'Grid Alone',
        key: 'gridAlone',
        color: '#760808',
      },
      {
        label: 'Grid and PV',
        key: 'gridAndPV',
        color: '#ffa500',
      },
    ],
    data: powerDemandData,
    unit: 'Power [kW]',
  };

  // // PV Production
  const pvProductionData = Months.map((name, index) => ({
    name: name,
    value: overviewData?.chartData?.pvGeneratedEnergyMonthly?.[index + 1],
  }));
  const pvProductionWithDataKeys = {
    dataKeys: [
      {
        label: 'PV Production',
        key: 'value',
        color: '#ffa500',
      },
    ],
    data: pvProductionData,
    unit: 'Energy [kWh]',
  };

  // // Use of PV energy
  const useOfPVEnergyData = Months.map((name, index) => ({
    name: name,
    pvGeneratedEnergy:
      overviewData?.chartData?.pvGeneratedEnergyMonthly?.[index + 1],
    directlyConsumed:
      overviewData?.chartData?.directlyConsumedMonthly?.[index + 1],
    gridFeedIn: overviewData?.chartData?.gridFeedIn?.[index + 1],
    curtailmentEnergy: overviewData?.chartData?.curtailmentEnergy?.[index + 1],
  }));
  const useOfPVEnergyWithDataKeys = {
    dataKeys: [
      {
        label: 'PV Generated Energy',
        key: 'pvGeneratedEnergy',
        color: '#ffa500',
      },
      {
        label: 'Directly Consumed',
        key: 'directlyConsumed',
        color: '#00275c',
      },
      {
        label: 'Grid Feed In',
        key: 'gridFeedIn',
        color: '#008000',
      },
      {
        key: 'curtailmentEnergy',
        label: 'Curtailment Energy',
        color: '#760808',
      },
    ],
    data: useOfPVEnergyData,
    unit: 'Energy [kWh]',
  };

  // // Consumption coverage
  const consumptionCoverageData = Months.map((name, index) => ({
    name: name,
    consumer: overviewData?.chartData?.consumer?.[index + 1],
    coveredByPvMonthly:
      overviewData?.chartData?.coveredByPvMonthly?.[index + 1],
    coveredByGridMonthly:
      overviewData?.chartData?.coveredByGridMonthly?.[index + 1],
  }));
  const consumptionCoverageWithDataKeys = {
    dataKeys: [
      {
        label: 'Consumer',
        key: 'consumer',
        color: '#808080',
      },
      {
        label: 'Covered By PV',
        key: 'coveredByPvMonthly',
        color: '#ffa500',
      },
      {
        label: 'Covered By Grid',
        key: 'coveredByGridMonthly',
        color: '#760808',
      },
    ],
    data: consumptionCoverageData,
    unit: 'Energy [kWh]',
  };

  // Energy forecast with load
  const energyForecastWithLoadData = Months.map((name, index) => ({
    name: name,
    pvGeneratedEnergyMonthly:
      overviewData?.chartData?.pvGeneratedEnergyMonthly?.[index + 1],
    totalGrid: overviewData?.chartData?.totalGrid?.[index + 1],
    consumer: overviewData?.chartData?.consumer?.[index + 1],
    gridFeedIn: overviewData?.chartData?.gridFeedIn?.[index + 1],
    curtailmentEnergy: overviewData?.chartData?.curtailmentEnergy?.[index + 1],
  }));
  const energyForecastWithLoadWithDataKeys = {
    dataKeys: [
      {
        label: 'PV Generated Energy',
        key: 'pvGeneratedEnergyMonthly',
        color: '#ffa500',
      },
      {
        label: 'Total Grid',
        key: 'totalGrid',
        color: '#d37787',
      },
      {
        label: 'Consumer',
        key: 'consumer',
        color: '#808080',
      },
      {
        label: 'Grid Feed In',
        key: 'gridFeedIn',
        color: '#008000',
      },
      {
        label: 'Curtailment Energy',
        key: 'curtailmentEnergy',
        color: '#760808',
      },
    ],
    data: energyForecastWithLoadData,
    unit: 'Energy [kWh]',
  };

  return {
    ENERGY_CONSUMPTION: energyConsumptionWithDataKeys,
    POWER_DEMAND: powerDemandWithDataKeys,
    PV_PRODUCTION: pvProductionWithDataKeys,
    USE_OF_PV_ENERGY: useOfPVEnergyWithDataKeys,
    CONSUMPTION_COVERAGE: consumptionCoverageWithDataKeys,
    ENERGY_FORECAST_WITH_LOAD: energyForecastWithLoadWithDataKeys,
  };
};

export const tabs = [
  {
    name: 'Energy Consumption',
    id: 'ENERGY_CONSUMPTION',
  },
  {
    name: 'Power Demand',
    id: 'POWER_DEMAND',
  },
  {
    name: 'PV Producation',
    id: 'PV_PRODUCTION',
  },
  {
    name: 'Use of PV energy',
    id: 'USE_OF_PV_ENERGY',
  },
  {
    name: 'Consumption coverage',
    id: 'CONSUMPTION_COVERAGE',
  },
  {
    name: 'Energy forecast with load',
    id: 'ENERGY_FORECAST_WITH_LOAD',
  },
];

export const dataKeys = [
  {
    label: 'Total Consumption',
    key: 'value',
    color: 'var(--primary-color)',
  },
];
