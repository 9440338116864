const AddIcon = () => {
  return (
    <svg
      width="6"
      height="7"
      viewBox="0 0 6 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.42578 6.44602V0.684659H3.57209V6.44602H2.42578ZM0.120384 4.13636V2.99006H5.88175V4.13636H0.120384Z"
        fill="#06367A"
      />
    </svg>
  );
};

export default AddIcon;
