import { Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FinancialAnalysisMainPropsType } from '../ResultTypes';
import PrintButton from '../PrintButton';
import { useRef } from 'react';

const FinancialAnalysisMain = ({
  data = [],
  className,
}: FinancialAnalysisMainPropsType) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton
        id={'FINANCIAL_ANALYSIS'}
        componentName="Financial Analysis"
        element={ref}
      />
      <Box
        className={`results__info ${className ?? ''}`}
        m={'25px'}
        p={'25px'}
        ref={ref}
      >
        <Typography variant={'h5'} mb={4} fontWeight={'bold'}>
          Financial Analysis
        </Typography>
        {data.length > 0 &&
          data?.map(({ title, items }) => (
            <>
              <Typography variant={'h6'}>{title}</Typography>
              {items?.map(({ name, value, unit }) => (
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  key={Math.random()}
                  mb={0.5}
                  maxWidth={'98%'}
                >
                  <Typography className="label">{t(name)}: </Typography>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'end'}
                    minWidth={150}
                    gap={1.5}
                  >
                    <Typography>{value}</Typography>
                    {unit && <Typography minWidth={'80px'}>{unit}</Typography>}
                  </Stack>
                </Stack>
              ))}
              <br />
            </>
          ))}
      </Box>
    </>
  );
};

export default FinancialAnalysisMain;
