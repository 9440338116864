import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, Grid, Stack } from '@mui/material';
import { TypeOfBatteryOptions } from './helper';
import ControlledInput from '../../../../../../blocks/ControlledInput';
import ETAccordion from '../../../../../../blocks/ETAccordion';

const ConfigureManually = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  return (
    <div>
      {/* Basic Data inputs */}
      <ETAccordion title={'storage.Basic_Data'} bgColor="transparent">
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={3}>
            <ControlledInput
              name={'manufacturer'}
              type="number"
              label={t('storage.Manufacturer')}
              fullWidth={false}
              required={true}
              registerOptions={{
                required: 'Manufacturer is required',
              }}
              endIcon={'g/kwh'}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              name={'model'}
              type="number"
              label={t('storage.Model')}
              required={true}
              registerOptions={{
                required: 'Model is required',
              }}
              endIcon={'g/kwh'}
            />
          </Grid>
        </Grid>
      </ETAccordion>

      {/* Battry Capacity inputs */}
      <ETAccordion title={'storage.Battery_Capacity'} bgColor="transparent">
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={3}>
            <ControlledInput
              name={'typeOfBattery'}
              type="select"
              label={t('storage.Type_of_battery')}
              options={TypeOfBatteryOptions}
              required={true}
              fullWidth={false}
              registerOptions={{
                required: 'Manufacturer is required',
              }}
              endIcon={'g/kwh'}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              name={'totalSizeOfStorage'}
              type="number"
              label={t('storage.Total_Size_of_the_storage_system')}
              required={true}
              registerOptions={{
                required: 'totalSizeOfStorage is required',
              }}
              endIcon={'kwh'}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              name={'depthOfDischarge'}
              type="number"
              label={t('storage.Depth_of_Discharge')}
              required={true}
              registerOptions={{
                required: 'depthOfDischarge is required',
              }}
              endIcon={'%'}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              name={'maxCRate'}
              type="number"
              label={t('storage.Maximum_C_Rate')}
              required={true}
              registerOptions={{
                required: 'maxCRate is required',
              }}
            />
          </Grid>
        </Grid>
      </ETAccordion>

      {/* End of Life Unit inputs */}
      <ETAccordion title={'storage.End_Of_Life_Unit'} bgColor="transparent">
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={4}>
            <Stack direction={'row'} gap={'0px'} alignItems={'center'}>
              <ControlledInput
                name={'numberOfCyclesReached'}
                type="checkbox"
                checkboxAsRadio
                value={watch('numberOfCyclesReached')}
                required
                label={t('storage.Number_of_Cycles_is_reached')}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setValue('throughputEnergyReached', false);
                    setValue('bothValues', false);
                  }
                }}
              />
              <ControlledInput
                name={'cycles'}
                type="number"
                required={watch('numberOfCyclesReached')? true : false}
                disabled={!watch('numberOfCyclesReached')}
                registerOptions={{
                  required: 'cycles is required',
                }}
                endIcon={t('storage.Cycles')}
              />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction={'row'} gap={'0px'} alignItems={'center'}>
              <ControlledInput
                name={'throughputEnergyReached'}
                type="checkbox"
                checkboxAsRadio
                value={watch('throughputEnergyReached')}
                required
                label={t('storage.Throughput_energy_is_reached')}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setValue('numberOfCyclesReached', false);
                    setValue('bothValues', false);
                  }
                }}
              />
              <ControlledInput
                name={'throughputEnergy'}
                type="number"
                required={watch('throughputEnergyReached')? true : false}
                disabled={!watch('throughputEnergyReached')}
                registerOptions={{
                  required: 'throughput Energy is required',
                }}
                endIcon={'%'}
              />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction={'row'} gap={'0px'} alignItems={'center'}>
              <ControlledInput
                name={'bothValues'}
                type="checkbox"
                checkboxAsRadio
                mb={1}
                value={watch('bothValues')}
                required
                label={t('storage.Both_values')}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setValue('numberOfCyclesReached', false);
                    setValue('throughputEnergyReached', false);
                  }
                }}
              />
              <ControlledInput
                name={'yearlyDepreciation'}
                type="number"
                label={t('storage.Yearly_depreciation')}
                required={watch('bothValues')? true : false}
                disabled={!watch('bothValues')}
                registerOptions={{
                  required: 'yearlyDep is required',
                }}
                endIcon={'%'}
              />
            </Stack>
          </Grid>
        </Grid>
      </ETAccordion>

      {/* Battery Capacity inputs */}
      <ETAccordion
        title={'storage.Battery_Capacity'}
        mb={0}
        bgColor="transparent"
      >
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={3}>
            <ControlledInput
              name={'chargingPower'}
              type="number"
              label={t('storage.Chargin_power')}
              required={true}
              registerOptions={{
                required: 'chargingPower is required',
              }}
              endIcon={'%'}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              name={'dischargingPower'}
              type="number"
              label={t('storage.Discharging_power')}
              required={true}
              registerOptions={{
                required: 'dischargingPower is required',
              }}
              endIcon={'%'}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              name={'chargingPowerEfficiency'}
              type="number"
              label={t('storage.Charging_power_efficiency')}
              required={true}
              registerOptions={{
                required: 'chargingPowerEfficiency is required',
              }}
              endIcon={'%'}
            />
          </Grid>
          <Grid item xs={3}>
            <Button variant="text" color="primary">
              {t('storage.Advanced_efficiency_table')}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              name={'dischargingPowerEfficiency'}
              type="number"
              label={t('storage.Discharging_power_efficinecy')}
              required={true}
              registerOptions={{
                required: 'dischargingPowerEfficiency is required',
              }}
              endIcon={'%'}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
              name={'averageSelfConsumption'}
              type="number"
              label={t('storage.Average_self_consumption')}
              required={true}
              registerOptions={{
                required: 'averageSelfConsumption is required',
              }}
              endIcon={'%'}
            />
          </Grid>
        </Grid>
      </ETAccordion>
    </div>
  );
};

export default ConfigureManually;
