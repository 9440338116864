import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import EmptyChart from './EmptyChart';
import { MODULES_COLORS } from '../../../../constants/constants';
import { MODLES_NAMES } from '../../../../types/miscTypes';
import * as XLSX from 'xlsx';
import { Stack, Typography } from '@mui/material';
import { COLORS } from '../../../pages/EnergyLayout/components/LoadFlow/utils';
import { useSelector } from 'react-redux';
import { SettingsTypes } from '../../../../types/reduxTypes';

interface SeriesDataItem {
  date: string;
  number: number;
}

interface SeriesData {
  name: MODLES_NAMES;
  data: SeriesDataItem[];
}

interface TimeSeriesTestChartProps {
  seriesData?: SeriesData[];
  height: number;
  title?: string;
}
interface ConvertedSeries {
  name: string;
  data: ConvertedData[];
}

interface ConvertedData {
  date: number;
  // Add other properties as needed
}

const exportToExcel = (data: { name: string; data: any[] }[]) => {
  const wb = XLSX.utils.book_new();

  data.forEach((item) => {
    const ws = XLSX.utils.json_to_sheet(item.data);
    XLSX.utils.book_append_sheet(wb, ws, `${item.name}`); // item.name is the sheet name that will be displayed in the Excel file
  });

  XLSX.writeFile(wb, 'MultiSheetData.xlsx');
};

const TimeSeriesTestChart: React.FC<TimeSeriesTestChartProps> = ({
  seriesData = [],
  height,
  title = '',
}) => {
  const { isDarkMode } = useSelector(
    ({ settings }: { settings: SettingsTypes }) => settings
  );

  const chartRef = useRef<am5.Root | null>(null);

  // Convert series data dates to timestamps
  const seriesDataConverted: ConvertedSeries[] = useMemo(() => {
    return seriesData.map((series) => {
      const seriesData = series?.data ?? [];
      return {
        name: series.name,
        data: seriesData?.map((item) => ({
          ...item,
          date: new Date(item.date).getTime(),
        })),
      };
    });
  }, [seriesData]);

  // Find the maximum date among all series data
  const maxDate = seriesDataConverted.reduce((max, series) => {
    const seriesMaxDate = series.data.reduce((seriesMax, item) => {
      return Math.max(seriesMax, new Date(item.date).getTime());
    }, 0);
    return Math.max(max, seriesMaxDate);
  }, 0);

  const isEmptyData = maxDate === 0;

  // Effect to create the chart
  useEffect(() => {
    if (isEmptyData) return;

    const root = am5.Root.new('chartdiv');
    // root.setThemes([am5themes_Animated.new(root)]);

    const xyChart = root.container.children.push(
      am5xy.XYChart.new(root, {
        wheelY: 'zoomX',
        panY: false,
        // panX: true,
        // layout: root.verticalLayout,
        // maxTooltipDistance: -1,
      })
    );

    const valueAxis = xyChart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        // inside: true,
        cursorOverStyle: 'pointer',
        marginLeft: 10,
        id: 'valueAxis',
        numberFormatter: am5.NumberFormatter.new(root, {
          numberFormat: '#a', // Use automatic abbreviations
        }),
      })
    );

    const dateAxis = xyChart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'minute', count: 1 }, // time unit display
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltipDateFormat: 'MMM dd', // date format
        // start: 11,
        maxDeviation: 1.2,
        marginTop: 25,
      })
    );

    // Set the color for the Y axis labels
    valueAxis.get('renderer').labels.template.setAll({
      fill: am5.color(isDarkMode ? '#fff' : '#000'),
    });

    // Set the color for the X axis labels
    dateAxis.get('renderer').labels.template.setAll({
      fill: am5.color(isDarkMode ? '#fff' : '#000'),
    });

    // For the Y axis grid lines
    valueAxis.get('renderer').grid.template.setAll({
      strokeDasharray: [4, 4], // Adjust dash pattern as needed
      strokeOpacity: 1, // Optional: Adjust the opacity of the grid lines
      stroke: am5.color('#d8dae5'), // Gray-10
      strokeWidth: 1,
    });

    // For the X axis grid lines
    dateAxis.get('renderer').grid.template.setAll({
      strokeDasharray: [4, 4], // Adjust dash pattern as needed
      strokeOpacity: 1, // using zero to hide vertical line
      stroke: am5.color('#d8dae5'),
      strokeWidth: 1,
    });

    // the following code creates a vertical line exactly from the time unit value
    // align with specific days only
    // dateAxis.get("renderer").grid.template.setAll({
    //   location: 0.5 // Positions grid lines in the middle of the baseInterval (day in this case)
    // });
    // dateAxis.get("renderer").labels.template.setAll({
    //   location: 0.5 // Positions labels in the middle of the baseInterval
    // });

    // Create the scrollbar
    const scrollbarX = xyChart.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 5,
        // disabled: true,
        marginBottom: 55,
        maxHeight: 8,
      })
    );
    // @ts-ignore
    scrollbarX.get('background').setAll({
      // @ts-ignore

      fill: '#ccccccf5',
      fillOpacity: 1,
    });
    // Configure scrollbar grips
    scrollbarX.startGrip.setAll({
      scale: 1,
      cursorOverStyle: 'pointer',
      icon: undefined,
      width: 25,
      height: 25,
      crisp: false,
      hoverOnFocus: false,
    });
    // @ts-ignore

    scrollbarX.startGrip.get('background').setAll({
      // @ts-ignore
      fill: '#ccccccf5',
      fillOpacity: 1,
    });
    scrollbarX.endGrip.setAll({
      scale: 1,
      cursorOverStyle: 'pointer',
      icon: undefined,
      width: 25,
      height: 25,
    });
    // @ts-ignore
    scrollbarX.endGrip.get('background').setAll({
      // @ts-ignore
      fill: '#ccccccf5',
      fillOpacity: 1,
    });

    // Add cursor
    xyChart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomXY',
        yAxis: valueAxis,
        xAxis: dateAxis,
      })
    );

    // Configure tooltips
    dateAxis.set(
      'tooltip',
      am5.Tooltip.new(root, {
        themeTags: ['axis'],
      })
    );

    valueAxis.set(
      'tooltip',
      am5.Tooltip.new(root, {
        themeTags: ['axis'],
      })
    );

    const seriesInstances: am5xy.LineSeries[] = [];

    // Create series for each data set
    seriesDataConverted.forEach((series, index) => {
      // Conditional color for each series
      // const isGrid = series.name === 'Grid';
      // const isPV = series.name === 'PV';
      // const randomColor = am5.color(
      //   `#${Math.floor(Math.random() * 16777215).toString(16)}`
      // );
      const seriesName = series.name.toUpperCase();
      const seriesInstance = xyChart.series.push(
        am5xy.LineSeries.new(root, {
          name: series.name,
          xAxis: dateAxis,
          yAxis: valueAxis,
          valueYField: 'electricLoad',
          valueXField: 'date',
          legendValueText: '{valueY}',
          tooltip: am5.Tooltip.new(root, {}),
          snapTooltip: true,
          calculateAggregates: true,
          //@ts-ignore
          fill: am5.color(MODULES_COLORS?.[seriesName] ?? COLORS[index]),
          //@ts-ignore
          stroke: am5.color(MODULES_COLORS?.[seriesName] ?? COLORS[index]),

          // fill: isGrid
          //   ? am5.color('#F31212')
          //   : isPV
          //   ? am5.color('#31AF75')
          //   : randomColor,
          // stroke: isGrid
          //   ? am5.color('#F31212')
          //   : isPV
          //   ? am5.color('#31AF75')
          //   : randomColor,
        })
      );

      // Add bullet for each data point in the series
      seriesInstance.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 1, // Size of the bullet point
            fill: seriesInstance.get('fill'),
          }),
        });
      });

      // Set data for the series
      seriesInstance.data.setAll(series.data);
      seriesInstances.push(seriesInstance);

      // Configure tooltip for each series
      seriesInstance
        .get('tooltip')
        ?.label.set('text', `{name}[/]\n{valueX.formatDate()}: {valueY}`);

      // Add legend for the series
      const legendData = xyChart.plotContainer.children.push(
        am5.Legend.new(root, {
          // @ts-ignore
          xyChart: xyChart,
          series: seriesInstance,
          draggable: true,
          x: index === 0 ? 0 : index * 130 + 10,
          y: am5.percent(-10),
          centerX: index * -20,
          label: am5.Label.new(root, {
            text: series.name,
            fill: am5.color('#000'),
            fontWeight: 'bold',
            fontSize: 12,
          }),
          // centerY: am5.percent(-30),
          width: 130,
          clickTarget: 'itemContainer',
          useDefaultMarker: true,

          background: am5.RoundedRectangle.new(root, {
            fill: am5.color('#fff'), // Change the value to a Color object
            fillOpacity: 1,
            cornerRadiusTL: 5,
            cornerRadiusTR: 5,
            cornerRadiusBL: 5,
            cornerRadiusBR: 5,

            shadowColor: am5.color('#ccc'),
            shadowOpacity: 0.8,
            shadowBlur: 5,
            // shadowSpread: 2)
          }),
        })
      );
      legendData.valueLabels.template.set('forceHidden', true);
      // Set styles for legend markers
      legendData.data.setAll([seriesInstance]);
      legendData.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
      });

      // I removed this code because I think it's not needed
      // Given date
      const givenDate = new Date(maxDate);
      // Add one hour to the given date
      const newDate = new Date(givenDate.getTime());
      newDate.setHours(givenDate.getHours() + 1);

      // the following code is to zoom to current day (this feature is not needed atm)
      // Calculate the date and time 24 hours ago
      const twentyFourHoursAgo = new Date(
        givenDate.getTime() - 168 * 60 * 60 * 1000
      );
      // Pre-zoom X axis
      seriesInstance.events.once('datavalidated', () => {
        dateAxis.zoomToDates(twentyFourHoursAgo, newDate);
      });
    });

    // Save chart instance to ref
    chartRef.current = root;

    // Cleanup chart instance on unmount
    return () => {
      root.dispose();
    };
  }, [seriesDataConverted]);

  // Memoize download click handler
  const handleDownloadClick = useCallback(() => {
    exportToExcel(seriesData);
  }, [seriesData]);

  if (isEmptyData) return null;
  return (
    <div className="analytics-chart-container">
      {isEmptyData ? (
        <EmptyChart height="400px" />
      ) : (
        <>
          <Stack
            direction="row"
            alignItems="center"
            marginLeft={'45px'}
            justifyContent={'space-between'}
          >
            <Typography
              variant={'inherit'}
              width={'fit-content'}
              fontWeight={'bold'}
            >
              {title}
            </Typography>{' '}
            <div className="chartcontrols">
              <img
                src="/images/download.svg"
                alt="download"
                onClick={handleDownloadClick}
                title="Download as Excel"
              />
            </div>
          </Stack>
          <div id="chartdiv" style={{ width: '100%', height }}></div>
        </>
      )}
    </div>
  );
};

export default TimeSeriesTestChart;
