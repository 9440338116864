import { lazy } from 'react';

export const Login = lazy(() => import('./components/pages/login'));
export const SampleLoadProfile = lazy(
  () =>
    import(
      './components/pages/EnergyLayout/components/LoadFlow/components/SampleLoadProfile/index'
    )
);
export const UploadAndGenerateLoadProfile = lazy(
  () =>
    import(
      './components/pages/EnergyLayout/components/LoadFlow/components/UploadAndGenerateLoadProfile/index'
    )
);
export const ForgetPassword = lazy(
  () => import('./components/pages/ForgetPassword.tsx')
);
export const Start = lazy(() => import('./components/pages/Start'));
export const ProjectData = lazy(() => import('./components/pages/ProjectData'));
export const BusinessModel = lazy(
  () => import('./components/pages/BusinessModel')
);
export const EnergyLayout = lazy(
  () => import('./components/pages/EnergyLayout')
);
export const ProjectOverview = lazy(
  () => import('./components/pages/ProjectOverview')
);
export const PrintReview = lazy(() => import('./components/pages/PrintReview'));
export const NotFound = lazy(() => import('./components/pages/NotFound'));

// results routes
export const Results = lazy(() => import('./components/pages/Results'));
export const PieResult = lazy(
  () => import('./components/pages/Results/PieResult.tsx')
);
export const FlowGraph = lazy(
  () => import('./components/pages/Results/FlowGraph.tsx')
);
export const LineChartsResult = lazy(
  () => import('./components/pages/Results/LineChartsResult.tsx')
);
export const BarChartsResult = lazy(
  () => import('./components/pages/Results/BarChartsResult.tsx')
);
export const TableResults = lazy(
  () => import('./components/pages/Results/TabelResults.tsx')
);
export const Profile = lazy(() => import('./components/pages/Profile'));
// Energy loyouts routes
export const LoadFlow = lazy(
  () => import('./components/pages/EnergyLayout/components/LoadFlow/index.tsx')
);
export const PvSystem = lazy(
  () =>
    import('./components/pages/EnergyLayout/components/PvSystemFlow/index.tsx')
);
export const Mobility = lazy(
  () =>
    import('./components/pages/EnergyLayout/components/MobilityFlow/index.tsx')
);
export const GridFlow = lazy(
  () => import('./components/pages/EnergyLayout/components/GridFlow/index.tsx')
);
