import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomDivider from './CustomDivider.tsx';
import { useTranslation } from 'react-i18next';

const FormTitle = ({
  title,
  dividerWidth,
  dividerHeight = '1px',
  mb = 1.5,
  mr = 0,
  width = 'auto',
}: {
  title: string;
  dividerWidth: string;
  dividerHeight?: string;
  mb?: number;
  mr?: number;
  width?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      mb={mb}
      mr={mr}
      width={width}
      className={'form-title'}
    >
      <Typography> {t(title)}</Typography>
      <CustomDivider h={dividerHeight} w={dividerWidth} />
    </Stack>
  );
};

export default FormTitle;
