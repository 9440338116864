import EnergyFlowDiagramSvg from '../../../SvgIcon/EnergyFlowDiagramSvg';
import { Box } from '@mui/material';
import PrintButton from '../PrintButton';
import { useRef } from 'react';

const EnergyFlowDiagram = ({
  data: {
    pvGeneratedEnergy,
    coveredByPv,
    totalLoad,
    coveredByGrid,
    feedIntoGrid,
    curtailedGeneration,
  },
}: {
  data: {
    pvGeneratedEnergy: number;
    coveredByPv: number;
    totalLoad: number;
    coveredByGrid: number;
    feedIntoGrid: number;
    curtailedGeneration: number;
  };
}) => {
  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton
        id={'ENERGY_FLOW_GRAPH'}
        componentName="Simulation"
        element={ref}
      />
      <Box m={'25px'} p={'25px'} className="results__info" ref={ref}>
        <h2>Energy Flow Graph</h2>
        <EnergyFlowDiagramSvg
          pvGeneratedEnergy={pvGeneratedEnergy}
          coveredByPv={coveredByPv}
          totalLoad={totalLoad}
          coveredByGrid={coveredByGrid}
          feedIntoGrid={feedIntoGrid}
          curtailedGeneration={curtailedGeneration}
        />
      </Box>
    </>
  );
};

export default EnergyFlowDiagram;
