import { useState } from 'react';
import html2canvas from 'html2canvas';

interface HookReturn {
  image: string | null;
  canvas: HTMLCanvasElement | null;
  takeScreenShot: (
    node: HTMLElement,
    options?: Options
  ) => Promise<{ base64Image: string; canvas: HTMLCanvasElement } | void>;
  loader: boolean;
  error: any;
}

interface Options {
  [key: string]: any;
}

const useScreenshot = ({
  type,
  quality,
}: { type?: string; quality?: number } = {}): HookReturn => {
  const [image, setImage] = useState<string | null>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [error, setError] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const takeScreenShot = (
    node: HTMLElement,
    options: Options = {}
  ): Promise<{ base64Image: string; canvas: HTMLCanvasElement } | void> => {
    setLoader(true);
    if (!node) {
      throw new Error('You should provide correct html node.');
    }
    // Apply the desired font family to the node
    node.style.fontFamily = 'inter-bold';

    return html2canvas(node, options)
      .then((canvas) => {
        const croppedCanvas = document.createElement('canvas');
        const croppedCanvasContext = croppedCanvas.getContext('2d');
        // init data
        const cropPositionTop = 0;
        const cropPositionLeft = 0;
        const cropWidth = canvas.width;
        const cropHeight = canvas.height;

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        croppedCanvasContext?.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop
        );
        // document.body.appendChild(canvas) // you can append canvas as well

        const base64Image = croppedCanvas.toDataURL(type, quality);
        setLoader(false);

        setImage(base64Image);
        setCanvas(canvas);
        return { base64Image, canvas };
      })
      .catch(setError);
  };

  return {
    image,
    canvas,
    takeScreenShot,
    loader,
    error,
  };
};

const createFileName = (extension = '', ...names: string[]): string => {
  if (!extension) {
    return '';
  }

  return `${names.join('')}.${extension}`;
};

export { useScreenshot, createFileName };
