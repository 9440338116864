import { Stack, Typography } from '@mui/material';

const ProjectDetailsItemWithValue = ({
  label,
  value,
  justifyContent = 'space-between',
  gap = 0,
  isLabelBold = false,
  labelVariant = 'body1',
}: {
  label: string;
  value: string;
  justifyContent?: string;
  gap?: number | string;
  isLabelBold?: boolean;
  labelVariant?: string;
}) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'start'}
      justifyContent={justifyContent}
      gap={gap}
    >
      <Typography
        variant={labelVariant as any}
        fontWeight={isLabelBold ? 600 : 400}
      >
        {label}:{' '}
      </Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
};

export default ProjectDetailsItemWithValue;
