import { useCallback, useState } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import { Typography } from '@mui/material';

export default function DonutChart3({
  data,
  height,
  width,
  innerRadius,
  outerRadius,
  withPercentage,
  withLegend,
  withInnerLabel,
  customInnerLabel = null,
  valueFontSize,
  nameFontSize,
  dy,
  dyy,
  withInnerSector = true,
  mainSectorWidth = 15,
  legendWithValue = false,
}: {
  data: any;
  height: number;
  width?: number;
  innerRadius: number;
  outerRadius: number;
  withPercentage: boolean;
  withLegend: boolean;
  withInnerLabel: boolean;
  customInnerLabel?: string | null;
  valueFontSize: number;
  nameFontSize: number;
  dy: number;
  dyy: number;
  withInnerSector?: boolean;
  mainSectorWidth?: number;
  legendWithValue?: boolean;
}) {
  const renderActiveShape = (props: {
    cx: number;
    cy: number;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    fill: string;
    payload: any;
    percent: number;
  }) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
    } = props;
    return (
      <>
        <g>
          {withInnerLabel && (
            <text
              x={cx}
              y={cy}
              dy={dy}
              textAnchor="middle"
              fill="#142452"
              fontSize={nameFontSize}
              fontWeight="500"
            >
              {customInnerLabel ?? payload.name}
            </text>
          )}
          {withPercentage ? (
            <text
              x={cx}
              y={cy}
              dy={!withInnerLabel ? dyy + dy : dyy}
              textAnchor="middle"
              fill="#142452"
              fontSize={valueFontSize}
              fontWeight="700"
            >
              {`${(percent * 100).toFixed(1)}%`}
            </text>
          ) : (
            <text
              x={cx}
              y={cy}
              dy={dyy}
              textAnchor="middle"
              fill="#142452"
              fontSize={valueFontSize}
              fontWeight="700"
            >
              {payload.value}
            </text>
          )}
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius - mainSectorWidth}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          {withInnerSector && (
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={77}
              outerRadius={80}
              fill={fill}
            />
          )}
        </g>
      </>
    );
  };
  const [activePie, setActivePie] = useState<{
    activeIndex: number | undefined;
    eventType: string | undefined;
  }>({
    activeIndex: undefined,
    eventType: undefined,
  });
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleMouseEnter = useCallback(
    (_: any, index: number) => {
      if (activePie.eventType !== 'click') {
        setActivePie({ activeIndex: index, eventType: 'hover' });
      }
    },
    [activePie.eventType]
  );

  const handleClick = useCallback(
    (_: unknown, index: number) => {
      setActivePie({
        activeIndex: index === activePie.activeIndex ? undefined : index,
        eventType: index === activePie.activeIndex ? undefined : 'click',
      });
    },
    [activePie.activeIndex]
  );

  const handleMouseLeave = useCallback(() => {
    if (activePie.eventType === 'hover') {
      setActivePie({ activeIndex: undefined, eventType: 'hover' });
    }
  }, [activePie.eventType]);

  const opacity = (index: number) => {
    if (!activePie.activeIndex && activePie.activeIndex !== 0) {
      return 1;
    } else if (
      activePie.activeIndex !== index &&
      activePie.eventType === 'click'
    ) {
      return 0.2;
    } else if (activePie.activeIndex === index) {
      return 1;
    }
  };

  return (
    <>
      <Typography
        variant={'h5'}
        fontSize={20}
        mx={'auto'}
        width={'fit-content'}
      >
        PV Generator Energy (AC grid)
      </Typography>
      <ResponsiveContainer width={width} height={height}>
        <PieChart className="donut">
          <Pie
            activeIndex={activePie.activeIndex}
            activeShape={renderActiveShape as any}
            blendStroke={true}
            data={data}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            dataKey="value"
            onMouseEnter={
              activePie.eventType !== 'click' ? handleMouseEnter : undefined
            }
            onMouseLeave={
              activePie.eventType !== 'click' ? handleMouseLeave : undefined
            }
          >
            {data.map((_: unknown, index: number) => (
              <Cell key={`cell-${index}`} opacity={opacity(index)} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {withLegend && (
        <div className="Legend">
          {data.map((entry: any, index: number) => (
            <div
              className={`legend-item`}
              key={index}
              style={{
                opacity: selectedItem
                  ? selectedItem === entry.name
                    ? 1
                    : 0.2
                  : 1,
              }}
              onClick={() => {
                if (selectedItem === entry.name) {
                  setSelectedItem(null);
                } else {
                  setSelectedItem(entry.name);
                }
                handleClick(entry, index);
              }}
            >
              <span
                className="legend-dot"
                style={{ backgroundColor: entry.fill }}
              ></span>
              <span className="legend-desc">
                {' '}
                {entry.name}
                {legendWithValue && `: ${entry.value}`}{' '}
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
