const PrintIcon = ({
  fill,
  height,
  width,
  ...props
}: {
  className?: string;
  width?: number;
  height?: number;
  onClick?: any;
} & Record<string, number | string | any>) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 13H13V15H7V13ZM20 14H17V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H5C4.46957 19 3.96086 18.7893 3.58579 18.4142C3.21071 18.0391 3 17.5304 3 17V14H0V6C0 5.46957 0.210714 4.96086 0.585786 4.58579C0.960859 4.21071 1.46957 4 2 4H3V2C3 1.46957 3.21071 0.960859 3.58579 0.585786C3.96086 0.210714 4.46957 0 5 0H15C15.5304 0 16.0391 0.210714 16.4142 0.585786C16.7893 0.960859 17 1.46957 17 2V4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V14ZM5 4H15V2H5V4ZM15 11H5V17H15V11ZM18 7.5C17.9999 7.30295 17.9611 7.10785 17.8856 6.92582C17.8101 6.7438 17.6995 6.57842 17.5602 6.43913C17.4208 6.29984 17.2553 6.18937 17.0733 6.11403C16.8912 6.03868 16.696 5.99993 16.499 6C16.302 6.00007 16.1068 6.03894 15.9248 6.11441C15.7428 6.18988 15.5774 6.30046 15.4381 6.43984C15.2988 6.57922 15.1884 6.74467 15.113 6.92675C15.0377 7.10882 14.9989 7.30395 14.999 7.501C14.9991 7.89896 15.1573 8.28056 15.4388 8.56187C15.7203 8.84317 16.102 9.00113 16.5 9.001C16.898 9.00087 17.2796 8.84265 17.5609 8.56116C17.8422 8.27967 18.0001 7.89796 18 7.5Z"
      fill="#0098E2"
    />
  </svg>
);

export default PrintIcon;
