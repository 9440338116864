import { Box } from '@mui/material';
import { DataKeysType, MonthlyPeacksDataTypes } from '../ResultTypes';
import { useRef } from 'react';
import PrintButton from '../PrintButton';
import ApexBarChart from '../../../blocks/charts/BarChart2/ApexBarChart';

const MonthlyPeacks = ({ data = null }: { data: MonthlyPeacksDataTypes }) => {
  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton
        id={'MONTHLY_PEAKS'}
        componentName="Simulation"
        element={ref}
      />
      <Box m={'25px'} p={'25px'} className="results__info" ref={ref}>
        <h2>Monthly Peaks</h2>
        <ApexBarChart
          data={data?.data || []}
          title={'Monthly Peaks'}
          height={400}
          withTabs={false}
          dataKeys={data?.dataKeys as DataKeysType}
          yAxisLabel="Power [kW]"
        />
      </Box>
    </>
  );
};

export default MonthlyPeacks;
