import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function useLanguageSelector() {
  const { i18n } = useTranslation();

   const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
   };

  useEffect(() => {
    document.head.lang = i18n.language === 'ar' ? 'ar' : 'en';
    document.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('lang', i18n.language);
  }, [i18n.language]);


  // return (
  //   <div>
  //     <button onClick={() => changeLanguage('en')}>English</button>
  //     <button onClick={() => changeLanguage('ar')}>Arabic</button>
  //   </div>
  // );

  return {
    changeLanguage,
    currentLanguage: i18n.language
  };
}
export default useLanguageSelector;
