import { Box, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '../SvgIcon/SocialMediaIcons/FacebookIcon.tsx';
import WhatsUpIcon from '../SvgIcon/SocialMediaIcons/WhatsUpIcon.tsx';
import YoutubeIcon from '../SvgIcon/SocialMediaIcons/YoutubeIcon.tsx';
import Typography from '@mui/material/Typography';

const Footer = () => {
  const { t } = useTranslation();

  const navLinkClass = ({ isActive }: { isActive: boolean }) =>
    isActive ? 'isActive' : '';

  return (
    <Box className={'footer'}>
      {/* Site Links */}
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'28px'}
      >
        <NavLink to={'/'} className={navLinkClass}>
          {t('Privacy')}
        </NavLink>
        <NavLink to={'/'} className={navLinkClass}>
          {t('Terms & Conditions')}
        </NavLink>
        <NavLink to={'/business-model'} className={navLinkClass}>
          {t('Contact')}
        </NavLink>
        <NavLink to={'/energy-layout'} className={navLinkClass}>
          {t('Adress')}
        </NavLink>
      </Stack>

      {/* Social media links */}
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'28px'}
        mt={3}
      >
        <NavLink to={'/'} className={navLinkClass}>
          <FacebookIcon />
        </NavLink>
        <NavLink to={'/'} className={navLinkClass}>
          <WhatsUpIcon />
        </NavLink>
        <NavLink to={'/'} className={navLinkClass}>
          <YoutubeIcon />
        </NavLink>
      </Stack>

      {/* Copy Write  */}
      <Typography variant={'body1'} textAlign={'center'} mt={2}>
        ©2023 WattFusion
      </Typography>

      {/* Sponsors links */}
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'38px'}
        mt={3}
        className={'footer__sponsors'}
      >
        <NavLink to={'/'} className={navLinkClass}>
          <img src={'/images/sponsor1.svg'} alt={'sponsor'} />
        </NavLink>
        <NavLink to={'/'} className={navLinkClass}>
          <img src={'/images/sponsor2.svg'} alt={'sponsor'} />
        </NavLink>
        <NavLink to={'/'} className={navLinkClass}>
          <img src={'/images/sponsor3.svg'} alt={'sponsor'} />
        </NavLink>
        <NavLink to={'/'} className={navLinkClass}>
          <img src={'/images/light-logo.png'} alt={'sponsor'} />
        </NavLink>
      </Stack>
    </Box>
  );
};

export default Footer;
