import { CircularProgress, Stack } from '@mui/material';

const MainLoader = () => (
  <Stack
    alignItems={'center'}
    justifyContent={'center'}
    className={'full-height'}
  >
    <CircularProgress color={'primary'} />
  </Stack>
);
export default MainLoader;
