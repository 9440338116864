import { createSlice } from '@reduxjs/toolkit';

const toastSlice = createSlice({
  name: 'toastData',
  initialState: {
    withSuccessToast: true,
    overlay: true,
    isToastOpen: false,
    manualClose: false,
    resetTimer: false,
  },
  reducers: {
    openToast: (toast, { payload }) => {
      Object.assign(toast, {
        ...payload,
        isToastOpen: true,
        resetTimer: toast.isToastOpen,
      });
    },
    closeToast: (toast) => {
      Object.assign(toast, {
        isToastOpen: false,
        manualClose: false,
        resetTimer: false,
      });
    },
  },
});

export const { openToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;
