import { CircularProgress, Stack } from '@mui/material';
import PrintIcon from '../../SvgIcon/PrintIcone.tsx';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPrint,
  setPrintStart,
} from '../../../redux/actions-reducers/print.ts';
import { useScreenshot } from './OverviewResult/ScreenShootMethods.tsx';
import { RefObject } from 'react';

const PrintButton = ({
  id,
  element,
  componentName = '',
  withShot = false,
}: {
  selectToPrint?: () => void;
  id?: string;
  element?: RefObject<HTMLElement>;
  componentName?: string;
  withShot?: boolean;
}) => {
  const { takeScreenShot } = useScreenshot();
  const getImage = () => takeScreenShot(element?.current as HTMLElement);

  const { data, isLoading, isShotLoading } = useSelector(
    ({ print }: { print: any }) => print
  );
  const dispatch = useDispatch();

  const handelClick = (type: string) => {
    // Filter the data to get the last id
    const lastId = data.filter((item: { id: string }) =>
      item.id.startsWith(id as string)
    );

    dispatch(setPrintStart(type));
    setTimeout(() => {
      getImage().then(({ base64Image }: any) => {
        dispatch(
          setPrint({
            componentName,
            image: base64Image, // Access the base64Image property
            id: type === 'shot' ? `${id}${lastId.length}` : id,
          })
        );
      });
    }, 200);
  };

  const isMarked = () => {
    return data.findIndex((item: { id: string }) => item.id === id) !== -1;
  };
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={'10px'}
        m={'25px'}
        className={'mark-print-button'}
        onClick={() => handelClick('print')}
      >
        {!withShot && (
          <>
            {isLoading ? <CircularProgress size={20} /> : <PrintIcon />}
            <Typography variant={'h6'}>Mark To Print</Typography>
            {isMarked() && <Typography variant={'body1'}>Marked</Typography>}
          </>
        )}
      </Stack>
      {withShot && (
        <Stack
          direction={'row'}
          alignItems={'center'}
          gap={'10px'}
          m={'25px'}
          mx={5}
          className={'mark-print-button'}
          onClick={() => handelClick('shot')}
        >
          {isShotLoading ? <CircularProgress size={20} /> : <PrintIcon />}
          <Typography variant={'h6'}>Take a Shot</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default PrintButton;
