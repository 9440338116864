import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  title: string;
  icon: ReactElement;
}

const ETHeader = (props: Props) => {
  const { title, icon } = props;

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      marginBlockEnd={2}
      className={'load__header'}
      mb={4}
    >
      {icon}
      <Typography variant="h2" color={'var(--primary-color)'}>
        {title}
      </Typography>
    </Stack>
  );
};

export default ETHeader;
