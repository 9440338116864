/* eslint-disable no-case-declarations */
import { ChangeEvent, ReactNode, useState } from 'react';
import { JSXElement } from '@babel/types';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { SettingsTypes } from '../../types/reduxTypes.ts';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFormContext,
} from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers';
import { DateRangePicker } from '@mui/lab';
import { SelectOptionType } from '../../types/miscTypes.ts';
import { convertTimeToDateString } from '../pages/EnergyLayout/components/LoadFlow/utils/index.ts';
import { format } from 'date-fns';

type CssTextFieldProps = {
  customcolor?: string;
  mt?: number;
  width?: number | string;
  mb?: number | string;
  rows?: number;
  disabled?: boolean;
  isdarkmode?: string;
};

const customInputStyle = ({
  customcolor,
  mt,
  mb,
  disabled,
  rows,
  isdarkmode,
  width,
}: CssTextFieldProps) => {
  return {
    marginTop: mt && `${mt}rem`,
    marginBottom: `${mb}rem`,
    width: width ?? '100%',
    '& .MuiInputBase-root ': {
      height: rows ? 'auto' : '39px',
      borderRadius: '8px',
      borderColor: customcolor || 'var(--text-color)',
      backgroundColor: disabled
        ? isdarkmode
          ? '#c4c4c40f'
          : '#e8e8e8'
        : 'transparent',
    },
    '& .MuiInputBase-input': {
      color: 'var(--third-color)',
      fontWeight: 'bold',
      fontSize: '13px',
      '&::placeholder': {
        fontWeight: '100',
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      color: 'var(--text-color)',
    },
    '& .MuiInputLabel-shrink': {
      top: '0 !important',
    },
    '& label.Mui-focused': {
      color: '#A0AAB4',
      top: '0 !important',
    },
    ...(!rows
      ? {
          '.MuiFormLabel-root': {
            top: `calc(50% - 28px)`,
            textTransform: 'capitalize',
            '& .MuiFormLabel-asterisk': {
              color: 'red',
              fontWeight: 900,
            },
          },
          '.css-t3vfsm-MuiFormLabel-root-MuiInputLabel-root.Mui-error': {
            top: '-6px',
          },
        }
      : {}),
    '& .MuiFormLabel-asterisk': {
      color: 'red',
      fontWeight: 900,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: customcolor || '#B2BAC2',
    },
    '& .MuiInputAdornment-root': {
      'img, svg': {
        width: '25px',
        height: '25px',
        fill: 'var(--block-color)',
      },
      p: {
        color: 'var(--primary-color)',
        fontSize: '12px',
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: customcolor || 'var(--third-color)',
        '& legend': {
          span: {
            padding: '0 8px',
          },
          '.notranslate': {
            padding: '0',
          },
        },
      },

      '&:hover fieldset': {
        borderColor: customcolor || '#7b91a2',
      },
      '&.Mui-focused fieldset': {
        borderColor: customcolor || 'var(--text-color)',
      },
      '.css-supohe-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
        ' -webkit-text-fill-color': 'var(--disabled-color)',
      },
      ' .css-zgan81-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
        ' -webkit-text-fill-color': 'var(--disabled-color)',
      },
    },
    '.css-1wc848c-MuiFormHelperText-root': {
      height: 0,
      margin: '0 5px',
    },
    '.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
      color: 'var(--primary-color)',
    },
    '.css-v3vozx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled':
      {
        '-webkit-text-fill-color': 'var(--disabled-color)',
      },
    '.css-10nl5b2-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled':
      {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--disabled-color)',
        },
      },
    '.css-6r42io-MuiSvgIcon-root-MuiSelect-icon': {
      color: 'var(--text-color)',
    },
    '.css-6r42io-MuiSvgIcon-root-MuiSelect-icon.Mui-disabled': {
      color: 'var(--disabled-color)',
    },
    '.css-1oyi4r-MuiButtonBase-root-MuiRadio-root.Mui-checked, .css-1oyi4r-MuiButtonBase-root-MuiRadio-root':
      {
        color: 'var(--primary-color)',
      },
    '.MuiFormControlLabel-label': {
      color: customcolor || 'var(--text-color)',
      fontSize: '14px',
      whiteSpace: 'nowrap',
    },
    '.MuiFormControlLabel-label.Mui-disabled': {
      color: 'var(--disabled-color)',
    },
  };
};
const CssTextField = styled(TextField)(customInputStyle);
const CssSelect = styled(Box)(customInputStyle);
const CssDate = styled(DatePicker)(customInputStyle);
const CssDateRange = styled(DateRangePicker)(customInputStyle);
const CssTimePicker = styled(DesktopTimePicker)(customInputStyle);
const CssRadio = styled(Box)(customInputStyle);
const CssCheckbox = styled(FormControlLabel as any)(customInputStyle);
const CssSwitch = styled(FormControlLabel as any)(customInputStyle);

type EtTextFieldPropsType = {
  name: string;
  variant?: 'filled' | 'standard' | 'outlined';
  helperText?: string | Merge<FieldError, FieldErrorsImpl<any>> | FieldError;
  required?: boolean;
  value?: string;
  label?: string | null;
  className?: string;
  placeholder?: string | any;
  error?: boolean;
  // errors?: Record<any, any>;
  disabled?: boolean;
  autoComplete?: string;
  color?: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  multiline?: boolean;
  multiple?: boolean;
  maxRows?: number;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: any;
  defaultValue?: string | boolean;
  type?:
    | 'text'
    | 'password'
    | 'number'
    | 'select'
    | 'date'
    | 'date-range'
    | 'textarea'
    | 'radio'
    | 'time'
    | 'checkbox'
    | 'switch';
  subLabel?: string;
  customLabel?: JSXElement;
  rows?: number;
  isLoading?: boolean;
  onChange?: (
    v:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>
      | unknown
      | any
  ) => void;
  validation?: string;
  options?: SelectOptionType[];
  mb?: number | string;
  mt?: number;
  minValue?: Date;
  maxValue?: Date;
  registerOptions?: any;
  row?: any;
  checkboxAsRadio?: boolean;
  width?: string | number;
  exclusiveList?: string[];
  minTime?: string;
  onKeyDown?: (e: any) => void;
};
const ControlledInput = ({
  label,
  type = 'text',
  isLoading = false,
  onChange,
  className,
  options = [],
  mb = 1,
  mt = 0,
  variant = 'outlined',
  placeholder,
  helperText,
  required = false,
  disabled = false,
  autoComplete,
  error = false,
  // errors,
  multiline,
  multiple,
  color, // secondary warning success
  maxRows = 10,
  rows = undefined,
  value,
  fullWidth = false,
  readOnly,
  startIcon,
  endIcon,
  name,
  registerOptions,
  minDate,
  maxDate,
  dateFormat = ['year', 'month', 'day'],
  checkboxAsRadio = false,
  width = 'auto',
  exclusiveList = [],
  defaultValue,
  minTime = '',
  // validation,
  // customLabel,
  // subLabel,
  // rows = 4,
  // minValue,
  // maxValue,
  ...props
}: EtTextFieldPropsType) => {
  const { isDarkMode } = useSelector(
    ({ settings }: { settings: SettingsTypes }) => settings
  );
  const { register, setValue, getValues, watch, formState } =
    useFormContext<any>();
  const [isChecked, setIsChecked] = useState(!!value);

  const { errors }: { errors: Record<any, any> } = formState;
  const { onChange: regOnchange, ...registerMethods } = register(name, {
    required: required ? `${label} is required` : false,
    // valueAsNumber: type === 'number',
    ...registerOptions,
  });

  switch (true) {
    // case isLoading:
    //   return (
    //     <div>
    //       <Skeleton variant="rectangular" width={200} height={35} />
    //     </div>
    //   );
    case type === 'text' ||
      type === 'password' ||
      type === 'textarea' ||
      type === 'number':
      return (
        <CssTextField
          // validation={errors[name]?.message ? 'error' : null}
          className={`text-input ${className && `${className}-input`}`}
          multiline={type === 'textarea' || multiline} // Enable multiline for textarea type
          rows={rows}
          {...(isDarkMode ? { isdarkmode: 'true' } : {})}
          // maxRows={maxRows}
          defaultValue={defaultValue}
          variant={variant}
          fullWidth={fullWidth ?? true}
          type={type}
          label={label}
          // value={
          //   type === 'number' ? Number(watch(name)).toFixed(2) : watch(name)
          // }
          error={errors ? !!errors[name] : false}
          placeholder={
            placeholder ?? (type === 'number' ? '' : (label as string))
          }
          helperText={helperText ?? (errors ? errors[name]?.message : '')}
          required={required}
          disabled={disabled || isLoading}
          autoComplete={autoComplete}
          mt={mt}
          mb={mb}
          customcolor={color}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            regOnchange(e);
          }}
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly,
            style: {
              border: 0,
            },
            // disableUnderline: true,
            startAdornment: startIcon && (
              <InputAdornment position="start">{startIcon}</InputAdornment>
            ),
            endAdornment: endIcon && (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            ),
          }}
          // width={width}
          {...props}
          {...registerMethods}
        />
      );
    case type === 'date':
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssDate
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            label={label}
            views={dateFormat}
            slotProps={{
              textField: {
                helperText: helperText ?? (errors ? errors[name]?.message : ''),
                InputLabelProps: {
                  shrink: true,
                  required,
                },
                error: errors ? !!errors[name] : false,
              },
              field: {
                clearable: true,
                // inputProps: {
                // }
              },
              clearIcon: {
                color: 'error',
              },
              clearButton: {
                color: 'error',
              },
            }}
            // fullWidth={fullWidth ?? true}
            // mt={mt}
            // mb={mb}
            value={getValues(name) ? new Date(getValues(name)) : ''}
            onChange={(date) => {
              if (onChange) {
                onChange(date);
              }
              setValue(name, date);
              // regOnchange(date);
            }}
            {...(isDarkMode ? { isdarkmode: 'true' } : {})}
            {...props}
            {...registerMethods}
          />
        </LocalizationProvider>
      );
    case type === 'date-range':
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssDateRange
            disabled={disabled}
            value={getValues(name) ? new Date(getValues(name)) : ''}
            onChange={(date: any) => {
              if (onChange) {
                onChange(date);
              }
              setValue(name, date);
            }}
            {...(isDarkMode ? { isdarkmode: 'true' } : {})}
            {...props}
            {...registerMethods}
          />
        </LocalizationProvider>
      );
    case type === 'select':
      return (
        <CssSelect
          sx={{ minWidth: 120 }}
          className={'controlled-select-input'}
          {...(isDarkMode ? { isdarkmode: 'true' } : {})}
          mt={mt}
          mb={mb}
        >
          <FormControl
            fullWidth={!fullWidth}
            color={'error'}
            error={errors ? !!errors[name] : false}
          >
            <InputLabel id="demo-simple-select-label" color={'primary'}>
              {label}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant={variant}
              fullWidth={fullWidth ?? true}
              type={type}
              label={label}
              error={errors ? !!errors[name] : false}
              placeholder={placeholder}
              multiple={multiple}
              required={required}
              disabled={disabled}
              value={
                multiple
                  ? typeof watch(name) !== 'string'
                    ? watch(name) ?? []
                    : []
                  : watch(name) ?? ''
              }
              color={'primary'}
              onChange={(e) => {
                if (onChange) {
                  onChange(e.target);
                }
                setValue(
                  name,
                  typeof e.target.value === 'string'
                    ? e.target.value.split(',')
                    : e.target.value
                );

                regOnchange(e);
              }}
              {...props}
              {...registerMethods}
            >
              {options.map(({ label, value }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {helperText ?? (errors ? errors[name]?.message : '')}
            </FormHelperText>
          </FormControl>
        </CssSelect>
      );
    case type === 'radio':
      return (
        <CssRadio mt={mt} mb={mb}>
          <FormControl>
            {label && (
              <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            )}
            <RadioGroup
              key={`${name}-${value}-radio`}
              aria-labelledby="demo-radio-buttons-group-label"
              value={value}
              name={name}
              onChange={(e) => {
                if (onChange) {
                  onChange(e);
                }
                setValue(name, e.target.value);
              }}
              {...props}
            >
              {options.map(({ label, value }) => (
                <FormControlLabel
                  value={value}
                  control={<Radio />}
                  label={label}
                  key={value}
                  disabled={disabled}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </CssRadio>
      );
    case type === 'time':
      const shouldDisableSpecificTime = (value: Date) => {
        // Get the current hour and minute from the value
        const hour = value.getHours();
        const minute = value.getMinutes();

        // Convert the disabled time to hours and minutes
        const [disabledHour, disabledMinute] = minTime.split(':').map(Number);

        // Check if the current time is before the disabled time
        if (
          hour < disabledHour ||
          (hour === disabledHour && minute <= disabledMinute)
        ) {
          return true; // Disable the time
        }
        return false; // Enable the time
      };

      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssTimePicker
            disabled={disabled}
            shouldDisableTime={(time) =>
              shouldDisableSpecificTime(new Date(time as string))
            }
            label={label}
            format="HH:mm"
            views={['hours']}
            ampm={false}
            slotProps={{
              textField: {
                helperText: helperText ?? (errors ? errors[name]?.message : ''),
                InputLabelProps: {
                  shrink: true,
                  required,
                },
                error: errors ? !!errors[name] : false,
              },
              field: {
                clearable: true,
              },
              clearIcon: {
                color: 'error',
              },
              clearButton: {
                color: 'error',
              },
            }}
            value={
              getValues(name)
                ? new Date(convertTimeToDateString(getValues(name)))
                : null
            }
            // value={new Date('Wed Nov 22 2023 04:00:00 GMT+0300 (GMT+03:00)')}
            onChange={(date) => {
              if (onChange) {
                onChange(format(new Date(date as string), 'HH:mm'));
              }
              setValue(name, format(new Date(date as string), 'HH:mm'));
            }}
            {...(isDarkMode ? { isdarkmode: 'true' } : {})}
            {...props}
            {...registerMethods}
          />
        </LocalizationProvider>
      );
    case type === 'switch':
      return (
        <CssSwitch
          {...(isDarkMode ? { isdarkmode: 'true' } : {})}
          {...props}
          {...registerMethods}
          customcolor={color}
          mb={mb}
          mt={mt}
          width={width ?? 'auto'}
          control={
            <Switch
              checked={!!value}
              onChange={(event) => {
                setIsChecked(event.target.checked);

                if (onChange) {
                  onChange(event.target.checked);
                }
                setValue(name, event.target.checked);
              }}
              name={name}
            />
          }
          label={label}
        />
      );
    case type === 'checkbox':
      return (
        <CssCheckbox
          {...(isDarkMode ? { isdarkmode: 'true' } : {})}
          {...props}
          {...registerMethods}
          // style={{ marginBottom: mb, marginTop: mt, width, color }}
          width={width ?? 'auto'}
          key={name}
          mb={mb}
          control={
            <Checkbox
              sx={{
                color: 'var(--primary-color)',
                '& .MuiSvgIcon-root': { fontSize: 20 },
                '&.Mui-checked': {
                  color: 'var(--primary-color)',
                },
              }}
              key={name}
              inputProps={{ 'aria-label': 'controlled' }}
              checked={checkboxAsRadio ? !!value : isChecked}
              // defaultChecked={!!defaultValue} // TODO need to check if not made any side effect
              onChange={(event) => {
                const inputElement = event.target as HTMLInputElement;
                setIsChecked(inputElement.checked);
                if (onChange) {
                  onChange(inputElement.checked);
                }
                setValue(name, inputElement.checked);

                if (inputElement.checked && exclusiveList.length > 0) {
                  exclusiveList.forEach((item) => {
                    return setValue(item, false);
                  });
                }
              }}
            />
          }
          labelPlacement="end"
          name={name}
          disabled={disabled}
          label={label}
        />
      );
    default:
      return null;
  }
};
export default ControlledInput;
