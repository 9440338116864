import { Box, Grid } from '@mui/material';
import OverviewCard from './OverviewCard';
import CustomDivider from '../../../blocks/CustomDivider';
import ETTabs from '../../../blocks/ETTabs';
import { useRef, useState } from 'react';
import { getOverviewCards, getOverviewChartsData, tabs } from './helper';
import { useSelector } from 'react-redux';
import { OverviewChartsDataType } from '../ResultTypes';
import PrintButton from '../PrintButton';
import ApexBarChart from '../../../blocks/charts/BarChart2/ApexBarChart';

const OverviewResult = () => {
  const [selectedTab, setSelectedTab] = useState<string>('ENERGY_CONSUMPTION');
  const { overviewData } = useSelector(
    ({ projectResult }: any) => projectResult
  );
  const { project } = useSelector(
    ({ project }: { project: Record<string, any> }) => ({
      project,
    })
  );
  const overviewCards = getOverviewCards(overviewData, project?.currency);

  const overviewChartsData: OverviewChartsDataType =
    getOverviewChartsData(overviewData);

  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton id={'overview'} componentName="overview" element={ref} />
      <Box m={'25px'} p={'25px'} className="results__info" ref={ref}>
        <h2>Overview Result</h2>
        <Grid container columnSpacing={2} mt={3} mb={3}>
          {overviewCards.map((item, index) => (
            <Grid item xs={6} key={index} mb={2}>
              <OverviewCard data={item} />
            </Grid>
          ))}
        </Grid>
        <CustomDivider bg={'var(--text-color)'} mb={3} />
        <ETTabs
          tabs={tabs}
          getActiveTab={(data) => setSelectedTab(data as string)}
          activeTab={selectedTab}
        />
        <br />
        <ApexBarChart
          data={
            overviewChartsData?.[selectedTab as keyof OverviewChartsDataType]
              ?.data as any
          }
          dataKeys={
            overviewChartsData[selectedTab as keyof OverviewChartsDataType]
              .dataKeys
          }
          title={tabs.find(({ id }) => id === selectedTab)?.name}
          height={400}
          withTabs={false}
          barSize={20}
          yAxisLabel={
            overviewChartsData?.[selectedTab as keyof OverviewChartsDataType]
              ?.unit as string
          }
        />
      </Box>
    </>
  );
};

export default OverviewResult;
