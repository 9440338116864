import { Box } from '@mui/material';

const CustomDivider = ({
  w = '100%',
  h = '1px',
  bg = 'var(--primary-color)',
  ...props
}) => {
  return (
    <Box width={w} height={h} {...props} style={{ backgroundColor: bg }}></Box>
  );
};

export default CustomDivider;
