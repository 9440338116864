import { useState } from 'react';
import { AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios';
import { apiService } from '../services/ApiService';
import { getResponseErrors } from '../utils/misc';
import { openToast } from '../redux/actions-reducers/toast';
import { useDispatch } from 'react-redux';

export type ErrorAPI =
  | AxiosError
  | null
  | unknown
  | (Record<string, any> & {
      message: string;
    });
function useApi() {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const makeRequest = async (
    method: Method,
    url: string,
    body?: any,
    config?: AxiosRequestConfig | any
  ) => {
    setIsLoading(true);
    setSuccess(false);
    setError(null);
    setData(null);

    try {
      let response: AxiosResponse<any>;
      switch (method) {
        case 'get':
          response = await apiService.api().get<any>(url, config);
          break;
        case 'post':
          response = await apiService.api().post<any>(url, body, config);
          break;
        case 'put':
          response = await apiService.api().put<any>(url, body, config);
          break;
        case 'delete':
          response = await apiService.api().delete(url, config);
          break;
        default:
          throw new Error(`Unsupported HTTP method: ${method}`);
      }
      setData(response.data);
      setSuccess(true);
      return { data: response.data.data ?? response.data, error: null }; // Return data and reset error
    } catch (error) {
      const { response }: any = error;
      // Todo If we need more details for error, we will implement these comments code
      // const axiosError = error as AxiosError;
      // const apiError: ErrorAPI = {
      //   message: axiosError?.response?.data?.message || 'An error occurred',
      //   status: axiosError?.response?.status,
      //   details: axiosError?.response?.data,
      // };
      // setError(apiError);
      setError(getResponseErrors(response?.data)?.message);
      dispatch(
        openToast({
          message: getResponseErrors(response?.data)?.message,
          mode: 'error',
        })
      );
      return { data: null, error: getResponseErrors(response?.data)?.message };
    } finally {
      setIsLoading(false);
    }
  };
  return { data, isLoading, error, success, makeRequest };
}

export default useApi;
