import { formattedNumber } from '../../../../../utils';
import { toFixed } from '../../../../../utils/misc';
import { COLORS } from '../../../EnergyLayout/components/LoadFlow/utils';
import { FinancialDataType } from '../../ResultTypes';

export const getFinancialAnalysisMainData = (
  financialData: FinancialDataType,
  currency: string
) => {
  const {
    internalRateOfReturn = null,
    accruedCashFlow = null,
    amortisationPeriod = null,
    averageEnergyPrice = null,
    averagePowerPrice = null,
    gridFeedInPrice = null,
    projectLifeTime = null,
    interestOnCapital = null,
    discountRate = null,
    inflation = null,
    returnOnInvestment = null,
    costOfElectricity = null,
    pvTotalInvestmentCosts = null,
    totalPvSubsidies = null,
    annualCosts = null,
    otherRevenuesOrSavings = null,
    totalRevenueFromGridFeedIn = null,
    totalSavingsFromPvProduction = null,
    gridEnergyReductionSavings = null,
    gridPowerReductionSavings = null,
    pvSpecificCost = null,
  } = financialData;

  const financialDataItems = [
    {
      title: 'Financial Inputs ',
      items: [
        {
          name: 'Average energy price ',
          value: averageEnergyPrice,
          unit: `${currency}/kWh`,
        },
        {
          name: 'Average power price ',
          value: averagePowerPrice,
          unit: `${currency}/kW/a`,
        },
        {
          name: 'Grid feed-in price ',
          value: gridFeedInPrice,
          unit: `${currency}/kWh`,
        },
        // {
        //   name: 'Grid Feed-in ',
        //   value: '4.68',
        //    unit: 'kWp',
        // },
        {
          name: 'Project lifetime ',
          value: projectLifeTime,
          unit: 'Years',
        },
        {
          name: 'Interest on Capital ',
          value: interestOnCapital,
          unit: '%',
        },
        {
          name: 'Discount Rate ',
          value: discountRate,
          unit: '%',
        },
        {
          name: 'Inflation ',
          value: inflation,
          unit: '%',
        },
      ],
    },
    {
      title: 'Economic Parameters ',
      items: [
        {
          name: 'PV specific cost ',
          value: formattedNumber(pvSpecificCost),
          unit: `${currency}/kWp`,
        },
        {
          name: 'Internal Rate of Return (IRR) ',
          value: toFixed(internalRateOfReturn as string, 2),

          unit: '%',
        },
        {
          name: 'Return on Investment (ROI) ',
          value: toFixed(returnOnInvestment as string, 2),

          unit: '%',
        },
        {
          name: 'Amortisation period ',
          value: toFixed(amortisationPeriod as string, 2),
          unit: 'Years',
        },
        {
          name: 'Accrued Cash Flow ',
          value: formattedNumber(accruedCashFlow),
          unit: currency,
        },
        {
          name: 'Cost of Electricity ',
          value: toFixed(costOfElectricity as string, 4),

          unit: `${currency}/kWh`,
        },
      ],
    },
    {
      title: 'Payment overview  ',
      items: [
        {
          name: 'PV total investment costs ',
          value: formattedNumber(pvTotalInvestmentCosts),
          unit: currency,
        },

        {
          name: 'Total PV subsidies  ',
          value: formattedNumber(totalPvSubsidies),
          unit: currency,
        },
        {
          name: 'Annual costs ',
          value: formattedNumber(annualCosts),
          unit: `${currency}/a`,
        },
        {
          name: 'Other revenues or savings ',
          value: formattedNumber(otherRevenuesOrSavings),
          unit: `${currency}`,
        },
      ],
    },
    {
      title: 'Savings and Revenues ',
      items: [
        {
          name: 'Total revenue from grid feed-in ',
          value: formattedNumber(totalRevenueFromGridFeedIn),
          unit: `${currency}/a`,
        },
        {
          name: 'Total Savings from PV production ',
          value: formattedNumber(totalSavingsFromPvProduction),
          unit: `${currency}/a`,
        },
        {
          name: 'Grid energy reduction savings ',
          value: formattedNumber(gridEnergyReductionSavings),
          unit: `${currency}/a`,
        },
        {
          name: 'Grid power reduction savings ',
          value: formattedNumber(gridPowerReductionSavings),
          unit: `${currency}/a`,
        },
      ],
    },
  ];

  return financialDataItems;
};

export const getFinancialCashFlowData = (financialData: FinancialDataType) => {
  const {
    investmentGrid = {},
    opexGrid = {},
    accruedCashFlowGrid = {},
    annualCashFlowGrid = {},

    investmentPV = {},
    opexPV = {},
    annualCashFlowPV = {},
    accruedCashFlowPV = {},
    costReductionGridPV = {},

    feedInProfitPVMap = {},
    electricitySavingPV = {},
  } = financialData;

  const financialCashFlowItemsUnit = ['$', '$', '$', '$', '$'];

  const PERIOD_INTERVAL = 5;
  //   Get the period based on the index.
  function getPeriod(index: number): number {
    return (index + 1) * PERIOD_INTERVAL;
  }
  // Create financial data for the given index and period.
  function createFinancialData(period: number) {
    return [
      {
        type: 'gridAlone',
        typeLabel: 'Grid Alone',
        details: {
          investments: Object.values(investmentGrid).slice(
            period - PERIOD_INTERVAL,
            period
          ),
          operatingCosts: Object.values(opexGrid).slice(
            period - PERIOD_INTERVAL,
            period
          ),
          annualCashflow: Object.values(annualCashFlowGrid).slice(
            period - PERIOD_INTERVAL,
            period
          ),
          accruedCashflow: Object.values(accruedCashFlowGrid).slice(
            period - PERIOD_INTERVAL,
            period
          ),
        },
        units: financialCashFlowItemsUnit,
      },
      {
        type: 'gridAndPv',
        typeLabel: 'Grid And PV',
        details: {
          investments: Object.values(investmentPV).slice(
            period - PERIOD_INTERVAL,
            period
          ),
          operatingCosts: Object.values(opexPV).slice(
            period - PERIOD_INTERVAL,
            period
          ),
          feedIn: Object.values(feedInProfitPVMap).slice(
            period - PERIOD_INTERVAL,
            period
          ),
          electricitySavingPV: Object.values(electricitySavingPV).slice(
            period - PERIOD_INTERVAL,
            period
          ),
          annualCashflow: Object.values(annualCashFlowPV).slice(
            period - PERIOD_INTERVAL,
            period
          ),
          accruedCashflow: Object.values(accruedCashFlowPV).slice(
            period - PERIOD_INTERVAL,
            period
          ),
        },
        units: financialCashFlowItemsUnit,
      },
      {
        type: 'pvAlone',
        typeLabel: '',
        details: {
          costReductionGridPV: Object.values(costReductionGridPV).slice(
            period - PERIOD_INTERVAL,
            period
          ),
        },
        units: financialCashFlowItemsUnit,
      },
    ];
  }

  // Calculate the number of periods needed
  const countOfPeriods = Math.ceil(
    Object.values(investmentGrid).length / PERIOD_INTERVAL
  );

  // Generate financial cash flow table data
  const financialCashFlowTablsData = Array.from(
    { length: countOfPeriods },
    (_, index) => {
      const period = getPeriod(index);
      return {
        data: createFinancialData(period),
        columns: [
          '',
          '',
          `Year ${index * PERIOD_INTERVAL + 1}`,
          `Year ${index * PERIOD_INTERVAL + 2}`,
          `Year ${index * PERIOD_INTERVAL + 3}`,
          `Year ${index * PERIOD_INTERVAL + 4}`,
          `Year ${index * PERIOD_INTERVAL + 5}`,
        ],
        period: PERIOD_INTERVAL,
      };
    }
  );

  return {
    financialCashFlowTablsData,
  };
};

export const getFinancialLoceAndSystemUtilitizationCost = (
  financialData: FinancialDataType
) => {
  const {
    gridAloneCostOfElectricity = null,
    gridPlusPvCostOfElectricity = null,
    gridAloneYearlySystemCost = null,
    gridPlusPvYearlySystemCost = null,
  } = financialData;

  const dataKeys = [
    {
      key: 'value',
      label: 'Grid Alone',
      color: '#269dd5',
    },
  ];
  const costOfElectricty = [
    {
      name: 'Grid Alone',
      value: gridAloneCostOfElectricity,
      color: '#760808',
    },
    {
      name: 'Grid + PV',
      value: gridPlusPvCostOfElectricity,
      color: '#ffa500',
    },
  ];

  const yearlySystemUtilizationCost = [
    {
      name: 'Grid Alone',
      value: gridAloneYearlySystemCost,
      color: '#760808',
    },
    {
      name: 'Grid + PV',
      value: gridPlusPvYearlySystemCost,
      color: '#ffa500',
    },
  ];

  return {
    costOfElectricty,
    dataKeys,
    yearlySystemUtilizationCost,
  };
};
export const getCostReductionGridPVData = (
  financialData: FinancialDataType
) => {
  const { costReductionGridPV = {} } = financialData;
  console.log({ costReductionGridPV });

  const costReductionGridPVData = Object.keys(costReductionGridPV).map(
    (key, index) => ({
      name: index + 1,
      costReductionGridPV: costReductionGridPV?.[+key],
    })
  );

  const costReductionGridPVWithDataKeys = {
    dataKeys: [
      {
        label: 'Cost Reduction Grid PV',
        key: 'costReductionGridPV',
        color: COLORS[0],
      },
    ],
    data: costReductionGridPVData,
  };

  return costReductionGridPVWithDataKeys;
};
