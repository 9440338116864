import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { apiService } from '../services/ApiService';
import { authService } from '../services/AuthService';
import { displayAlert } from '../utils/misc';

const useTokenManagement = () => {
  const [refreshTimeout, setRefreshTimeout] = useState<any>(null);
  // const [rToken, setRToken] = useState(
  //   localStorage.getItem('ETRefershAccessToken') ?? ''
  // );

  const accessToken = localStorage.getItem('ETAccessToken') ?? '';
  // const RAccsessToken = localStorage.getItem('ETRefershAccessToken') ?? '';
  // useEffect(() => {
  //   setRToken(RAccsessToken);
  // }, [RAccsessToken]);

  const isTokenExpired = () => {
    if (!accessToken) return true;
    try {
      const decodedToken = jwtDecode(accessToken);

      if (decodedToken && decodedToken.exp) {
        const currentTime = Math.floor(Date.now() / 1000);
        return currentTime > decodedToken.exp;
      }

      return false;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };

  const scheduleTokenRefresh = () => {
    if (!accessToken) return;
    const decodedToken = jwtDecode(accessToken);

    if (decodedToken && decodedToken.exp) {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeUntilExpiration = decodedToken.exp - currentTime;

      // Schedule the refresh 5 minutes before expiration (adjust as needed)
      const intervalID = setInterval(() => {
        refreshToken();
      }, timeUntilExpiration - 300 * 1000);

      setRefreshTimeout(intervalID);
    }
  };

  const refreshToken = async () => {
    const refershToeknFromStorage = localStorage.getItem(
      'ETRefershAccessToken'
    ); // TODO this just for testing
    const {
      success,
      accessToken: newAccessToken,
      refreshToken: newRefershToken,
    } = await authService.refreshToken(refershToeknFromStorage as string);
    if (!success) {
      displayAlert(
        'Session Expired',
        'Your account has been accessed from another device or location. \n \n For your security, please log in again to continue.',
        'error',
        ['Login']
      ).then(() => {
        window.localStorage.clear();
        window.location.href = '/login';
      });
    }
    if (newAccessToken) {
      apiService.storeToken(newAccessToken);
      apiService.storeRefershToken(newRefershToken);
      // setRToken(newRefershToken);
    } else {
      window.localStorage.clear();
      window.location.href = '/login';
    }
  };

  // Cleanup: Clear the timeout when the component is unmounted
  useEffect(() => {
    return () => {
      if (refreshTimeout) {
        clearInterval(refreshTimeout);
      }
    };
  }, [refreshTimeout]);

  return { isTokenExpired, scheduleTokenRefresh, refreshToken };
};

export default useTokenManagement;
