import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import AutoSizeStorageSystem from './AutoSizeStorageSystem';
import SelectStorageSystem from './SelectStorageSystem';
import ConfigureManually from './ConfigureManually';
import ETAccordion from '../../../../../../blocks/ETAccordion';
import ControlledInput from '../../../../../../blocks/ControlledInput';
import { useWatchFormValues } from '../../helper';

const StorageSelectionForm = () => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const { selectStorageSystem, manuallyStorageSystem }
    = useWatchFormValues();

  const components = {
    autoSize: AutoSizeStorageSystem,
    selectStorageSystem: SelectStorageSystem,
    manuallyStorageSystem: ConfigureManually,
  };

  useEffect(() => {
    if (!(selectStorageSystem || manuallyStorageSystem))
      setValue('autoSize', true);
  }, []);

  const currentComponent = Object.keys(components).find(
    (key) => watch(key) === true
  );

  const Component =
    components[currentComponent as keyof typeof components] || null;

  return (
    <>
      <ETAccordion title={''} mb={0}>
        {/*  Tabs */}
        <Box>
          <ControlledInput
            name={'autoSize'}
            type="checkbox"
            checkboxAsRadio
            value={watch('autoSize')}
            required
            label={t('storage.Auto_size_the_Storage_system')}
            onChange={(isChecked) => {
              if (isChecked) {
                setValue('selectStorageSystem', false);
                setValue('manuallyStorageSystem', false);
              }
            }}
          />
          <ControlledInput
            name={'selectStorageSystem'}
            type="checkbox"
            checkboxAsRadio
            value={watch('selectStorageSystem')}
            required
            label={t('storage.Select_a_storage_system')}
            onChange={(isChecked) => {
              if (isChecked) {
                setValue('autoSize', false);
                setValue('manuallyStorageSystem', false);
              }
            }}
          />
          <ControlledInput
            name={'manuallyStorageSystem'}
            type="checkbox"
            checkboxAsRadio
            value={watch('manuallyStorageSystem')}
            required
            label={t('storage.Configure_manually_a_storage_system')}
            onChange={(isChecked) => {
              if (isChecked) {
                setValue('autoSize', false);
                setValue('selectStorageSystem', false);
              }
            }}
          />
        </Box>
        {Component && <Component />}
      </ETAccordion>
    </>
  );
};

export default StorageSelectionForm;
