const ResultsIcon = ({fill="#0098E2", ...props}: {fill?: string; width?: number; height?: number } & Record<string, number | string>) => (
	<svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_465_3132)">
			<path d="M27.9563 19.8996C27.8327 19.5287 27.5539 19.3316 27.1133 19.3227C26.735 19.3149 26.3556 19.3205 25.9532 19.3205V18.8894C25.9532 13.9232 25.9532 8.958 25.9532 3.99173C25.9532 2.97598 25.4109 2.42912 24.4116 2.42801C23.8102 2.42801 23.2089 2.42801 22.6075 2.42801C21.5404 2.42801 21.0112 2.96596 21.0112 4.05076C21.0112 9.00701 21.0112 13.9633 21.0112 18.9206V19.2993H18.9885V18.9518C18.9885 16.9938 18.9918 15.0347 18.9863 13.0767C18.9841 12.2325 18.4199 11.6622 17.5955 11.6566C16.9034 11.6522 16.2102 11.6544 15.5181 11.6566C14.6063 11.6589 14.0574 12.218 14.0574 13.1491C14.0563 15.0893 14.0574 17.0283 14.0574 18.9685V19.2959H12.0347C12.0347 18.3359 12.0369 17.3992 12.0347 16.4625C12.0325 15.5715 11.4771 15.0013 10.6013 14.9946C9.91792 14.989 9.23456 14.9901 8.55121 14.9946C7.65028 15.0002 7.10687 15.5548 7.1025 16.4703C7.09922 17.3146 7.1025 18.1599 7.10141 19.0041C7.10141 19.1022 7.09266 19.1991 7.08829 19.3205C6.7395 19.3205 6.42133 19.3205 6.10316 19.3205C5.43512 19.3205 5.03713 19.6412 5.04479 20.1703C5.05244 20.6926 5.44058 21 6.09551 21C13.0493 21 20.002 21 26.9558 21C27.0914 21 27.2314 20.9967 27.3637 20.9699C27.836 20.873 28.1137 20.3663 27.9585 19.9007L27.9563 19.8996Z" fill={fill}/>
			<path d="M6.09205 11.025C6.1172 11.0406 6.14344 11.0562 6.16859 11.0729C6.2331 11.1074 6.29761 11.1408 6.36212 11.1754C9.97678 13.1501 14.5219 10.6441 14.8707 6.48419C15.1604 3.04601 12.6457 0.142425 9.26172 0.00431863C4.85108 -0.173883 1.84652 4.63757 3.88783 8.62373C4.01138 8.86542 3.97202 8.98459 3.79817 9.15945C2.68841 10.2732 1.58739 11.397 0.488556 12.5219C-0.0373531 13.0598 -0.144503 13.7637 0.193347 14.3785C0.686455 15.2774 1.83996 15.4188 2.5911 14.6603C3.69868 13.5432 4.79751 12.4183 5.89744 11.2945C5.97397 11.2166 6.02755 11.1152 6.09205 11.025ZM9.07694 2.91569C10.7214 2.91904 12.0422 4.2756 12.0312 5.94958C12.0203 7.60686 10.6918 8.94227 9.05945 8.94004C7.42705 8.9367 6.11283 7.59684 6.10955 5.93176C6.10627 4.25444 7.42705 2.91235 9.07694 2.91569Z" fill={fill}/>
			<path d="M9.05852 7.26954C9.79982 7.27288 10.3979 6.66588 10.3924 5.91521C10.387 5.17567 9.79764 4.58426 9.06508 4.58426C8.33034 4.58426 7.75086 5.17344 7.74976 5.92189C7.74758 6.66811 8.32815 7.26509 9.05852 7.26843V7.26954Z" fill={fill}/>
		</g>
		<defs>
			<clipPath id="clip0_465_3132">
				<rect width="28" height="21" fill="white"/>
			</clipPath>
		</defs>
	</svg>

);

export default ResultsIcon