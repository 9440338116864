import { lazy } from 'react';
import { useFormContext } from 'react-hook-form';

const EconomicConstraints = lazy(
  () => import('./EconomicConstraints')
);
const EconomicsHForm = lazy(
  () => import('./EconomicsHForm')
);
const StorageIncentives = lazy(
  () => import('./StorageIncentives')
);
const StorageEconomicsForm = () => {
  const { getValues } = useFormContext();
  const [ autoSize, selectStorageSystem, manuallyStorageSystem,
  ] = getValues([
        'autoSize',
        "selectStorageSystem",
        "manuallyStorageSystem",
      ]);

  return (
    <>
      {(selectStorageSystem || manuallyStorageSystem) && <EconomicsHForm/>}
      {autoSize && <EconomicConstraints/>}
      <StorageIncentives/>
    </>
  );
};

export default StorageEconomicsForm;
