import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OverviewCardPropsType } from '../ResultTypes';

const OverviewCard = ({ data: { title, data } }: OverviewCardPropsType) => {
  const { t } = useTranslation();

  return (
    <Box className="overview_card">
      <Typography variant={'h6'}>{title}</Typography>
      {data?.map(
        ({ name, value, unit, mt = 0, justifyContent = 'space-between' }) => (
          <Stack
            direction={'row'}
            alignItems={'start'}
            justifyContent={justifyContent}
            key={Math.random()}
            mb={0.5}
            mt={mt}
          >
            <Typography className="label" minWidth={'170px'}>
              {t(name)}:{' '}
            </Typography>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'end'}
              gap={0}
            >
              <Typography
                style={{ whiteSpace: 'pre-wrap', alignItems: 'start' }}
              >
                {value}
              </Typography>
              <Typography width={80}>{unit}</Typography>
            </Stack>
          </Stack>
        )
      )}
    </Box>
  );
};

export default OverviewCard;
