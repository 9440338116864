import swal from 'sweetalert';
import { ErrorResponseAPI } from '../types/miscTypes';
export const handleResponse = <T extends Record<string, any>>({
  success,
  ...rest
}: { success: boolean } & T) => ({ success, ...rest });

export const displayAlert = (
  title: string,
  text: string,
  icon: string,
  buttons: string[]
) => swal({ title, text, icon, buttons });

export const refactoringSelectData = (
  key: string,
  value: string,
  data: Array<Record<string, string>>
) => {
  if (!Array.isArray(data)) return [];
  return data.map((item: Record<string, string>) => {
    return {
      label: item[key],
      value: item[value],
      ...item,
    };
  });
};

export const getResponseErrors = (responseErrors: ErrorResponseAPI) => {
  const isThereFieldError =
    responseErrors?.details &&
    responseErrors?.details?.findIndex(
      ({ field }: { field: string }) => field
    ) !== -1;

  let fieldsErrors: any = [];
  if (isThereFieldError) {
    fieldsErrors = responseErrors?.details?.map(
      ({ field, errorMessage }: { field: string; errorMessage: string }) =>
        `${field}: ${errorMessage}, `
    );
  }

  const detailsSingleError =
    responseErrors?.details && responseErrors?.details[0];

  return {
    message: isThereFieldError
      ? fieldsErrors[0]
      : responseErrors.message ?? detailsSingleError,
  };
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const desktop = window.innerWidth > 992;
export const tablet = window.innerWidth < 768;
export const phone = window.innerWidth < 576;
export const biggerThanPhone = window.innerWidth > 576;
export const smallPhone = window.innerWidth < 426;

export const isDarkMode = () => {
  return document.querySelector('body')?.getAttribute('data-theme') === 'dark';
};

export const mockData = {
  title: 'test',
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple'],
  datasets: [
    {
      label: '# of Test',
      data: [12, 19, 3, 5, 22, 50],
      backgroundColor: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'silver'],
      borderWidth: 0,
    },
  ],
};

export const mockData2 = [
  {
    name: 'Rejected',
    value: 5,
    fill: '#F2ADAF',
  },
  {
    name: 'Authorised',
    value: 20,
    fill: '#70EA92',
  },
  {
    name: 'Awaiting',
    value: 24,
    fill: '#F4B676',
  },
];

export const data1 = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export const MonthsData = [
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
  {
    hour: 1,
    jan: 30000,
    feb: 30000,
    mar: 30000,
    apr: 30000,
    may: 30000,
    jun: 30000,
    jul: 30000,
    aug: 30000,
    sep: 30000,
    oct: 30000,
    nov: 30000,
    dec: 30000,
  },
];
export const Months = [
  {
    Header: 'Hour',
    accessor: 'hour',
  },
  {
    Header: 'Jan',
    accessor: 'Jan',
  },
  {
    Header: 'Feb',
    accessor: 'Feb',
  },
  {
    Header: 'Mar',
    accessor: 'Mar',
  },
  {
    Header: 'Apr',
    accessor: 'Apr',
  },

  {
    Header: 'May',
    accessor: 'May',
  },
  {
    Header: 'Jun',
    accessor: 'Jun',
  },
  {
    Header: 'Jul',
    accessor: 'Jul',
  },
  {
    Header: 'Aug',
    accessor: 'Aug',
  },
  {
    Header: 'Sep',
    accessor: 'Sep',
  },
  {
    Header: 'Oct',
    accessor: 'Oct',
  },
  {
    Header: 'Nov',
    accessor: 'Nov',
  },
  {
    Header: 'Dec',
    accessor: 'Dec',
  },
];
export const Months2 = [
  {
    Header: 'Hour',
    accessor: 'hour',
  },
  {
    Header: 'Jan',
    accessor: 'JANUARY',
  },
  {
    Header: 'Feb',
    accessor: 'FEBRUARY',
  },
  {
    Header: 'Mar',
    accessor: 'MARCH',
  },
  {
    Header: 'Apr',
    accessor: 'APRIL',
  },

  {
    Header: 'May',
    accessor: 'MAY',
  },
  {
    Header: 'Jun',
    accessor: 'JUNE',
  },
  {
    Header: 'Jul',
    accessor: 'JULY',
  },
  {
    Header: 'Aug',
    accessor: 'AUGUST',
  },
  {
    Header: 'Sep',
    accessor: 'SEPTEMBER',
  },
  {
    Header: 'Oct',
    accessor: 'OCTOBER',
  },
  {
    Header: 'Nov',
    accessor: 'NOVEMBER',
  },
  {
    Header: 'Dec',
    accessor: 'DECEMBER',
  },
];

export const isSubmitDisabled = (fields: string[]) => {
  return !fields.every((item) => !!item);
};

export const currencyOptions = [
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'CHF',
    value: 'CHF',
  },
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'CZK',
    value: 'CZK',
  },
  {
    label: 'USD',
    value: 'USD',
  },
];

export const energyLayoutItemsEnum = {
  LOAD: 'LOAD',
  PV: 'PV',
  GRID: 'GRID',
  GENERATE: 'GENERATE',
  STORAGE: 'STORAGE',
  MOBILITY: 'MOBILITY',
};

export function arraysAreEqual(arr1: number[], arr2: number[]) {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Iterate through each element and compare
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false; // If any element is different, arrays are not equal
    }
  }

  // If all elements are the same, arrays are equal
  return true;
}

export const toFixed = (num: number | string, fixed: number) => {
  if (!num) return 0;
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)![0];
};

// export function camelCaseToNormalText(str: string) {
//   // Insert a space before each uppercase letter
//   let normalText = str.replace(/([A-Z])/g, ' $1');
//   // Convert the entire string to lowercase and capitalize the first letter
//   normalText = normalText.toLowerCase();
//   // Capitalize the first letter
//   normalText = normalText.charAt(0).toUpperCase() + normalText.slice(1);
//   return normalText;
// }
export function camelCaseToNormalText(str: string) {
  if (!str) return '';

  // Step 1: Use a regex to identify transitions from lowercase to uppercase and insert spaces
  let normalText = str.replace(/([a-z])([A-Z])/g, '$1 $2');

  // Step 2: Handle sequences of uppercase letters (like "PV") that should stay together
  normalText = normalText.replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2');

  // Step 3: Convert to lowercase
  normalText = normalText.toLowerCase();

  // Step 4: Capitalize the first letter
  normalText = normalText.charAt(0).toUpperCase() + normalText.slice(1);

  return normalText;
}
