import { persistReducer, persistStore } from 'redux-persist';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../RootReducer';

const persistedReducer = persistReducer(
  { key: 'WattFusion', storage },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false, // Disable serializable check for non-serializable values
  }),
});

const persistor = persistStore(store);

export { store, persistor };
