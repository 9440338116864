import React from 'react';
import { FinancialCashFlowItemType } from '../pages/Results/ResultTypes';
import { camelCaseToNormalText } from '../../utils/misc';
import { formattedNumber } from '../../utils';

const RowspanTable = ({
  data,
  columns = [],
  isUnitFirst = true,
  period,
}: {
  data: FinancialCashFlowItemType[];
  columns: string[];
  isUnitFirst?: boolean;
  period?: string | number;
}) => {
  if (!data?.length) return;
  return (
    <table className="rowspan-table">
      <thead>
        <tr>
          {columns?.map((column, index) => (
            <th key={index}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <React.Fragment key={index}>
            {item.typeLabel !== undefined && (
              <tr>
                <td
                  rowSpan={Object.keys(item.details).length + 1}
                  className="rowspan-table__rowspan-row"
                >
                  {item.typeLabel}
                </td>
              </tr>
            )}
            {Object.entries(item.details).map(([key, value], innerIndex) =>
              renderDetailRow(key, value, innerIndex, item, isUnitFirst, period)
            )}
            {/* <tr className="rowspan-table__separator"> // TODO remove commeted code
              <td colSpan={6}></td>
            </tr> */}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

function renderDetailRow(
  key: string,
  value: any[],
  innerIndex: number,
  item: any,
  isUnitFirst: boolean,
  period?: string | number
) {
  const missingCells = 5 - value.length;
  return (
    <tr key={`${key}-${innerIndex}`}>
      <td>{camelCaseToNormalText(key)}</td>

      {value.map((val, i) => (
        <React.Fragment key={`${key}-${innerIndex}-${i}`}>
          <th title={val}>
            <p>
              {isUnitFirst && item?.units[innerIndex]} {formattedNumber(val)}{' '}
              {!isUnitFirst && item?.units[innerIndex]}
            </p>
          </th>
        </React.Fragment>
      ))}
      {!!period &&
        missingCells > 0 &&
        Array.from({ length: missingCells }).map((_, i) => (
          <th key={`missing-${i}`}>
            <p>-</p>
          </th>
        ))}
    </tr>
  );
}

export default RowspanTable;
