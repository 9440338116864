const ProjectDataIcon = ({fill="#0098E2", ...props}: {fill?: string; width?: number; height?: number } & Record<string, number | string>) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<path d="M2.12372 12.6588C2.56895 12.6588 3.01418 12.657 3.45942 12.6588C3.93464 12.6617 4.17366 12.8651 4.26927 13.3312C4.8476 16.1501 6.43543 18.1718 9.119 19.224C11.6292 20.2086 14.0484 19.8973 16.3102 18.4241C16.8426 18.0771 17.0835 18.0903 17.5372 18.5432C18.1342 19.1396 18.7313 19.7351 19.3265 20.3334C19.7399 20.7488 19.7061 21.1905 19.2375 21.543C16.9232 23.2854 14.3146 24.1031 11.4248 23.9896C6.08112 23.7814 1.47509 19.962 0.23688 14.7275C0.144084 14.3337 0.0812831 13.9314 0.0147327 13.5309C-0.0677522 13.0368 0.241566 12.6663 0.7421 12.6588C1.20233 12.6513 1.66349 12.657 2.12466 12.657L2.12372 12.6588Z" fill={fill}/>
	<path d="M24 11.6742C23.9672 14.6872 23.149 17.1206 21.5114 19.2756C21.1852 19.7051 20.7335 19.736 20.3491 19.3534C19.724 18.7307 19.1006 18.1053 18.4773 17.4807C18.1071 17.1103 18.0789 16.8149 18.377 16.3798C19.3237 14.9976 19.7989 13.4681 19.7605 11.7942C19.6752 8.04419 17.0226 4.94681 13.3342 4.26788C12.8674 4.18161 12.6574 3.93686 12.6565 3.46517C12.6546 2.55931 12.6518 1.65251 12.6565 0.746649C12.6593 0.244955 13.0277 -0.0588747 13.5216 0.00958077C15.8537 0.332165 17.9337 1.23146 19.723 2.76374C21.9932 4.70769 23.3739 7.16364 23.8454 10.1185C23.9438 10.7336 23.9691 11.3601 23.9991 11.6751L24 11.6742Z" fill={fill}/>
	<path d="M2.13025 11.2484C1.66908 11.2484 1.20886 11.2531 0.74769 11.2475C0.245282 11.2419 -0.0602869 10.8762 0.0100128 10.382C0.416813 7.54341 1.64003 5.12027 3.73402 3.15945C5.56931 1.44056 7.74391 0.398724 10.2363 0.0423809C10.449 0.012373 10.6918 0.0283147 10.8896 0.10521C11.1389 0.201798 11.243 0.447487 11.2439 0.717558C11.2458 1.6553 11.2486 2.59305 11.243 3.5308C11.2401 3.9434 11.0161 4.18628 10.5728 4.26974C8.91089 4.58201 7.48615 5.33877 6.31449 6.55971C5.23093 7.68876 4.55981 9.03255 4.26923 10.5695C4.17831 11.0487 3.93648 11.2475 3.44345 11.2494C3.00571 11.2513 2.56892 11.2494 2.13119 11.2494L2.13025 11.2484Z" fill={fill}/>
	<path d="M13.1045 16.0197H10.7771V15.7581C10.7771 13.1652 10.7771 10.5724 10.7771 7.98044C10.7771 7.38216 11.0339 7.1224 11.6282 7.12147C11.8935 7.12147 12.1587 7.11584 12.424 7.12428C12.8242 7.13647 13.1185 7.42717 13.1195 7.82853C13.1232 10.5302 13.1214 13.2328 13.1195 15.9344C13.1195 15.9569 13.111 15.9785 13.1035 16.0197H13.1045Z" fill={fill}/>
	<path d="M14.5452 9.00063C15.1179 9.00063 15.6784 8.98563 16.2389 9.00626C16.6148 9.02032 16.8697 9.33728 16.8707 9.7452C16.8735 11.0571 16.8716 12.369 16.8716 13.6809C16.8716 14.2117 16.8744 14.7424 16.8716 15.2741C16.8688 15.7421 16.5792 16.0281 16.1077 16.0309C15.5931 16.0337 15.0785 16.0309 14.5461 16.0309V9.00063H14.5452Z" fill={fill}/>
	<path d="M9.36363 10.8753V16.0311C8.94651 16.0311 8.5369 16.0311 8.12635 16.0311C7.99419 16.0311 7.86109 16.0348 7.72892 16.0301C7.34181 16.0151 7.03624 15.7319 7.03343 15.3493C7.02499 14.0852 7.02499 12.8202 7.03343 11.5561C7.0353 11.1895 7.32119 10.8903 7.68112 10.88C8.23321 10.8641 8.78717 10.8763 9.36456 10.8763L9.36363 10.8753Z" fill={fill}/>
</svg>
);

export default ProjectDataIcon