const LeftArrowIcon = ({
  stroke = 'var(--primary-color)',
  ...props
}: { stroke?: string; width?: number; height?: number } & Record<
  string,
  number | string
>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="15"
      viewBox="0 0 8 15"
      {...props}
      fill="none"
    >
      <path
        d="M7 1.5L1 7.5L7 13.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeftArrowIcon;
