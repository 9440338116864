import { Box, Divider, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import DonutChart3 from '../../../blocks/charts/DoughnutChart3.tsx';
import { SimulationResultPropsType } from '../ResultTypes.ts';
import PrintButton from '../PrintButton.tsx';
import { useRef } from 'react';

const SimulationResult = ({
  data = [],
  className,
}: SimulationResultPropsType) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton
        id={'SIMULATION_RESULT'}
        componentName="Simulation"
        element={ref}
      />
      <Box
        className={`results__info ${className ?? ''}`}
        m={'25px'}
        p={'25px'}
        ref={ref}
      >
        <h2>Simulation</h2>

        {data.length > 0 &&
          data?.map(({ title, items, chartData }, index) => (
            <Box py={6}>
              <Typography variant={'h6'}>{title}</Typography>
              <Grid container key={index} columnSpacing={8} mt={2}>
                <Grid item xs={8}>
                  {items?.map(({ name, value, unit, isSubItem }) => (
                    <Box maxWidth={'500px'}>
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        key={Math.random()}
                        mb={0.5}
                        paddingLeft={isSubItem ? 1.5 : 0}
                      >
                        <Typography className="label">{t(name)}: </Typography>
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          justifyContent={'end'}
                          minWidth={150}
                          gap={1.5}
                        >
                          <Typography>{value}</Typography>
                          {unit && (
                            <Typography width={'80px'} minWidth={'70px'}>
                              {unit}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                      <Divider />
                    </Box>
                  ))}
                </Grid>
                <Grid item xs={4} style={{ position: 'relative' }}>
                  <DonutChart3
                    data={chartData}
                    height={400}
                    innerRadius={135}
                    outerRadius={170}
                    nameFontSize={14}
                    valueFontSize={48}
                    dy={-15}
                    dyy={40}
                    mainSectorWidth={10}
                    withInnerLabel={true}
                    withLegend={true}
                    legendWithValue={true}
                    withInnerSector={false}
                    withPercentage={false}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
      </Box>
    </>
  );
};

export default SimulationResult;
