import SunIcon from '../SvgIcon/SunIcon.tsx';
import MoonIcon from '../SvgIcon/MoonIcon.tsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { setSettings } from '../../redux/actions-reducers/settings.ts';
import { useDispatch, useSelector } from 'react-redux';

const DarkMode = () => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { isDarkMode } = useSelector(({ settings }: any) => settings);
  const setDarkMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'dark');
    dispatch(setSettings({ isDarkMode: true }));
  };
  const setLightMode = () => {
    document.querySelector('body')?.setAttribute('data-theme', 'light');
    dispatch(setSettings({ isDarkMode: false }));
  };

  const toggleTheme = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      return setDarkMode();
    }
    setLightMode();
  };
  useEffect(() => {
    setIsChecked(isDarkMode);
  }, [isDarkMode]);

  return (
    <div className="dark_mode">
      <input
        className="dark_mode_input"
        type="checkbox"
        id="darkmode-toggle"
        onChange={toggleTheme}
        checked={isChecked}
      />
      <label className="dark_mode_label" htmlFor="darkmode-toggle">
        <SunIcon className="sun" />
        <MoonIcon className="moon" />
      </label>
    </div>
  );
};

export default DarkMode;
