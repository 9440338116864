import { useTranslation } from 'react-i18next';
import {  Grid } from '@mui/material';
import ControlledInput from '../../../../../../blocks/ControlledInput';
import ETAccordion from '../../../../../../blocks/ETAccordion';

const AutoSizeStorageSystem = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* battery size inputs */}
      <ETAccordion title={'storage.Battery_Size'} bgColor="transparent">
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={3}>
          <ControlledInput
              name={'minBatteryCapacity'}
              type="number"
              label={t('storage.Minimum_Battery_Capacity')}
              required={true}
              registerOptions={{
                required: 'min Battery Capacity is required',
              }}
              endIcon={'kwh'}
            />
          </Grid>
          <Grid item xs={3}>
          <ControlledInput
              name={'maxBatteryCapacity'}
              type="number"
              label={t('storage.Maximum_Battery_Capacity')}
              required={true}
              registerOptions={{
                required: 'max Battery Capacity is required',
              }}
              endIcon={'kwh'}
            />
          </Grid>
        </Grid>
      </ETAccordion>

      {/* Battry Power inputs */}
      <ETAccordion title={'storage.Battery_Power'} bgColor="transparent">
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={3}>
          <ControlledInput
              name={'minBatteryPower'}
              type="number"
              label={t('storage.Minimum_Battery_Power')}
              required={true}
              registerOptions={{
                required: 'min Battery Power is required',
              }}
              endIcon={'kw'}
            />
          </Grid>
          <Grid item xs={3}>
          <ControlledInput
              name={'maxBatteryPower'}
              type="number"
              label={t('storage.Maximum_Battery_Power')}
              required={true}
              registerOptions={{
                required: 'max Battery Power is required',
              }}
              endIcon={'kw'}
            />
          </Grid>
        </Grid>
      </ETAccordion>

      {/* Battry Parameters */}
      <ETAccordion title={'storage.Battery_Parameters'} mb={0} bgColor="transparent">
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid item xs={3}>
            <ControlledInput
                name={'depthOfDischarge'}
                type="number"
                label={t('storage.Depth_of_Discharge')}
                required={true}
                registerOptions={{
                  required: 'depth Of Discharge is required',
                }}
                endIcon={'%'}
              />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
                name={'maxNumberOfCycles'}
                type="number"
                label={t('storage.Maximum_Number_of_cycles')}
                required={true}
                registerOptions={{
                  required: 'max Number Of Cycles is required',
                }}
                endIcon={'%'}
            />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
                name={'yearlyDepreciation'}
                type="number"
                label={t('storage.Yearly_Deprciation')}
                required={true}
                registerOptions={{
                  required: 'yearly Depreciation is required',
                }}
                endIcon={'%'}
              />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
                name={'roundTripEfficiency'}
                type="number"
                label={t('storage.Round_Trip_Efficiency')}
                required={true}
                registerOptions={{
                  required: 'Model is required',
                }}
                endIcon={'%'}
              />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
                name={'initialStateOfCharge'}
                type="number"
                label={t('storage.Initial_State_of_Charge')}
                required={true}
                registerOptions={{
                  required: 'Model is required',
                }}
                endIcon={'%'}
              />
          </Grid>
          <Grid item xs={3}>
            <ControlledInput
                name={'minStateOfCharge'}
                type="number"
                label={t('storage.Minimum_State_of_Charge')}
                required={true}
                registerOptions={{
                  required: 'Model is required',
                }}
                endIcon={'%'}
              />
            </Grid>
          <Grid item xs={3}>
            <ControlledInput
                name={'maxStateOfCharge'}
                type="number"
                label={t('storage.Maximum_State_of_Charge')}
                required={true}
                registerOptions={{
                  required: 'Model is required',
                }}
                endIcon={'%'}
              />
          </Grid>
        </Grid>
      </ETAccordion>
    </>
  );
};

export default AutoSizeStorageSystem;
