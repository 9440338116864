import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { ManufacturerOptions, ModelOptions } from './helper';
import PropertiesModal from './ProprietiesModal';
import ControlledInput from '../../../../../../blocks/ControlledInput';
import Popup from '../../../../../../blocks/Modal';

const SelectStorageSystem = () => {
  const { t } = useTranslation();
  const [proprietiesModal, setProprietiesModal] = useState<boolean>(false);

  return(
  <Grid container columnSpacing={4} rowSpacing={4} p={3}>
    <Grid item xs={3}>
      <ControlledInput
        name={'manufacturer'}
        type="select"
        label={t('storage.Manufacturer')}
        options={ManufacturerOptions}
        required={true}
        fullWidth={false}
        registerOptions={{
          required: 'Manufacturer is required',
        }}
        endIcon={'g/kwh'}
      />
    </Grid>
    <Grid item xs={3}>
      <ControlledInput
        name={'model'}
        type="select"
        label={t('storage.Model')}
        options={ModelOptions}
        required={true}
        registerOptions={{
          required: 'Model is required',
        }}
        endIcon={'g/kwh'}
      />
    </Grid>
    <Grid item xs={4}>
      <Button
        variant="text"
        color="primary"
        onClick={() => setProprietiesModal(true)}
        >
        {t('storage.See_properties')}
      </Button>
    </Grid>
    <Popup
      open={proprietiesModal}
      onClose={()=> setProprietiesModal(false)}
      title={t('mobitity.new_ev_template_title')}
    >
      <PropertiesModal onClose={()=> setProprietiesModal(false)} />
    </Popup>
</Grid>
)};

export default SelectStorageSystem;
