import { Button, ButtonProps, styled } from '@mui/material';
import { TFunction } from 'i18next';

export const Mobility = ['Shell Tank Station', '+'];

export const ConfigType = (
  t: TFunction<'translation', undefined, 'translation'>
) => [
  { label: t('mobitity.automatic_config'), value: 'automaticConfig' },
  { label: t('mobitity.manual_config'), value: 'manualConfig' },
];

export const ChargeType = [
  { label: 'AC', value: 'ac' },
  { label: 'DC', value: 'dc' },
  { label: 'Hybrid', value: 'hybrid' },
];

export const OptimizationType = (
  t: TFunction<'translation', undefined, 'translation'>
) => [
  { label: t('mobitity.optimize_time'), value: 'time' },
  { label: t('mobitity.optimize_power'), value: 'power' },
];

export const tableCells = (
  t: TFunction<'translation', undefined, 'translation'>
) => [
  t('mobitity.charger_type'),
  t('mobitity.manufacturer'),
  t('mobitity.model'),
  t('mobitity.qty'),
  t('mobitity.charging_power'),
  t('mobitity.charging_points'),
  t('mobitity.num_utilization'),
  t('mobitity.charging_duration'),
];

export const EvCells = (
  t: TFunction<'translation', undefined, 'translation'>
) => [
  t('mobitity.ev_brand'),
  t('mobitity.model'),
  t('mobitity.qty'),
  t('mobitity.charging_power'),
  t('mobitity.battery_capacity'),
  t('mobitity.average_milage'),
  t('mobitity.consumption'),
];

export const ButtonWithIcon = styled(Button)<ButtonProps>(() => ({
  padding: '10px 16px',
  borderRadius: '8px',
  textTransform: 'capitalize',
  fontSize: '12px',
  fontWeight: 500,
  width: 'fit-content',
  marginInlineEnd: '8px',
  '&.primaryButton': {
    '&, &:hover': {
      backgroundColor: '#0098E2',
      color: '#F1F2F7',
    },
  },
  '&.outlineButton': {
    '&, &:hover': {
      border: '1px #4A567F solid',
      color: '#4A567F',
      background: 'transparent',
    },
  },
  svg: {
    marginInlineEnd: '6px',
  },
}));
