import { Box, Divider, ListItemIcon, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ReactNode } from 'react';
import NotificationIcon from '../SvgIcon/NotificationIcon';

type FloatingMenuPropsType = {
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  open: boolean;
  className?: string;
  withoutArrow?: boolean;
  isNotification?: boolean;
  headerTitle?: string;
  handleClose: () => void;
  items: {
    title: string;
    subTitle: string;
    img?: string;
    alt?: string;
    onClick: () => void;
    icon?: ReactNode;
  }[];
};
const FloatingMenu = ({
  anchorEl,
  open,
  handleClose,
  items,
  className = '',
  isNotification = false,
  withoutArrow = false,
  headerTitle,
}: FloatingMenuPropsType) => {
  return (
    <Menu
      anchorEl={anchorEl as () => Element}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '&:before': {
            content: '""',
            display: withoutArrow ? 'none' : 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      className={className}
    >
      {headerTitle && <Typography variant="body1">{headerTitle}</Typography>}
      {items.map(
        ({ title, img, alt = '/', onClick, icon, subTitle }, index) => {
          return (
            <div key={index}>
              <MenuItem onClick={onClick}>
                <ListItemIcon>
                  {isNotification ? (
                    <NotificationIcon fill={'var(--primary-color)'} />
                  ) : (
                    icon ?? (img && <img src={img} alt={alt} />)
                  )}
                </ListItemIcon>
                <Box>
                  <Typography variant="subtitle1">{title}</Typography>
                  <span title={subTitle}>{subTitle}</span>
                </Box>
              </MenuItem>
              {index !== items.length - 1 && <Divider />}
            </div>
          );
        }
      )}
    </Menu>
  );
};

export default FloatingMenu;
