import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import { ButtonWithIcon } from '../../../MobilityFlow/helper';
import CloseIcon from '../../../../../../SvgIcon/CloseIcon';
import ETAccordion from '../../../../../../blocks/ETAccordion';
import ProjectDetailsItemWithValue from '../../../../../../blocks/ProjectDetailsItemWithValue';
interface Props {
  onClose: () => void;
}

function PropertiesModal(props: Props) {
  const { t } = useTranslation();

  // from api and iterate data .map
  return (
  <ETAccordion title={'storage.Basic_Data'} bgColor="transparent">
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <ProjectDetailsItemWithValue
          label={t('Company')}
          value={'Amazon'}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectDetailsItemWithValue
          label={t('Name')}
          value={'Khaled Ziad hhhh'}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectDetailsItemWithValue
          label={t('Description')}
          value={'dfghjk fghjk ghjkl;'}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectDetailsItemWithValue
          label={t('Version')}
          value={'345678'}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectDetailsItemWithValue
          label={t('Created_at')}
          value={'14/6/2013 20:04:66 am'}
        />
      </Grid>
      <Grid item xs={12}>
        <ProjectDetailsItemWithValue
          label={t('User_ID')}
          value={'dfghjk456789'}
        />
      </Grid>

      <Grid item xs={12}>
        <Stack direction={'row'} alignItems={'end'} justifyContent={'end'}>
          <ButtonWithIcon
            variant="contained"
            fullWidth
            className="outlineButton"
            onClick={props.onClose}
          >
            <CloseIcon />
            {t('Close')}
          </ButtonWithIcon>

        </Stack>
      </Grid>
    </Grid>
  </ETAccordion>
  );
}

export default PropertiesModal;
