import { createSlice } from '@reduxjs/toolkit';
// import { apiEndPoints } from '../../api';
// import { apiService } from '../../services/ApiService';

// export const login = createAsyncThunk('currentUser/login', async (userData) => {
//   const { data } = await apiService
//     .api()
//     .post(apiEndPoints.auth.login, userData)
//     .then(({ data }) => data);

//   return data;
// });

const userSlice = createSlice({
  name: 'currentUser',
  initialState: {},
  reducers: {
    setUser: (user, { payload }) => {
      Object.assign(user, payload);
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
