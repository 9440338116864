export const numberFormatOptions = [
  {
    label: '####.##',
    value: 'DECIMAL',
  },
  {
    label: '####,##',
    value: 'THOUSAND',
  },
];

export const unitOptions = [
  {
    label: 'kw',
    value: 'KW',
  },
  {
    label: 'w',
    value: 'W',
  },
  {
    label: 'kwh',
    value: 'KWH',
  },
];
export const timeIntervalOptions = [
  {
    label: '1 Min',
    value: '1',
  },
  {
    label: '15 Min',
    value: '15',
  },
  {
    label: '30 Min',
    value: '30',
  },
  {
    label: '60 Min',
    value: '60',
  },
];

const currentYear = new Date().getFullYear();
export const startYearOptions = Array.from(
  { length: currentYear - 1999 },
  (_, index) => currentYear - index
).map((item) => ({ label: item.toString(), value: item }));

export const workingDaysOptions = [
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
  {
    label: 'Sunday',
    value: 7,
  },
];

export const COLORS = [
  '#67b7dc',
  '#E5A662',
  '#0A9966',
  '#3b54ed',
  '#dbb363',
  '#990a1d',
  '#000',
];

export function convertTimeToDateString(timeString: string): string {
  const now = new Date();
  const [hours, minutes] = timeString.split(':').map(Number);

  // Set only the time values in the Date object
  now.setHours(hours);
  now.setMinutes(minutes);
  now.setSeconds(0);

  // Convert the Date object to the desired string format
  const dateString = now.toString();

  return dateString;
}
