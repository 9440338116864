import {
  ChartDataTypes,
  EnergyPriceAnalysisDataTypes,
  FinancialDataType,
  OverviewDataType,
  SimulationData,
} from '../../components/pages/Results/ResultTypes';

export interface RefactoredResultData {
  overviewData: OverviewDataType;
  simulationData: SimulationData;
  financialData: FinancialDataType;
  energyPricesAnalysisData: EnergyPriceAnalysisDataTypes;
}

export const getRefactoredResultData = (data: any): RefactoredResultData => {
  const {
    projectName,
    projectNumber,
    projectAddress,
    contactPerson,
    contactNumber,
    businessLayoutResult,
    internalRateOfReturn,
    amortisationPeriod,
    accruedCashFlow,
    totalConsumptions,
    loadMaxPower,
    loadAvgPower,
    numberOfPhases,
    gridVoltage,
    systemConfiguration,
    gridOperation,
    powerFactor,

    pvSystemRatedPower,
    specificAnnualYield,
    performanceRatio,
    pvGeneratedEnergy,
    directPowerConsumption,
    pvCO2EmissionAvoided,
    levelOfSelfSufficiency,
    totalLoad,
    gridAlone,
    gridAndPV,
    pvGeneratedEnergyMonthly,
    consumer,
    coveredByPvMonthly,
    coveredByGridMonthly,
    directlyConsumedMonthly,
    gridFeedIn,
    curtailmentEnergy,
    totalGrid,
    directlyConsumed,
    feedIntoTheGrid,
    curtailedGeneration,
    coveredByPv,
    coveredByGrid,
    loadSelfSufficiency,
    numberOfLoad,
    maximumTotalPeak,
    businessLayoutModels,
    loadProfileData,
    gridTimeSeriesElectricLoadData,
    pvTimeSeriesElectricLoadData,
    loadTimeSeriesElectricLoadData,

    /// Financial main Inputs
    averageEnergyPrice,
    averagePowerPrice,
    gridFeedInPrice,
    projectLifeTime,
    interestOnCapital,
    discountRate,
    inflation,
    returnOnInvestment,
    costOfElectricity,
    pvTotalInvestmentCosts,
    totalPvSubsidies,
    annualCosts,
    otherRevenuesOrSavings,
    totalRevenueFromGridFeedIn,
    totalSavingsFromPvProduction,
    gridEnergyReductionSavings,
    gridPowerReductionSavings,
    pvSpecificCost,

    // Financial Cash Flow Table
    investmentGrid,
    investmentPV,
    opexGrid,
    opexPV,
    annualCashFlowGrid,
    annualCashFlowPV,
    feedInProfitPVMap,
    electricitySavingPV,
    accruedCashFlowGrid,
    accruedCashFlowPV,
    costReductionGridPV,

    // Financial LOCE and System Utilization Costs
    gridAloneCostOfElectricity,
    gridPlusPvCostOfElectricity,
    gridAloneYearlySystemCost,
    gridPlusPvYearlySystemCost,

    // Energy Price Analysis
    avgPurchaseCostHourlyGrid,
    maxPurchaseCostHourlyGrid,
    minPurchaseCostHourlyGrid,
    avgPurchaseCostHourlyPV,
    maxPurchaseCostHourlyPV,
    minPurchaseCostHourlyPV,
    powerVariableAverage,
    mean,
    median,
    min,
    max,
    standardDeviation,
    skewness,
    kurtosis,
    hourlyPatterns,
    seasonalTrends,
    priceRange,
    iqr,
    correlationWithLagged,
    peakHoursFrequency,
    offPeakHoursFrequency,
    percentageOfNegativePrices,
    energyTotalCostGrid,
    energyTotalCostPV,
    powerTotalCostGrid,
    powerTotalCostPV,
    pvNewMaxPower,
    totalCostGridYearly,
    totalCostPVYearly,
    lcoeGrid,
    lcoePV,

    energyCostGridAlone,
    energyCostGridAndPV,
    energyPriceCurve,
    powerPriceCurve,
  } = data;

  const overviewData: OverviewDataType & {
    chartData: Omit<ChartDataTypes, 'name'>;
  } = {
    projectName,
    projectNumber,
    projectAddress,
    contactPerson,
    contactNumber,
    businessLayoutResult,
    internalRateOfReturn,
    amortisationPeriod,
    accruedCashFlow,
    totalConsumptions,
    loadMaxPower,
    loadAvgPower,
    numberOfPhases,
    gridVoltage,
    pvSystemRatedPower,
    specificAnnualYield,
    performanceRatio,
    pvGeneratedEnergy,
    directPowerConsumption,
    pvCO2EmissionAvoided,
    levelOfSelfSufficiency,
    businessLayoutModels,
    systemConfiguration,
    loadSelfSufficiency,
    gridOperation,
    powerFactor,
    chartData: {
      totalLoad,
      gridAlone,
      gridAndPV,
      pvGeneratedEnergyMonthly,
      consumer,
      coveredByPvMonthly,
      coveredByGridMonthly,
      directlyConsumedMonthly,
      gridFeedIn,
      curtailmentEnergy,
      totalGrid,
    },
  };
  const simulationData: SimulationData = {
    pvSystemRatedPower,
    specificAnnualYield,
    performanceRatio,
    pvGeneratedEnergy,
    directlyConsumed,
    feedIntoTheGrid,
    curtailedGeneration,
    directPowerConsumption,
    pvCO2EmissionAvoided,
    totalLoad,
    coveredByPv,
    coveredByGrid,
    loadSelfSufficiency,
    directlyConsumedMonthly,
    numberOfLoad,
    maximumTotalPeak,
    totalConsumptions,
    pvGeneratedEnergyMonthly,
    totalGrid,
    consumer,
    gridFeedIn,
    curtailmentEnergy,
    gridAlone,
    gridAndPV,
    coveredByPvMonthly,
    coveredByGridMonthly,
    loadProfileData,
    gridTimeSeriesElectricLoadData,
    pvTimeSeriesElectricLoadData,
    loadTimeSeriesElectricLoadData,
  };

  const financialData: FinancialDataType = {
    internalRateOfReturn,
    accruedCashFlow,
    amortisationPeriod,
    averageEnergyPrice,
    averagePowerPrice,
    gridFeedInPrice,
    projectLifeTime,
    interestOnCapital,
    discountRate,
    inflation,
    returnOnInvestment,
    costOfElectricity,
    pvTotalInvestmentCosts,
    totalPvSubsidies,
    annualCosts,
    otherRevenuesOrSavings,
    totalRevenueFromGridFeedIn,
    totalSavingsFromPvProduction,
    gridEnergyReductionSavings,
    gridPowerReductionSavings,
    pvSpecificCost,

    // Cash Flow Table
    investmentGrid,
    investmentPV,
    opexGrid,
    opexPV,
    annualCashFlowGrid,
    annualCashFlowPV,
    feedInProfitPVMap,
    electricitySavingPV,
    accruedCashFlowGrid,
    accruedCashFlowPV,
    costReductionGridPV,
    // Financial LOCE and System Utilization Costs
    gridAloneCostOfElectricity,
    gridPlusPvCostOfElectricity,
    gridAloneYearlySystemCost,
    gridPlusPvYearlySystemCost,
  };

  const energyPricesAnalysisData: EnergyPriceAnalysisDataTypes = {
    avgPurchaseCostHourlyGrid,
    maxPurchaseCostHourlyGrid,
    minPurchaseCostHourlyGrid,
    avgPurchaseCostHourlyPV,
    maxPurchaseCostHourlyPV,
    minPurchaseCostHourlyPV,
    powerVariableAverage,
    mean,
    median,
    min,
    max,
    standardDeviation,
    skewness,
    kurtosis,
    hourlyPatterns,
    seasonalTrends,
    priceRange,
    iqr,
    correlationWithLagged,
    peakHoursFrequency,
    offPeakHoursFrequency,
    percentageOfNegativePrices,

    energyTotalCostGrid,
    energyTotalCostPV,
    powerTotalCostGrid,
    powerTotalCostPV,
    loadMaxPower,
    pvNewMaxPower,
    totalCostGridYearly,
    totalCostPVYearly,
    lcoeGrid,
    lcoePV,

    energyCostGridAlone,
    energyCostGridAndPV,
    energyPriceCurve,
    powerPriceCurve,
  };

  return {
    overviewData,
    simulationData,
    financialData,
    energyPricesAnalysisData,
  };
};

// - 3c LCOE and Systems Utilization Costs
//  Grid alone = gridAloneCostOfElectricity;
//  Grid + PV == gridPlusPvCostOfElectricity;
//  Grid alone == gridAloneYearlySystemCost;
//  Grid + PV == gridPlusPvYearlySystemCost;
