import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Box, Grid, Stack, Switch } from '@mui/material';
import StartIcon from '../SvgIcon/StepsBar/StartIcon.tsx';
import BusinessModelIcon from '../SvgIcon/StepsBar/BusinessModelIcon.tsx';
import EnergyLayoutIcon from '../SvgIcon/StepsBar/EnergyLayoutIcon.tsx';
import PrintPreviewIcon from '../SvgIcon/StepsBar/PrintPreviewIcon.tsx';
import ProjectDataIcon from '../SvgIcon/StepsBar/ProjectData.tsx';
import ResultsIcon from '../SvgIcon/StepsBar/ResultsIcon.tsx';
import { useLocation, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { setSettings } from '../../redux/actions-reducers/settings.ts';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsTypes } from '../../types/reduxTypes.ts';
import { useTranslation } from 'react-i18next';
import ConditionalLinkWrapper from '../HOC/ConditinalComponent.tsx';

const BottomHeader = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navLinkClass = (href: string) => {
    return pathname.includes(href) ? 'isActive' : '';
  };

  const { settings, project, projectResult } = useSelector(
    ({
      settings,
      project,
      projectResult,
    }: {
      settings: SettingsTypes;
      project: any;
      projectResult: any;
    }) => ({
      settings,
      project,
      projectResult,
    })
  );

  const params = useParams();

  const { projectId } = params;
  const { componentDraws } = project;
  const { isProjectDetailsOpen } = settings;
  const dispatch = useDispatch();

  return (
    <Box className={'steps-bar'}>
      <Grid container>
        {/*Steps  and save Buttons */}
        <Grid item xs={9}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className={'steps-bar_buttons-save'}
          >
            <ButtonGroup
              aria-label="text button group"
              className={'steps-bar_buttons'}
            >
              <ConditionalLinkWrapper
                link={'/'}
                className={navLinkClass('start')}
              >
                {/* <NavLink to={'/'} className={navLinkClass('/')}> */}
                <Button>
                  <StartIcon />
                  {t('Start')}
                </Button>
                {/* </NavLink> */}
              </ConditionalLinkWrapper>

              <ConditionalLinkWrapper
                link={`/project-Data/${projectId}`}
                className={navLinkClass('project-Data')}
              >
                {/* <NavLink to={'/project-Data'} className={navLinkClass('/')}> */}
                <Button>
                  <ProjectDataIcon />
                  {t('Project_Data')}
                </Button>
                {/* </NavLink> */}
              </ConditionalLinkWrapper>

              <ConditionalLinkWrapper
                link={`/business-model/${projectId}`}
                className={navLinkClass('business-model')}
              >
                {/* <NavLink to={'/business-model'} className={navLinkClass('/')}> */}
                <Button>
                  <BusinessModelIcon />
                  {t('Business_Model')}
                </Button>
                {/* </NavLink> */}
              </ConditionalLinkWrapper>
              <ConditionalLinkWrapper
                link={
                  componentDraws?.length > 0
                    ? `/energy-layout/${projectId}`
                    : ''
                }
                className={navLinkClass('energy-layout')}
              >
                {/* <NavLink to={'/energy-layout'} className={navLinkClass('/')}> */}
                <Button>
                  <EnergyLayoutIcon />
                  {t('Energy_Layout')}
                </Button>
                {/* </NavLink> */}
              </ConditionalLinkWrapper>
              {/* <ConditionalLinkWrapper
                link={
                  projectResult.overviewData
                    ? `/project-overview/${projectId}`
                    : ''
                }
                className={navLinkClass('project-overview')}
              >
                <Button
                  className={projectResult.overviewData ? '' : 'opacity-5'}
                >
                  <ProjectOverviewIcon />
                  {t('Project_Overview')}
                </Button>
              </ConditionalLinkWrapper> */}
              <ConditionalLinkWrapper
                link={
                  projectResult.overviewData
                    ? `/results/${projectId}/overview`
                    : ''
                }
                className={navLinkClass('results')}
              >
                {/* <NavLink to={'/results/'} className={navLinkClass('results')}> */}
                <Button
                  className={projectResult.overviewData ? '' : 'opacity-5'}
                >
                  <ResultsIcon />
                  {t('Results')}
                </Button>
                {/* </NavLink> */}
              </ConditionalLinkWrapper>
              <ConditionalLinkWrapper
                link={
                  projectResult.overviewData
                    ? `/print-preview/${projectId}`
                    : ''
                }
                className={navLinkClass('print-preview')}
              >
                <Button
                  className={projectResult.overviewData ? '' : 'opacity-5'}
                >
                  <PrintPreviewIcon />
                  {t('Print_Preview')}
                </Button>
              </ConditionalLinkWrapper>
            </ButtonGroup>
            {/* TODO we need to remove this button in future */}
            {/* <Button className="steps-bar__save-button">
              <SaveIcon />
              {t('Save')}
            </Button> */}
          </Stack>
        </Grid>
        {/*Project details section*/}
        <Grid item xs={3}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-around'}
            className={'steps-bar_project-details'}
          >
            <Typography variant={'body1'} component="p">
              {t('Project_Details')}
            </Typography>
            <Switch
              color="primary"
              onChange={() => {
                dispatch(
                  setSettings({ isProjectDetailsOpen: !isProjectDetailsOpen })
                );
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BottomHeader;
