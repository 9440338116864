import { Box } from '@mui/material';
import { ConverageOfConsumptionPropsType, DataKeysType } from '../ResultTypes';
import PrintButton from '../PrintButton';
import { useRef } from 'react';
import ApexBarChart from '../../../blocks/charts/BarChart2/ApexBarChart';

const ConverageOfConsumption = ({
  data = null,
}: {
  data: ConverageOfConsumptionPropsType;
}) => {
  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton
        id={'COVERAGE_OF_CONSUMPTION'}
        componentName="Simulation"
        element={ref}
      />
      <Box m={'25px'} p={'25px'} className="results__info" ref={ref}>
        <h2>Converage Of Consumption</h2>
        <ApexBarChart
          data={data?.data || []}
          title={'Energy Forcast With Consumption'}
          height={400}
          withTabs={false}
          dataKeys={data?.dataKeys as DataKeysType}
          barSize={20}
          yAxisLabel="Energy [kWh]"
        />
      </Box>
    </>
  );
};

export default ConverageOfConsumption;
