const PrintPreviewIcon = ({fill="#0098E2", ...props}: {fill?: string; width?: number; height?: number } & Record<string, number | string>) => (
	<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_465_3138)">
			<path d="M19.003 18.733V14.9527C19.1434 14.9527 19.2757 14.9573 19.4071 14.9517C19.7895 14.9348 20.0738 14.6255 20.0675 14.2393C20.0612 13.8625 19.7805 13.5672 19.4071 13.5494C19.3468 13.5466 19.2874 13.5485 19.2271 13.5485C14.0604 13.5485 8.8946 13.5485 3.7279 13.5485C3.63072 13.5485 3.53084 13.5429 3.43636 13.5616C3.10164 13.6282 2.87218 13.9384 2.89018 14.2871C2.90908 14.6452 3.17812 14.9283 3.52904 14.9498C3.66312 14.9583 3.79809 14.9508 3.94836 14.9508V18.6768C1.68984 18.9701 0.116071 17.266 0.0278902 15.471C-0.0215992 14.4662 0.00539503 13.4566 0.00539503 12.449C0.00539503 11.387 -0.0215992 10.3231 0.0296899 9.26292C0.113372 7.53912 1.56566 6.10591 3.22311 6.05061C3.36528 6.04592 3.50835 6.04779 3.65052 6.04779C8.95219 6.04779 14.253 6.04779 19.5546 6.04779C21.2823 6.04779 22.6509 7.23917 22.9514 9.00796C22.9874 9.22167 22.9946 9.44383 22.9946 9.66129C22.9982 11.4732 23.0018 13.286 22.9946 15.0979C22.9883 16.8742 21.9103 18.2859 20.2799 18.6261C19.8732 18.7114 19.4476 18.6974 19.0012 18.7311L19.003 18.733ZM4.63221 10.4543C4.98403 10.4543 5.33676 10.458 5.68858 10.4534C6.0926 10.4477 6.38594 10.1553 6.39133 9.76159C6.39673 9.35853 6.0989 9.05295 5.68409 9.05108C4.98673 9.04733 4.29028 9.04733 3.59293 9.05108C3.18892 9.05389 2.89558 9.34822 2.89018 9.74284C2.88478 10.1459 3.18352 10.4496 3.59743 10.4543C3.94206 10.459 4.28668 10.4543 4.63131 10.4543H4.63221Z" fill={fill}/>
			<path d="M5.31525 14.9658H17.6336C17.6372 15.0483 17.6435 15.1308 17.6435 15.2133C17.6435 17.6185 17.6444 20.0237 17.6435 22.429C17.6435 23.4357 17.1018 24 16.1426 24C13.032 24 9.92047 24 6.80983 24C5.86414 24 5.31615 23.4329 5.31525 22.4468C5.31345 20.0341 5.31525 17.6204 5.31525 15.2076C5.31525 15.1308 5.31525 15.0548 5.31525 14.9658ZM11.4888 16.548C10.9121 16.548 10.3344 16.5471 9.75761 16.548C9.3167 16.549 9.01436 16.8311 9.00897 17.2398C9.00357 17.6579 9.3104 17.9513 9.76121 17.9522C10.9076 17.9531 12.0548 17.9541 13.2012 17.9522C13.6421 17.9522 13.9435 17.6701 13.9489 17.2595C13.9543 16.8405 13.6484 16.549 13.1967 16.548C12.6271 16.5462 12.0575 16.548 11.4879 16.548H11.4888ZM11.4618 20.9527C12.0467 20.9527 12.6307 20.9545 13.2156 20.9527C13.6502 20.9508 13.9525 20.6565 13.9498 20.2459C13.9471 19.8353 13.6457 19.5494 13.2066 19.5485C12.0521 19.5466 10.8986 19.5466 9.74411 19.5485C9.3104 19.5485 9.00717 19.8447 9.00987 20.2553C9.01256 20.6658 9.3149 20.9508 9.75311 20.9527C10.3227 20.9545 10.8923 20.9527 11.4618 20.9527Z" fill={fill}/>
			<path d="M3.99429 4.63426C3.68295 2.96577 4.29932 1.35634 5.56355 0.532402C6.08994 0.190268 6.66582 0.00560908 7.28669 0.00467172C10.0833 0.000922311 12.879 -0.00563916 15.6756 0.00654643C17.4194 0.0140453 18.8708 1.45851 18.977 3.27697C19.0031 3.72596 18.9806 4.17871 18.9806 4.6352H3.99429V4.63426Z" fill={fill}/>
		</g>
		<defs>
			<clipPath id="clip0_465_3138">
				<rect width="23" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>

);

export default PrintPreviewIcon