const SunIcon = ({
  fill,
  width,
  height,
  ...props
}: { fill?: string; width?: number; height?: number } & Record<
  string,
  number | string
>) => (
  <svg
    width={width ?? '25'}
    height={height ?? '24'}
    viewBox="0 0 25 24"
    fill={fill ?? 'none'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0911 6.00091C15.3024 6.03431 18.0168 8.71528 18.0063 12.0164C17.998 15.3091 15.2523 18.1007 11.8134 18.0005C8.66262 17.9107 5.97749 15.259 6.00463 11.9621C6.03177 8.55659 8.88186 5.93618 12.0911 5.99882V6.00091Z"
      fill="white"
    />
    <path
      d="M13.1622 21.9155C13.1622 22.2704 13.1935 22.6296 13.1559 22.9783C13.0891 23.59 12.5629 24.0202 11.9595 23.9993C11.3874 23.9805 10.8675 23.4961 10.8487 22.9031C10.8257 22.2099 10.8299 21.5146 10.8612 20.8214C10.8863 20.2451 11.4041 19.7878 11.9783 19.7816C12.565 19.7753 13.1037 20.22 13.1518 20.7921C13.1831 21.1638 13.158 21.5396 13.158 21.9155C13.1601 21.9155 13.1622 21.9155 13.1643 21.9155H13.1622Z"
      fill="white"
    />
    <path
      d="M13.1768 2.1298C13.1768 2.43882 13.181 2.74784 13.1768 3.05477C13.1643 3.7271 12.6381 4.2303 11.9679 4.21986C11.3979 4.21151 10.8675 3.71249 10.8487 3.11532C10.8278 2.42838 10.832 1.74144 10.8633 1.05449C10.8863 0.461506 11.3999 0.00632696 11.9888 6.3026e-05C12.5629 -0.0062009 13.1016 0.455242 13.1497 1.02944C13.1789 1.39274 13.1559 1.76232 13.1559 2.1298C13.1622 2.1298 13.1685 2.1298 13.1747 2.1298H13.1768Z"
      fill="white"
    />
    <path
      d="M21.9067 13.1543C21.5434 13.1543 21.178 13.1794 20.8188 13.1481C20.278 13.1021 19.8563 12.6616 19.7894 12.1249C19.7268 11.6217 20.0379 11.1123 20.539 10.9264C20.6852 10.8722 20.8501 10.8408 21.0067 10.8388C21.6081 10.8283 22.2094 10.8283 22.8108 10.8346C23.4768 10.8408 24.0134 11.3816 24.0051 12.0247C23.9988 12.6449 23.4893 13.1522 22.8442 13.171C22.531 13.1794 22.2199 13.171 21.9067 13.171C21.9067 13.1648 21.9067 13.1606 21.9067 13.1543Z"
      fill="white"
    />
    <path
      d="M2.12516 13.1543C1.75768 13.1543 1.39019 13.1794 1.0248 13.1481C0.481921 13.1 0.066414 12.6532 0.00586264 12.102C-0.0484248 11.605 0.279388 11.0893 0.778414 10.9181C0.935012 10.8659 1.10623 10.8367 1.26909 10.8346C1.8579 10.8241 2.4488 10.8262 3.03761 10.8304C3.67235 10.8367 4.21522 11.3649 4.22149 11.9725C4.22775 12.6198 3.7162 13.1501 3.06057 13.1668C2.74946 13.1752 2.43627 13.1668 2.12307 13.1668C2.12307 13.1627 2.12307 13.1564 2.12307 13.1522L2.12516 13.1543Z"
      fill="white"
    />
    <path
      d="M20.823 19.7711C20.8021 20.1344 20.585 20.4789 20.1757 20.694C19.7748 20.9028 19.3739 20.8673 19.0169 20.6063C18.7789 20.433 18.5722 20.2138 18.3613 20.005C18.0773 19.7252 17.8017 19.437 17.5261 19.151C17.3068 18.9234 17.1586 18.6561 17.169 18.3367C17.1857 17.8314 17.4384 17.4556 17.8977 17.253C18.3383 17.0588 18.7622 17.1361 19.1109 17.4723C19.5869 17.9295 20.0484 18.4014 20.5098 18.8733C20.729 19.0967 20.8251 19.3702 20.8251 19.7711H20.823Z"
      fill="white"
    />
    <path
      d="M19.7247 3.18214C20.1653 3.2051 20.5181 3.43896 20.7165 3.87743C20.9169 4.32635 20.8501 4.75856 20.514 5.1156C20.0588 5.59793 19.5806 6.05937 19.1108 6.52708C19.0586 6.57928 18.996 6.62103 18.9334 6.66071C18.1817 7.13468 17.3444 6.62103 17.1857 5.87354C17.1064 5.49979 17.2296 5.17407 17.478 4.90472C17.7975 4.55811 18.1378 4.22821 18.4698 3.89414C18.616 3.74798 18.7601 3.59973 18.9146 3.46401C19.1317 3.27401 19.3906 3.18631 19.7247 3.18422V3.18214Z"
      fill="white"
    />
    <path
      d="M3.18794 4.25745C3.21091 3.85865 3.43432 3.49743 3.87071 3.2949C4.3071 3.09236 4.73931 3.14456 5.09009 3.47029C5.58703 3.93173 6.0777 4.4057 6.53288 4.9089C7.20521 5.65013 6.69574 6.5772 6.01089 6.78599C5.61417 6.90918 5.23625 6.84863 4.93349 6.55632C4.44281 6.08234 3.96258 5.59585 3.48652 5.10726C3.28399 4.89846 3.18794 4.63538 3.18585 4.25745H3.18794Z"
      fill="white"
    />
    <path
      d="M4.25699 20.8172C3.85192 20.7859 3.48862 20.5625 3.29026 20.1156C3.09399 19.6751 3.16289 19.2512 3.48862 18.8983C3.8394 18.5162 4.21732 18.1592 4.58272 17.7896C4.66415 17.7061 4.74349 17.6205 4.82701 17.537C5.18614 17.1758 5.61209 17.0526 6.08815 17.2426C6.56003 17.4284 6.8085 17.8084 6.84191 18.3075C6.86279 18.6019 6.75212 18.8754 6.5475 19.0863C6.07353 19.5728 5.5933 20.053 5.1068 20.527C4.898 20.7316 4.61821 20.8235 4.25699 20.8172Z"
      fill="white"
    />
  </svg>
);

export default SunIcon;
