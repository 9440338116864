import Logo from '/images/light-logo.png';
import Typography from '@mui/material/Typography';
import {Button} from '@mui/material';
import {useTranslation} from 'react-i18next';

export default function ErrorBoundaryPage() {
	const { t } = useTranslation()
	return (
		<div className='ErrorBoundayContainer'>
			<img src={Logo} alt='DariLogo' />
			<Typography variant='h4' color='textSecondary'>
				{t('oops_something_went_wrong')}
			</Typography>
			<Button variant='contained' color='secondary' component='a' href='/'>
				{t('Go_to_home_page')}
			</Button>
		</div>
	);
}
