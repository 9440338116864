import { Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiService } from '../../services/ApiService';
import { apiEndPoints } from '../../api';
import { getRefactoredResultData } from '../helper';
import { openToast } from './toast';

export const getProjectResultDetails = createAsyncThunk(
  'projectResut/getResultDetails',
  async (
    projectId: number,
    { dispatch, getState }: { dispatch: Dispatch; getState: any }
  ) => {
    const businessLayoutModels = getState().project?.businessLayoutModels.map(
      ({ name }: { name: string }) => name
    );
    const { data } = await apiService
      .api()
      .post(apiEndPoints.result.getResults + projectId)
      .then(({ data }) => data)
      .catch(({ response }) => {
        dispatch(
          openToast({
            message: response.data.message,
            mode: 'error',
          })
        );
      });

    return { ...data, businessLayoutModels };
  }
);

const projectResultSlice = createSlice({
  name: 'projectResult',
  initialState: { isloader: false },
  reducers: {
    setProjectResult: (resultState, { payload }) => {
      Object.assign(resultState, payload);
    },
    resetProjectResult: () => ({ isloader: false }),
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProjectResultDetails.pending, (state) => {
        state.isloader = true;
      })
      .addCase(getProjectResultDetails.fulfilled, (state, { payload }) => {
        state.isloader = false;

        const refactoredResultData = getRefactoredResultData(payload);

        Object.assign(state, refactoredResultData);
      })
      .addCase(getProjectResultDetails.rejected, (state, { payload }) => {
        console.log(state, payload);
        state.isloader = false;
      });
  },
});

export const { setProjectResult, resetProjectResult } =
  projectResultSlice.actions;

export default projectResultSlice.reducer;
