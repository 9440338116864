import './assets/style/main.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SettingsTypes } from './types/reduxTypes.ts';
import { ROUTES } from './constants/constants.ts';
import useTokenManagement from './hooks/useTokenManagement.ts';
import ConditionalPathWrapper from './components/HOC/ConditinalPathComponent.tsx';
import Header from './components/blocks/Header.tsx';
import Footer from './components/blocks/Footer.tsx';
import useCustomNavigate from './hooks/useCustomNavigate.tsx';
import MainLoader from './components/blocks/MainLoader.tsx';
import {
  BarChartsResult,
  BusinessModel,
  EnergyLayout,
  FlowGraph,
  ForgetPassword,
  GridFlow,
  LineChartsResult,
  LoadFlow,
  Login,
  Mobility,
  NotFound,
  PieResult,
  PrintReview,
  Profile,
  ProjectData,
  ProjectOverview,
  PvSystem,
  Results,
  SampleLoadProfile,
  Start,
  TableResults,
  UploadAndGenerateLoadProfile,
} from './LazyImports.ts';
import { scrollToTop } from './utils/misc.ts';
import FinancialAnalysis from './components/pages/Results/FinancialAnalysis';
import Simulation from './components/pages/Results/Simulation';
import AIPoweredSummary from './components/pages/Results/AiPoweredSummary';
import OverviewResult from './components/pages/Results/OverviewResult';
import PvSystemEnergyBalance from './components/pages/Results/PvSystemEnergyBalance';
import EnergyPricesAnalysisResult from './components/pages/Results/EnergyPricesAnalysis/EnergyPricesAnalysisResult.tsx';
import StorageFlow from './components/pages/EnergyLayout/components/StorageFlow/index.tsx';

function App() {
  const { pathname } = useLocation();
  const { isDarkMode, isProjectDetailsOpen } = useSelector(
    ({ settings }: { settings: SettingsTypes }) => settings
  );

  const { isTokenExpired, scheduleTokenRefresh, refreshToken } =
    useTokenManagement();

  const { redirectToLogin } = useCustomNavigate();
  const accessToken = localStorage.getItem('ETAccessToken');

  useEffect(() => {
    document
      .querySelector('body')
      ?.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, []);

  useEffect(() => {
    if (!accessToken || accessToken === 'undefined') {
      return redirectToLogin();
    }

    const accessTokenExpired = isTokenExpired();
    if (accessTokenExpired) {
      // If expired, refresh the token
      refreshToken();
    } else {
      // If not expired, schedule the next refresh
      scheduleTokenRefresh();
    }
  }, [accessToken]);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);
  return (
    <div>
      <ConditionalPathWrapper
        paths={[ROUTES.LOGIN, ROUTES.FORGET_PASSWORD]}
        currentPath={pathname}
      >
        <Header />
      </ConditionalPathWrapper>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />
          <Route path={ROUTES.START} element={<Start />} />
          <Route
            path={ROUTES.PROJECT_DATA}
            element={
              <ProjectData isProjectDetailsOpen={isProjectDetailsOpen} />
            }
          />
          <Route
            path={ROUTES.BUSINESS_MODEL}
            element={
              <BusinessModel isProjectDetailsOpen={isProjectDetailsOpen} />
            }
          />
          <Route
            path={ROUTES.PROJECT_OVERVIEW}
            element={
              <ProjectOverview isProjectDetailsOpen={isProjectDetailsOpen} />
            }
          />

          <Route path={ROUTES.ENERGY_LAYOUT}>
            <Route
              index
              element={
                <EnergyLayout isProjectDetailsOpen={isProjectDetailsOpen} />
              }
            />
            <Route
              path={ROUTES.GRID_FLOW}
              element={<GridFlow isProjectDetailsOpen={isProjectDetailsOpen} />}
            />
            <Route
              path={ROUTES.STORAGE_FLOW}
              element={
                <StorageFlow isProjectDetailsOpen={isProjectDetailsOpen} />
              }
            />
            <Route
              path={ROUTES.PV_SYSTEM}
              element={<PvSystem isProjectDetailsOpen={isProjectDetailsOpen} />}
            />

            <Route path={ROUTES.GENERATE_FLOW} element={<PieResult />} />
            <Route path={ROUTES.WIND_FLOW} element={<PieResult />} />
            <Route
              path={ROUTES.MOBILITY_FLOW}
              element={<Mobility isProjectDetailsOpen={isProjectDetailsOpen} />}
            />
            {/* load flow routes */}
            <Route
              path={ROUTES.LOAD_FLOW}
              element={<LoadFlow isProjectDetailsOpen={isProjectDetailsOpen} />}
            />
            <Route
              path={ROUTES.LOAD_FLOW_UPLOAD_PROFILE}
              element={
                <UploadAndGenerateLoadProfile
                  isProjectDetailsOpen={isProjectDetailsOpen}
                />
              }
            />
            <Route
              path={ROUTES.LOAD_FLOW_GENERATE_PROFILE}
              element={
                <UploadAndGenerateLoadProfile
                  isProjectDetailsOpen={isProjectDetailsOpen}
                />
              }
            />
            <Route
              path={ROUTES.LOAD_FLOW_SAMPLE_PROFILE}
              element={
                <SampleLoadProfile
                  isProjectDetailsOpen={isProjectDetailsOpen}
                />
              }
            />
          </Route>

          <Route path={ROUTES.RESULTS} element={<Results />}>
            <Route path={ROUTES.RESULTS_PIE_INFO} element={<PieResult />} />
            <Route path={ROUTES.RESULTS_FLOW_GRAPH} element={<FlowGraph />} />
            <Route
              path={ROUTES.RESULTS_LINE_CHARTS_RESULT}
              element={<LineChartsResult />}
            />
            <Route
              path={ROUTES.RESULTS_BAR_CHARTS_RESULT}
              element={<BarChartsResult />}
            />
            <Route
              path={ROUTES.RESULTS_TABLE_RESULTS}
              element={<TableResults />}
            />
            <Route
              path={ROUTES.RESULTS_FINANCIAL_ANALYSIS}
              element={<FinancialAnalysis />}
            />
            <Route
              path={ROUTES.RESULTS_OVERVIEW}
              element={<OverviewResult />}
            />
            <Route path={ROUTES.RESULTS_SIMULATION} element={<Simulation />} />
            <Route
              path={ROUTES.RESULTS_AI_POWERED_SUMMARY}
              element={<AIPoweredSummary />}
            />
            <Route
              path={ROUTES.RESULTS_PV_SYSTEM_ENERGY_BALANCE}
              element={<PvSystemEnergyBalance />}
            />
            <Route
              path={ROUTES.ENERGY_PRICES_ANALYSIS}
              element={<EnergyPricesAnalysisResult />} // ENERGY_PRICES_ANALYSIS
            />
          </Route>

          <Route path={ROUTES.PRINT_PREVIEW} element={<PrintReview />} />

          <Route path={ROUTES.PROFILE} element={<Profile />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
      <ConditionalPathWrapper
        paths={[ROUTES.LOGIN, ROUTES.FORGET_PASSWORD]}
        currentPath={pathname}
      >
        <Footer />
      </ConditionalPathWrapper>
    </div>
  );
}

export default App;
