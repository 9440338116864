import { apiEndPoints } from '../api';
import { handleResponse } from '../utils/misc';
import { apiService } from './ApiService';

class AuthService {
  baseURL = import.meta.env.VITE_API_ENDPOINT as string;

  logout() {
    window.localStorage.clear();
    window.location.href = '/login';
  }

  async login(guestData: { username: string; password: string }) {
    const endpoint = apiEndPoints.auth.login;
    const { success, data } = await apiService.makeApiRequest(
      'post',
      endpoint,
      guestData
    );
    return handleResponse({ success, ...data });
  }

  async refreshToken(refreshToken: string) {
    const endpoint = apiEndPoints.auth.refreshToken;
    const { success, data } = await apiService.makeApiRequest(
      'post',
      endpoint,
      { refreshToken }
    );
    return handleResponse({ success, ...data });
  }

  async forgetPassword(email: any) {
    const endpoint = apiEndPoints.auth.login;
    const { success, data } = await apiService.makeApiRequest(
      'post',
      endpoint,
      email
    );
    return handleResponse({ success, ...data });
  }

  /// Todo remove these
  // async downloadExcelFile2(endPoint, userData) {
  //   const apiUrl = this.baseURL + endPoint;

  //   try {
  //     const response = await axios.post(apiUrl, userData, {
  //       headers: {
  //         Accept: 'application/json',
  //         'Accept-Language': 'en-US,en',
  //         'Access-Control-Allow-Origin': '*',
  //         Authorization:
  //           apiService.accessToken !== 0 ? apiService.accessToken : '',
  //         Connection: 'keep-alive',
  //         'Content-Type': 'application/json',
  //         Language: '1',
  //         Origin: 'http://127.0.0.1:5173',
  //         Referer: 'http://127.0.0.1:5173/',
  //         'Sec-GPC': '1',
  //         'User-Agent':
  //           'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
  //       },
  //       responseType: 'blob', // Specify the response type as 'blob' to handle binary data
  //     });

  //     // Create a download link and trigger the download
  //     const downloadLink = document.createElement('a');
  //     downloadLink.href = window.URL.createObjectURL(response.data);
  //     downloadLink.download = 'ProfileData.xlsx';
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  //   } catch (error) {
  //     console.error('Error downloading Excel file:', error);
  //   }
  // }

  // async downloadExcelFile(endPint, userData) {
  //   const apiUrl = this.baseURL + endPint;

  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Accept-Language': 'en-US,en',
  //         'Access-Control-Allow-Origin': '*',
  //         Authorization:
  //           apiService.accessToken !== 0 ? apiService.accessToken : '',
  //         Connection: 'keep-alive',
  //         'Content-Type': 'application/json',
  //         Language: '1',
  //         Origin: 'http://127.0.0.1:5173',
  //         Referer: 'http://127.0.0.1:5173/',
  //         'Sec-GPC': '1',
  //         'User-Agent':
  //           'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/118.0.0.0 Safari/537.36',
  //       },
  //       body: JSON.stringify(userData),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     const blob = await response.blob();
  //     // Create a download link and trigger the download
  //     const downloadLink = document.createElement('a');
  //     downloadLink.href = window.URL.createObjectURL(blob);
  //     downloadLink.download = 'ProfileData.xlsx';
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //     document.body.removeChild(downloadLink);
  //   } catch (error) {
  //     console.error('Error downloading Excel file:', error);
  //   }
  // }

  // async getOtp(userData) {
  //     try {
  //         const data = await apiService
  //             .api()
  //             .post(apiEndPoints.auth.getOtp, userData)
  //             .then(({ data }) => data);
  //         return handleResponse({ success: true, ...data });
  //     } catch ({ response }) {
  //         return handleResponse({ success: false, ...response?.data });
  //     }
  // }

  // async verifyPhoneOTP(optData) {
  //     try {
  //         const data = await apiService
  //             .api()
  //             .post(apiEndPoints.auth.verifyPhoneOTP, optData)
  //             .then(({ data }) => data);

  //         return handleResponse({ success: Boolean(data.data), ...data });
  //     } catch ({ response }) {
  //         return handleResponse({ success: false, ...response?.data });
  //     }
  // }

  //   async login(guestData: { username: string; password: string }) {
  //     try {
  //       const data = await apiService
  //         .api()
  //         .post(apiEndPoints.auth.login, guestData)
  //         .then(({ data }) => data);

  //       console.log({ data }, 'from');

  //       return handleResponse({ success: true, ...data });
  //     } catch ({ response }: { response: ErrorResponseAPI } | any) {
  //       return handleResponse({ success: false, ...response?.data });
  //     }
  //   }
}

export const authService = new AuthService();
