import { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import { Stack } from '@mui/system';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { thousandMillionNumberFormatter } from '../../../../utils';
interface BarChartProps {
  data: {
    name: string;
    value?: number;
    color?: string;
    [key: string]: any;
  }[];
  className?: string;
  barSize?: number;
  width?: number;
  height?: number | string;
  dataKeys: Array<{ key: string; color: string; label: string }>;
  title?: string;
  withLegend?: boolean;
  withTabs?: boolean;
  yAxisLabel?: string;
  yAxisDigits?: number;
}

const ApexBarChart = ({
  data,
  dataKeys,
  title = '',
  //   className = '',
  barSize = 25,
  //   width,
  height = 350,
  yAxisLabel = '',
  withLegend = true,
  withTabs = true,
  yAxisDigits = 0,
}: BarChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [selectedSubTab, setSelectedSubTab] = useState<string>('grouped');
  const isGrouped = selectedSubTab === 'grouped';
  const isStacked = selectedSubTab === 'stacked';

  useEffect(() => {
    // check if data item have specific color for each bar
    const isDataItemHaveColor = data[0]?.color ? true : false;
    // prepare data for chart
    const dataReadyToUse = dataKeys.map(({ label, key, color }) => {
      return {
        name: label,
        key,
        color: isDataItemHaveColor ? undefined : color,
        data: [],
      };
    });
    // fill data for each bar
    dataReadyToUse.forEach((item) => {
      data.forEach((element: any) => {
        //@ts-ignore
        item.data.push(element[item.key]);
      });
    });

    const options: ApexCharts.ApexOptions = {
      title: {
        text: title ?? '',
        align: 'center',
      },
      series: dataReadyToUse,
      chart: {
        type: 'bar',
        height: height,
        stacked: isStacked,
      },
      colors: data.map(({ color }) => color),
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: isDataItemHaveColor ? true : false,
          ...(dataReadyToUse.length > 3
            ? {}
            : {
                columnWidth: barSize,
              }),
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: data.map(({ name }) => name),
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          color: '#78909C',
          //   width: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        show: true,
        showAlways: false,
        showForNullSeries: true,
        seriesName: undefined,
        opposite: false,
        reversed: false,
        logarithmic: false,
        logBase: 10,
        tickAmount: undefined,
        min: undefined,
        max: undefined,
        stepSize: undefined,
        forceNiceScale: false,
        floating: false,
        decimalsInFloat: 0,
        // yAxis title settings
        title: {
          text: yAxisLabel,
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-title',
          },
        },
        // yAxis number settings
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [],
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => {
            return thousandMillionNumberFormatter(value, yAxisDigits);
          },
        },
        axisBorder: {
          show: true,
          color: '#78909C',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          color: '#78909C',
          width: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      fill: {
        opacity: 1,
      },

      tooltip: {
        // y: {
        //   formatter: function (val: number) {
        //     return '$ ' + val + ' thousands';
        //   },
        // },
        theme: 'dark',
      },
      legend: {
        show: withLegend,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        // formatter: (legendName, apts) => {
        //   console.log({ legendName, apts });
        //   return legendName.toUpperCase();
        // },
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
          colors: undefined,
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    };

    const chart = new ApexCharts(chartRef.current!, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, selectedSubTab]);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        mb={2}
        alignItems="center"
        justifyContent={'end'}
      >
        {/* <Typography
          variant={'inherit'}
          width={'fit-content'}
          margin={'auto !important'}
          marginBottom={'-20px !important'}
        >
          {title}
        </Typography> */}
        {withTabs && (
          <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
            <Chip
              label={t('Grouped_bar_chart')}
              variant={isGrouped ? 'filled' : 'outlined'}
              color={isGrouped ? 'primary' : 'default'}
              onClick={() => {
                setSelectedSubTab('grouped');
              }}
            />
            <Chip
              label={t('Stacked_bar_chart')}
              variant={isStacked ? 'filled' : 'outlined'}
              color={isStacked ? 'primary' : 'default'}
              onClick={() => {
                setSelectedSubTab('stacked');
              }}
            />
          </Stack>
        )}
      </Stack>

      <div id="chart" ref={chartRef}></div>
    </>
  );
};

export default ApexBarChart;
