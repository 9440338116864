import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FinancialAnalysisMain from './FinancialAnalysisMain.tsx';
import CashFlowTables from './CashFlowTables.tsx';
import AccruedCashFlow from './AccruedCashFlow.tsx';
import LoceAndSystemUtilitizationCost from './LoceAndSystemUtilitizationCost.tsx';
import {
  getCostReductionGridPVData,
  getFinancialAnalysisMainData,
  getFinancialCashFlowData,
  getFinancialLoceAndSystemUtilitizationCost,
} from './helper/helper.ts';

const FinancialAnalysis = () => {
  const { type } = useParams<{ id: string; type: string }>();
  const { financialData = {} } = useSelector(
    ({ projectResult }: any) => projectResult
  );

  const { project } = useSelector(
    ({ project }: { project: Record<string, any> }) => ({
      project,
    })
  );

  const components: Record<string, React.ComponentType<any>> = {
    FINANCIAL_ANALYSIS: FinancialAnalysisMain,
    CASH_FLOW_TABLES: CashFlowTables,
    ACCURED_CASH_FLOW_CASH_BALANCE: AccruedCashFlow,
    LOCE_SYSTEM_UTILIZATION_COSTS: LoceAndSystemUtilitizationCost,
  };
  const FinancialAnalysisMainData = getFinancialAnalysisMainData(
    financialData,
    project.currency
  );
  const FinancialCashFlowData = getFinancialCashFlowData(financialData);
  const CostReductionGridPVData = getCostReductionGridPVData(financialData);
  const FinancialLoceAndSystemUrilitizationCostData =
    getFinancialLoceAndSystemUtilitizationCost(financialData);

  const data: Record<string, any> = {
    FINANCIAL_ANALYSIS: FinancialAnalysisMainData,
    CASH_FLOW_TABLES: FinancialCashFlowData,
    ACCURED_CASH_FLOW_CASH_BALANCE: CostReductionGridPVData,
    LOCE_SYSTEM_UTILIZATION_COSTS: FinancialLoceAndSystemUrilitizationCostData,
  };

  const FinancialComponent = type ? components?.[type] : null;
  const FinancialDataComponent = type ? data?.[type] : null;

  return (
    <>
      {FinancialComponent && (
        <FinancialComponent data={FinancialDataComponent} />
      )}
    </>
  );
};

export default FinancialAnalysis;
