import { ReactNode } from 'react';

const ConditionalPathWrapper = ({
  paths,
  currentPath,
  children,
}: {
  paths: string[];
  currentPath: string;
  children: ReactNode;
}) => {
  if (!paths.includes(currentPath)) {
    return <>{children}</>;
  }
  return null;
};

export default ConditionalPathWrapper;
