import { createSlice } from "@reduxjs/toolkit";


const settingsSlice = createSlice({
	name: "settings",
	initialState: { isDarkMode: false, isProjectDetailsOpen: false },
	reducers: {
		setSettings: (setting, { payload }) => {
			Object.assign(setting, payload);
		},
	},
});

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;