import { Stack, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataKeysType, ReportDataType } from '../ResultTypes';
import { useRef } from 'react';
import PrintButton from '../PrintButton';
import ApexBarChart from '../../../blocks/charts/BarChart2/ApexBarChart';

type EnergyForcastWithConsumptionPropsType = {
  data: {
    electricLoad: Omit<ReportDataType, 'chartData'>;
    energyForcastWithConsumptionWithDataKeys: {
      dataKeys: DataKeysType;
      data: Array<Record<string, string>>;
    };
  } | null;
};
const EnergyForcastWithConsumption = ({
  data = null,
}: EnergyForcastWithConsumptionPropsType) => {
  const { t } = useTranslation();
  const { electricLoad, energyForcastWithConsumptionWithDataKeys = null } =
    data || {};

  const ref = useRef<HTMLElement | null>(null);

  return (
    <>
      <PrintButton
        id={'ENERGY_FORCAST_WITH_CONSUMPTION'}
        componentName="Simulation"
        element={ref}
      />
      <Box m={'25px'} p={'25px'} className="results__info" ref={ref}>
        <h2>Energy Forcast With Consumption</h2>
        <Box py={6}>
          <Typography variant={'h6'}>{electricLoad?.title}</Typography>

          {electricLoad?.items?.map(({ name, value, unit }) => (
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              key={Math.random()}
              mb={0.5}
              // maxWidth={'98%'}
            >
              <Typography className="label">{t(name)}: </Typography>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'end'}
                minWidth={150}
                gap={0}
              >
                <Typography>{value}</Typography>
                {unit && <Typography width={'45px'}>{unit}</Typography>}
              </Stack>
            </Stack>
          ))}
        </Box>
        <ApexBarChart
          data={energyForcastWithConsumptionWithDataKeys?.data as any}
          title={'Energy Forcast With Consumption'}
          height={400}
          withTabs={false}
          dataKeys={
            energyForcastWithConsumptionWithDataKeys?.dataKeys as DataKeysType
          }
          yAxisLabel="Energy (kWh)"
        />
      </Box>
    </>
  );
};

export default EnergyForcastWithConsumption;
